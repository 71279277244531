import Vue from 'vue';
import VueI18n from 'vue-i18n';
import axios from 'axios';
import {TLangRequest} from '@/i18n/types/locales.type.ts';
import UtilsHelper from '@/_helpers/utils.helper';

Vue.use(VueI18n);

export const APP_AVAILABLE_LANGUAGES: string[] = ['en', 'uk', 'de', 'lt', 'zh', 'es', 'vi', 'bg', 'sq', 'sr', 'ru'];

function getPathLanguage(): string {
  const path: string = window.location.pathname.replace(/\/+/g, '/');
  const pathParts: string[] = path.split('/');
  const pathLang: string = pathParts[1];
  if (!pathLang || (APP_AVAILABLE_LANGUAGES.indexOf(pathLang) === -1)) {
    return null;
  }
  return pathLang;
}

const PATH_LANGUAGE: string = getPathLanguage();
const LANG_CODE = process.env.VUE_APP_I18N_FALLBACK_LOCALE || PATH_LANGUAGE || 'uk';

if (!PATH_LANGUAGE) {
  const wl = window.location;
  window.location.replace(wl.protocol + '//' + wl.host + '/' + LANG_CODE + wl.pathname + wl.search + wl.hash);
}

const i18n = new VueI18n({
  locale: LANG_CODE,
  fallbackLocale: LANG_CODE,
  messages: null,
});

localStorage.setItem('isTranslationsEmpty', 'true');
window.dispatchEvent(new CustomEvent('isTranslationsEmpty-localstorage-changed', {
  detail: {
    storage: localStorage.getItem('isTranslationsEmpty')
  }
}));

async function loadLocaleMessages(): Promise<void> {

  const response = await axios.request<TLangRequest>({
    url: `${process.env.VUE_APP_API_BASE_URL}/interface_translation/list`,
    responseType: 'json',
    method: 'GET',
    headers: {
      Accept: 'application/json; odata=verbose'
    },
    params: {platform: 'web', lang: LANG_CODE}
  });

  const parsedTranslations = await UtilsHelper.flatJsonToNestedObj(response.data);
  await i18n.setLocaleMessage(LANG_CODE, parsedTranslations);

  if (response.status === 200) {
    localStorage.setItem('isTranslationsEmpty', 'false');
    window.dispatchEvent(new CustomEvent('isTranslationsEmpty-localstorage-changed', {
      detail: {
        storage: localStorage.getItem('isTranslationsEmpty')
      }
    }));
  }
}

loadLocaleMessages();

export default i18n;
