
import {TUser} from '@/_types/user.type';

export default class UserHelper {

  public static responseToUserInfoConverter(responseObj: any): TUser {
    if(!responseObj) return null;
    return Object.assign({}, responseObj);
  }

}
