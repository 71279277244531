


import {Vue, Component, Watch} from 'vue-property-decorator';
import _cloneDeep from 'lodash.clonedeep';
import loginApi, {TLoginByTokenResponse} from '@/_api/login/login.api';
import CookiesHelper from '@/_helpers/cookies-helper';
import { Getter, Action } from 'vuex-class';
import simplePopup from '@/_modules/controls/components/simple-popup/simple-popup.vue';

@Component({
  components: {
    simplePopup,
  }
})
export default class PersonalCodeAcceptor extends Vue {

  @Getter('authStore/isAuthenticated') isAuthenticated: boolean;
  @Getter('authStore/isLoading') isAuthLoading: boolean;
  @Action('authStore/authLogout') authLogout: any;

  public isCodeNotFound: boolean = false;
  public isCodeInvalidPopupAllowed: boolean = false;
  public isAlreadySignedInPopupAllowed: boolean = false;

  public get code(): string {
    // Route.query.code can be an array. We will only accept it if it is a string.
    if (this.$route.query && this.$route.query.code && !Array.isArray(this.$route.query.code)) {
      return this.$route.query.code as string;
    }
    return '';
  }

  public get deviceId(): string {
    return this.$store.state.authStore.deviceId;
  }

  public get isCodeValid(): boolean {
    const uuidRegex = /^[\dabcdef]{8}-[\dabcdef]{4}-[\dabcdef]{4}-[\dabcdef]{4}-[\dabcdef]{12}$/;
    return uuidRegex.test(this.code);
  }

  public get isCodeInvalidPopupVisible(): boolean {
    return (!this.isCodeValid && this.isCodeInvalidPopupAllowed)
    ||
    (this.isCodeNotFound && this.isCodeInvalidPopupAllowed);
  }

  public get isAlreadySignedInPopupVisible(): boolean {
    return this.isAuthenticated && this.isAlreadySignedInPopupAllowed;
  }

  @Watch('code', {immediate: true})
  private onCodeChange(): void {
    this.isCodeNotFound = false;
    if (this.isCodeValid) {
      this.attemptToLoginByToken();
    }
  }

  @Watch('isCodeValid', {immediate: true})
  private onIsCodeValidChange(): void {
    // nextTick is needed for the fade-popup transition.
    this.$nextTick(() => {
      this.isCodeInvalidPopupAllowed = !this.isCodeValid;
    });
  }

  @Watch('isAuthenticated', {immediate: true})
  private onIsAuthenticatedChange(): void {
    // nextTick is needed for the fade-popup transition.
    this.$nextTick(() => {
      this.isAlreadySignedInPopupAllowed = (this.isCodeValid && this.isAuthenticated);
    });
  }

  // TODO: move some parts to auth store
  private async attemptToLoginByToken(): Promise<void> {
    try {
      const attemptResult: TLoginByTokenResponse = await loginApi.loginByToken({
        token: this.code.trim(),
        platform: 'WEB',
        device_id: this.deviceId,
      });

      if (attemptResult && attemptResult.token) {
        const AUTH_COOKIE_MAX_AGE = 60 * 60 * 24 * 30;
        CookiesHelper.createCookie({name: 'user-token', value: attemptResult.token, maxAge: AUTH_COOKIE_MAX_AGE});
      }

    } catch (e) {
      // console.log('e.message = ', e.message);
      this.handleLoginAttemptErrors(e.message);
      return;
    }

    this.redirectAfterAttempt();
  }

  private handleLoginAttemptErrors(errorMessage: string = ''): void {
    switch (errorMessage) {
      case 'record not found':
        this.$nextTick(() => {
          this.showCodeIsInvalidPopup();
        });
        break;
      default:
        break;
    }
  }

  private showCodeIsInvalidPopup(): void {
    this.isCodeInvalidPopupAllowed = true;
    this.isCodeNotFound = true;
  }

  private redirectAfterAttempt(): void {
    const newQuery: {[key: string]: string | (string | null)[]} = _cloneDeep(this.$route.query);
    delete newQuery.code;

    window.location.replace(this.$router.resolve({
      name: this.getRouteNameForRedirection(),
      params: _cloneDeep(this.$route.params),
      query: newQuery,
      hash: window.location.hash
    }).href);
  }

  private getRouteNameForRedirection(): string {
    if (!this.isCodeValid || this.isCodeNotFound) {
      return this.$route.name;
    }
    if (this.$route.params.eventId) {
      return 'event-info';
    }
    return 'event-list-my';
  }

  private onCodeInvalidPopupClose(): void {
    this.isCodeInvalidPopupAllowed = false;
    this.doDelayedRedirect();
  }

  private onAlreadySignedInPopupClose(): void {
    this.isAlreadySignedInPopupAllowed = false;
    this.doDelayedRedirect();
  }

  // Timeout to give running time to fadeout transition
  private doDelayedRedirect(): void {
    window.setTimeout(() => {
      this.redirectAfterAttempt();
    }, 400);
  }

  private async onLogoutClick(): Promise<void> {
    await this.authLogout();
    await this.attemptToLoginByToken();
  }
}
