var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ew-password",class:{
    'ew-password-disabled': _vm.disabled,
    'ew-password-focused': _vm.isFocused,
    'ew-password-has-label': _vm.labelText !== '',
    'ew-password-has-error': _vm.errorText !== '',
    'ew-password-full-width': _vm.fullWidth,
  }},[(_vm.labelText)?_c('label',{on:{"click":_vm.onLabelClick}},[_vm._v(" "+_vm._s(_vm.labelText)+" ")]):_vm._e(),_c('div',{staticClass:"ew-password-field-wrap",on:{"click":_vm.onLabelClick}},[_c('input',{ref:"input",staticClass:"ew-password",class:{
        'ew-password-disabled': _vm.disabled
      },attrs:{"type":_vm.inputTypeAttribute,"disabled":_vm.disabled,"placeholder":_vm.placeholderText,"minlength":_vm.minlength,"maxlength":_vm.maxlength,"autocomplete":_vm.autocomplete},domProps:{"value":_vm.value},on:{"focus":function($event){return _vm.onInputFocus()},"blur":function($event){return _vm.onInputBlur()},"input":function($event){return _vm.$emit('input', $event.target.value)},"keyup":function($event){return _vm.$emit('keyup', $event.keyup)}}}),_c('div',{staticClass:"password-eye",on:{"click":_vm.togglePasswordVisibility}},[(_vm.isPasswordVisible)?_c('icon-ew-password-eye-open'):_c('icon-ew-password-eye-closed')],1)]),(_vm.isBottomTextEmpty && !_vm.showActionSlot)?_c('div',{staticClass:"ew-password-bottom-text ew-password-bottom-text-empty"}):_vm._e(),(_vm.helpText !== '' && _vm.errorText === '')?_c('div',{staticClass:"ew-password-bottom-text ew-password-help-text"},[_vm._v(" "+_vm._s(_vm.helpText)+" ")]):_vm._e(),(_vm.showActionSlot)?_c('div',{staticClass:"ew-password-bottom-text ew-password-action-text"},[(_vm.showActionIcon)?_c('IconEwInputQuestion',{staticClass:"icon"}):_vm._e(),_vm._t("action-text")],2):_vm._e(),(_vm.errorText !== '')?_c('div',{staticClass:"ew-password-bottom-text ew-password-error-wrap"},[(_vm.showErrorIcon && !(_vm.errorData && Object.keys(_vm.errorData).length > 0))?_c('icon-ew-input-error',{staticClass:"icon"}):_c('ErrorInfo',{attrs:{"error-data":_vm.errorData}}),_c('div',{staticClass:"error-text"},[_vm._v(_vm._s(_vm.errorText))])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }