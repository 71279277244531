import { render, staticRenderFns } from "./branding-header.html?vue&type=template&id=041a909e&scoped=true&"
import script from "./branding-header.vue?vue&type=script&lang=ts&"
export * from "./branding-header.vue?vue&type=script&lang=ts&"
import style0 from "./branding-header.scss?vue&type=style&index=0&id=041a909e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "041a909e",
  null
  
)

export default component.exports