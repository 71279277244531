export enum DiscoveryMessageType {
  COMPANY_VIDEO_CHAT_UPDATE = 'company-video-chat-update',
  CHAT_GROUP_MESSAGES_PAGE = 'chat-group-messages-page',
  CHAT_MESSAGE = 'chat-message',
  CHAT_MESSAGE_DELETE = 'chat-message-delete',
  CHAT_GROUP_CONTACTS = 'chat-group-contacts',
  CHAT_GROUP_CONTACT_CONNECTED = 'chat-group-contact-connected',
  CHAT_GROUP_CONTACT_DISCONNECTED = 'chat-group-contact-disconnected',
  MEETING_IS_WAITING = 'meeting-is-waiting',
  ONLINE_CONTACTS = 'online-contacts',
  SESSION_ONLINE_CHECK = 'session-online-check',
  PUBLIC_NEWS_NOTIFICATION = 'public-news-notification',
  NEW_MESSAGE = 'NEW_MESSAGE',
  MEETING_CANCELED = 'MEETING_CANCELED',
  MEETING_CONFIRMED = 'MEETING_CONFIRMED',
  MEETING_REQUEST = 'MEETING_REQUEST',
  MEETING_REMINDER = 'MEETING_REMINDER',
}
