


import { Vue, Component, Watch } from 'vue-property-decorator';
import { TUser } from '@/_types/user.type';
import { Getter } from 'vuex-class';

/* See task https://biletum.myjetbrains.com/youtrack/issue/AW-2721 */

// «eventswallet_auth_source» shortened
export const AUTH_SOURCE_LOCAL_STORAGE_ITEM_NAME = 'eventswallet_as';

// N.B. values will be saved to localStorage, so it is better to make them not obvious
export enum AuthSources {
  SIGN_IN = '1',
  SIGN_UP = '2',
}

@Component
export default class TrackUserIdUserStatus extends Vue {

  @Getter('_userStore/user') user: TUser;
  public authSources: typeof AuthSources = AuthSources;

  public get authSourceFormName(): string {
    if (!window || !window.localStorage) {
      return null;
    }
    const locallyStoredAuthSource: string = window.localStorage.getItem(AUTH_SOURCE_LOCAL_STORAGE_ITEM_NAME);
    if (Object.values(this.authSources).includes(locallyStoredAuthSource as AuthSources) === false) {
      window.localStorage.removeItem(AUTH_SOURCE_LOCAL_STORAGE_ITEM_NAME);
      return null;
    }
    return locallyStoredAuthSource;
  }

  public get userStatusValue(): string {
    let result;
    switch (this.authSourceFormName) {
      case AuthSources.SIGN_IN:
        result = 'signedin';
        break;
      case AuthSources.SIGN_UP:
        result = 'registered';
        break;
      default:
        result = 'signedin';
    }
    return result;
  }

  @Watch('$route.name', { immediate: true })
  private onRouteNameChange(): void {
    this.pushToDataLayer();
  }

  private pushToDataLayer(): void {
    if (!this.user || !this.user.id || !this.userStatusValue ) {
      return;
    }
    try {
      (window as any).dataLayer = (window as any).dataLayer || [];
      ((window as any).dataLayer as any).push({
        userID: this.user.id.toFixed(0),
        userstatus: this.userStatusValue,
        event: 'user_status_event'
      });
    } catch { /* ignore */ }
  }
}
