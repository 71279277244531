import axios, { AxiosInstance } from 'axios';
import { fromEvent, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { IDiscoveryServiceProvider } from '@/_types/discovery/discovery-service-provider.interface';
import { TDiscoveryServiceProviderConfig } from '@/_types/discovery/discovery-service-provider-config.type';
import { DiscoveryMessageType } from '@/_types/discovery/discovery-message-type.enum';
import { IDiscoveryMessage } from '@/_types/discovery/discovery-message.interface';
import { TCompanyVideoChatState } from '@/_modules/meeting-rooms/types/company-video-chat-state.type';
import { TDiscoveryChatGroupMessagesPageResponse } from '@/_types/discovery/discovery-chat-group-messages-page-response.type';
import { TChatMessage, TChatMessageContext } from '@/_modules/chat/types/chat-message.type';
import { ChatMessageType } from '@/_modules/chat/types/chat-message-type.enum';
import { TDiscoveryChatGroupContactsResponse } from '@/_types/discovery/discovery-chat-group-contacts-response.type';
import { TDiscoveryChatGroupContactConnectedResponse } from '@/_types/discovery/discovery-chat-group-contact-connected-response.type';
import { TDiscoveryChatGroupContactDisconnectedResponse } from '@/_types/discovery/discovery-chat-group-contact-disconnected-response.type';
import { TDiscoveryOnlineContactsResponse } from '@/_types/discovery/discovery-online-contacts-response.type';
import { TDiscoveryWaitingMeetingNotificationResponse } from '@/_types/discovery/discovery-waiting-meeting-notification-response.type';
import { TDiscoveryNewsArticleNotificationResponse } from '@/_types/discovery/discovery-news-article-notification-response.type';
import { TMessage } from '@/_types/messages.type';
import { TMeeting } from '@/_types/meeting/meeting.type';
import { TDiscoverySessionOnlineCheck } from '@/_types/discovery/discovery-session-online-check.type';

const SOCKET_URL = process.env.VUE_APP_DISCOVERY_SOCKET_URL;
const REST_URL = process.env.VUE_APP_DISCOVERY_REST_URL;
const SOCKET_CONNECT_TIMEOUT = 10000;
const SOCKET_RECONNECT_TIMEOUT = 3000;
const REST_TIMEOUT = 10000;
export const CHAT_GROUP_PREFIX = 'chat_';

enum EwDiscoveryCommand {
  TOUCH_EVENT = 'touch_event',
  DIRECT_TO_USER = 'direct_to_user',
  ALL_IN_ROOM = 'all_in_room',
  SUBSCRIBE = 'subscribe',
  UNSUBSCRIBE = 'unsubscribe',
  NOTIFY = 'notify',
  TOPIC_MEMBERS = 'topic_members',
  CHAT_ENTER = 'chat_enter',
  CHAT_LEAVE = 'chat_leave',
  CHAT_MESSAGE = 'chat_message',
  CHAT_MESSAGE_REMOVE = 'chat_message_delete',
  CHAT_HISTORY = 'chat_history',
}

type TDiscoveryState<T> = {
  name: string;
  context?: T;
}

type TEwDiscoveryCommand = {
  command: EwDiscoveryCommand;
  params?: { [param: string]: any };
  context?: any;
}
type TEwDiscoveryResponse<T> = {
  status: string;
  data: T;
}

export default class EwDiscoveryServiceProvider implements IDiscoveryServiceProvider {

  public connected$: Subject<TDiscoveryServiceProviderConfig> = new Subject<TDiscoveryServiceProviderConfig>();
  public disconnected$: Subject<void> = new Subject<void>();
  public message$: Subject<IDiscoveryMessage<any>> = new Subject<IDiscoveryMessage<any>>();

  private _config: TDiscoveryServiceProviderConfig = null;
  private _axios: AxiosInstance = null;
  private _socket: WebSocket = null;
  private _isConnected: boolean = false;
  private _isDisconnected: boolean = false;
  private _disconnected$: Subject<void> = new Subject<void>();

  constructor() {
    this._createAxiosInstance();
  }

  public async connect(config: TDiscoveryServiceProviderConfig): Promise<void> {
    if (this._socket) {
      return;
    }

    this._config = config;
    await this._connect();
  }

  public disconnect(): void {
    if (!this._socket) {
      return;
    }
    this._disconnect();
  }

  public isConnected(): boolean {
    return this._isConnected;
  }

  public async getState<T>(stateId: string): Promise<T> {
    if (!this._isConnected) {
      throw new Error('[getState] Request is made while not connected.');
    }
    const response = await this._axios.request<TEwDiscoveryResponse<TDiscoveryState<T>>>({
      url: '/status',
      method: 'GET',
      params: {
        name: stateId,
      }
    });
    return (response.data && response.data.data && response.data.data.context) || null;
  }

  public async getStatesGroup<T>(statesGroupId: string): Promise<T[]> {
    if (!this._isConnected) {
      throw new Error('[getStatesGroup] Request is made while not connected.');
    }
    const response = await this._axios.request<TEwDiscoveryResponse<TDiscoveryState<T>[]>>({
      url: '/status',
      method: 'GET',
      params: {
        group: statesGroupId,
      }
    });
    const states: T[] = [];
    if (response.data && response.data.data && response.data.data.length) {
      response.data.data.forEach(discoveryState => {
        if (discoveryState && discoveryState.context) {
          states.push(discoveryState.context);
        }
      });
    }
    return states;
  }

  public touchState<T>(params: { stateId: string; maxAgeSec: number; statesGroupId?: string; state?: T }): void {
    if (!this._isConnected) {
      throw new Error('[touchState] Request is made while not connected.');
    }
    const { stateId, maxAgeSec, statesGroupId, state } = params;
    this._sendCommand({
      command: EwDiscoveryCommand.TOUCH_EVENT,
      params: {
        name: stateId,
        timeout_sec: maxAgeSec,
        group: statesGroupId || undefined,
      },
      context: state || undefined,
    });
  }

  public toContact<T>(params: { contactId: number; message: T }): void {
    if (!this._isConnected) {
      throw new Error('[toContact] Request is made while not connected.');
    }
    const { contactId, message } = params;
    this._sendCommand({
      command: EwDiscoveryCommand.DIRECT_TO_USER,
      params: {
        user_id: contactId,
      },
      context: message
    });
  }

  public toAll<T>(message: T): void {
    if (!this._isConnected) {
      throw new Error('[toAll] Request is made while not connected.');
    }
    this._sendCommand({
      command: EwDiscoveryCommand.ALL_IN_ROOM,
      context: message
    });
  }

  public subscribe(topic: string): void {
    if (!this._isConnected) {
      throw new Error('[subscribe] Request is made while not connected.');
    }
    this._sendCommand({
      command: EwDiscoveryCommand.SUBSCRIBE,
      params: {
        topic: topic,
      }
    });
  }

  public unsubscribe(topic: string): void {
    if (!this._isConnected) {
      throw new Error('[unsubscribe] Request is made while not connected.');
    }
    this._sendCommand({
      command: EwDiscoveryCommand.UNSUBSCRIBE,
      params: {
        topic: topic,
      }
    });
  }

  public toTopic<T>(params: { topic: string; message: T }): void {
    if (!this._isConnected) {
      throw new Error('[toTopic] Request is made while not connected.');
    }
    const { topic, message } = params;
    this._sendCommand({
      command: EwDiscoveryCommand.NOTIFY,
      params: {
        topic: topic,
      },
      context: message
    });
  }

  public enterChatGroup(groupId: string): void {
    if (!this._isConnected) {
      throw new Error('[enterChatGroup] Request is made while not connected.');
    }
    this._sendCommand({
      command: EwDiscoveryCommand.CHAT_ENTER,
      params: {
        chat_room: CHAT_GROUP_PREFIX + groupId,
      },
    });
  }

  public leaveChatGroup(groupId: string): void {
    if (!this._isConnected) {
      throw new Error('[leaveChatGroup] Request is made while not connected.');
    }
    this._sendCommand({
      command: EwDiscoveryCommand.CHAT_LEAVE,
      params: {
        chat_room: CHAT_GROUP_PREFIX + groupId,
      },
    });
  }

  public requestChatGroupMessagesPage(groupId: string, limit: number, lastMessageId?: number): void {
    if (!this._isConnected) {
      throw new Error('[requestChatGroupMessagesPage] Request is made while not connected.');
    }
    this._sendCommand({
      command: EwDiscoveryCommand.CHAT_HISTORY,
      params: {
        chat_room: CHAT_GROUP_PREFIX + groupId,
        limit,
        last_message_id: lastMessageId,
      },
    });
  }

  public requestChatGroupContacts(groupId: string): void {
    if (!this._isConnected) {
      throw new Error('[requestChatGroupContacts] Request is made while not connected.');
    }
    this._sendCommand({
      command: EwDiscoveryCommand.TOPIC_MEMBERS,
      params: {
        topic: CHAT_GROUP_PREFIX + groupId,
      },
    });
  }

  public requestOnlineContacts(): void {
    if (!this._isConnected) {
      throw new Error('[requestOnlineContacts] Request is made while not connected.');
    }
    this._sendCommand({
      command: EwDiscoveryCommand.TOPIC_MEMBERS,
      params: {
        topic: '',
      },
    });
  }

  public sendChatGroupTextMessage(groupId: string, message: string, context: TChatMessageContext): void {
    if (!this._isConnected) {
      throw new Error('[sendChatGroupTextMessage] Request is made while not connected.');
    }
    this._sendCommand({
      command: EwDiscoveryCommand.CHAT_MESSAGE,
      params: {
        chat_room: CHAT_GROUP_PREFIX + groupId,
        message
      },
      context
    });
  }

  public removeChatGroupTextMessage(groupId: string, messageId: string): void {
    if (!this._isConnected) {
      throw new Error('[sendChatGroupTextMessage] Request is made while not connected.');
    }
    this._sendCommand({
      command: EwDiscoveryCommand.CHAT_MESSAGE_REMOVE,
      params: {
        chat_room: CHAT_GROUP_PREFIX + groupId,
        message_id: messageId
      }
    });
  }

  private async _connect(): Promise<void> {
    if (this._socket) {
      return;
    }
    await this._createSocketInstance();
    if (this._socket) {
      this.connected$.next(Object.assign({}, this._config));
    }
  }

  private _disconnect(): void {
    if (!this._socket) {
      return;
    }

    this._isDisconnected = true;
    this._socket.close();
    this._onDisconnected();
  }

  private _onDisconnected(): void {
    this._disconnected$.next();
    this._socket = null;
    this._isConnected = false;

    if (!this._isDisconnected) {
      setTimeout(() => {
        this._connect();
      }, SOCKET_RECONNECT_TIMEOUT);
    }
  }

  private _createAxiosInstance(): void {
    this._axios = axios.create({
      baseURL: REST_URL,
      timeout: REST_TIMEOUT,
    });
  }

  private async _createSocketInstance(): Promise<void> {
    if (this._socket || !this._config.eventId || !this._config.contactId) {
      return;
    }

    await new Promise<void>((resolve, reject) => {

      this._isDisconnected = false;

      try {
        this._socket = new WebSocket(`${SOCKET_URL}?room_id=${this._config.eventId}&user_id=${this._config.contactId}`);
      } catch (error) {
        this._onDisconnected();
        reject(new Error('Could not connect to ws.'));
        return;
      }

      fromEvent<Event>(this._socket, 'open')
        .pipe(take(1))
        .subscribe((/* event: Event */ ) => {
          this._isConnected = true;
          this._subscribeToSocketEvents();
          resolve();
        });

      setTimeout(() => {
        if (this._isConnected || !this._socket) {
          return;
        }
        this._onDisconnected();
        reject(new Error('Connect to ws time out.'));
      }, SOCKET_CONNECT_TIMEOUT);

    });
  }

  private _subscribeToSocketEvents(): void {
    if (!this._socket) {
      return;
    }

    fromEvent<CloseEvent>(this._socket, 'close')
      .pipe(take(1))
      .subscribe(this._onSocketClose.bind(this));

    fromEvent<Event>(this._socket, 'error')
      .pipe(takeUntil(this._disconnected$))
      .subscribe(this._onSocketError.bind(this));

    fromEvent<MessageEvent>(this._socket, 'message')
      .pipe(takeUntil(this._disconnected$))
      .subscribe(this._onSocketMessage.bind(this));
  }

  private _onSocketClose(/* event: CloseEvent */): void {
    this._onDisconnected();
    this.disconnected$.next();
  }

  private _onSocketError(/* event: Event */): void {
    // TODO: ?
  }

  private _onSocketMessage(event: MessageEvent): void {
    if (!event || !event.data) {
      return;
    }
    let message: any;
    try {
      message = JSON.parse(event.data);
    } catch (error) {
      /* TODO: ignore ? */
      return;
    }
    if (!message) {
      return;
    }

    // console.log('');
    // console.log('_onSocketMessage', message);
    // console.log('');

    switch (message.type) {

      case 'company-video-chat-update': {
        this.message$.next({
          type: DiscoveryMessageType.COMPANY_VIDEO_CHAT_UPDATE,
          data: message.data,
        } as IDiscoveryMessage<TCompanyVideoChatState>);
        break;
      }

      case 'CHAT_MESSAGE_LIST': {
        const data = typeof message.content === 'object' ? message.content : {};
        const response: TDiscoveryChatGroupMessagesPageResponse = {
          groupId: typeof data.chat_name === 'string' ? data.chat_name.substr(CHAT_GROUP_PREFIX.length) : null,
          limit: typeof data.limit === 'number' ? data.limit : null,
          lastMessageId: typeof data.last_message_id === 'number' ? data.last_message_id : null,
          total: typeof data.total === 'number' ? data.total : null,
          messages: Array.isArray(data.list) ? data.list.map((providerMessage: any) => this.providerMessage2ChatMessage(providerMessage)) : null,
        };
        this.message$.next({
          type: DiscoveryMessageType.CHAT_GROUP_MESSAGES_PAGE,
          data: response,
        } as IDiscoveryMessage<TDiscoveryChatGroupMessagesPageResponse>);
        break;
      }

      case 'CHAT_MESSAGE': {
        const providerMessage: any = typeof message.content === 'object' ? message.content : {};
        this.message$.next({
          type: DiscoveryMessageType.CHAT_MESSAGE,
          data: this.providerMessage2ChatMessage(providerMessage),
        } as IDiscoveryMessage<TChatMessage>);
        break;
      }

      case 'CHAT_MESSAGE_DELETE': {
        const providerMessage: any = typeof message.content === 'object' ? message.content : {};
        this.message$.next({
          type: DiscoveryMessageType.CHAT_MESSAGE_DELETE,
          data: this.providerMessage2ChatMessage(providerMessage),
        } as IDiscoveryMessage<TChatMessage>);
        break;
      }

      case 'TOPIC_MEMBERS': {
        const content: any = typeof message.content === 'object' ? message.content : {};
        const topic: string = typeof content.topic === 'string' ? content.topic : null;
        const members: number[] = Array.isArray(content.members) ? content.members : null;
        if (topic && topic.indexOf(CHAT_GROUP_PREFIX) === 0) {
          this.message$.next({
            type: DiscoveryMessageType.CHAT_GROUP_CONTACTS,
            data: {
              contactIds: members,
              groupId: topic.substr(CHAT_GROUP_PREFIX.length),
            } as TDiscoveryChatGroupContactsResponse,
          } as IDiscoveryMessage<TDiscoveryChatGroupContactsResponse>);
        } else if (topic === '') {
          this.message$.next({
            type: DiscoveryMessageType.ONLINE_CONTACTS,
            data: {
              contactIds: members
            } as TDiscoveryOnlineContactsResponse,
          } as IDiscoveryMessage<TDiscoveryOnlineContactsResponse>);
        }
        break;
      }

      case 'CONNECTION_REPORT': {
        const content: any = typeof message.content === 'object' ? message.content : {};
        const topic: string = typeof content.topic === 'string' ? content.topic : null;
        const userId: number = typeof content.user_id === 'number' ? content.user_id : null;
        const status: string = typeof content.status === 'string' ? content.status : null;
        if (topic && topic.indexOf(CHAT_GROUP_PREFIX) === 0 && userId) {
          if (status === 'CONNECTED') {
            this.message$.next({
              type: DiscoveryMessageType.CHAT_GROUP_CONTACT_CONNECTED,
              data: {
                contactId: userId,
                groupId: topic.substr(CHAT_GROUP_PREFIX.length),
              } as TDiscoveryChatGroupContactConnectedResponse,
            } as IDiscoveryMessage<TDiscoveryChatGroupContactConnectedResponse>);
          } else if (status === 'DISCONNECTED') {
            this.message$.next({
              type: DiscoveryMessageType.CHAT_GROUP_CONTACT_DISCONNECTED,
              data: {
                contactId: userId,
                groupId: topic.substr(CHAT_GROUP_PREFIX.length),
              } as TDiscoveryChatGroupContactDisconnectedResponse,
            } as IDiscoveryMessage<TDiscoveryChatGroupContactDisconnectedResponse>);
          }
        } else if (topic === '' && (typeof userId === 'number')) {
          this.requestOnlineContacts();
        }
        break;
      }

      case DiscoveryMessageType.MEETING_IS_WAITING: {
        this.message$.next({
          type: DiscoveryMessageType.MEETING_IS_WAITING,
          data: {
            type: message.type,
            eventId: message.eventId,
            meetingId: message.meetingId,
            meetingDate: message.meetingDate,
            moderatorContactId: message.moderatorContactId,
            externalId: message.externalId
          }
        } as IDiscoveryMessage<TDiscoveryWaitingMeetingNotificationResponse>);
        break;
      }

      case DiscoveryMessageType.PUBLIC_NEWS_NOTIFICATION: {
        this.message$.next({
          type: DiscoveryMessageType.PUBLIC_NEWS_NOTIFICATION,
          data: {
            // TODO: message.message → message
            eventId: message.message.eventId,
            newsId: message.message.newsId,
            newsTitle: message.message.newsTitle,
          }
        } as IDiscoveryMessage<TDiscoveryNewsArticleNotificationResponse>);
        break;
      }

      case DiscoveryMessageType.NEW_MESSAGE: {
        this.message$.next({
          type: message.type,
          data: message.content,
        } as IDiscoveryMessage<TMessage>);
        break;
      }

      case DiscoveryMessageType.SESSION_ONLINE_CHECK: {
        this.message$.next({
          type: message.type,
          data: {...message},
        } as IDiscoveryMessage<TDiscoverySessionOnlineCheck>);
        break;
      }

      case DiscoveryMessageType.MEETING_CANCELED:
      case DiscoveryMessageType.MEETING_CONFIRMED:
      case DiscoveryMessageType.MEETING_REMINDER:
      case DiscoveryMessageType.MEETING_REQUEST: {
        this.message$.next({
          type: message.type,
          data: message.content,
        } as IDiscoveryMessage<TMeeting>);
        break;
      }

    }
  }

  private _sendCommand(command: TEwDiscoveryCommand): void {
    if (!command || !this._isConnected) {
      return;
    }
    this._socket.send(JSON.stringify(command));
  }

  private providerMessage2ChatMessage(discoveryMessage: any): TChatMessage {
    return {
      id: typeof discoveryMessage.ID === 'number' ? discoveryMessage.ID : null,
      type: ChatMessageType.TEXT,
      to: typeof discoveryMessage.chat_name === 'string' ? discoveryMessage.chat_name.substr(CHAT_GROUP_PREFIX.length) : null,
      from: typeof discoveryMessage.user_id === 'number' ? discoveryMessage.user_id : null,
      time: typeof discoveryMessage.date === 'string' ? (new Date(discoveryMessage.date)) : null,
      message: typeof discoveryMessage.message === 'string' ? discoveryMessage.message : null,
      messageId: typeof discoveryMessage.message_id === 'number' ? discoveryMessage.message_id : null,
      data: typeof discoveryMessage.context !== 'undefined' ? discoveryMessage.context : undefined,
    };
  }

}
