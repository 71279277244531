import api from "../../api";

export default {
  strict: true,
  namespaced: true,
  state: {
    image: {},
    photos: {},
    files: {},
    uploadedImage: {},
    uploadedFile: {},
    uploadedPhotoVideo: {},
    photoVideoError: {},
    userFileError: {},
    uploadImageError: {},
    loadingImage: false,
    loadingPhotos: false,
    loadingFiles: false,
    loading: false,
  },
  getters: {
    getLoadingPhoto(state) {
      return state.loading
    }
  },
  mutations: {
    setImage(state, data) {
      state.image = data;
    },
    setPhotos(state, data) {
      state.photos = data;
    },
    setFiles(state, data) {
      state.files = data;
    },
    setUploadedImage(state, data) {
      state.uploadedImage = data;
    },
    setUploadedFile(state, data) {
      state.uploadedFile = data;
    },
    setUploadedPhotoVideo(state, data) {
      state.uploadedPhotoVideo = data;
    },
    setPhotoVideoError(state, data) {
      state.photoVideoError = data;
    },
    clearPhotoVideoError(state, data) {
      state.uploadedPhotoVideoError = data;
    },
    setUserFileError(state, data) {
      state.userFileError = data;
    },
    clearUserFileError(state, data) {
      state.userFileError = data;
    },
    setUploadImageError(state, data) {
      state.uploadImageError = data;
    },
    clearUploadImageError(state, data) {
      state.uploadImageError = data;
    },

    loadingImage(state, data) {
      state.loadingImage = data
    },
    loadingPhotos(state, data) {
      state.loadingPhotos = data
    },
    loadingFiles(state, data) {
      state.loadingFiles = data
    },
    loading(state, data) {
      state.loading = data
    }
  },
  actions: {
    image: async function ({commit}, formData) {
      commit('setImage', formData);
    },
    photos: async function ({commit}, formData) {
      commit('setPhotos', formData);
    },
    file: async function ({commit}, formData) {
      commit('setFiles', formData);
    },
    uploadImage: async function ({commit, rootState}, image) {
      let token = rootState.authStore.token;
      commit('loading', true);
      commit('setUploadedImage', {});

      await api.createEvent.uploadImages({token, image})
        .then((resolve) => {
          commit('setUploadedImage', resolve);
          commit('loading', false);
        }).catch((e) => {

          commit('setUploadImageError', e.data);
          commit('loading', false);
        });

    },
    clearUploadImageError({commit}) {
      commit('setUploadImageError', '');
    },
    uploadUserFile: async function ({commit, rootState}, file) {
      let token = rootState.authStore.token;
      commit('loadingFiles', true);
      commit('setUploadedFile', {});
      await api.createEvent.uploadFiles({token, file})
        .then((resolve) => {
          commit('setUploadedFile', resolve);
          commit('loadingFiles', false);
        }).catch((e) => {
          commit('setUserFileError', e.data);
          commit('loadingFiles', false);
        });

    },
    clearUserFileError({commit}) {
      commit('setUserFileError', '');
    },
    uploadPromoPagePhotoVideo: async function ({commit, rootState}, file) {
      let token = rootState.authStore.token;
      commit('loading', true);
      await api.createEvent.uploadFiles({token, file})
        .then((resolve) => {
          commit('setUploadedPhotoVideo', resolve);
          commit('loading', false);
        }).catch((e) => {
          commit('setPhotoVideoError', e.data);
          commit('loading', false);
        });

    },
    clearPromoPagePhotoVideoError({commit}) {
      commit('setPhotoVideoError', '');
    }
  }
}
