<template>
  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M5.39071 0.911143C5.5146 0.962486 5.62716 1.03774 5.72196 1.1326L8.86714 4.27765C8.96207 4.37248 9.03738 4.48509 9.08876 4.60904C9.14014 4.73299 9.16659 4.86586 9.16659 5.00004C9.16659 5.13422 9.14014 5.26709 9.08876 5.39104C9.03738 5.515 8.96207 5.62761 8.86714 5.72243L5.72196 8.86748C5.62716 8.96234 5.5146 9.0376 5.39071 9.08894C5.26682 9.14028 5.13402 9.16671 4.99992 9.16671C4.86581 9.16671 4.73302 9.14028 4.60913 9.08894C4.48524 9.0376 4.37268 8.96234 4.27787 8.86748L1.1327 5.72243C1.03777 5.62761 0.962462 5.515 0.91108 5.39104C0.859699 5.26709 0.833252 5.13422 0.833252 5.00004C0.833252 4.86586 0.859699 4.73299 0.91108 4.60904C0.962462 4.48509 1.03777 4.37248 1.1327 4.27765L4.27787 1.1326C4.37268 1.03774 4.48524 0.962486 4.60913 0.911143C4.73302 0.8598 4.86581 0.833374 4.99992 0.833374C5.13402 0.833374 5.26682 0.8598 5.39071 0.911143ZM4.63935 5.87037C4.73498 5.96601 4.86468 6.01974 4.99992 6.01974C5.13516 6.01974 5.26486 5.96601 5.36048 5.87037C5.45611 5.77473 5.50984 5.64502 5.50984 5.50976V2.95986C5.50984 2.82461 5.45611 2.69489 5.36048 2.59925C5.26486 2.50361 5.13516 2.44988 4.99992 2.44988C4.86468 2.44988 4.73498 2.50361 4.63935 2.59925C4.54372 2.69489 4.49 2.82461 4.49 2.95986V5.50976C4.49 5.64502 4.54372 5.77473 4.63935 5.87037ZM4.63935 7.40032C4.73498 7.49596 4.86468 7.54969 4.99992 7.54969C5.13516 7.54969 5.26486 7.49596 5.36048 7.40032C5.45611 7.30468 5.50984 7.17496 5.50984 7.03971C5.50984 6.90445 5.45611 6.77474 5.36048 6.6791C5.26486 6.58346 5.13516 6.52973 4.99992 6.52973C4.86468 6.52973 4.73498 6.58346 4.63935 6.6791C4.54372 6.77474 4.49 6.90445 4.49 7.03971C4.49 7.17496 4.54372 7.30468 4.63935 7.40032Z"
          fill="#F5222D"/>
  </svg>
</template>
<script>
import Vue from 'vue';

const IconEwInputError = Vue.extend({
  name: 'icon-ew-input-error',
});
export default IconEwInputError;
</script>
