class ValidationError extends Error {
  constructor(message) {
    super(message);
    this.name = 'ValidationError';
    this.message = message
  }
}

class APIError extends Error {
  constructor(data) {
    super();
    this.name = 'APIError';
    this.message = data.error;
    this.data = data;
  }
}

class NetworkError extends Error {
  constructor(data) {
    super();
    this.name = 'Network Error';
    this.message = data.error;
    this.data = data;
  }
}

/**
 * @return {string}
 */
let ErrTextByCode = function (code, errorText) {
  switch (code) {
    // case 2:
    //   return 'errors.api.invalid_object_schema';
    case 3:
      return 'errors.api.auth_fail';
    case 5:
      return 'errors.api.invalid_sms_code';
/*    case 7:
      return 'errors.api.forbidden_firewall_auth';*/
    // case 11:
    //   return 'errors.api.internal_server_error';
    // case 0:
    //   return 'errors.api.connection_error';
    default:
      return errorText;
  }
};


module.exports = {
  ValidationError,
  APIError,
  NetworkError,
  ErrTextByCode,
};
