import {AxiosRequestConfig, AxiosResponse, CancelToken} from 'axios';
import BaseApi from '@/_types/api/base.api.class';
import { TApiListResponse } from '@/_types/api/api-list-response.type';
import { TTextChat } from '@/_types/text-chats/text-chat.type';

export type TGetTextChatsParams = {
  eventId: number;
}

export type TCreateEditTextChatQueryParams = {
  name: string;
  internal_name: string; // N.B. has to be a-zA-Z0-9_
  sorting: number;
  description?: string;
  photo_url?: string;
  is_hidden?: boolean;
}

export type TCreateTextChatParams = {
  eventId: number;
  data: TCreateEditTextChatQueryParams;
}

export type TEditTextChatParams = {
  eventId: number;
  chatId: number;
  data: TCreateEditTextChatQueryParams;
}

export type TDeleteTextChatParams = {
  eventId: number;
  textChatId: number;
}

export class TextChatsApi extends BaseApi {

  public async getTextChats(params: TGetTextChatsParams, cancelToken?: CancelToken): Promise<TTextChat[]> {
    const { eventId } = params;
    const config: AxiosRequestConfig = {
      url: `/event/${eventId}/chat/list`,
      method: 'GET',
      cancelToken,
    };

    let response;
    try {
      response = await this.axios.request<TApiListResponse<TTextChat>>(config);
    } catch (error) {
      throw this.responseError(error);
    }

    return response.data.List;
  }

  public async createTextChat(params: TCreateTextChatParams): Promise<TTextChat> {

    const { eventId, data } = params;
    const config: AxiosRequestConfig = {
      url: `event/${eventId}/chat`,
      method: 'POST',
      params: data
    };

    let response: AxiosResponse;
    try {
      response = await this.axios.request<TTextChat>(config);
    } catch (error) {
      throw this.responseError(error);
    }

    return response.data;
  }

  public async editTextChat(params: TEditTextChatParams): Promise<boolean> {

    const { eventId, chatId, data } = params;
    const config: AxiosRequestConfig = {
      url: `event/${eventId}/chat/${chatId}`,
      method: 'PATCH',
      params: data
    };

    let response: AxiosResponse;
    let result = false;
    try {
      response = await this.axios.request<any>(config);
      result = response && response.status === 202;
    } catch (error) {
      throw this.responseError(error);
    }

    return result;
  }

  public async deleteTextChat(params: TDeleteTextChatParams): Promise<void> {
    const { eventId, textChatId } = params;

    const config: AxiosRequestConfig = {
      url: `event/${eventId}/chat/${textChatId}`,
      method: 'DELETE',
    };

    try {
      await this.axios.request<any>(config);
    } catch (error) {
      throw this.responseError(error);
    }

  }

}

const textChatsApi = new TextChatsApi();
export default textChatsApi;
