import { TVideoChatParticipantState } from '@/_modules/meeting-rooms/types/video-chat-participant-state.type';
import { VIDEO_CHAT_ROOM_TOTAL_PARTICIPANTS } from '@/_modules/meeting-rooms/components/company-room/company-room.vue';

const SLOT_NAME_PREFIX = 'slot_';

export type TParticipantSlots = {
  slot_0?: TVideoChatParticipantState;
  slot_1?: TVideoChatParticipantState;
  slot_2?: TVideoChatParticipantState;
  slot_3?: TVideoChatParticipantState;
  slot_4?: TVideoChatParticipantState;
  slot_5?: TVideoChatParticipantState;
  slot_6?: TVideoChatParticipantState;
}

export default class CompanyVideoChatRoomHelper {

  public static participantSlotsToParticipantArray(participantSlots: TParticipantSlots): TVideoChatParticipantState[] {
    const slotNames: string[] = [];
    const result: TVideoChatParticipantState[] = [];

    for (let i = 0; i < VIDEO_CHAT_ROOM_TOTAL_PARTICIPANTS; i++) {
      slotNames.push(SLOT_NAME_PREFIX + i.toFixed(0));
    }

    slotNames.forEach((slotName, index) => {
      result[index] = null;
      if (Object.prototype.hasOwnProperty.call(participantSlots, slotName)) {
        const participant: TVideoChatParticipantState = participantSlots[slotName as keyof TParticipantSlots];
        if (participant && Object.prototype.hasOwnProperty.call(participant, 'contactId')) {
          result[index] = {...participant};
        }
      }
    });
    return result;
  }

  public static participantArrayToParticipantSlots(participants: any[]): TParticipantSlots {
    const result: TParticipantSlots = {};

    for (let i = 0; i < VIDEO_CHAT_ROOM_TOTAL_PARTICIPANTS; i++) {
      const slotName: string = SLOT_NAME_PREFIX + i.toFixed(0);
      result[slotName as keyof TParticipantSlots] = null;
      if (participants[i] && participants[i].contactId) {
        result[slotName as keyof TParticipantSlots] = { ...participants[i] };
      }
    }

    return result;
  }
}
