import api from "../../api";

export default {
  strict: true,
  namespaced: true,
  state: {
    noteList: [],
    noteListPromoPage: null,
    noteError: '',
    notesByContactId: {},
  },
  getters: {
    noteList(state) {
      if (!state.noteList.List) {
        return [];
      }
      return state.noteList.List;
    },
    noteListByContactId(state) {
      return function (contactId) {
        return state.notesByContactId[contactId] || [];
      };
    },
    companyNotes(state) {
      return state.noteListPromoPage && state.noteListPromoPage.List ? state.noteListPromoPage.List || [] : [];
    },
  },
  mutations: {
    noteError(state, data) {
      state.noteError = data
    },
    setNoteList(state, data) {
      state.noteList = {
        Limit: data.Limit,
        Offset: data.Offset,
        Total: data.Total,
        List: data.List ? data.List.reverse() : [],
      };
    },
    setNotesByContactId(state) {
      if (!state.noteList.List) {
        return;
      }
      state.noteList.List.forEach(note => {
        if (!state.notesByContactId[note.owner.id]) {
          state.notesByContactId[note.owner.id] = [];
        }
        if (!state.notesByContactId[note.owner.id].find(existingNote => existingNote.id === note.id)) {
          state.notesByContactId[note.owner.id].push(note);
        }
      });
    },
    setNoteListPromoPage(state, data) {
      state.noteListPromoPage = {
        Limit: data.Limit,
        Offset: data.Offset,
        Total: data.Total,
        List: data.List || [],
      };
    },
  },
  actions: {
    addNote: async function ({commit}, data) {
      try {
        await api.notes.addNote(data)
      } catch (e) {
        commit('noteError', e.data);
      }
    },
    editNote: async function ({commit}, data) {
      await api.notes.editNote(data)
        .catch((e) => {
          commit('noteError', e.data);
        });
    },
    removeNote: async function ({commit}, data) {
      await api.notes.removeNote(data)
        .then(() => {
        }).catch((e) => {
          commit('noteError', e.data);
        });
    },
    addFav: async function ({commit}, data) {
      await api.notes.addFav(data)
        .then(() => {
        }).catch((e) => {
          commit('noteError', e.data);
        });
    },
    removeFav: async function ({commit}, data) {
      await api.notes.removeFav(data)
        .then(() => {
        }).catch((e) => {
          commit('noteError', e.data);
        });
    },
    callNoteList: async function ({commit}, data) {
      await api.notes.noteList(data)
        .then((resolve) => {
          commit('setNoteList', resolve);
        }).catch((e) => {
          commit('noteError', e.data);
        });
    },
    callNoteListAll: async function ({commit}, data) {
      await api.notes.noteListAll(data)
        .then((resolve) => {
          commit('setNoteList', resolve);
          commit('setNotesByContactId');
        }).catch((e) => {
          commit('noteError', e.data);
        });
    },
    callNoteListAllPromoPage: async function ({commit}, data) {
      await api.notes.noteListAll(data)
        .then((resolve) => {
          commit('setNoteListPromoPage', resolve);
        }).catch((e) => {
          commit('noteError', e.data);
        });
    },
  }

}

