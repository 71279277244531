import { AxiosRequestConfig } from 'axios';
import BaseApi from '@/_types/api/base.api.class';
import {TCategory} from '@/_types/event.type';
import {TApiListResponse} from '@/_types/api/api-list-response.type';

export class CategoriesApi extends BaseApi {

  public async getCategories(): Promise<TCategory[]> {
    const config: AxiosRequestConfig = {
      url: '/category/list',
      method: 'GET',
    };

    let response;
    try {
      response = await this.axios.request<TApiListResponse<TCategory>>(config);
    } catch (error) {
      throw this.responseError(error);
    }

    return response.data.List;
  }

}

const categoriesApi = new CategoriesApi();
export default categoriesApi;
