


import {Vue, Component} from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import AuthContent from '@/_components/auth/auth-content/auth-content.vue';
import EwPopUp from '@/_modules/standalone-company/components/ew-pop-up/ew-pop-up.vue';

@Component({
  components: {
    AuthContent,
    EwPopUp,
  }
})
export default class AuthOverlay extends Vue  {

  @Getter('authStore/isStandaloneAuthVisible') isStandaloneAuthVisible: boolean;
  @Action('authStore/setStandaloneAuthVisibility') setStandaloneAuthVisibility: (isVisible: boolean) => Promise<void>;

  public authContentTitle: string = '';

  public get isCloseButtonHidden(): boolean {
    return this.$route.name === 'auth-page';
  }

  public onNewAuthScreenTitle(newTitle: string): void {
    this.authContentTitle = newTitle;
  }

  public onClose(): void {
    this.setStandaloneAuthVisibility(false);
  }

}
