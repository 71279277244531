


import { Component, Vue } from 'vue-property-decorator';
import {Action, Getter} from 'vuex-class';
import { email, required } from 'vuelidate/lib/validators';
import { TVuelidateRuleSet } from '@/_types/vuelitation-rule-set.type';
import { Validations } from 'vuelidate-property-decorators';
import { TEvent } from '@/_types/event.type';
import { TEventSettings } from '@/_types/event-settings.type';
import {
  DEFAULT_CONTACT_INVITER_SETTINGS,
  CONTACT_INVITER_SETTINGS_KEY_NAME,
} from '@/_modules/promo-cabinet/components/cabinet-contact-inviter-settings/cabinet-contact-inviter-settings.vue';
import EwInput from '@/_modules/standalone-company/components/UI/ew-input/ew-input.vue';
import EwButton from '@/_modules/standalone-company/components/UI/Ew-Button/Ew-Button.vue';
import {TEditContactActionParams} from '@/_modules/promo/store/promo-page.store';
import {TUserProfile} from '@/_api/user/user.api';
import {TContact} from '@/_types/contact.type';
import eventApi, {TGetEventSettingsParams} from '@/_modules/events/api/event/event.api';

@Component({
  components: {
    EwInput,
    EwButton,
  }
})
export default class ContactInfoInviter extends Vue {

  @Validations()
  public readonly validations: TVuelidateRuleSet<string> | any = {
    myName: { required },
    mySurname: { required },
    myCountry: { required },
    myCompanyName: { required },
    myJobPosition: { required },
    myPhoneNumber: { required },
    myEmail: { required, email },
  };

  @Getter('_eventStore/event') event: TEvent;
  @Getter('_eventStore/eventSettings') eventSettings: TEventSettings;
  @Action('authStore/setStandaloneAuthVisibility') setStandaloneAuthVisibility: (isVisible: boolean) => void;
  @Action('authStore/setContactInfoInviterVisibility') setContactInfoInviterVisibility: (isVisible: boolean) => Promise<void>;
  @Action('_eventStore/getEventSettings') getEventSettings: (params: TGetEventSettingsParams) => Promise<void>;
  @Getter('_userStore/profile') userProfile: TUserProfile;
  @Action('_userStore/getUserProfile') getUserProfile: () => Promise<TUserProfile>;
  @Getter('promoPageStore/contact') myself: TContact;
  @Action('promoPageStore/getContact') getContact: (params: { eventId: number }, force?: boolean) => Promise<TContact>;
  @Action('promoPageStore/contactLookup') contactLookup: (params: { eventId: number }) => Promise<{ contact_exists: boolean }>;
  @Action('authStore/setContactInfoInviterBackgroundMode') setContactInfoInviterBackgroundMode: (isRunningOnBackground: boolean) => void;

  public isLoadingCurtainVisible: boolean = true;

  public myName: string = '';
  public mySurname: string = '';
  public myCountry: string = '';
  public myCity: string = '';
  public myCompanyName: string = '';
  public myJobPosition: string = '';
  public myPhoneNumber: string = '';
  public myEmail: string = '';
  public myPhoto: string = '';

  public myNameIsIncorrect: string = '';
  public mySurnameIsIncorrect: string = '';
  public myCountryIsIncorrect: string = '';
  public myCompanyNameIsIncorrect: string = '';
  public myJobPositionIsIncorrect: string = '';
  public myPhoneNumberIsIncorrect: string = '';
  public myEmailIsIncorrect: string = '';

  public get eventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  public get isUpdateContactButtonDisabled(): boolean {
    const fieldSettings = this.contactInviterFieldSettings;
    const isCountryRequired = (fieldSettings && fieldSettings.country) ? fieldSettings.country.isRequired : false;
    const isCompanyNameRequired = (fieldSettings && fieldSettings.companyName) ? fieldSettings.companyName.isRequired : false;
    const isJobPositionRequired = (fieldSettings && fieldSettings.jobPosition) ? fieldSettings.jobPosition.isRequired : false;
    const isPhoneRequired = (fieldSettings && fieldSettings.phone) ? fieldSettings.phone.isRequired : false;
    return this.$v.mySurname.$invalid
      || this.$v.myName.$invalid
      || (isCountryRequired ? this.$v.myCountry.$invalid : false)
      || (isCompanyNameRequired ? this.$v.myCompanyName.$invalid : false)
      || (isJobPositionRequired ? this.$v.myJobPosition.$invalid : false)
      || (isPhoneRequired ? this.$v.myPhoneNumber.$invalid : false);
  }

  public get isAllRequiredContactInfoProvided(): boolean {
    const fieldSettings = this.contactInviterFieldSettings;
    const isCountryRequired = (fieldSettings && fieldSettings.country) ? fieldSettings.country.isRequired : false;
    const isCompanyNameRequired = (fieldSettings && fieldSettings.companyName) ? fieldSettings.companyName.isRequired : false;
    const isJobPositionRequired = (fieldSettings && fieldSettings.jobPosition) ? fieldSettings.jobPosition.isRequired : false;
    const isPhoneRequired = (fieldSettings && fieldSettings.phone) ? fieldSettings.phone.isRequired : false;

    return this.myContactName !== ''
      && this.myContactSurname !== ''
      && (isCountryRequired ? this.myContactCountry !== '' : true)
      && (isCompanyNameRequired ? this.myContactCompanyName !== '' : true)
      && (isJobPositionRequired ? this.myContactCompanyPosition !== '' : true)
      && (isPhoneRequired ? this.myContactPhone !== '' : true);
  }

  public get contactInviterFieldSettings(): any {
    const remoteSettingsLayout: any = (this.eventSettings && this.eventSettings.layout) || null;
    const contactInviterSettings: any = (remoteSettingsLayout && remoteSettingsLayout[CONTACT_INVITER_SETTINGS_KEY_NAME]) || DEFAULT_CONTACT_INVITER_SETTINGS;
    return contactInviterSettings.field_settings;
  }

  public get myCountryErrorText(): string {
    if (!this.contactInviterFieldSettings || !this.contactInviterFieldSettings.country || !this.contactInviterFieldSettings.country.isRequired) {
      return '';
    }

    return this.$v.myCountry.$dirty && this.$v.myCountry.$invalid ? this.$t('contacts.popups.contactInfoInviter.fieldErrors.country') as string : '';
  }

  public get myCompanyNameErrorText(): string {
    if (!this.contactInviterFieldSettings || !this.contactInviterFieldSettings.companyName || !this.contactInviterFieldSettings.companyName.isRequired) {
      return '';
    }

    return this.$v.myCompanyName.$dirty && this.$v.myCompanyName.$invalid ? this.$t('contacts.popups.contactInfoInviter.fieldErrors.companyName') as string : '';
  }

  public get myJobPositionErrorText(): string {
    if (!this.contactInviterFieldSettings || !this.contactInviterFieldSettings.jobPosition || !this.contactInviterFieldSettings.jobPosition.isRequired) {
      return '';
    }

    return this.$v.myJobPosition.$dirty && this.$v.myJobPosition.$invalid ? this.$t('contacts.popups.contactInfoInviter.fieldErrors.jobPosition') as string : '';
  }

  public get myPhoneNumberErrorText(): string {
    if (!this.contactInviterFieldSettings || !this.contactInviterFieldSettings.phone || !this.contactInviterFieldSettings.phone.isRequired) {
      return '';
    }

    return this.$v.myPhoneNumber.$dirty && this.$v.myPhoneNumber.$invalid ? this.$t('contacts.popups.contactInfoInviter.fieldErrors.phone') as string : '';
  }

  public get isOptionalFieldsHeadingVisible(): boolean {
    const defaultRequiredFieldKeys: string[] = Object
      .keys(DEFAULT_CONTACT_INVITER_SETTINGS.field_settings)
      .filter(key => {
        return !!DEFAULT_CONTACT_INVITER_SETTINGS.field_settings[key].isRequired;
      });
    const localRequiredFieldKeys: string[] = Object
      .keys(this.contactInviterFieldSettings)
      .filter(key => {
        return !!this.contactInviterFieldSettings[key].isRequired;
      });

    return defaultRequiredFieldKeys.length === localRequiredFieldKeys.length; // N.B.: this is currently possible only if field isRequired settings are default, because we force name and lastName isRequired = true
  }

  public get eventLanguages(): string[] {
    if (!this.event || !this.event.languages || !this.event.languages.length) {
      return ['en'];
    }
    return this.event.languages;
  }

  public get myContactName(): string {
    return (this.myself && this.myself.name) || '';
  }

  public get myContactSurname(): string {
    return (this.myself && this.myself.surname) || '';
  }

  public get myContactCompanyPosition(): string {
    return (this.myself && this.myself.company_position) || '';
  }

  public get myContactCountry(): string {
    return (this.myself && this.myself.country) || '';
  }

  public get myContactCity(): string {
    return (this.myself && this.myself.city) || '';
  }

  public get myContactCompanyName(): string {
    return (this.myself && this.myself.company_name) || '';
  }

  public get myContactPhone(): string {
    return (this.myself && this.myself.phone) || '';
  }

  public get myContactPhoto(): string {
    return (this.myself && this.myself.photo_url) || '';
  }

  public get myContactEmail(): string {
    return (this.myself && this.myself.email) || '';
  }

  // =============================================== // TODO: remove this comment line

  public async mounted(): Promise<void> {

    this.isLoadingCurtainVisible = true;
    await this.getUserProfile();
    const isExistingContact = (await this.contactLookup({ eventId: this.eventId })).contact_exists || false;
    await this.getEventSettings({ eventId: this.eventId });

    if (isExistingContact) {
      await this.initForExistingContact();
    } else {
      await this.initForNewContact();
    }

    this.isLoadingCurtainVisible = false;
  }

  public async initForExistingContact(): Promise<void> {
    await this.getContact({ eventId: this.eventId });
    if (this.isAllRequiredContactInfoProvided) {
      await this.setContactInfoInviterVisibility(false);
      return;
    }

    await this.setContactInfoInviterBackgroundMode(false);
    this.initContactInviterFields();
  }

  public async initForNewContact(): Promise<void> {
    await this.setContactInfoInviterBackgroundMode(false);
    await eventApi.patchIsGoing({ eventId: this.eventId, isGoing: true });
    await this.getContact({ eventId: this.eventId });
    this.initContactInviterFields();
  }

  public initContactInviterFields(): void {
    const profile = this.userProfile;
    this.myName = this.myContactName || (profile && profile.first_name) || '';
    this.mySurname = this.myContactSurname || (profile && profile.last_name) || '';
    this.myCompanyName = this.myContactCompanyName || (profile && profile.company_name) || '';
    this.myJobPosition = this.myContactCompanyPosition || (profile && profile.job_position) || '';
    this.myPhoneNumber = this.myContactPhone || (profile && profile.phone) || '';
    this.myPhoto = this.myContactPhoto || (profile && profile.photo_url) || '';
    this.myEmail = (profile && profile.email) || this.myContactEmail || ''; // N.B. Different logic here, because contact is always created with an email, but we have to prefer email from profile
    this.myCountry = this.myContactCountry || (profile && profile.country) || '';
    this.myCity = this.myContactCity || (profile && profile.city) || '';
  }

  public async onNextClick(): Promise<void> {
    this.isLoadingCurtainVisible = true;
    if (this.isUpdateContactButtonDisabled || !this.event) {
      this.isLoadingCurtainVisible = false;
      return;
    }

    await this.updateContact();
  }

  public async updateContact(): Promise<void> {

    const payload = this.getUpdateContactPayload();

    const success = await Promise.all(this.eventLanguages.map(async (langCode) => {
      payload.lang = langCode;
      await this.$store.dispatch('promoPageStore/editContact', payload);
    }));

    if (success && success.length) {
      await this.onUpdateContactSuccess();
    }

    this.isLoadingCurtainVisible = false;
    // TODO: do smth on error?
  }

  public getUpdateContactPayload(): TEditContactActionParams {
    const payload: TEditContactActionParams = {
      event_id: this.eventId,
      name: this.myName,
      surname: this.mySurname,
      country: this.myCountry,
      city: this.myCity,
      company_name: this.myCompanyName,
      company_position: this.myJobPosition,
      phone: this.myPhoneNumber,
      tags: [],
      lang: ''
    };

    if (this.myPhoto) {
      payload.photo_url = this.myPhoto;
    }

    if (this.myEmail) {
      payload.email = this.myEmail;
    }

    return payload;
  }

  public async onUpdateContactSuccess(): Promise<void> {
    await this.getContact({ eventId: this.eventId }, true);
    await this.setContactInfoInviterBackgroundMode(true);
    await this.setContactInfoInviterVisibility(false);
    await this.setStandaloneAuthVisibility(true); // N.B. this will run manageAuthOnEventPage → redirectAfterAuthSuccess
  }

  public cleanMyPhoneNumber(): void {
    const cleanValue: string = this.myPhoneNumber.replace(/[^\d]/g, '');
    this.myPhoneNumber = cleanValue !== '' ? '+' + cleanValue : '';
  }

}
