import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import { StatusCodes } from 'http-status-codes';
import store from '@/store';
import router from '@/router';
import i18n from '@/i18n';
import APIError from '@/_types/api/api-error.class';
import { IAnyResponseData } from '@/_types/api/any-response-data.interface';

const BASE_URL = process.env.VUE_APP_API_BASE_URL;
const TIMEOUT = 10000;

export default abstract class BaseApi {

  private static _axios: AxiosInstance;
  private static _token: string;

  constructor() {
    if (!BaseApi._axios) {
      BaseApi._axios = axios.create({
        baseURL: BASE_URL,
        timeout: TIMEOUT,
      });
      BaseApi._axios.interceptors.request.use((config: AxiosRequestConfig) => {
        if (BaseApi._token) {
          config.headers['X-Token-Key'] = BaseApi._token;
        }
        if (config.headers['Accept-Language']) {
          return config;
        } else if (i18n.locale) {
          config.headers['Accept-Language'] = i18n.locale;
        }
        return config;
      });
    }
  }

  public get axios(): AxiosInstance {
    return BaseApi._axios;
  }

  public get token(): string {
    return BaseApi._token;
  }

  public set token(token: string) {
    BaseApi._token = token;
  }

  public responseError(axiosError: AxiosError<IAnyResponseData>): APIError {
    const { response } = axiosError;
    const error = new APIError(axiosError);

    if (!response) {
      return error;
    }

    const { status } = response;
    if (status === StatusCodes.UNAUTHORIZED) {
      this.token = null;
      store.dispatch('authStore/authLogout'); // TODO: refactoring - using store here is probably a bad place
      router.push({
        name: 'auth-page',
        params: {
          redirect: window.location.pathname
        }
      }); // TODO: refactoring - using router here is probably a bad place
    }

    return error;
  }

}
