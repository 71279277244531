import { AxiosRequestConfig, AxiosResponse, CancelToken } from 'axios';
import BaseApi from '@/_types/api/base.api.class';
import { TApiListResponse } from '@/_types/api/api-list-response.type';
import { TCountryState } from '@/_types/country-state.type';
import { TCountry } from '@/_types/country.type';
import { TCity } from '@/_types/city.type';

export type TGetStatesOrCitiesParams = {
  countryId: number;
}

export class LocationApi extends BaseApi {

  public async getCountries(params: any, cancelToken?: CancelToken): Promise<TCountry[]> {
    const config: AxiosRequestConfig = {
      url: 'country/list',
      method: 'GET',
      cancelToken,
    };

    let response;
    try {
      response = await this.axios.request<TApiListResponse<TCountry>>(config);
    } catch (error) {
      throw this.responseError(error);
    }

    return response.data.List;
  }

  public async getStates(params: TGetStatesOrCitiesParams, cancelToken?: CancelToken): Promise<TCountryState[]> {
    const { countryId } = params;
    const config: AxiosRequestConfig = {
      url: 'state/list',
      method: 'GET',
      cancelToken,
      params: {
        country_id: countryId,
      },
    };

    let response;
    try {
      response = await this.axios.request<TApiListResponse<TCountryState>>(config);
    } catch (error) {
      throw this.responseError(error);
    }

    return response.data.List;
  }

  public async getCities(params: TGetStatesOrCitiesParams): Promise<TCity[]> {

    const { countryId } = params;
    const config: AxiosRequestConfig = {
      url: 'city/list',
      method: 'GET',
      params: {
        country_id: countryId,
      },
    };

    let response: AxiosResponse;
    try {
      response = await this.axios.request<TApiListResponse<TCountryState>>(config);
    } catch (error) {
      throw this.responseError(error);
    }

    return response.data.List;
  }

}

const locationApi = new LocationApi();
export default locationApi;
