import { render, staticRenderFns } from "./personal-code-acceptor.html?vue&type=template&id=cc6a7bf4&scoped=true&"
import script from "./personal-code-acceptor.vue?vue&type=script&lang=ts&"
export * from "./personal-code-acceptor.vue?vue&type=script&lang=ts&"
import style0 from "./personal-code-acceptor.scss?vue&type=style&index=0&id=cc6a7bf4&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cc6a7bf4",
  null
  
)

export default component.exports