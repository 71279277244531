import BaseApi from '@/_types/api/base.api.class';
import { AxiosRequestConfig, CancelToken } from 'axios';
import FormData from 'form-data';

export type TUploadFileParams = {
  file: File;
  w?: number;
  h?: number;
  mode?: string;
}

export type TUploadFileResponse = {
  url: string;
  filename: string;
  size: number;
  type: string;
}

export type TImageDimensionParams = {
  w?: number;
  h?: number;
  mode?: string;
}

export class UploadApi extends BaseApi {

  public async uploadFile(params: TUploadFileParams, cancelToken?: CancelToken): Promise<TUploadFileResponse> {

    const formData = new FormData();
    formData.append('file', params.file);

    const imageDimensionParams: TImageDimensionParams = {};
    if (params.w) {
      imageDimensionParams.w = params.w;
    }
    if (params.h) {
      imageDimensionParams.h = params.h;
    }
    if (params.mode && params.w && params.h) {
      imageDimensionParams.mode = params.mode;
    }

    const config: AxiosRequestConfig = {
      url: '/upload/file',
      method: 'POST',
      timeout: 86400 * 1000, // TODO: refactor. Without timeout here request gets cancelled after around 1000 ms. timeout 0 does not help
      cancelToken,
      headers: {
        ...formData.getHeaders,
        'Content-Type': `multipart/form-data; boundary=${formData.getBoundary}`,
      },
      params: imageDimensionParams
    };

    let response;
    try {
      response = await this.axios.post<TUploadFileResponse>(config.url, formData, config);
    } catch (error) {
      throw this.responseError(error);
    }

    return response.data;

  }

}

const uploadApi = new UploadApi();
export default uploadApi;
