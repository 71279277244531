

import Component from 'vue-class-component';
import { Prop, Vue, Watch } from 'vue-property-decorator';
import { TFile } from '@/_types/file.type';
import uploadApi, { TUploadFileParams, TUploadFileResponse} from '@/_api/upload/upload.api';
import FileHelper from '@/_helpers/file.helper';
import { TranslateResult } from 'vue-i18n';
import ApiErrorResponseData from '@/_types/api/api-error-response-data.class';

// TODO: refactor error messages

@Component
export default class FormFileUploader extends Vue {

  @Prop({ type: String, default: 'any' })
  public readonly filter: 'any' | 'image' | 'video' | 'image-or-video';

  @Prop({ type: Number, default: 300 })
  public readonly maxFileSizeAny: number;

  @Prop({ type: Number, default: 10 })
  public readonly maxFileSizeImage: number;

  @Prop({ type: Number, default: 300 })
  public readonly maxFileSizeVideo: number;

  @Prop({ type: Boolean, default: false })
  public readonly multiple: boolean;

  @Prop({ type: [ Object, Array, String ] })
  public readonly value: TFile | TFile[];

  @Prop({ type: Boolean, default: false })
  public readonly invisible: boolean;

  @Prop({ type: Number, default: -1 })
  public readonly imageTargetWidth: number;

  @Prop({ type: Number, default: -1 })
  public readonly imageTargetHeight: number;

  @Prop({ type: String, default: '' })
  public readonly imageTargetMode: string;

  public isUploading: boolean = false;
  public errorMessage: string | TranslateResult;
  public errorData: ApiErrorResponseData = null
  public internalValue: TFile | TFile[] = null;
  public isEmptyValue: boolean = false;

  public get maxFileSizeMB(): number {
    switch (this.filter) {
      case 'image':
        return this.maxFileSizeImage;

      case 'video':
        return this.maxFileSizeVideo;

      case 'image-or-video':
        return this.maxFileSizeVideo;
    }

    return this.maxFileSizeAny;
  }

  public get supportedFormats(): string {
    switch (this.filter) {
      case 'image':
        return 'JPG, PNG';

      case 'video':
        return 'MPG, MP4';

      case 'image-or-video':
        return 'JPG, PNG, MPG, MP4';
    }

    return '*';
  }

  public updateValue(value: TFile | TFile[]): void {
    this.setInternalValue(value);
    this.$emit('input', this.internalValue);
  }

  public removeCurrentFile(): void {
    if (!this.multiple) {
      this.updateValue(null);
      // return;
    }

    // TODO:
  }

  public onFileChange(event: Event): void {
    if (this.isUploading) {
      return;
    }

    const target = event.target as HTMLInputElement;
    const files: FileList = target.files || (event as DragEvent).dataTransfer.files;
    if (!files || !files.length) {
      return;
    }

    this.processFile(files[0]);
  }

  private processFile(file: File): void {
    this.$emit('uploading', {
      url: '',
      filename: file.name,
    });
    this.isUploading = true;
    this.errorMessage = null;
    this.errorData = null;
    const reader = new FileReader();

    reader.onload = async (): Promise<void> => {
      if (!this.checkFile(file)) {
        this.isUploading = false;
        this.$emit('idle');
        return;
      }

      let uploadedFileResponse: TUploadFileResponse;
      let fileValue: TFile;
      const uploadFileData: TUploadFileParams = this.addDimensionsIfImage(file);
      try {
        uploadedFileResponse = await uploadApi.uploadFile({ ...uploadFileData });
        fileValue = {
          url: uploadedFileResponse.url,
          filename: uploadedFileResponse.filename,
        };
      } catch (error) {
        this.errorData = error.data;
      }

      if (!fileValue || !fileValue.url) {
        /* TODO: why connection_timed_out? */
        this.$emit('error', 'errors.validation.connection_timed_out', this.errorData);
        this.isUploading = false;
        this.$emit('idle');
        return;
      }

      this.isUploading = false;
      this.$emit('idle');
      if (this.multiple) {
        this.updateValue([ ...((this.internalValue || []) as TFile[]), fileValue ]);
      } else {
        this.updateValue(fileValue);
      }
    };

    reader.readAsDataURL(file);
  }

  private addDimensionsIfImage(file: File): TUploadFileParams {
    const result: TUploadFileParams = { file };

    if (!FileHelper.isImage(file.name) && !(/\.svg$/i).test(file.name)) {
      return result;
    }

    if (this.imageTargetWidth > -1) {
      result.w = this.imageTargetWidth;
    }

    if (this.imageTargetHeight > -1) {
      result.h = this.imageTargetHeight;
    }

    if( this.imageTargetMode && (result.w && result.h) ) {
      result.mode = this.imageTargetMode;
    }

    return result;
  }

  private checkFile(file: File): boolean {

    switch (this.filter) {

      case 'image':
        if (!FileHelper.isImage(file.name)) {
          this.errorMessage = this.$t('errors.validation.file_upload_failed');
          this.$emit('error', 'errors.validation.file_upload_failed');
          return false;
        }
        if (file.size > this.maxFileSizeImage * 1024 * 1024) {
          this.errorMessage = this.$t('errors.validation.file_size_too_large');
          this.$emit('error', 'errors.validation.file_size_too_large');
          return false;
        }
        break;

      case 'video':
        if (!FileHelper.isVideo(file.name)) {
          this.errorMessage = this.$t('errors.validation.file_upload_failed');
          this.$emit('error', 'errors.validation.file_upload_failed');
          return false;
        }
        if (file.size > this.maxFileSizeVideo * 1024 * 1024) {
          this.errorMessage = this.$t('errors.validation.file_size_too_large');
          this.$emit('error', 'errors.validation.file_size_too_large');
          return false;
        }
        break;

      case 'image-or-video':
        if (FileHelper.isImage(file.name)) {
          if (file.size > this.maxFileSizeImage * 1024 * 1024) {
            this.errorMessage = this.$t('errors.validation.file_size_too_large');
            this.$emit('error', 'errors.validation.file_size_too_large');
            return false;
          }
        } else if (FileHelper.isVideo(file.name)) {
          if (file.size > this.maxFileSizeVideo * 1024 * 1024) {
            this.errorMessage = this.$t('errors.validation.file_size_too_large');
            this.$emit('error', 'errors.validation.file_size_too_large');
            return false;
          }
        } else {
          this.errorMessage = this.$t('errors.validation.file_upload_failed');
          this.$emit('error', 'errors.validation.file_upload_failed');
          return false;
        }
        break;

      case 'any':
        if (file.size > this.maxFileSizeAny * 1024 * 1024) {
          this.errorMessage = this.$t('errors.validation.file_size_too_large');
          this.$emit('error', 'errors.validation.file_size_too_large');
          return false;
        }
        break;

      default:
        this.errorMessage = this.$t('errors.validation.file_upload_failed');
        this.$emit('error', 'errors.validation.file_upload_failed');
        return false;
    }

    return true;
  }

  @Watch('value', { immediate: true })
  private onValueChange(): void {
    this.setInternalValue(this.value || null);
  }

  private setInternalValue(value: TFile | TFile[]): void {
    this.internalValue = value;
    this.isEmptyValue = !this.internalValue || (this.multiple && !(this.internalValue as TFile[]).length);
  }
}
