





















import {Component, Vue} from 'vue-property-decorator';

@Component
export default class IconNews extends Vue {
};
