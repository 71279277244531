<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.2929 14.7071C12.9024 14.3166 12.9024 13.6834 13.2929 13.2929C13.6834 12.9024 14.3166 12.9024 14.7071 13.2929L26.7071 25.2929C27.0976 25.6834 27.0976 26.3166 26.7071 26.7071C26.3166 27.0976 25.6834 27.0976 25.2929 26.7071L13.2929 14.7071Z" fill="black"/>
    <circle cx="20" cy="20" r="19.5" stroke="black"/>
    <path d="M26.7071 14.7071C27.0976 14.3166 27.0976 13.6834 26.7071 13.2929C26.3166 12.9024 25.6834 12.9024 25.2929 13.2929L13.2929 25.2929C12.9024 25.6834 12.9024 26.3166 13.2929 26.7071C13.6834 27.0976 14.3166 27.0976 14.7071 26.7071L26.7071 14.7071Z" fill="black"/>
  </svg>
</template>
<script>
import Vue from 'vue';

const IconCloseQrPopup = Vue.extend({
  name: 'icon-close-qr-popup',
});
export default IconCloseQrPopup;
</script>
