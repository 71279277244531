import {ActionContext, Module} from 'vuex';
import {AxiosResponse} from 'axios';
import {TAppStoreState} from '@/_types/store/app-store-state.type';
import mailSettingsApi, {
  TRequestMailSettingsParams,
} from '@/_components/mail-settings/api/mail-settings.api.ts';
import { TMailSettingsStoreState } from '@/_components/mail-settings/types/mail-store-state.type';

const mailSettingsStore: Module<TMailSettingsStoreState, TAppStoreState> = {
  namespaced: true,
  state: {
    error: null,
  },
  getters: {},
  actions: {
    unsubscribeRequest: async ({ commit }: ActionContext<TMailSettingsStoreState, TAppStoreState>, params: TRequestMailSettingsParams): Promise<AxiosResponse> => {
      commit('setError', null);
      let response;
      try {
        response = await mailSettingsApi.unsubscribe(params);
        return response;
      } catch(error) {
        commit('setError', error);
        return error.data;
      }
    },
  },
  mutations: {
    setError(state: TMailSettingsStoreState, error: Error): void {
      state.error = error;
    },
  },
};

export default mailSettingsStore;
