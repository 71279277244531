import { TUser } from '@/_types/user.type';
import { TEvent } from '@/_types/event.type';
import { TContact } from '@/_types/contact.type';
import { TPromoPage } from '@/_types/promo-page/promo-page.type';
import ContactHelper from '@/_helpers/contact.helper';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

const HELPCRUNCH_APPLICATION_SECRET = process.env.VUE_APP_HELPCRUNCH_APPLICATION_SECRET;
const HELPCRUNCH_DOMAIN = process.env.VUE_APP_HELPCRUNCH_DOMAIN;
const HELPCRUNCH_LOCALES = (process.env.VUE_APP_HELPCRUNCH_LOCALES).split(',');
const HELPCRUNCH_UPDATE_DEBOUNCE_TIME = 3000;

export type THelpCrunchConfig = {
  user: TUser;
  event: TEvent;
  contact: TContact;
  promoPage: TPromoPage;
};

export type THelpCrunchUserConfig = {
  user_id: string;
  name: string;
  phone: string;
  email: string;
  company: string;
}

export class HelpCrunchService {

  private _locale: string = 'en';
  private _isInitialized: boolean = false;
  private _config: THelpCrunchConfig = null;
  private _configure$: Subject<void> = new Subject<void>();

  constructor() {
    this._configure$.pipe(
      debounceTime(HELPCRUNCH_UPDATE_DEBOUNCE_TIME),
    ).subscribe(() => {
      this._configure();
    });
  }

  public get locale(): string {
    return this._locale;
  }

  public set locale(locale: string) {
    if (HELPCRUNCH_LOCALES.indexOf(locale) === -1) {
      return;
    }
    this._locale = locale;
    if (this._isInitialized) {
      this._helpCrunch('setPhraseList', this._locale);
    }
  }

  public configure(config: THelpCrunchConfig): void {
    this._config = config;
    this._configure$.next();
  }

  public hideWidget(): void {
    if (!this._isInitialized) {
      return;
    }
    this._helpCrunch('hideChatWidget');
  }

  public showWidget(): void {
    if (!this._isInitialized) {
      return;
    }
    this._helpCrunch('showChatWidget');
  }

  public get _helpCrunch(): any {
    return (window as any).HelpCrunch || null;
  }

  public get isInitialized(): boolean {
    return this._isInitialized;
  }

  private _configure(): void {
    if (!this._isInitialized) {
      if (!this._helpCrunch) {
        return;
      }
      this._helpCrunch('init', HELPCRUNCH_DOMAIN, {
        applicationId: 1,
        applicationSecret: HELPCRUNCH_APPLICATION_SECRET,
        user: this._userConfig,
      });
      this._helpCrunch('setPhraseList', this._locale);
      this._isInitialized = true;
    } else {
      this._helpCrunch('updateUser', this._userConfig);
    }
  }

  private get _userId(): string {
    return this._config.user ? ('' + this._config.user.id) : '';
  }

  private get _phone(): string {
    return this._config.user ? ('' + this._config.user.phone) : '';
  }

  private get _name(): string {
    const { user, contact } = this._config;
    if (!user || !contact) {
      return '';
    }
    return ContactHelper.getFullName(contact);
  };

  private get _company(): string {
    const { user, promoPage, event } = this._config;
    if (!user) {
      return '';
    }

    let companyName = '';
    if (promoPage) {
      if (promoPage.title) {
        companyName = ('' + promoPage.title).trim();
      }
      companyName = companyName || 'Unnamed Company';
    }
    if (event && event.title) {
      companyName += (companyName ? ' ' : '') + `(${event.title})`;
    }

    return companyName;
  }

  private get _userConfig(): THelpCrunchUserConfig {
    return {
      user_id: this._userId,
      phone: this._phone,
      email: '',
      name: this._name,
      company: this._company,
    };
  }

  /* https://docs.helpcrunch.com/developers/methods/#setChatButtonOffset */
  public setChatButtonOffset(horizontal: number = 0, bottom: number = 0): void {
    if (!this.isInitialized) {
      window.setTimeout(() => {
        this.setChatButtonOffset(horizontal, bottom);
      }, 1000);
      return;
    }
    this._helpCrunch('setChatButtonOffset', horizontal, bottom);
  }

}

const helpCrunchService = new HelpCrunchService();
export default helpCrunchService;
