import { AxiosRequestConfig, AxiosResponse } from 'axios';
import BaseApi from '@/_types/api/base.api.class';

export class SeatPlanApi extends BaseApi {

  public async getSeatPlanJson(params: { eventId: number }): Promise<any> {
    const config: AxiosRequestConfig = {
      url: `event/${params.eventId}/seatplan`,
      method: 'GET',
    };

    let response;
    try {
      response = await this.axios.request<AxiosResponse>(config);
    } catch (error) {
      throw this.responseError(error);
    }

    return response.data;
  }

}

const seatPlanApi = new SeatPlanApi();
export default seatPlanApi;
