<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17 17L14 14" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <circle cx="7.5" cy="7.5" r="6.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>

</template>

<script>
import Vue from 'vue';

const IconSearch = Vue.extend({
  name: 'icon-search',
  props: {
    color: {
      type: String,
      default: '#000',
    },
  },
  computed: {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    style() {
      return `stroke: ${this.color};`;
    },
  },
});

export default IconSearch;
</script>
<style scoped lang="scss">
  /* stylelint-disable */
</style>
