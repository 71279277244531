













import Component from 'vue-class-component';
import { Vue } from 'vue-property-decorator';

@Component({
  name: 'icon-full-screen',
  props: {
    color: {
      type: String,
      default: '#fff',
    },
  },
})
export default class IconFullScreen extends Vue {

  public color: string;

  public get style(): string {
    return `fill: ${this.color}`;
  }

}
