import VueRouter, { Route } from 'vue-router';
import RouterHelper from '@/router/middleware';
import routes from '@/router/routers';
import Vue from 'vue'
import {Position, PositionResult} from 'vue-router/types/router';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to: Route, from: Route, savedPosition: Position | void): PositionResult | Promise<PositionResult> | undefined | null {
    if (to && to.meta && to.meta.isScrollToTopNeeded === false) {
      return savedPosition || {
        x: window.scrollX,
        y: window.scrollY,
      } as PositionResult;
    }
    return savedPosition || { x: 0, y: 0 } as PositionResult;
  },
});
router.beforeEach(RouterHelper.setLang);

export default router;
