import { ActionContext, Module } from 'vuex';
import { TAppStoreState } from '@/_types/store/app-store-state.type';
import { TUserStoreState } from '@/_types/store/user-store-state.type';
import AxiosCancellableRequest from '@/_types/api/axios-cancellable-request.class';
import userApi, { TGetUserInfoParams, TUserProfile } from '@/_api/user/user.api';
import { TUser } from '@/_types/user.type';
import { TUserConfig } from '@/_types/user-config.type';
import ApiErrorResponseData from '@/_types/api/api-error-response-data.class';

const getUserInfoRequest = new AxiosCancellableRequest<TGetUserInfoParams, TUser>(userApi.getUserInfo.bind(userApi));
const getUserProfileRequest = new AxiosCancellableRequest<any, TUserProfile>(userApi.getUserProfile.bind(userApi));

const _userStore: Module<TUserStoreState, TAppStoreState> = {
  namespaced: true,
  state: {
    userEntity: {
      data: null,
      isLoading: false,
      error: null,
    },
    userProfileEntity: {
      data: null,
      isLoading: false,
      error: null,
    },
    isMenuUserGuideVisible: false,
  },
  getters: {
    user: (state: TUserStoreState): TUser => {
      return state.userEntity.data;
    },
    profile: (state: TUserStoreState): TUserProfile => {
      return state.userProfileEntity.data;
    },
    isLoading: (state: TUserStoreState): boolean => {
      return state.userEntity.isLoading;
    },
    isMenuUserGuideVisible: (state: TUserStoreState): boolean => {
      return state.isMenuUserGuideVisible;
    },
  },
  actions: {

    reset: ({ commit }: ActionContext<TUserStoreState, TAppStoreState>): void => {
      commit('reset');
    },

    getUser: async (context: ActionContext<TUserStoreState, TAppStoreState>): Promise<TUser> => {
      const { commit, state } = context;

      if (state.userEntity.data) {
        return state.userEntity.data;
      }

      if (state.userEntity.isLoading) {
        try {
          return await getUserInfoRequest.promise;
        } catch (error) {
          return null;
        }
      }

      commit('userInfoRequest');
      let data;
      try {
        data = await getUserInfoRequest.load({});
        return data;
      } catch (error) {
        commit('userInfoError', error);
        return null;
      } finally {
        commit('userInfo', data);
      }
    },

    getUserProfile: async (context: ActionContext<TUserStoreState, TAppStoreState>): Promise<TUserProfile> => {
      const { commit, state } = context;

      if (state.userProfileEntity.data) {
        return state.userProfileEntity.data;
      }

      if (state.userProfileEntity.isLoading) {
        try {
          return await getUserProfileRequest.promise;
        } catch (error) {
          return null;
        }
      }

      commit('userProfileRequest');
      let data;
      try {
        data = await getUserProfileRequest.load({});
        return data;
      } catch (error) {
        commit('userProfileError', error);
        return null;
      } finally {
        commit('userProfile', data);
      }
    },

    setMenuUserGuideVisibility({ commit }: ActionContext<TUserStoreState, TAppStoreState>, isVisible: boolean): void{
      commit('setMenuUserGuideVisibility', isVisible);
    },

    patchUserConfig: async ({ commit }: ActionContext<TUserStoreState, TAppStoreState>, params: TUserConfig): Promise<boolean> => {
      const responseResult: boolean = await userApi.patchUserConfig(params);
      commit('setUserConfig', params);
      return responseResult;
    }
  },
  mutations: {
    reset(state: TUserStoreState): void {
      getUserInfoRequest.cancel();
      getUserProfileRequest.cancel();
      state.userEntity.data = null;
      state.userEntity.isLoading = false;
      state.userEntity.error = null;
      state.userProfileEntity.data = null;
      state.userProfileEntity.isLoading = false;
      state.userProfileEntity.error = null;
    },
    userInfoRequest(state: TUserStoreState): void {
      state.userEntity.data = null;
      state.userEntity.isLoading = true;
      state.userEntity.error = null;
    },
    userProfileRequest(state: TUserStoreState): void {
      state.userProfileEntity.data = null;
      state.userProfileEntity.isLoading = true;
      state.userProfileEntity.error = null;
    },
    userInfoError(state: TUserStoreState, error: ApiErrorResponseData): void {
      state.userEntity.error = error;
    },
    userProfileError(state: TUserStoreState, error: ApiErrorResponseData): void {
      state.userProfileEntity.error = error;
    },
    userInfo(state: TUserStoreState, userInfo: TUser): void {
      state.userEntity.data = userInfo || null;
      state.userEntity.isLoading = false;
    },
    userProfile(state: TUserStoreState, userProfile: TUserProfile): void {
      state.userProfileEntity.data = userProfile || null;
      state.userProfileEntity.isLoading = false;
    },
    setMenuUserGuideVisibility(state: TUserStoreState, isVisible: boolean): void {
      state.isMenuUserGuideVisible = isVisible;
    },
    setUserConfig(state: TUserStoreState, payload: TUserConfig): void {
      state.userEntity.data.config = payload;
    },
  }
};

export default _userStore;
