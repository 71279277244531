import { BehaviorSubject } from 'rxjs';
import { TBroadcast } from '@/_types/broadcasts/broadcast.type';
import { BroadcastType } from '@/_types/broadcasts/broadcast-type.enum';
import eventApi from '@/_modules/events/api/event/event.api';
import { TApiListResponse } from '@/_types/api/api-list-response.type';
import { TListOfBroadcastsItem } from '@/_types/list-of-broadcasts.type';
import store from '@/store';

const STREAMING_APP_ID = parseInt(process.env.VUE_APP_TENCENT_STREAMING_APP_ID, 10);
const PROGRAM_APP_ID = parseInt(process.env.VUE_APP_TENCENT_PROGRAM_APP_ID, 10);
const CHECK_INTERVAL_TIMEOUT = 10000;

export class BroadcastsService {

  public activeBroadcasts$: BehaviorSubject<TBroadcast[]> = new BehaviorSubject<TBroadcast[]>([]);

  private _isActiveBroadcastsCheckEnabled: boolean = false;
  private _isCheckingActiveBroadcasts: boolean = false;
  private _checkActiveBroadcastsIntervalId: number = null;
  private _eventId: number;

  private _setEventId(eventId: number): void {
    if (this._eventId === eventId) {
      return;
    }

    this.clearActiveBroadcasts();
    this._eventId = eventId || null;
  }

  public enableActiveBroadcastsCheck(eventId: number): void {

    this._setEventId(eventId);

    if (this._isActiveBroadcastsCheckEnabled) {
      return;
    }

    this._isActiveBroadcastsCheckEnabled = true;
    if (this._checkActiveBroadcastsIntervalId) {
      clearInterval(this._checkActiveBroadcastsIntervalId);
      this._checkActiveBroadcastsIntervalId = null;
    }
    this._checkActiveBroadcasts();
    this._checkActiveBroadcastsIntervalId = window.setInterval(this._checkActiveBroadcasts.bind(this), CHECK_INTERVAL_TIMEOUT);
  }

  public disableActiveBroadcastsCheck(): void {
    if (!this._isActiveBroadcastsCheckEnabled) {
      return;
    }
    this._isActiveBroadcastsCheckEnabled = false;
    if (this._checkActiveBroadcastsIntervalId) {
      clearInterval(this._checkActiveBroadcastsIntervalId);
      this._checkActiveBroadcastsIntervalId = null;
    }
  }

  public getActiveBroadcasts(): TBroadcast[] {
    return this.activeBroadcasts$.getValue();
  }

  public clearActiveBroadcasts(): void {
    this.activeBroadcasts$.next([]);
  }

  private async _checkActiveBroadcasts(): Promise<void> {

    if (this._isCheckingActiveBroadcasts || !this._eventId) {
      return;
    }

    this._isCheckingActiveBroadcasts = true;
    const activeBroadcasts: TBroadcast[] = [];

    let activeBroadcastsAPIResponse: TApiListResponse<TListOfBroadcastsItem>;
    try {
      activeBroadcastsAPIResponse = await eventApi.getListOfBroadcasts({eventId: this._eventId});
    } catch (error) {
      return;
    }

    activeBroadcastsAPIResponse.List.forEach((item: TListOfBroadcastsItem): void => {
      if (item.app_id === STREAMING_APP_ID) {
        activeBroadcasts.push({
          type: BroadcastType.PROMO_CONTACT,
          eventId: this._eventId,
          contactId: item.contact.id,
          name: item.stream_name,
          stream_url: item.stream_url,
          details: item
        });
      } else if (item.app_id === PROGRAM_APP_ID) {
        activeBroadcasts.push({
          type: BroadcastType.PROGRAM_SPEAKER,
          eventId: this._eventId,
          contactId: item.contact.id,
          name: item.stream_name,
          stream_url: item.stream_url,
          details: item
        });
      }
    });

    this.activeBroadcasts$.next(activeBroadcasts);

    await store.dispatch('promoStore/applyActiveBroadcasts', activeBroadcasts);
    await store.dispatch('promoProgramStore/applyActiveBroadcasts', activeBroadcasts);

    this._isCheckingActiveBroadcasts = false;

  }

}

const broadcastsService = new BroadcastsService();
export default broadcastsService;
