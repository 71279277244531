


import Component from 'vue-class-component';
import { Vue } from 'vue-property-decorator';
import DeviceHelper from '@/_helpers/device.helper';
import CookiesHelper from '@/_helpers/cookies-helper';
import IconCloseQrPopup from '@/_modules/icons/components/qrpopup/icon-close-qr-popup.vue';

const MOB_APP_INVITER_COOKIE_NAME = 'mobile_app_inviter_shown';
const MOB_APP_INVITER_COOKIE_VALUE = '1';

@Component({
  components: {
    IconCloseQrPopup
  }
})
export default class MobileAppHint extends Vue {

  public showFlag: boolean = false;

  public get appUrlIos(): string {
    return 'https://apps.apple.com/app/events-wallet/id1506448963';
  }

  public get appUrlAndroid(): string {
    return 'https://play.google.com/store/apps/details?id=com.biletum&hl=' + this.$i18n.locale;
  }

  public get isMobileAppInviterVisible(): boolean {
    return this.showFlag && DeviceHelper.isUserAgentMobile() && (this.isIOS || this.isAndroid);
  }

  public get isMainButtonVisible(): boolean {
    return this.isIOS || this.isAndroid;
  }

  public get isIOS(): boolean {
    return DeviceHelper.isIOS();
  }

  public get isAndroid(): boolean {
    return DeviceHelper.isAndroid();
  }

  public get mainButtonHref(): string {
    if (this.isIOS) {
      return this.appUrlIos;
    }

    if (this.isAndroid) {
      return this.appUrlAndroid;
    }

    return '';
  }

  public get OSName(): string {
    const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return 'Windows Phone';
    }

    if (/android/i.test(userAgent)) {
      return 'Android';
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'iOS';
    }

    return 'unknown';
  }

  public get appStoreName(): string {
    switch (this.OSName) {
      case 'iOS':
        return 'App Store';
      case 'Android':
        return 'Google Play';
      default:
        return '';
    }
  }

  public mounted(): void {
    const isCookieSet: boolean = CookiesHelper.readCookie(MOB_APP_INVITER_COOKIE_NAME) === MOB_APP_INVITER_COOKIE_VALUE;
    this.showFlag = !isCookieSet;
  }

  public proceedButtonClickHandler(): void {
    CookiesHelper.createCookie({
      name: MOB_APP_INVITER_COOKIE_NAME,
      value: MOB_APP_INVITER_COOKIE_VALUE,
      maxAge: 2 * 3600, // Two hours
    });
    this.showFlag = false;
  }
}
