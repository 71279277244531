import Vue from 'vue';
import { Route } from 'vue-router';
import { NavigationGuardNext } from 'vue-router/types/router';
import store from '@/store';
import router from '@/router';
import i18n, { APP_AVAILABLE_LANGUAGES } from '@/i18n';
import helpCrunchService from '@/_services/help-crunch.service';

export default class RouterHelper {

  public static authorized(to: Route, from: Route, next: NavigationGuardNext): void {
    if (!store.state.authStore.token) {
      router.push({ name: 'auth-page', params: { redirect: to.path } });
      return;
    }
    next();
  }

  public static setLang(to: Route, from: Route, next: NavigationGuardNext): void {
    if (APP_AVAILABLE_LANGUAGES.indexOf(to.params.lang) === -1) {
      router.push({
        name: to.name || undefined,
        params: Object.assign({}, to.params, { lang: i18n.locale }),
      });
      return;
    }

    if (i18n.locale !== to.params.lang) {
      i18n.locale = to.params.lang;
      Vue.moment.locale(to.params.lang);
      helpCrunchService.locale = to.params.lang;
    }
    next();
  }

  public static parseEventId(to: Route, from: Route, next: NavigationGuardNext): void {
    // N.B.: This is needed to accept eventIds like «548» as well as «123foo_bar-123-548»
    if (to && to.params && to.params.eventId) {
      to.params.eventId = to.params.eventId.split('-').pop();
    }
    next();
  }
}
