


import {Vue, Component, Prop} from 'vue-property-decorator';

const DEFAULT_SIZE = 32;
const CSS_SIZE_UNIT = 'px';

@Component
export default class EwCircularLoading extends Vue {

  @Prop({type: Number, default: DEFAULT_SIZE})
  public size: number;

  @Prop({type: String})
  public color: string;

  public get preparedSize(): string {
    return (this.size > 0 ? this.size : DEFAULT_SIZE).toString(10) + CSS_SIZE_UNIT;
  }

  public get preparedColor(): string {
    return this.color || '';
  }

  public get ewCircularLoadingStyles(): {[key: string]: any} {
    const styleObj: {[key: string]: any} = {
      width: this.preparedSize,
      height: this.preparedSize,
    };

    if (this.preparedColor) {
      styleObj['border-color'] = this.preparedColor + ' ' + 'transparent';
    }

    return styleObj;
  }
}
