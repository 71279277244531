<template>
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g fill="black">
      <path d="M8.98174 14.5017H5.64705V10.8944C5.64705 9.58973 4.58579 8.53334 3.286 8.53334C1.9862 8.53334 0.924942 9.58973 0.924942 10.8944V18.7175C0.924942 18.9999 1.15375 19.2287 1.4361 19.2287H4.77078V23.4932C4.77078 23.7755 4.99959 24.0043 5.28194 24.0043H8.98174C9.26409 24.0043 9.49289 23.7755 9.49289 23.4932V15.0129C9.49289 14.7305 9.26409 14.5017 8.98174 14.5017ZM8.47058 22.982H5.7931V18.7175C5.7931 18.4352 5.56429 18.2064 5.28194 18.2064H1.94725V10.8944C1.94725 10.1544 2.54604 9.55565 3.286 9.55565C4.02596 9.55565 4.62474 10.1544 4.62474 10.8944V15.0129C4.62474 15.2952 4.85354 15.524 5.1359 15.524H8.47058V22.982Z"/>
      <path d="M20.714 6.57688C22.5249 6.57688 24 5.10183 24 3.286C24 1.47505 22.5249 0 20.714 0C18.903 0 17.428 1.47505 17.428 3.29087C17.428 5.10183 18.903 6.57688 20.714 6.57688ZM20.714 1.02231C21.9602 1.02231 22.9777 2.03976 22.9777 3.29087C22.9777 4.54199 21.9602 5.55943 20.714 5.55943C19.4677 5.55943 18.4503 4.53712 18.4503 3.29087C18.4503 2.03976 19.4677 1.02231 20.714 1.02231Z"/>
      <path d="M16.3083 11.9945C16.3083 11.7122 16.0795 11.4834 15.7972 11.4834H8.20284C7.92048 11.4834 7.69168 11.7122 7.69168 11.9945C7.69168 12.2769 7.92048 12.5057 8.20284 12.5057H11.4888V23.4834C11.4888 23.7657 11.7176 23.9945 12 23.9945C12.2823 23.9945 12.5112 23.7657 12.5112 23.4834V12.5057H15.7972C16.0795 12.5057 16.3083 12.2769 16.3083 11.9945Z"/>
      <path d="M3.286 6.57688C5.09696 6.57688 6.57201 5.10183 6.57201 3.286C6.57201 1.47505 5.09696 0 3.286 0C1.47505 0 0 1.47505 0 3.29087C0 5.10183 1.47505 6.57688 3.286 6.57688ZM3.286 1.02231C4.53225 1.02231 5.5497 2.03976 5.5497 3.29087C5.5497 4.54199 4.53225 5.55943 3.286 5.55943C2.03976 5.55943 1.02231 4.53712 1.02231 3.29087C1.02231 2.03976 2.03976 1.02231 3.286 1.02231Z"/>
      <path d="M20.714 8.53334C19.4142 8.53334 18.3529 9.5946 18.3529 10.8944V14.5017H15.0183C14.7359 14.5017 14.5071 14.7305 14.5071 15.0129V23.4932C14.5071 23.7755 14.7359 24.0043 15.0183 24.0043H18.718C19.0004 24.0043 19.2292 23.7755 19.2292 23.4932V19.2287H22.5639C22.8462 19.2287 23.075 18.9999 23.075 18.7175V10.8944C23.075 9.58973 22.0138 8.53334 20.714 8.53334ZM22.0527 18.2064H18.718C18.4357 18.2064 18.2069 18.4352 18.2069 18.7175V22.982H15.5294V15.524H18.8641C19.1464 15.524 19.3752 15.2952 19.3752 15.0129V10.8944C19.3752 10.1544 19.974 9.55565 20.714 9.55565C21.454 9.55565 22.0527 10.1593 22.0527 10.8944V18.2064Z"/>
    </g>
  </svg>
</template>
<script>
import {Component, Vue} from 'vue-property-decorator';

@Component
export default class IconMeetings extends Vue {
};
</script>
