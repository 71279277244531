import {ActionContext, Module} from 'vuex';
import {TCategoriesStoreState} from '@/_types/store/categories-store-state.type';
import {TAppStoreState} from '@/_types/store/app-store-state.type';
import {TCategory} from '@/_types/event.type';
import categoriesApi from '@/_api/categories.api';

const categoriesStore: Module<TCategoriesStoreState, TAppStoreState> = {
  namespaced: true,
  state: {
    categories: null,
    isLoading: false,
  },
  getters: {
    categories: (state: TCategoriesStoreState): TCategory[] => state.categories || [],
    isLoading: (state: TCategoriesStoreState): boolean => state.isLoading,
  },
  actions: {

    getCategories: async ({ commit, state }: ActionContext<TCategoriesStoreState, TAppStoreState>, forced?: boolean): Promise<void> => {
      if (state.categories && !forced) {
        return;
      }

      commit('setIsLoading', true);
      await categoriesApi.getCategories()
        .then(resolve => {
          commit('setCategories', resolve);
          commit('setIsLoading', false);
        }).catch(() => {
          commit('setIsLoading', false);
        });
    },

  },
  mutations: {

    setCategories(state: TCategoriesStoreState, categories: TCategory[]): void {
      state.categories = categories;
    },

    setIsLoading(state: TCategoriesStoreState, isLoading: boolean): void {
      state.isLoading = isLoading;
    },

  },
};

export default categoriesStore;
