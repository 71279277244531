var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"avatar",class:{'avatar-modern': _vm.mode === 'avatar-modern'},style:(_vm.style)},[(_vm.mode === 'avatar-modern')?_c('div',{staticClass:"avatar-modern-wrap",class:{'avatar-modern-wrap-br10': _vm.borderRadiusMode === 'br10'}},[(_vm.src)?_c('div',{staticClass:"image image-has-image",style:({
            'background-image' : 'url(' + _vm.src + ')'
           })}):_c('div',{staticClass:"image image-default",class:( _obj = {}, _obj['image-default-' + _vm.entityName] = _vm.entityName, _obj )})]):_c('div',{staticClass:"avatar-image",style:({
      'width': (_vm.width + "px"),
      'height': (_vm.width + "px"),
      'min-width': (_vm.width + "px"),
      'min-height': (_vm.width + "px"),
      'max-width': (_vm.width + "px"),
      'max-height': (_vm.width + "px"),
      'background-image' : 'url(' + _vm.computedSrc + ')'
    })})])}
var staticRenderFns = []

export { render, staticRenderFns }