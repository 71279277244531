import { ActionContext, Module } from 'vuex';
import AxiosCancellableRequest from '@/_types/api/axios-cancellable-request.class';
import { TAppStoreState } from '@/_types/store/app-store-state.type';
import { TContactsStoreState } from '@/_modules/contacts/types/contacts-store-state.type';
import { TContact } from '@/_types/contact.type';
import contactsApi, {
  TGetEventContactsPageParams,
  TAddContactByCodeParams,
  TPutContactFileParams,
  TDeleteContactFileParams,
  TDeleteContactParams, TGetContactCountriesParams,
} from '@/_api/contacts/contacts.api';
import { TApiListResponse } from '@/_types/api/api-list-response.type';
import store from '@/store';
import { TContactCard } from '@/_types/contact-card.type';

export type TContactPermissionsParams = {
  eventId: number;
  contactId: number;
}

export type TContactPermissions = {
  contact_id: number;
  id: number;
  permissions_data: string[];
}

const OPENED_CONTACT_CARD_MAX_WIDTH = 410;
const OPENED_CONTACT_CARD_GAP = 15;
const MAX_OPENED_CONTACT_CARDS = 6;
const FALLBACK_OPENED_CONTACT_CARDS = 1;

const getMaxOpenedContactCards = (): number => {
  let result: number;
  try {
    result = Math.floor(window.screen.width / (OPENED_CONTACT_CARD_MAX_WIDTH + OPENED_CONTACT_CARD_GAP));
  } catch {
    // ignore
  }

  return result ? Math.min(result, MAX_OPENED_CONTACT_CARDS) : FALLBACK_OPENED_CONTACT_CARDS;
};

const loadManageContactPageRequest = new AxiosCancellableRequest<TGetEventContactsPageParams, TApiListResponse<TContact>>(contactsApi.getEventContactsPage.bind(contactsApi));
const contactsListPageRequest = new AxiosCancellableRequest<TGetEventContactsPageParams, TApiListResponse<TContact>>(contactsApi.getEventContactsPage.bind(contactsApi));
const searchContactsRequest = new AxiosCancellableRequest<TGetEventContactsPageParams, TApiListResponse<TContact>>(contactsApi.getEventContactsPage.bind(contactsApi));
const addContactByCodeRequest = new AxiosCancellableRequest<TAddContactByCodeParams, boolean>(contactsApi.addContactByCode.bind(contactsApi));
const getContactCountriesRequest = new AxiosCancellableRequest<TGetContactCountriesParams, string[]>(contactsApi.getContactCountries.bind(contactsApi));

const contactsPermissions = new AxiosCancellableRequest<TContactPermissionsParams, TContactPermissions>(contactsApi.contactsPermissions.bind(contactsApi));

const contactsStore: Module<TContactsStoreState, TAppStoreState> = {
  namespaced: true,
  state: {
    eventId: null,
    contactsById: {},
    contactsByIdRequested: {},
    manageContactsPage: null,
    isManageContactsPageLoading: false,
    lastError: null,
    onlineContactIds: [],
    openedContactCards: [],
    contactCountries: [],
    isContactCountriesListLoading: false,
    contactPermissions: null
  },
  getters: {

    eventId: (state: TContactsStoreState): number => {
      return state.eventId;
    },

    lastError: (state: TContactsStoreState): Error => {
      return state.lastError;
    },

    contactById: (state: TContactsStoreState) => (contactId: number): TContact => {
      const existingContact = state.contactsById[contactId] || null;

      if (!existingContact && !state.contactsByIdRequested[contactId]) {
        store.dispatch('contactsStore/requestContact', { contactId });
      }

      return existingContact;
    },

    manageContactsPage: (state: TContactsStoreState): TApiListResponse<TContact> => {
      return state.manageContactsPage;
    },

    isManageContactsPageLoading: (state: TContactsStoreState): boolean => {
      return state.isManageContactsPageLoading;
    },

    onlineContactIds: (state: TContactsStoreState): number[] => {
      return state.onlineContactIds;
    },

    isContactOnline: (state: TContactsStoreState) => (contactId: number): boolean => {
      return !!state.onlineContactIds.find(onlineContactId => onlineContactId === contactId);
    },

    openedContactCards: (state: TContactsStoreState): TContactCard[] => {
      return state.openedContactCards;
    },

    openedContactCardByContactId: (state: TContactsStoreState) => (contactId: number): TContactCard => {
      return state.openedContactCards.find(card => card.contactId === contactId);
    },

    contactCountries: (state: TContactsStoreState): string[] => {
      return state.contactCountries || [];
    },

    isContactCountriesListLoading: (state: TContactsStoreState): boolean => {
      return state.isContactCountriesListLoading;
    },

    contactPermissions: (state: TContactsStoreState): TContactPermissions => {
      return state.contactPermissions;
    }
  },
  actions: {

    reset: ({ commit }: ActionContext<TContactsStoreState, TAppStoreState>): void => {
      commit('setEventId', null);
    },

    setEventId: async ({ commit, state }: ActionContext<TContactsStoreState, TAppStoreState>, eventId: number): Promise<void> => {
      if (state.eventId === eventId) {
        return;
      }

      commit('setEventId', eventId);
    },

    requestManageContactsPage: async ({ commit }: ActionContext<TContactsStoreState, TAppStoreState>, params: TGetEventContactsPageParams): Promise<void> => {

      commit('requestManageContactPage');
      loadManageContactPageRequest.cancel();

      let data;
      try {
        data = await loadManageContactPageRequest.load(params);
        commit('contactsPageReceived', data);
      } catch (error) {
        commit('setLastError', error);
      } finally {
        commit('setManageContactPage', data);
      }
    },

    searchContacts: async ({ commit }: ActionContext<TContactsStoreState, TAppStoreState>, params: TGetEventContactsPageParams): Promise<TApiListResponse<TContact>> => {
      searchContactsRequest.cancel();
      let data;
      try {
        data = await searchContactsRequest.load(params);
        commit('contactsPageReceived', data);
        return data;
      } catch (error) {
        commit('setLastError', error);
      }
      return null;
    },

    requestContactsListPage: async ({ commit }: ActionContext<TContactsStoreState, TAppStoreState>, params: TGetEventContactsPageParams): Promise<TApiListResponse<TContact>> => {
      contactsListPageRequest.cancel();
      let data;
      try {
        data = await contactsListPageRequest.load(params);
        commit('contactsPageReceived', data);
        return data;
      } catch (error) {
        commit('setLastError', error);
      }
      return null;
    },

    requestContact: async ({ commit, state }: ActionContext<TContactsStoreState, TAppStoreState>, params: { contactId: number; force?: boolean }): Promise<void> => {
      const { contactId, force } = params;
      if (!state.eventId) {
        return;
      }
      if (state.contactsById[contactId] && !force) {
        return;
      }

      commit('markContactRequested', contactId);

      try {
        const contact = await contactsApi.getContact({
          eventId: state.eventId,
          contactId: contactId,
        });
        commit('setContact', contact);
        commit('updateOpenContactCard', contactId);
      } catch (error) {
        /* ignoring */
      }
    },

    clearContactUnreadMessages: ({ commit }: ActionContext<TContactsStoreState, TAppStoreState>, contactId: number): void => {
      commit('clearContactUnreadMessages', { contactId });
    },

    addContactByCode: async ({ commit }: ActionContext<TContactsStoreState, TAppStoreState>, params: TAddContactByCodeParams): Promise<boolean> => {

      let responseSuccess = false;
      try {
        responseSuccess = await addContactByCodeRequest.load(params);
      } catch (error) {
        commit('setLastError', error);
      }

      return responseSuccess;

    },

    deleteContact: ({ commit, state }: ActionContext<TContactsStoreState, TAppStoreState>, params: TDeleteContactParams): void => {
      try {
        contactsApi.deleteContact({ eventId: state.eventId, contactId: params.contactId });
      } catch (error) {
        commit('setLastError', error);
      }

      commit('removeContactById', params.contactId);
    },

    addFavContact: ({ commit, state }: ActionContext<TContactsStoreState, TAppStoreState>, params: { contactId: number }): void => {
      contactsApi.addFavContact({...params, eventId: state.eventId });
      commit('setContactFavorite', { contactId: params.contactId, favState: true});
    },

    removeFavContact: ({ commit, state }: ActionContext<TContactsStoreState, TAppStoreState>, params: { contactId: number }): void => {
      contactsApi.removeFavContact({...params, eventId: state.eventId });
      commit('setContactFavorite', { contactId: params.contactId, favState: false});
    },

    putContactFile: async ({ commit }: ActionContext<TContactsStoreState, TAppStoreState>, params: TPutContactFileParams): Promise<void> => {
      await contactsApi.putContactFile(params)
        .then(() => {
          /* ignore */
        }).catch((e: Error) => {
          commit('setLastError', e);
        });
    },

    deleteContactFile: async ({ commit }: ActionContext<TContactsStoreState, TAppStoreState>, params: TDeleteContactFileParams): Promise<void> => {
      await contactsApi.deleteContactFile(params)
        .then(() => {
          /* ignore */
        }).catch((e: Error) => {
          commit('setLastError', e);
        });
    },

    setOnlineContactIds: ({ commit }: ActionContext<TContactsStoreState, TAppStoreState>, params: number[]): void => {
      commit('setOnlineContactIds', params);
    },

    clearOnlineContactIds: ({ commit }: ActionContext<TContactsStoreState, TAppStoreState>): void => {
      commit('setOnlineContactIds', []);
    },

    openContactCard: ({ commit, getters }: ActionContext<TContactsStoreState, TAppStoreState>, params: { contactId: number; startupTabName?: string }): void => {
      const { contactId, startupTabName } = params;

      if (!contactId) {
        return;
      }

      const contact: TContact = getters.contactById(contactId) || (store.getters['standaloneContactStore/contact'] || null);
      commit('openContactCard', {
        contactId,
        contact: contact,
        startupTabName,
      });

      window.setTimeout(() => {
        commit('maximizeContactCardByContactId', contactId);
      }, 100);
    },

    closeContactCard: ({ commit }: ActionContext<TContactsStoreState, TAppStoreState>, params: { contactId: number }): void => {
      if (!params || !params.contactId) {
        return;
      }
      commit('closeContactCard', params.contactId);
    },

    setContactCardIsMinimized: ({ commit }: ActionContext<TContactsStoreState, TAppStoreState>, params: { contactId: number; isMinimized: boolean }): void => {
      const { contactId, isMinimized } = params;
      if (isMinimized) {
        commit('minimizeContactCardByContactId', contactId);
      } else {
        commit('maximizeContactCardByContactId', contactId);
      }
    },

    toggleContactCardIsMinimized: ({ commit }: ActionContext<TContactsStoreState, TAppStoreState>, params: { contactId: number }): void => {
      const { contactId } = params;
      commit('toggleContactCardIsMinimized', contactId);
    },

    clearOpenedContactCards: ({ commit }: ActionContext<TContactsStoreState, TAppStoreState>): void => {
      commit('clearOpenedContactCards');
    },

    getContactCountries: async ({ commit }: ActionContext<TContactsStoreState, TAppStoreState>, params: TGetContactCountriesParams): Promise<void> => {

      commit('requestContactCountries');
      getContactCountriesRequest.cancel();

      let data;
      try {
        data = await getContactCountriesRequest.load(params);
        commit('contactCountriesReceived');
      } catch (error) {
        commit('setLastError', error);
      } finally {
        commit('setContactCountriesList', data);
      }
    },

    resetContactCountries: ({ commit }: ActionContext<TContactsStoreState, TAppStoreState>): void => {
      commit('setContactCountriesList', []);
    },

    contactsPermissions: async ({ commit }: ActionContext<TContactsStoreState, TAppStoreState>, params: TContactPermissionsParams): Promise<TContactPermissions> => {

      let responseSuccess = null;
      try {
        responseSuccess = await contactsPermissions.load(params);
      } catch (error) {
        commit('setLastError', error);
      }

      commit('contactPermissions', responseSuccess);

      return responseSuccess;

    },

  },
  mutations: {

    setEventId(state: TContactsStoreState, eventId: number): void {
      if (state.eventId === eventId) {
        return;
      }

      loadManageContactPageRequest.cancel();
      contactsListPageRequest.cancel();

      state.eventId = eventId;
      state.contactsById = {};
      state.contactsByIdRequested = {};
      state.contactCountries = [];
      state.lastError = null;

      state.manageContactsPage = null;
      state.isManageContactsPageLoading = false;
    },

    setLastError(state: TContactsStoreState, lastError: Error): void {
      state.lastError = lastError;
    },

    requestManageContactPage(state: TContactsStoreState): void {
      state.isManageContactsPageLoading = true;
    },

    removeContactById(state: TContactsStoreState, contactId: number): void {
      if (state.contactsById[contactId]) {
        delete state.contactsByIdRequested[contactId];
        delete state.contactsById[contactId];
      }
    },

    setManageContactPage(state: TContactsStoreState, data: TApiListResponse<TContact>): void {
      state.isManageContactsPageLoading = false;

      if (!data) {
        return;
      }

      state.manageContactsPage = data;
    },

    contactsPageReceived(state: TContactsStoreState, data: TApiListResponse<TContact>): void {
      if (!data) {
        return;
      }
      data.List.forEach((contact: TContact): void => {
        state.contactsByIdRequested[contact.id] = true;
        state.contactsById[contact.id] = contact;
      });
    },

    markContactRequested(state: TContactsStoreState, contactId: number): void {
      state.contactsByIdRequested[contactId] = true;
    },

    setContact(state: TContactsStoreState, contact: TContact): void {
      if (!contact) {
        return;
      }
      state.contactsByIdRequested[contact.id] = true;
      state.contactsById = {
        ...state.contactsById,
        [contact.id]: contact,
      };
    },

    setContactFavorite(state: TContactsStoreState, payload: { contactId: number; favState: boolean }): void {
      const { contactId, favState } = payload;
      if (!state.contactsById[contactId]) {
        return;
      }
      state.contactsById[contactId].is_favorite = favState;
    },

    clearContactUnreadMessages(state: TContactsStoreState, payload: { contactId: number }): void {
      const { contactId } = payload;
      if (!state.contactsById[contactId]) {
        return;
      }
      state.contactsById[contactId].unread_messages = 0;
    },

    setOnlineContactIds(state: TContactsStoreState, payload: number[]): void {
      state.onlineContactIds = payload;
    },

    openContactCard(state: TContactsStoreState, payload: { contactId: number; contact?: TContact; startupTabName?: string }): void {
      const { contactId, contact, startupTabName } = payload;
      const alreadyOpened: TContactCard = state.openedContactCards.find(card => card.contactId === contactId);

      if (alreadyOpened) {
        alreadyOpened.isMinimized = false;
        return;
      }

      if ((state.openedContactCards.length + 1) > getMaxOpenedContactCards()) {
        const lastCard = state.openedContactCards[state.openedContactCards.length - 1];
        lastCard.contactId = contactId;
        lastCard.isMinimized = false;
        lastCard.contact = contact;
        lastCard.startupTabName = startupTabName;
        return;
      }

      state.openedContactCards.push({
        contactId,
        isMinimized: true,
        contact: contact || null,
        startupTabName,
      });

    },

    updateOpenContactCard(state: TContactsStoreState, contactId: number): void {
      if (!contactId || !state.contactsByIdRequested[contactId]) {
        return;
      }
      const alreadyOpenedContact = state.openedContactCards.find(contactCard => contactCard.contactId === contactId);
      alreadyOpenedContact.contact = state.contactsById[contactId];
    },

    closeContactCard(state: TContactsStoreState, contactId: number): void {
      state.openedContactCards = state.openedContactCards.filter(card => card.contactId !== contactId);
    },

    minimizeContactCardByContactId(state: TContactsStoreState, contactId: number): void {
      const targetCard: TContactCard = state.openedContactCards.find(card => card.contactId === contactId);
      if (targetCard) {
        targetCard.isMinimized = true;
      }
    },

    maximizeContactCardByContactId(state: TContactsStoreState, contactId: number): void {
      const targetCard: TContactCard = state.openedContactCards.find(card => card.contactId === contactId);
      if (targetCard) {
        targetCard.isMinimized = false;
      }
    },

    toggleContactCardIsMinimized(state: TContactsStoreState, contactId: number): void {
      const targetCard: TContactCard = state.openedContactCards.find(card => card.contactId === contactId);
      if (targetCard) {
        targetCard.isMinimized = !targetCard.isMinimized;
      }
    },

    clearOpenedContactCards(state: TContactsStoreState): void {
      state.openedContactCards = [];
    },

    requestContactCountries(state: TContactsStoreState): void {
      state.isContactCountriesListLoading = true;
    },

    contactCountriesReceived(state: TContactsStoreState): void {
      state.isContactCountriesListLoading = false;
    },

    setContactCountriesList(state: TContactsStoreState, data: string[]): void {
      state.contactCountries = data;
    },

    contactPermissions(state: TContactsStoreState, contactPermissions: TContactPermissions): void {
      state.contactPermissions = contactPermissions;
    },

  },
};

export default contactsStore;
