


import { Vue, Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { TEvent } from '@/_types/event.type';
import { TContact } from '@/_types/contact.type';
import EventHelper from '@/_helpers/event.helper';
import EwButton from '@/_modules/standalone-company/components/UI/Ew-Button/Ew-Button.vue';

@Component({
  components: {
    EwButton,
  },
})
export default class EditEntityButton extends Vue {

  @Getter('_eventStore/event') event: TEvent;
  @Getter('authStore/isAuthenticated') isAuthenticated: boolean;
  @Getter('promoPageStore/contact') myself: TContact;

  @Prop({ type: Boolean, default: false })
  private readonly isForcedVisible: boolean;

  @Prop({ type: String, default: 'promo-page-cabinet' })
  private readonly targetRouteName: string;

  public get eventEditors(): TContact[] {
    if (!this.event || !this.event.editors) {
      return [];
    }

    return this.event.editors;
  }

  public get isEventOrganizer(): boolean {
    return EventHelper.isContactEventOrganizer(this.event, this.myself);
  }

  public get isEventEditor(): boolean {
    if (!this.myself) {
      return false;
    }
    return !this.isEventOrganizer && !!this.eventEditors.find((editor: TContact) => editor.id === this.myself.id);
  }

  public get isButtonVisible(): boolean {
    return this.isForcedVisible || this.isEventOrganizer || this.isEventEditor;
  }

  public onClick(): void {
    this.$router.push({
      name: this.targetRouteName,
    }).catch(() => { /* ignore */ });
  }

}
