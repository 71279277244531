import BaseApi from '@/_types/api/base.api.class';
import { AxiosRequestConfig, AxiosResponse} from 'axios';

export type TRequestMailSettingsParams = {
  event_id: number;
  contact_id: number;
  email: string;
  hash: string;
};

export class MailSettingsApi extends BaseApi {
  public async unsubscribe(params: TRequestMailSettingsParams): Promise<AxiosResponse<any>> { // TODO: set type
    const config: AxiosRequestConfig = {
      url: `/event/${params.event_id}/contact/${params.contact_id}/notification/settings`,
      method: 'POST',
      params: Object.assign({
        email: undefined,
        hash: undefined,
        allow_event_summary: undefined,
        allow_new_meeting_request: undefined,
        allow_meeting_reminder: undefined,
        allow_meeting_confirm: undefined,
        allow_new_message: undefined,
      }, params),
    };

    try {
      return await this.axios.request(config);
    } catch (error) {
      throw this.responseError(error);
    }
  }

}

const mailSettingsApi = new MailSettingsApi();
export default mailSettingsApi;
