<template>
  <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M7.15388 8.57007C4.13964 8.64149 2.17332 6.01089 1.52091 4.99736C2.23912 3.85098 4.04794 1.50394 6.84657 1.43108C9.84891 1.35323 11.8264 3.99026 12.4788 5.00379C11.7613 6.15017 9.95181 8.49721 7.15388 8.57007ZM13.9076 4.64523C13.461 3.85098 10.9942 -0.130282 6.81091 0.00328353C2.94126 0.103279 0.690733 3.5817 0.092926 4.64523C-0.0309755 4.86522 -0.0309755 5.13592 0.092926 5.35591C0.533231 6.13945 2.91326 10 7.01741 10C7.07481 10 7.13221 9.99929 7.18961 9.99786C11.0586 9.89715 13.3098 6.41944 13.9076 5.35591C14.0308 5.13592 14.0308 4.86522 13.9076 4.64523Z"
          fill="#00B6F8"/>
    <path
      d="M3.8244 4.47022C4.20253 4.99634 5.34218 6.3619 7.08919 6.32483C8.71083 6.28701 9.7596 5.06865 10.1755 4.47355L11.0035 5.18476C10.6571 5.73684 9.35228 7.54214 7.1099 7.59442C7.07663 7.59516 7.04336 7.59553 7.01009 7.59553C4.63139 7.59553 3.25196 5.59149 2.99676 5.18476L3.8244 4.47022Z"
      fill="#00B6F8"/>
  </svg>
</template>
<script>
import Vue from 'vue';

const IconEwPasswordEyeClosed = Vue.extend({
  name: 'icon-ew-password-eye-closed',
});
export default IconEwPasswordEyeClosed;
</script>
