import { ActionContext, Module } from 'vuex';
import { TAppStoreState } from '@/_types/store/app-store-state.type';
import AxiosCancellableRequest from '@/_types/api/axios-cancellable-request.class';
import notesApi, {TGetNotesParams, TCreateOrEditNoteParams} from '@/_api/notes/notes.api';
import {TApiListResponse} from '@/_types/api/api-list-response.type';
import {TNote} from '@/_types/store/note.type';
import {TStoreEntityState} from '@/_types/store/store-entity-state.type';
import ApiErrorResponseData from '@/_types/api/api-error-response-data.class';

const getNotesRequest = new AxiosCancellableRequest<TGetNotesParams, TApiListResponse<TNote>>(notesApi.getAllNotes.bind(notesApi));
const getNotesMyRequest = new AxiosCancellableRequest<TGetNotesParams, TApiListResponse<TNote>>(notesApi.getMyNotes.bind(notesApi));
const addNote = new AxiosCancellableRequest<TCreateOrEditNoteParams, TNote>(notesApi.addNote.bind(notesApi));
const editNote = new AxiosCancellableRequest<TCreateOrEditNoteParams, boolean>(notesApi.editNote.bind(notesApi));
const removeNote = new AxiosCancellableRequest<TGetNotesParams, boolean>(notesApi.removeNote.bind(notesApi));
const addFav = new AxiosCancellableRequest<number, boolean>(notesApi.addFav.bind(notesApi));
const removeFav = new AxiosCancellableRequest<number, boolean>(notesApi.removeFav.bind(notesApi));

type TNotesStoreState = {
  eventId: number;
  notesStoreStateEntity: TStoreEntityState<TApiListResponse<TNote>>;
}

const notesStore: Module<TNotesStoreState, TAppStoreState> = {
  namespaced: true,
  state: {
    eventId: null,
    notesStoreStateEntity: {
      data: null,
      isLoading: false,
      error: null,
    },
  },
  getters: {
    eventId: (state: TNotesStoreState): number => {
      return state.eventId;
    },
    isNotesStoreStateEntityLoading: (state: TNotesStoreState): boolean => {
      return state.notesStoreStateEntity.isLoading;
    },
    notesStoreStateEntity: (state: TNotesStoreState): TApiListResponse<TNote> => {
      return state.notesStoreStateEntity.data;
    },
  },
  actions: {

    getAllNotes: async ({ commit, state }: ActionContext<TNotesStoreState, TAppStoreState>, params: TGetNotesParams): Promise<TApiListResponse<TNote>> => {
      const {eventId} = params;
      if (state.eventId !== eventId) {
        commit('setEventId', eventId);
      }

      let data;

      try {
        data = await getNotesRequest.load(params);
        commit('setNoteList', data);
        return data;
      } catch (error) {
        commit('noteListError', error);
        return null;
      } finally {
        commit('setNoteList', data);
      }
    },

    getMyNotes: async ({ commit, state }: ActionContext<TNotesStoreState, TAppStoreState>, params: TGetNotesParams): Promise<TApiListResponse<TNote>> => {
      const {eventId} = params;
      if (state.eventId !== eventId) {
        commit('setEventId', eventId);
      }

      let data;

      try {
        data = await getNotesMyRequest.load({ eventId });
        commit('setNoteList', data);
        return data;
      } catch (error) {
        commit('noteListError', error);
        return null;
      } finally {
        commit('setNoteList', data);
      }
    },

    addNote: async ({ commit }: ActionContext<TNotesStoreState, TAppStoreState>, params: TCreateOrEditNoteParams): Promise<TNote> => {
      try {
        return await addNote.load(params);
      } catch (error) {
        commit('noteListError', error);
        return null;
      }
    },

    editNote: async ({ commit }: ActionContext<TNotesStoreState, TAppStoreState>, params: TCreateOrEditNoteParams): Promise<boolean> => {
      try {
        return await editNote.load(params);
      } catch (error) {
        commit('noteListError', error);
        return false;
      }
    },

    removeNote: async ({ commit }: ActionContext<TNotesStoreState, TAppStoreState>, params): Promise<boolean> => {
      try {
        return await removeNote.load(params);
      } catch (error) {
        commit('noteListError', error);
        return false;
      }
    },

    addFav: async ({ commit }: ActionContext<TNotesStoreState, TAppStoreState>, params): Promise<boolean> => {
      try {
        return await addFav.load(params);
      } catch (error) {
        commit('noteListError', error);
        return false;
      }
    },

    removeFav: async ({ commit }: ActionContext<TNotesStoreState, TAppStoreState>, params): Promise<boolean> => {
      try {
        return await removeFav.load(params);
      } catch (error) {
        commit('noteListError', error);
        return false;
      }
    },

  },
  mutations: {

    setEventId(state: TNotesStoreState, eventId: number): void {
      if (state.eventId === eventId) {
        return;
      }

      state.eventId = eventId || null;
      state.notesStoreStateEntity.data = null;
      state.notesStoreStateEntity.isLoading = false;
      state.notesStoreStateEntity.error = null;
    },

    setNoteList(state: TNotesStoreState, noteList: TApiListResponse<TNote>): void {
      state.notesStoreStateEntity.data = noteList || null;
      state.notesStoreStateEntity.isLoading = false;
    },

    noteListError(state: TNotesStoreState, error: ApiErrorResponseData): void {
      state.notesStoreStateEntity.error = error;
    },

  },
};

export default notesStore;
