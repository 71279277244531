


import {Vue, Component, Prop, Ref} from 'vue-property-decorator';
import IconEwInputError from '@/_modules/icons/components/icon-ew-input-error.vue';
import ApiErrorResponseData from '@/_types/api/api-error-response-data.class';
import ErrorInfo from '@/_modules/error-info/error-info.vue';
import IconEwInputQuestion from '@/_modules/icons/components/icon-ew-input-question.vue';

@Component({
  components: {
    IconEwInputError,
    ErrorInfo,
    IconEwInputQuestion,
  }
})
export default class EwInput extends Vue {

  @Ref('input') inputElement: HTMLInputElement;

  public isFocused: boolean = false;

  @Prop({type: String, default: ''})
  public readonly value: string;

  @Prop({type: String, default: ''})
  public readonly labelText: string;

  @Prop({type: String, default: ''})
  public readonly placeholderText: string;

  @Prop({type: String, default: ''})
  public readonly errorText: string;

  @Prop({ type: Object, default: (): ApiErrorResponseData => null })
  public readonly errorData: ApiErrorResponseData;

  @Prop({type: String, default: ''})
  public readonly helpText: string;

  @Prop({type: Boolean, default: false})
  public readonly disabled: boolean;

  @Prop({type: Boolean, default: false})
  public readonly required: boolean;

  @Prop({type: Boolean, default: false})
  public readonly fullWidth: boolean;

  @Prop({type: Number})
  public readonly minlength: number;

  @Prop({type: Number})
  public readonly maxlength: number;

  @Prop({type: Boolean, default: true})
  public readonly showErrorIcon: boolean;

  @Prop({type: Boolean, default: true})
  public readonly showActionIcon: boolean;

  @Prop({type: Boolean, default: false})
  public readonly showActionSlot: boolean;

  @Prop({type: String, default: null})
  public readonly autocomplete: string;

  public get isBottomTextEmpty(): boolean {
    return this.helpText === '' && this.errorText === '';
  }

  public onLabelClick(): void {
    this.inputElement.focus();
  }

  public onInputFocus(): void {
    this.isFocused = true;
    this.$emit('ew-input-focus');
  }

  public onInputBlur(): void {
    this.isFocused = false;
    this.$emit('ew-input-blur');
  }

}
