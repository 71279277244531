import { TAppStoreState } from '@/_types/store/app-store-state.type';
import { ActionContext } from 'vuex/types';
import { TSideBarLeftStoreState } from '@/_modules/promo/types/side-bar-left-store-state.type';
import { Module } from 'vuex';

const sideBarLeftStore: Module<TSideBarLeftStoreState, TAppStoreState> = {
  namespaced: true,
  state: {
    isOpen: false,
    defaultScreen: ''
  },
  getters: {
    isOpen: (state: TSideBarLeftStoreState): boolean => {
      return state.isOpen;
    },
    getDefaultScreen: (state: TSideBarLeftStoreState): string => {
      return state.defaultScreen;
    },
  },
  actions: {

    open: ({ commit }: ActionContext<TSideBarLeftStoreState, TAppStoreState>): void => {
      commit('open');
    },

    close: ({ commit }: ActionContext<TSideBarLeftStoreState, TAppStoreState>): void => {
      commit('close');
    },

    toggle: ({ commit }: ActionContext<TSideBarLeftStoreState, TAppStoreState>): void => {
      commit('toggle');
    },
    defaultScreen: ({ commit }: ActionContext<TSideBarLeftStoreState, TAppStoreState>, routeName): void => {
      commit('defaultScreen', routeName);
    },

  },
  mutations: {

    open(state: TSideBarLeftStoreState): void {
      state.isOpen = true;
    },

    close(state: TSideBarLeftStoreState): void {
      state.isOpen = false;
    },

    toggle(state: TSideBarLeftStoreState): void {
      state.isOpen = !state.isOpen;
    },
    defaultScreen(state: TSideBarLeftStoreState, routeName: string): void {
      state.defaultScreen = routeName;
    },

  },
};

export default sideBarLeftStore;
