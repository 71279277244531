


import {Component, Vue, Prop} from 'vue-property-decorator';
import {Action, Getter} from 'vuex-class';
import {TEvent} from '@/_types/event.type';
import {TEventSettings} from '@/_types/event-settings.type';
import {TGetEventSettingsParams} from '@/_modules/events/api/event/event.api';
import {APP_AVAILABLE_LANGUAGES} from '@/i18n';
import {
  MENU_PROPERTIES_KEY_NAME,
  MENU_CUSTOM_TITLE_KEY_NAME
} from '@/_modules/promo-cabinet/components/cabinet-event-settings/cabinet-event-settings.vue';
import EwInput from '@/_modules/standalone-company/components/UI/ew-input/ew-input.vue';
import EwButton from '@/_modules/standalone-company/components/UI/Ew-Button/Ew-Button.vue';

enum MenuNameEditorLanguageListModes {
  EVENT_LANGUAGES = 'event-languages',
  APP_LANGUAGES = 'app-languages',
}

@Component({
  components: {
    EwInput,
    EwButton,
  }
})
export default class EditMenuItemName extends Vue {

  @Getter('_eventStore/event') event: TEvent;
  @Getter('_eventStore/eventSettings') eventSettings: TEventSettings;
  @Getter('_eventStore/isEventSettingsPolled') isEventSettingsPolled: boolean;

  @Action('_eventStore/getEventSettings') getEventSettings: (params: TGetEventSettingsParams) => Promise<void>;

  @Prop({type: String, default: ''})
  public readonly menuItemName: string;

  @Prop({type: String, default: ''})
  public readonly menuItemAlias: string;

  public orderedLanguages: string[] = APP_AVAILABLE_LANGUAGES; // N.B. for fixed ordering in the v-for
  public isDictionaryInitialized: boolean = false;
  public dictionary: { [key: string]: string } = {};
  public previewLang: string = 'en';
  public languageListMode: MenuNameEditorLanguageListModes = MenuNameEditorLanguageListModes.EVENT_LANGUAGES;

  public get eventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  public get eventLanguages(): string[] {
    return (this.event && this.event.languages) || ['en'];
  }

  public get layout(): any {
    return (this.eventSettings && this.eventSettings.layout) || null;
  }

  public get menuProps(): { [key: string]: any } {
    return (this.layout && this.layout[MENU_PROPERTIES_KEY_NAME]) || null;
  }

  public get previewTitle(): string {
    return (this.dictionary && this.dictionary[this.previewLang]) || this.menuItemName;
  }

  public get isLangModeSelectorOpen(): boolean {
    return this.languageListMode === MenuNameEditorLanguageListModes.APP_LANGUAGES;
  }

  public mounted(): void {
    this.initDictionary();
  }

  public onLangInputFocus(langCode: string): void {
    this.previewLang = langCode;
  }

  public onLangInputBlur(langCode: string): void {
    const val = this.dictionary[langCode] || '';
    this.dictionary[langCode] = val.trim().replace(/\s{2,}/g, ' ');
  }

  public async initDictionary(): Promise<void> {
    if (!this.isEventSettingsPolled) {
      await this.getEventSettings({eventId: this.eventId});
      window.setTimeout(this.initDictionary, 200);
      return;
    }

    this.createEmptyDictionary();

    if (!this.menuProps) {
      // TODO: shall we do smth when no properties object?
      this.isDictionaryInitialized = true;
      return;
    }

    const existingTitles: { [key: string]: string } = (this.menuProps[this.menuItemAlias] && this.menuProps[this.menuItemAlias][MENU_CUSTOM_TITLE_KEY_NAME]) || null;
    if (existingTitles) {
      this.dictionary = Object.assign({}, this.dictionary, existingTitles);
    }

    this.isDictionaryInitialized = true;
  }

  public onSave(): void {
    this.$emit('save', this.dictionary);
  }

  public onClear(): void {
    this.createEmptyDictionary();
  }

  public createEmptyDictionary(): void {
    APP_AVAILABLE_LANGUAGES.forEach(langCode => {
      this.dictionary[langCode] = '';
    });
  }

  public isLanguageInputVisible(langCode: string): boolean {
    if (this.languageListMode === MenuNameEditorLanguageListModes.APP_LANGUAGES) {
      return true;
    } else if (this.languageListMode === MenuNameEditorLanguageListModes.EVENT_LANGUAGES) {
      return Array.isArray(this.eventLanguages) && this.eventLanguages.indexOf(langCode) >= 0;
    }
    return true;
  }

  public onLanguageModeSelectorClick(): void {
    if (this.languageListMode === MenuNameEditorLanguageListModes.APP_LANGUAGES) {
      this.languageListMode = MenuNameEditorLanguageListModes.EVENT_LANGUAGES;
    } else if (this.languageListMode === MenuNameEditorLanguageListModes.EVENT_LANGUAGES) {
      this.languageListMode = MenuNameEditorLanguageListModes.APP_LANGUAGES;
    }
  }

}
