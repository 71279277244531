
export default class ValidationHelper {

  public static isValidVideoStreamEmbed(value: string): boolean {
    if (value === null || value === undefined || value === '') {
      return true;
    }
    return (new RegExp('^<iframe(?:\\s)+[^>]*src=[^>]*((></iframe>)|(/>))$', 'im')).test(value);
  }

  public static isValidPhoneNumberLength(phone: string): boolean {
    if (!phone) {
      return true;
    }
    return (phone.length > 9);
  }

  public static isValidPhoneNumber(value: string): boolean {
    if (value === null || value === undefined || value === '') {
      return true;
    }
    return (new RegExp(/^[[+]\d]?(?:[\d]*)$/, )).test(value);
  }

  public static isValidExternalId(string: string): boolean {

    const disallowedSymbols = /[`~!@#$%^&*()|+=?;:'",\s<>{}[\]\\/]/g;

    return !string.match(disallowedSymbols);

  }

  public static isValidEmail(inputString: string): boolean {

    const emailRegexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return !!inputString.match(emailRegexp);

  }

  public static isValidUrl(value: string): boolean {

    if (value === null || value === undefined || value === '') {
      return true;
    }

    const urlRegex = /(https?:\/\/[^\s]+)/g;

    return !!value.match(urlRegex);

  }
}
