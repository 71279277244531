import moment from 'moment';
import store from '@/store';
import { TMessage } from '@/_types/messages.type';
import { NotificationType } from '@/_modules/promo/types/notification-type.enum';
import ContactHelper from '@/_helpers/contact.helper';
import { TMeeting } from '@/_types/meeting/meeting.type';
import broadcastsService from '@/_services/broadcasts.service';
import { TBroadcast } from '@/_types/broadcasts/broadcast.type';
import { TConferenceProgram } from '@/_modules/promo/types/conference-program.type';
import { TConferenceRoom } from '@/_modules/promo/types/conference-room.type';
import {
  POPUP_NOTIFICATION_INTERVAL_BETWEEN,
  POPUP_NOTIFICATION_ANIMATION_TIME,
  POPUP_NOTIFICATION_DISAPPEAR_TIMEOUT,
  POPUP_NOTIFICATIONS_LIMIT
} from '@/_modules/promo/store/notifications.store';

const POLLING_INTERVAL = 10000;

class NotificationsService {

  private eventId: number;
  private contactId: number;
  private userId: number;
  private pollingIntervalId: number;
  private isActive: boolean = false;
  private isChecking: boolean = false;
  private maxPopupsAmount: number = POPUP_NOTIFICATIONS_LIMIT;
  private activePopupsCount: number = 0;

  public configure(config: { eventId: number; contactId: number; userId: number }): void {
    const eventId = config.eventId || null;
    const contactId = config.contactId || null;
    const userId = config.userId || null;
    if (eventId === this.eventId && contactId === this.contactId && userId === this.userId) {
      return;
    }

    this.eventId = eventId;
    this.contactId = contactId;
    this.userId = userId;

    store.dispatch('notificationsStore/reset');

    if (!this.eventId || !this.contactId || !this.userId) {
      this.isActive = false;
      this.stopPollingInterval();
    } else {
      this.isActive = true;
      this.startPollingInterval();
    }
  }

  private stopPollingInterval(): void {
    if (!this.pollingIntervalId) {
      return;
    }
    window.clearInterval(this.pollingIntervalId);
    this.pollingIntervalId = null;
  }

  private startPollingInterval(): void {
    if (this.pollingIntervalId) {
      return;
    }
    this.pollingIntervalId = window.setInterval(this.pollingRoutinesDispatcher.bind(this), POLLING_INTERVAL);
  }

  private async pollingRoutinesDispatcher(): Promise<void> {
    if (!this.isActive || !this.eventId || !this.contactId || !this.userId || this.isChecking) {
      return;
    }

    this.isChecking = true;

    const promises: Promise<void>[] = [];

    promises.push(
      this.updateBroadcastsNotifications(),
      this.updateProgramSessionsNotifications()
    );
    await Promise.all(promises);

    this.isChecking = false;
  }

  private incrementActivePopupsCount(): void {
    this.activePopupsCount++;
    if (this.activePopupsCount > this.maxPopupsAmount) {
      this.activePopupsCount = this.maxPopupsAmount;
    }
  }

  private decrementActivePopupsCount(): void {
    this.activePopupsCount--;
    if (this.activePopupsCount <= 0) {
      this.activePopupsCount = 0;
    }
  }

  private delayedDecrementActivePopupsCount(): void {
    const delay: number = POPUP_NOTIFICATION_INTERVAL_BETWEEN + POPUP_NOTIFICATION_ANIMATION_TIME + POPUP_NOTIFICATION_DISAPPEAR_TIMEOUT;
    setTimeout(() => {
      this.decrementActivePopupsCount();
    }, delay);
  }

  private prepareMeetingData(meeting: TMeeting): TMeeting {
    if (!meeting.creator_contact.fullName) {
      meeting.creator_contact.fullName = ContactHelper.getFullName(meeting.creator_contact);
    }
    if (!meeting.creator_contact.fullCompany) {
      meeting.creator_contact.fullCompany = ContactHelper.getFullCompany(meeting.creator_contact);
    }

    return meeting;
  }

  private async updateBroadcastsNotifications(): Promise<void> {

    broadcastsService.enableActiveBroadcastsCheck(this.eventId);
    const broadcasts: TBroadcast[] = broadcastsService.getActiveBroadcasts();

    for (let i = 0; i < broadcasts.length; i++) {
      if (!broadcasts[i].details || !broadcasts[i].details.promopage || (this.contactId === broadcasts[i].contactId)) {
        continue;
      }
      // TODO: session notifications work in a separate way, AW-1613, see this.updateProgramSessionsNotifications
      if (broadcasts[i].type === 'program-speaker') {
        continue;
      }
      store.dispatch('notificationsStore/push', {
        id: 'broadcast_company_' + broadcasts[i].details.promopage.external_id,
        entityId: broadcasts[i].details.promopage.id,
        type: NotificationType.NEW_COMPANY_BROADCAST_STARTED,
        time: moment(),
        external_id: broadcasts[i].details.promopage.external_id,
        skipPopup: this.activePopupsCount >= this.maxPopupsAmount,
      });

      this.incrementActivePopupsCount();
      this.delayedDecrementActivePopupsCount();
    }

  }

  private async updateProgramSessionsNotifications(): Promise<void> {

    const MS_TO_SESSION_START = 7000;
    const nowDate = new Date();
    let conferenceRooms: TConferenceRoom[] = [];
    let sessions: TConferenceProgram[] = [];

    if (!store || !store.getters || !store.getters['promoProgramStore/conferenceRooms']) {
      return;
    }

    conferenceRooms = store.getters['promoProgramStore/conferenceRooms'];
    conferenceRooms.forEach((confRoom) => {
      sessions.push(...confRoom.programs);
    });

    sessions = sessions.filter((session) => {
      const sessionTimestamp: number = ((session.date_start as unknown) as Date).getTime();
      const nowTimestamp: number = nowDate.getTime();
      const millisecondDifference: number = sessionTimestamp - nowTimestamp;
      return ((sessionTimestamp >= nowTimestamp) && (MS_TO_SESSION_START >= millisecondDifference));
    });

    for (let i = 0; i < sessions.length; i++) {
      const session = sessions[i];
      if (!session.conference_id || !session.id) {
        continue;
      }

      store.dispatch('notificationsStore/push', {
        id: ['conferenceProgram', this.eventId, session.conference_id, session.id].join('_'),
        entityId: session.id,
        type: NotificationType.CONFERENCE_PROGRAM_STARTED,
        time: moment((session.date_start as unknown) as Date),
        isFading: false,
        conferenceProgram: session,
        skipPopup: this.activePopupsCount >= this.maxPopupsAmount,
      });

      this.incrementActivePopupsCount();
      this.delayedDecrementActivePopupsCount();
    }

  }

  public processNewMessageSocketNotification(message: TMessage): void {

    if (!message || (message.sender && message.sender.id === this.contactId)) {
      return;
    }

    if (message.sender) {
      if (!message.sender.fullName) {
        message.sender.fullName = ContactHelper.getFullName(message.sender);
      }
      if (message.sender.fullCompany) {
        message.sender.fullCompany = ContactHelper.getFullCompany(message.sender);
      }
    }

    store.dispatch('notificationsStore/push', {
      id: 'message_' + message.id,
      entityId: message.id,
      type: NotificationType.CONTACT_MESSAGE,
      time: moment(message.created_at),
      contact: message.sender,
      skipPopup: this.activePopupsCount >= this.maxPopupsAmount,
    });

    this.incrementActivePopupsCount();
    this.delayedDecrementActivePopupsCount();

    store.dispatch('notificationsStore/incrementMessagesCount');

    store.dispatch('messagesStore/callMessageList', {
      eventId: this.eventId,
      userId: this.userId,
      limit: 1000
    });

  }

  public processMeetingRequestSocketNotification(meeting: TMeeting): void {
    if (!meeting || !meeting.creator_contact || (meeting.creator_contact.id === this.contactId)) {
      return;
    }

    meeting = this.prepareMeetingData(meeting);

    store.dispatch('notificationsStore/push', {
      id: 'meeting_' + meeting.id,
      entityId: meeting.id,
      type: NotificationType.CONTACT_MEETING_REQUEST,
      time: moment(),
      contact: meeting.creator_contact,
      skipPopup: this.activePopupsCount >= this.maxPopupsAmount,
      meeting: meeting,
    });

    this.incrementActivePopupsCount();
    this.delayedDecrementActivePopupsCount();

    store.dispatch('notificationsStore/incrementMeetingsCount');
  }

  public processMeetingConfirmedSocketNotification(meeting: TMeeting): void {
    if (!meeting || !meeting.contact || (meeting.contact.id === this.contactId)) {
      return;
    }

    meeting = this.prepareMeetingData(meeting);

    store.dispatch('notificationsStore/push', {
      id: 'meeting_' + meeting.id,
      entityId: meeting.id,
      type: NotificationType.MEETING_IS_CONFIRMED,
      time: moment(),
      contact: meeting.contact,
      skipPopup: this.activePopupsCount >= this.maxPopupsAmount,
      meeting: meeting,
    });

    this.incrementActivePopupsCount();
    this.delayedDecrementActivePopupsCount();

    store.dispatch('meetingsStore/setMeetingConfirmedByMeetingId', meeting.id);
  }

  public processMeetingReminderSocketNotification(meeting: TMeeting): void {
    if (!meeting || !meeting.contact || (meeting.contact.id === this.contactId)) {
      return;
    }

    meeting = this.prepareMeetingData(meeting);

    store.dispatch('notificationsStore/push', {
      id: 'meeting_' + meeting.id,
      entityId: meeting.id,
      type: NotificationType.CONTACT_MEETING_REMINDER,
      time: moment(),
      contact: meeting.creator_contact,
      skipPopup: this.activePopupsCount >= this.maxPopupsAmount,
      meeting: meeting,
    });

    this.incrementActivePopupsCount();
    this.delayedDecrementActivePopupsCount();

  }

}

const notificationsService = new NotificationsService();
export default notificationsService;
