import { ActionContext, Module } from 'vuex';
import { TAppStoreState } from '@/_types/store/app-store-state.type';
import { TLocationStoreState } from '@/_types/store/location-store-state.type';
import { TCountry } from '@/_types/country.type';
import { TCountryState } from '@/_types/country-state.type';
import { TCity } from '@/_types/city.type';
import AxiosCancellableRequest from '@/_types/api/axios-cancellable-request.class';
import locationApi, {TGetStatesOrCitiesParams} from '@/_api/location.api';
import UtilsHelper from '@/_helpers/utils.helper';

const getCountriesRequest = new AxiosCancellableRequest<any, TCountry[]>(locationApi.getCountries.bind(locationApi));
const getStatesRequest = new AxiosCancellableRequest<TGetStatesOrCitiesParams, TCountryState[]>(locationApi.getStates.bind(locationApi));
const getCitiesRequest = new AxiosCancellableRequest<TGetStatesOrCitiesParams, TCity[]>(locationApi.getCities.bind(locationApi));

const locationStore: Module<TLocationStoreState, TAppStoreState> = {
  namespaced: true,
  state: {
    countries: [],
    statesByCountryId: {},
    citiesByCountryId: {},
    isCountryListLoading: false,
    isStateListLoading: false,
    isCityListLoading: false,
    countriesRequestError: null,
    statesRequestError: null,
    citiesRequestError: null,
  },
  getters: {
    isCountryListLoading: (state: TLocationStoreState): boolean => state.isCountryListLoading,
    isStateListLoading: (state: TLocationStoreState): boolean => state.isStateListLoading,
    isCityListLoading: (state: TLocationStoreState): boolean => state.isCityListLoading,
    countries: (state: TLocationStoreState): TCountry[] => state.countries || [],
    statesByCountryId: (state: TLocationStoreState) => (countryId: number): TCountryState[] => {
      return (state.statesByCountryId && state.statesByCountryId[countryId]) || [];
    },
    citiesByCountryId: (state: TLocationStoreState) => (countryId: number): TCity[] => {
      return (state.citiesByCountryId && state.citiesByCountryId[countryId]) || [];
    },
  },
  actions: {
    requestCountries: async (context: ActionContext<TLocationStoreState, TAppStoreState>): Promise<TCountry[]> => {
      const { commit, state } = context;

      if (state.countries && state.countries.length) {
        return state.countries;
      }

      if (state.isCountryListLoading) {
        try {
          return await getCountriesRequest.promise;
        } catch (error) {
          return null;
        }
      }

      commit('setCountriesLoading', true);
      commit('requestCountriesError', null);

      let data;
      try {
        data = await getCountriesRequest.load({});
        commit('setCountriesLoading', false);
        return data;
      } catch (error) {
        commit('requestCountriesError', error);
        return null;
      } finally {
        commit('setCountries', data);
      }
    },

    requestStates: async (context: ActionContext<TLocationStoreState, TAppStoreState>, countryId: number): Promise<TCountryState[]> => {
      if (!countryId) {
        return [];
      }
      const { commit, state } = context;

      if (state.statesByCountryId[countryId]) {
        return state.statesByCountryId[countryId];
      }

      if (state.isStateListLoading) {
        try {
          return await getStatesRequest.promise;
        } catch (error) {
          return null;
        }
      }

      commit('setStatesLoading', true);
      commit('requestStatesError', null);

      let data;
      try {
        data = await getStatesRequest.load({countryId});
        commit('setStatesLoading', false);
        return data;
      } catch (error) {
        commit('requestStatesError', error);
        return null;
      } finally {
        commit('setStates', {countryId, states: data});
      }
    },

    requestCities: async (context: ActionContext<TLocationStoreState, TAppStoreState>, countryId: number): Promise<TCity[]> => {
      if (!countryId) {
        return [];
      }
      const { commit, state } = context;

      if (state.citiesByCountryId[countryId]) {
        return state.citiesByCountryId[countryId];
      }

      if (state.isCityListLoading) {
        try {
          return await getCitiesRequest.promise;
        } catch (error) {
          return null;
        }
      }

      commit('setCitiesLoading', true);
      commit('requestCitiesError', null);

      let data;
      try {
        data = await getCitiesRequest.load({countryId});
        commit('setCitiesLoading', false);
        return data;
      } catch (error) {
        commit('requestCitiesError', error);
        return null;
      } finally {
        commit('setCities', {countryId, cities: data});
      }
    },
  },
  mutations: {

    setCountriesLoading(state: TLocationStoreState, isLoading: boolean): void {
      state.isCountryListLoading = isLoading;
    },

    setStatesLoading(state: TLocationStoreState, isLoading: boolean): void {
      state.isStateListLoading = isLoading;
    },

    setCitiesLoading(state: TLocationStoreState, isLoading: boolean): void {
      state.isCityListLoading = isLoading;
    },

    setCountries(state: TLocationStoreState, payload: TCountry[]): void {
      state.countries = payload.sort(UtilsHelper.sortByNameField);
      state.isCountryListLoading = false;
    },

    setStates(state: TLocationStoreState, payload: { countryId: number; states: TCountryState[] }): void {
      const { countryId, states } = payload;
      state.statesByCountryId = {...Object.assign(state.statesByCountryId, {[countryId]: states})};
      state.isStateListLoading = false;
    },

    setCities(state: TLocationStoreState, payload: { countryId: number; cities: TCity[] }): void {
      const { countryId, cities } = payload;
      state.citiesByCountryId = {...Object.assign(state.citiesByCountryId, {[countryId]: cities})};
      state.isCityListLoading = false;
    },

    requestCountriesError(state: TLocationStoreState, error: Error): void {
      state.countriesRequestError = error;
    },

    requestStatesError(state: TLocationStoreState, error: Error): void {
      state.statesRequestError = error;
    },

    requestCitiesError(state: TLocationStoreState, error: Error): void {
      state.citiesRequestError = error;
    },

  },
};

export default locationStore;
