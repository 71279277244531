import { ActionContext, Module } from 'vuex';
import { TAppStoreState } from '@/_types/store/app-store-state.type';
import {
  TabNames, TLastContact,
  TSideBarRightStoreState
} from '@/_modules/promo/types/side-bar-right-store-state.type.ts';
import { TContact } from '@/_types/contact.type';

const sideBarRightStore: Module<TSideBarRightStoreState, TAppStoreState> = {
  namespaced: true,
  state: {
    isOpen: false,
    isSideBarRightComponentPresent: false,
    activeTabName: TabNames.NONE,
    isUserSettingsOpen: false,
    isQRCodePopupVisible: false,
    lastContactsMap: {},
    correspondenceTabSearchString: '',
  },
  getters: {

    isOpen: (state: TSideBarRightStoreState): boolean => {
      return state.isOpen;
    },

    isSideBarRightComponentPresent: (state: TSideBarRightStoreState): boolean => {
      return state.isSideBarRightComponentPresent;
    },

    activeTabName: (state: TSideBarRightStoreState): TabNames => {
      return state.activeTabName;
    },

    isUserSettingsOpen: (state: TSideBarRightStoreState): boolean => {
      return state.isUserSettingsOpen;
    },

    isQRCodePopupVisible: (state: TSideBarRightStoreState): boolean => {
      return state.isQRCodePopupVisible;
    },

    lastContactsMap: (state: TSideBarRightStoreState): { [id: string]: TLastContact } => {
      return state.lastContactsMap;
    },

    lastContactsCollection: (state: TSideBarRightStoreState): TLastContact[] => {
      return Object.values(state.lastContactsMap).sort((a, b) => {
        if (a.timestamp < b.timestamp) {
          return -1;
        } else if (a > b) {
          return 1;
        }
        return 0;
      });
    },

    correspondenceTabSearchString: (state: TSideBarRightStoreState): string => {
      return state.correspondenceTabSearchString || '';
    }
  },
  actions: {

    open: ({commit}: ActionContext<TSideBarRightStoreState, TAppStoreState>): void => {
      commit('open');
    },

    close: ({commit}: ActionContext<TSideBarRightStoreState, TAppStoreState>): void => {
      commit('close');
    },

    toggle: ({commit}: ActionContext<TSideBarRightStoreState, TAppStoreState>): void => {
      commit('toggle');
    },

    setActiveTab: ({commit}: ActionContext<TSideBarRightStoreState, TAppStoreState>, tabName: TabNames): void => {
      commit('setActiveTab', tabName);
    },

    clearActiveTab: ({commit}: ActionContext<TSideBarRightStoreState, TAppStoreState>): void => {
      commit('setActiveTab', TabNames.NONE);
    },

    setComponentPresence: ({commit}: ActionContext<TSideBarRightStoreState, TAppStoreState>, value: boolean): void => {
      commit('setComponentPresence', value);
    },

    setIsUserSettingsOpen: ({commit}: ActionContext<TSideBarRightStoreState, TAppStoreState>, value: boolean): void => {
      commit('setIsUserSettingsOpen', value);
    },

    setIsQRCodePopupVisible: ({commit}: ActionContext<TSideBarRightStoreState, TAppStoreState>, value: boolean): void => {
      commit('setIsQRCodePopupVisible', value);
    },

    addLastContact: ({ commit }: ActionContext<TSideBarRightStoreState, TAppStoreState>, payload: TContact): void => {
      commit('addLastContact', payload);
    },

    setCorrespondenceTabSearchString: ({ commit }: ActionContext<TSideBarRightStoreState, TAppStoreState>, payload: string): void => {
      commit('setCorrespondenceTabSearchString', payload);
    },
  },
  mutations: {

    open(state: TSideBarRightStoreState): void {
      state.isOpen = true;
    },

    close(state: TSideBarRightStoreState): void {
      state.isOpen = false;
    },

    toggle(state: TSideBarRightStoreState): void {
      state.isOpen = !state.isOpen;
    },

    setActiveTab(state: TSideBarRightStoreState, tabName: TabNames): void {
      state.activeTabName = tabName;
    },

    setComponentPresence(state: TSideBarRightStoreState, value: boolean): void {
      state.isSideBarRightComponentPresent = value;
    },

    setIsUserSettingsOpen(state: TSideBarRightStoreState, value: boolean): void {
      state.isUserSettingsOpen = value;
    },

    setIsQRCodePopupVisible(state: TSideBarRightStoreState, value: boolean): void {
      state.isQRCodePopupVisible = value;
    },

    addLastContact(state: TSideBarRightStoreState, contact: TContact): void {
      state.lastContactsMap[contact.id] = Object.assign({}, {
        contact,
        timestamp: (new Date()).getTime(),
      });
      state.lastContactsMap = { ...state.lastContactsMap };
    },

    setCorrespondenceTabSearchString(state: TSideBarRightStoreState, value: string): void {
      state.correspondenceTabSearchString = value || '';
    },

  },
};

export default sideBarRightStore;
