<template>
  <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M7.00014 6.07189C6.42123 6.07189 5.95013 5.5912 5.95013 5.00051C5.95013 4.40982 6.42123 3.92912 7.00014 3.92912C7.57905 3.92912 8.05015 4.40982 8.05015 5.00051C8.05015 5.5912 7.57905 6.07189 7.00014 6.07189ZM7.00014 2.50061C5.64912 2.50061 4.55011 3.62199 4.55011 5.00051C4.55011 6.37902 5.64912 7.5004 7.00014 7.5004C8.35116 7.5004 9.45017 6.37902 9.45017 5.00051C9.45017 3.62199 8.35116 2.50061 7.00014 2.50061ZM7.15388 8.57007C4.13964 8.64149 2.17332 6.01089 1.52091 4.99736C2.23912 3.85098 4.04794 1.50394 6.84657 1.43108C9.84891 1.35323 11.8264 3.99026 12.4788 5.00379C11.7613 6.15017 9.95181 8.49721 7.15388 8.57007ZM13.9076 4.64523C13.461 3.85098 10.9942 -0.130282 6.81091 0.00328353C2.94126 0.103279 0.690734 3.5817 0.0929262 4.64523C-0.0309754 4.86522 -0.0309754 5.13592 0.0929262 5.35591C0.533232 6.13945 2.91326 10 7.01741 10C7.07481 10 7.13221 9.99929 7.18961 9.99786C11.0586 9.89715 13.3098 6.41944 13.9076 5.35591C14.0308 5.13592 14.0308 4.86522 13.9076 4.64523Z"
          fill="#00B6F8"/>
  </svg>
</template>
<script>
import Vue from 'vue';

const IconEwPasswordEyeOpen = Vue.extend({
  name: 'icon-ew-password-eye-open',
});
export default IconEwPasswordEyeOpen;
</script>
