
import DateTimeHelper from '@/_helpers/date-time.helper';
import {TNote} from '@/_types/store/note.type';
import ContactHelper from '@/_helpers/contact.helper';

export default class NotesHelper {

  public static responseToNoteItemConverter(responseObj: any): TNote {
    if(!responseObj) return null;
    return Object.assign({}, responseObj, {
      created_at: DateTimeHelper.apiDateToDate(responseObj.created_at),
      updated_at: DateTimeHelper.apiDateToDate(responseObj.updated_at),
      owner: Object.assign({}, responseObj.owner, {
        fullName: ContactHelper.getFullName(responseObj.owner),
        fullCompany: ContactHelper.getFullCompany(responseObj.owner),
      })
    });
  }

}
