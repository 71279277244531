



import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import {
  MENU_CUSTOM_TITLE_KEY_NAME,
  MENU_PROPERTIES_KEY_NAME,
} from '@/_modules/promo-cabinet/components/cabinet-event-settings/cabinet-event-settings.vue';
import { TEventSettings } from '@/_types/event-settings.type';
import {TEventRestrictions} from '@/_modules/events/api/event/event.api';
import {TDragOptions} from '@/_types/drag-options.type';
import iconHome from '@/_modules/icons/components/sidebar/icon-home.vue';
import iconInfo from '@/_modules/icons/components/sidebar/icon-info.vue';
import IconProgram from '@/_modules/icons/components/sidebar/icon-program.vue';
import iconHall from '@/_modules/icons/components/sidebar/icon-hall.vue';
import iconContacts from '@/_modules/icons/components/sidebar/icon-contacts.vue';
import iconMeetings from '@/_modules/icons/components/sidebar/icon-meetings.vue';
import iconDiscussions from '@/_modules/icons/components/sidebar/icon-discussions.vue';
import iconNotes from '@/_modules/icons/components/sidebar/icon-notes.vue';
import iconResult from '@/_modules/icons/components/sidebar/icon-result.vue';
import iconTextChats from '@/_modules/icons/components/sidebar/icon-text-chats.vue';
import iconNews from '@/_modules/icons/components/sidebar/icon-news.vue';
import IconTickets from '@/_modules/icons/components/sidebar/icon-tickets.vue';
import EwPopUp from '@/_modules/standalone-company/components/ew-pop-up/ew-pop-up.vue';
import EwButton from '@/_modules/standalone-company/components/UI/Ew-Button/Ew-Button.vue';
import draggable from 'vuedraggable';
import SimplePopup from '@/_modules/controls/components/simple-popup/simple-popup.vue';
import EditMenuItemName from '@/_modules/promo-cabinet/components/edit-menu-item-name/edit-menu-item-name.vue';
import _cloneDeep from 'lodash.clonedeep';

export type TSideBarMenuItems = {
  [key: string]: TSideBarMenuManagedItem;
}

export type TSideBarMenuItemProperties = {
  isShown?: boolean;
  sorting?: number;
  isSorted?: boolean; // TODO: better naming = isSortable, but requires renaming of data in the DB
  properties?: { // TODO: this is because we need to support legacy bugs. Remove it when iOS and Android and web all get rid of .properties usage
    isShown?: boolean;
    sorting?: number;
    isSorted?: boolean;
    customTitle?: { [lang: string]: string };
  }
  customTitle?: { [lang: string]: string };
}

export type TSideBarMenuManagedItem = {
  properties?: TSideBarMenuItemProperties;
  title?: string;
  iconComponentName: string;
}

@Component({
  components: {
    iconHome,
    iconInfo,
    IconProgram,
    iconHall,
    iconContacts,
    iconMeetings,
    iconDiscussions,
    iconNotes,
    iconResult,
    iconTextChats,
    iconNews,
    IconTickets,
    EwPopUp,
    EwButton,
    draggable,
    SimplePopup,
    EditMenuItemName,
  }
})
export default class SideBarMenuManager extends Vue {

  @Getter('_eventStore/eventSettings') eventSettings: TEventSettings;
  @Getter('_eventStore/isEventSettingsPolled') isEventSettingsPolled: boolean;
  @Getter('_eventStore/eventRestrictions') eventRestrictions: TEventRestrictions;

  @Prop({type: Boolean, default: true})
  private allowEventSettingsReRendering: boolean;

  public isRestrictedMenuItemDialogVisible: boolean = false;
  public defaultScreen: string = '';
  public draggableMenuItems: { keyName: string; menuItemData: TSideBarMenuManagedItem }[] = [];
  public isDragInProgress: boolean = false;

  public dragOptions: TDragOptions = {
    animation: 200,
    group: 'menuList',
    disabled: false,
  };

  public menuItems: TSideBarMenuItems = {
    // 'event_info': {
    //   properties: {
    //     isShown: true,
    //   },
    //   title: this.$t('sideBar.info') as string,
    //   iconComponentName: 'icon-home',
    // },
    promo_program: {
      properties: {
        isShown: false,
        isSorted: true,
        sorting: 1,
      },
      title: this.$t('organizerCabinet.sections.eventSettings.menu.program') as string,
      iconComponentName: 'icon-program',
    },
    promo_contacts: {
      properties: {
        isShown: true,
        isSorted: true,
        sorting: 2,
      },
      title: this.$t('organizerCabinet.sections.eventSettings.menu.contacts') as string,
      iconComponentName: 'icon-contacts',
    },
    promo_page_events_companies: {
      properties: {
        isShown: false,
        isSorted: true,
        sorting: 3,
      },
      title: this.$t('organizerCabinet.sections.eventSettings.menu.hall') as string,
      iconComponentName: 'icon-hall',
    },
    promo_live: {
      properties: {
        isShown: true,
        isSorted: true,
        sorting: 4,
      },
      title: this.$t('organizerCabinet.sections.eventSettings.menu.live') as string,
      iconComponentName: 'icon-info',
    },
    text_chats: {
      properties: {
        isShown: false,
        isSorted: true,
        sorting: 5,
      },
      title: this.$t('organizerCabinet.sections.eventSettings.menu.textChats') as string,
      iconComponentName: 'icon-text-chats',
    },
    news: {
      properties: {
        isShown: false,
        isSorted: true,
        sorting: 6,
      },
      title: this.$t('sideBar.news') as string,
      iconComponentName: 'icon-news',
    },
    notes_list: {
      properties: {
        isShown: false,
        isSorted: true,
        sorting: 7,
      },
      title: this.$t('organizerCabinet.sections.eventSettings.menu.notes') as string,
      iconComponentName: 'icon-notes',
    },
    promo_page_calendar: {
      properties: {
        isShown: false,
        isSorted: false,
        sorting: 8,
      },
      title: this.$t('organizerCabinet.sections.eventSettings.menu.calendar') as string,
      iconComponentName: 'icon-meetings',
    },
    result: {
      properties: {
        isShown: false,
        isSorted: false,
        sorting: 9,
      },
      title: this.$t('organizerCabinet.sections.eventSettings.menu.result') as string,
      iconComponentName: 'icon-result',
    },
    tickets: {
      properties: {
        isShown: false,
        isSorted: false,
        sorting: 10,
      },
      title: this.$t('sideBar.tickets') as string,
      iconComponentName: 'icon-tickets',
    },
  };

  public defaultMenuItemProperties: TSideBarMenuItemProperties = {
    isShown: true
  };

  public get eventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  public get layout(): any {
    return (this.eventSettings && this.eventSettings.layout) || null;
  }

  public get menuProps(): any {
    return (this.layout && this.layout[MENU_PROPERTIES_KEY_NAME]) || null;
  }

  public get menuItemKeyNames(): string[] {
    return Object.keys(this.menuItems);
  }

  public get sortableMenuItemKeyNames(): string[] {
    return Object
      .entries(this.menuItems)
      .filter(entry => entry[1].properties && entry[1].properties.isSorted === true)
      .map(item => item[0]);
  }

  public get nonSortableMenuItemKeyNames(): string[] {
    return Object
      .entries(this.menuItems)
      .filter(entry => entry[1].properties && entry[1].properties.isSorted !== true)
      .map(item => item[0]);
  }

  @Watch('eventSettings', { immediate: true })
  public onEventSettingsChange(): void {
    this.initEventMenuItemProperties();
  }

  @Watch('draggableMenuItems', { immediate: true })
  public onDraggableMenuItemsChange(): void {

    const items: TSideBarMenuItems = {};

    this.draggableMenuItems.forEach((item, index) => {
      const keyName = item.keyName;
      items[keyName] = item.menuItemData;
      items[keyName].properties.sorting = index + 1;
    });

    this.nonSortableMenuItemKeyNames.forEach((keyName, index) => {
      items[keyName] = this.menuItems[keyName];
      items[keyName].properties.sorting = this.draggableMenuItems.length + index + 1;
    });

    this.menuItems = items;
  }

  public initEventMenuItemProperties(): void {
    if (!this.allowEventSettingsReRendering) {
      return;
    }

    if (this.menuProps) {
      this.initExistingMenuItemProperties();
    } else {
      this.createMenuItemProperties();
    }

    this.menuItems = Object.assign(this.menuItems, {});

    this.initDraggableMenuItems();
  }

  public initExistingMenuItemProperties(): void {
    this.defaultScreen = this.layout.defaultScreen || '';

    const keyNamesFromStore: string[] = Object.keys(this.menuProps);
    keyNamesFromStore.forEach((key, index) => {
      if (!Object.prototype.hasOwnProperty.call(this.menuItems, key)) {
        return; // continue forEach
      }

      const menuItemsClone = _cloneDeep(this.menuItems);
      this.menuItems[key].properties = _cloneDeep(this.menuProps[key].properties || {});
      if (!this.menuItems[key].properties.sorting) {
        this.menuItems[key].properties.sorting = index + 1;
      }
      this.menuItems[key].properties.isSorted = !!menuItemsClone[key].properties.isSorted;

      this.menuItems[key].properties = Object.assign(
        {},
        this.menuProps[key].properties,
        this.menuItems[key].properties,
      );

    });
  }

  public createMenuItemProperties(): void {
    this.menuItemKeyNames.forEach(key => {
      this.menuItems[key].properties = Object.assign(
        {},
        this.menuItems[key].properties,
        this.defaultMenuItemProperties
      );
    });
  }

  public initDraggableMenuItems(): void {
    this.draggableMenuItems = this.sortableMenuItemKeyNames.map(keyName => {
      return { keyName, menuItemData: this.menuItems[keyName] };
    });

    this.draggableMenuItems.sort((a, b) => {
      if (
        !Object.hasOwnProperty.call(a.menuItemData.properties, 'sorting')
        || !Object.hasOwnProperty.call(b.menuItemData.properties, 'sorting')
      ) {
        return 0;
      }
      if (a.menuItemData.properties.sorting < b.menuItemData.properties.sorting) {
        return -1;
      } else if (a.menuItemData.properties.sorting > b.menuItemData.properties.sorting) {
        return 1;
      }
      return 0;
    });
  }

  public patchEventSettings(): void {
    const newLayout: { [key: string]: any } = {
      ..._cloneDeep(this.layout || {}),
      defaultScreen: this.defaultScreen || this.layout.defaultScreen || '',
      [MENU_PROPERTIES_KEY_NAME]: this.getEventMenuPropertiesWithLegacyFields()
    };

    if (newLayout.defaultScreen && !this.menuItems[newLayout.defaultScreen].properties.isShown) {
      newLayout.defaultScreen = '';
    }

    this.$store.dispatch('_eventStore/patchEventSettings', {
      eventId: this.eventId,
      layout: newLayout,
      show_meetings: this.getShowMeetingsSetting()
    });

  }

  public getEventMenuPropertiesWithLegacyFields(): { [menuItemAlias: string]: TSideBarMenuItemProperties } {
    return this.menuItemKeyNames.reduce(
      (result: { [menuItemAlias: string]: TSideBarMenuItemProperties }, menuItemAlias: string) => {
        if (!this.menuItems[menuItemAlias].properties.isShown) {
          this.menuItems[menuItemAlias].properties.isShown = false;
        }
        if (Object.hasOwnProperty.call(this.menuItems[menuItemAlias].properties, 'isSorted') === false) {
          this.menuItems[menuItemAlias].properties.isSorted = true;
        }
        result[menuItemAlias] = {
          isShown: this.menuItems[menuItemAlias].properties.isShown,
          isSorted: this.menuItems[menuItemAlias].properties.isSorted,
          sorting: this.menuItems[menuItemAlias].properties.sorting,
          customTitle: this.menuItems[menuItemAlias].properties.customTitle,
          properties: this.menuItems[menuItemAlias].properties, // because legacy bugs... TODO: remove
        };

        if (Object.hasOwnProperty.call(this.menuItems[menuItemAlias].properties, 'customTitle')) {
          result[menuItemAlias].customTitle = this.menuItems[menuItemAlias].properties.customTitle;
        }

        try {
          // @ts-ignore // TODO: remove these temporary garbage removers in this try-catch block
          delete result[menuItemAlias].properties.properties;
          // @ts-ignore
          delete result[menuItemAlias].iconComponentName;
          // @ts-ignore
          delete result[menuItemAlias].title;
        } catch {}

        return result;
      },
      {}
    );
  }

  public onMenuItemIsShownChange(menuItemName: string): void {
    if (this.hasRestrictions(menuItemName) && this.menuItems[menuItemName].properties.isShown) {
      this.isRestrictedMenuItemDialogVisible = true;
    }

    this.patchEventSettings();
  }

  public isChangingDefaultScreenDisallowedForMenuItem(menuItemName: string): boolean {
    return !this.isMenuItemShown(menuItemName) || (this.defaultScreen !== menuItemName && this.hasRestrictions(menuItemName));
  }

  public isMenuItemShown(menuItemName: string): boolean {
    const itemData = this.menuItems[menuItemName];
    // TODO: add types, remove as any in the below line
    const isShown = itemData && (itemData as any).isShown === true;

    // below two lines support a legacy field TODO: remove when life allows it
    const itemDataProperties = itemData.properties || null;
    const isShownInProperties = itemDataProperties && itemDataProperties.isShown === true;

    return isShown || isShownInProperties;
  }

  public onDragStart(): void {
    this.isDragInProgress = true;
  }

  public onDragEnd(): void {
    this.isDragInProgress = false;
    this.patchEventSettings();
  }

  public get restrictionsData(): string[] {
    return (this.eventRestrictions && this.eventRestrictions.restrictions_data) || [];
  }

  public hasRestrictions(itemName: string): boolean {
    return this.restrictionsData.indexOf(itemName) >= 0
  }

  public closeRestrictionPopUp(): void {
    this.isRestrictedMenuItemDialogVisible = false;
  }

  public getShowMeetingsSetting(): boolean {
    // N.B. show_meetings setting is being set in admin.eventswallet.com
    if (
      !this.eventSettings
      || !Object.prototype.hasOwnProperty.call(this.eventSettings, 'show_meetings')
      || typeof this.eventSettings.show_meetings !== 'boolean'
    ) {
      return null;
    }

    return this.eventSettings.show_meetings;
  }

  public isMenuItemNameEditorPopupVisible: boolean = false;
  public editedMenuItemName: string = '';
  public editedMenuItemRoute: string = '';

  public onEditMenuItemNameClick(menuItemName: string): void {
    this.editedMenuItemName = this.getMenuItemTitle(menuItemName);
    this.editedMenuItemRoute = menuItemName;
    this.isMenuItemNameEditorPopupVisible = true;
  }

  public closeMenuItemNameEditorPopup(): void {
    this.isMenuItemNameEditorPopupVisible = false;
    this.editedMenuItemName = '';
    this.editedMenuItemRoute = '';
  }

  public onMenuItemEditorSave(dict: {[key: string]: string}): void {
    this.menuItems[this.editedMenuItemRoute].properties = {
      ...(this.menuItems[this.editedMenuItemRoute].properties || this.defaultMenuItemProperties),
      customTitle: dict,
    };
    this.patchEventSettings();
    this.closeMenuItemNameEditorPopup();
  }

  public getMenuItemTitle(itemKey: string): string {
    const menuItemProps = (this.menuProps && this.menuProps[itemKey]) || null;
    const customTitleDict = (menuItemProps && menuItemProps[MENU_CUSTOM_TITLE_KEY_NAME]) || null;
    const customTitleDictLegacy = (menuItemProps && menuItemProps.properties && menuItemProps.properties[MENU_CUSTOM_TITLE_KEY_NAME]) || null;
    return (customTitleDict && customTitleDict[this.$i18n.locale]) || (customTitleDictLegacy && customTitleDictLegacy[this.$i18n.locale]) || this.$t(this.menuItems[itemKey].title) as string;
  }
}
