<template>
  <svg fill="none" height="10" viewBox="0 0 10 10" width="10" xmlns="http://www.w3.org/2000/svg">
    <path clip-rule="evenodd" d="M5 10C2.23858 10 0 7.76142 0 5C0 2.23858 2.23858 0 5 0C7.76142 0 10 2.23858 10 5C10 7.76142 7.76142 10 5 10ZM5.00006 8.97842C7.19725 8.97842 8.97842 7.19725 8.97842 5.00006C8.97842 2.80288 7.19725 1.02171 5.00006 1.02171C2.80288 1.02171 1.02171 2.80288 1.02171 5.00006C1.02171 7.19725 2.80288 8.97842 5.00006 8.97842Z" fill="#00B6F8" fill-rule="evenodd"/>
    <path d="M5.00023 7.33773C5.28723 7.33773 5.51989 7.12837 5.51989 6.87012C5.51989 6.61186 5.28723 6.40251 5.00023 6.40251C4.71324 6.40251 4.48058 6.61186 4.48058 6.87012C4.48058 7.12837 4.71324 7.33773 5.00023 7.33773Z" fill="#00B6F8"/>
    <path d="M4.48058 5.93567H5.51954V5.46806C5.51954 5.46806 5.522 5.46677 5.52756 5.46125C5.54119 5.44773 5.5735 5.41569 5.63408 5.37242C5.68612 5.33526 5.70226 5.32564 5.83373 5.25061C6.28113 4.9953 6.55851 4.55134 6.55851 4.06523C6.55851 3.29047 5.86077 2.6624 5.00006 2.6624C4.13936 2.6624 3.44162 3.29047 3.44162 4.06523H4.48058C4.48058 3.80698 4.71316 3.59762 5.00006 3.59762C5.28696 3.59762 5.51954 3.80698 5.51954 4.06523C5.51954 4.22764 5.42736 4.3752 5.27745 4.46074C5.10924 4.55673 5.08132 4.57338 4.98837 4.63976C4.67941 4.86039 4.48058 5.12329 4.48058 5.46806V5.93567Z" fill="#00B6F8"/>
  </svg>
</template>
<script>
import Vue from 'vue';

const IconEwInputQuestion = Vue.extend({
  name: 'icon-ew-input-question',
});
export default IconEwInputQuestion;
</script>
