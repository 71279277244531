import { TContact } from '@/_types/contact.type';

export enum TabNames {
  NONE = '_nothing_',
  NOTIFICATIONS = 'notifications',
  CORRESPONDENCE = 'correspondence',
  SCHEDULE = 'schedule',
  CONTACT = 'contact',
}

export type TLastContact = {
  contact: TContact;
  timestamp: number;
}

export type TSideBarRightStoreState = {
  isOpen: boolean;
  isSideBarRightComponentPresent: boolean;
  activeTabName: TabNames;
  isUserSettingsOpen: boolean;
  isQRCodePopupVisible: boolean;
  lastContactsMap: { [id: string]: TLastContact };
  correspondenceTabSearchString: string;
}
