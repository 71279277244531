import BaseApi from '@/_types/api/base.api.class';
import { AxiosRequestConfig, CancelToken } from 'axios';
import { TApiListResponse } from '@/_types/api/api-list-response.type';
import { TMessage } from '@/_types/messages.type';
import MessagesHelper from '@/_helpers/messages.helper';

export type TCallMessageListParams = {
  eventId: number;
  limit?: number;
  offset?: number;
}

export type TCallUserMessageListParams = {
  eventId: number;
  userId: number;
  limit?: number;
  offset?: number;
}

export type TSendMessageParams = {
  eventId: number;
  userId: number;
  text: string;
}

export class MessagesApi extends BaseApi {

  public async callMessageList(params: TCallMessageListParams, cancelToken?: CancelToken): Promise<TApiListResponse<TMessage>> {
    const { eventId, limit } = params;
    const url = `/event/${eventId}/message/list`;
    const config: AxiosRequestConfig = {
      url: url,
      method: 'GET',
      params: {},
      cancelToken,
    };

    if (limit) {
      config.params.limit = limit;
    }

    let response;

    try {
      response = await this.axios.request<TApiListResponse<any>>(config);
    } catch (error) {
      throw this.responseError(error);
    }

    response.data.List = response.data.List.map(item => {
      return MessagesHelper.responseToMessageItemConverter(item);
    });

    return response.data;
  }

  public async callUserMessageList(params: TCallUserMessageListParams, cancelToken?: CancelToken): Promise<TApiListResponse<TMessage>> {
    const { eventId, userId, limit, offset } = params;
    const url = `/event/${eventId}/user/${userId}/message/list`;
    const config: AxiosRequestConfig = {
      url: url,
      method: 'GET',
      cancelToken,
    };

    if (limit || offset) {
      config.params = {};
      if (limit) {
        config.params.limit = limit;
      }
      if (offset) {
        config.params.offset = offset;
      }
    }

    let response;

    try {
      response = await this.axios.request<TApiListResponse<any>>(config);
    } catch (error) {
      throw this.responseError(error);
    }

    response.data.List = response.data.List.map(item => {
      return MessagesHelper.responseToMessageItemConverter(item);
    });

    return response.data;
  }

  public async sendMessage(params: TSendMessageParams, cancelToken?: CancelToken): Promise<void> {
    const { eventId, userId, text } = params;
    const url = `/event/${eventId}/user/${userId}/message`;

    const config: AxiosRequestConfig = {
      url: url,
      params: { text },
      method: 'PUT',
      cancelToken,
    };
    let response;

    try {
      response = await this.axios.request<void>(config);
    } catch (error) {
      throw this.responseError(error);
    }

    return response.data;
  }

}

const messagesApi = new MessagesApi();
export default messagesApi;
