


import {Vue, Component, Prop, Ref} from 'vue-property-decorator';
import IconEwInputError from '@/_modules/icons/components/icon-ew-input-error.vue';
import IconEwPasswordEyeOpen from '@/_modules/icons/components/icon-ew-password-eye-open.vue';
import IconEwPasswordEyeClosed from '@/_modules/icons/components/icon-ew-password-eye-closed.vue';
import IconEwInputQuestion from '@/_modules/icons/components/icon-ew-input-question.vue';
import ErrorInfo from '@/_modules/error-info/error-info.vue';
import ApiErrorResponseData from '@/_types/api/api-error-response-data.class';

@Component({
  components: {
    IconEwInputError,
    IconEwPasswordEyeOpen,
    IconEwPasswordEyeClosed,
    IconEwInputQuestion,
    ErrorInfo
  }
})
export default class EwInput extends Vue {

  @Ref('input') inputElement: HTMLInputElement;

  public isFocused: boolean = false;
  public isPasswordVisible: boolean = false;

  @Prop({type: String, default: ''})
  public readonly value: string;

  @Prop({type: String, default: ''})
  public readonly labelText: string;

  @Prop({type: String, default: ''})
  public readonly placeholderText: string;

  @Prop({type: String, default: ''})
  public readonly errorText: string;

  @Prop({type: String, default: ''})
  public readonly helpText: string;

  @Prop({type: Object, default: {}})
  public readonly errorData: ApiErrorResponseData;

  @Prop({type: Boolean, default: false})
  public readonly disabled: boolean;

  @Prop({type: Boolean, default: false})
  public readonly fullWidth: boolean;

  @Prop({type: Number})
  public readonly minlength: number;

  @Prop({type: Number})
  public readonly maxlength: number;

  @Prop({type: Boolean, default: true})
  public readonly showErrorIcon: boolean;

  @Prop({type: Boolean, default: true})
  public readonly showActionIcon: boolean;

  @Prop({type: Boolean, default: false})
  public readonly showActionSlot: boolean;

  @Prop({type: String, default: null})
  public readonly autocomplete: string;

  public get isBottomTextEmpty(): boolean {
    return this.helpText === '' && this.errorText === '';
  }

  public get inputTypeAttribute(): 'text' | 'password' {
    return this.isPasswordVisible ? 'text' : 'password';
  }

  public onLabelClick(): void {
    this.inputElement.focus();
  }

  public onInputFocus(): void {
    this.isFocused = true;
  }

  public onInputBlur(): void {
    this.isFocused = false;
  }

  public togglePasswordVisibility(): void {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

}
