var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isSharerVisible),expression:"isSharerVisible"}],ref:"sharerReference",staticClass:"biletum-box sharer",style:({
    top: _vm.top,
    left: _vm.left
  })},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t('button.share'))+" ")]),_c('div',{staticClass:"soc-icons"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: 'Wechat',
        classes: ['vue-tooltip-dark'],
        placement: 'bottom',
        delay: 0,
      }),expression:"{\n        content: 'Wechat',\n        classes: ['vue-tooltip-dark'],\n        placement: 'bottom',\n        delay: 0,\n      }"}],staticClass:"soc-icon soc-icon-wechat",on:{"click":function($event){return _vm.showWechatOverlay()}}}),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: 'Facebook',
        classes: ['vue-tooltip-dark'],
        placement: 'bottom',
        delay: 0,
      }),expression:"{\n        content: 'Facebook',\n        classes: ['vue-tooltip-dark'],\n        placement: 'bottom',\n        delay: 0,\n      }"}],staticClass:"soc-icon soc-icon-fb",on:{"click":function($event){return _vm.share('facebook')}}}),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: 'LinkedIn',
        classes: ['vue-tooltip-dark'],
        placement: 'bottom',
        delay: 0,
      }),expression:"{\n        content: 'LinkedIn',\n        classes: ['vue-tooltip-dark'],\n        placement: 'bottom',\n        delay: 0,\n      }"}],staticClass:"soc-icon soc-icon-in",on:{"click":function($event){return _vm.share('linkedin')}}}),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: 'Telegram',
        classes: ['vue-tooltip-dark'],
        placement: 'bottom',
        delay: 0,
      }),expression:"{\n        content: 'Telegram',\n        classes: ['vue-tooltip-dark'],\n        placement: 'bottom',\n        delay: 0,\n      }"}],staticClass:"soc-icon soc-icon-tg",on:{"click":function($event){return _vm.share('telegram')}}}),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: 'X / Twitter',
        classes: ['vue-tooltip-dark'],
        placement: 'bottom',
        delay: 0,
      }),expression:"{\n        content: 'X / Twitter',\n        classes: ['vue-tooltip-dark'],\n        placement: 'bottom',\n        delay: 0,\n      }"}],staticClass:"soc-icon soc-icon-tw",on:{"click":function($event){return _vm.share('twitter')}}}),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: 'WhatsApp',
        classes: ['vue-tooltip-dark'],
        placement: 'bottom',
        delay: 0,
      }),expression:"{\n        content: 'WhatsApp',\n        classes: ['vue-tooltip-dark'],\n        placement: 'bottom',\n        delay: 0,\n      }"}],staticClass:"soc-icon soc-icon-wa",on:{"click":function($event){return _vm.share('whatsapp')}}}),_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: 'E-mail',
        classes: ['vue-tooltip-dark'],
        placement: 'bottom',
        delay: 0,
      }),expression:"{\n        content: 'E-mail',\n        classes: ['vue-tooltip-dark'],\n        placement: 'bottom',\n        delay: 0,\n      }"}],staticClass:"soc-icon soc-icon-email",attrs:{"href":_vm.mailtoHref}}),(_vm.isNavigatorShareButtonVisible)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: 'Other...',
        classes: ['vue-tooltip-dark'],
        placement: 'bottom',
        delay: 0,
      }),expression:"{\n        content: 'Other...',\n        classes: ['vue-tooltip-dark'],\n        placement: 'bottom',\n        delay: 0,\n      }"}],staticClass:"soc-icon soc-icon-systemshare",on:{"click":function($event){return _vm.onSystemShareButtonClick()}}}):_vm._e()]),_c('div',{staticClass:"copier-title"},[_vm._v(" Copy link ")]),_c('div',{ref:"copierWrap",staticClass:"copier-wrap"},[_c('input',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: _vm.$t('controls.form-input-text.copy'),
        classes: ['vue-tooltip-dark'],
        placement: 'bottom',
        delay: 0,
      }),expression:"{\n        content: $t('controls.form-input-text.copy'),\n        classes: ['vue-tooltip-dark'],\n        placement: 'bottom',\n        delay: 0,\n      }"}],ref:"sharerInput",attrs:{"type":"text","readonly":""},domProps:{"value":_vm.urlToShare},on:{"click":_vm.copyToClipBoard}}),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: _vm.$t('controls.form-input-text.copy'),
        classes: ['vue-tooltip-dark'],
        placement: 'bottom',
        delay: 0,
      }),expression:"{\n        content: $t('controls.form-input-text.copy'),\n        classes: ['vue-tooltip-dark'],\n        placement: 'bottom',\n        delay: 0,\n      }"}],ref:"copierControl",staticClass:"copy",on:{"click":_vm.copyToClipBoard}}),_c('span',{staticClass:"success-message"},[_vm._v(" "+_vm._s(_vm.$t('sharer.copiedSuccessfully'))+" ")])]),(_vm.isWechatBoxVisible)?_c('div',{staticClass:"sharer-wechat"},[_c('div',{staticClass:"content"},[_c('h4',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t('sharer.wechatOverlayTitle'))+" ")]),_c('div',{staticClass:"qr"},[_c('qrcode-vue',{attrs:{"size":200,"value":_vm.urlToShare,"level":"Q"}})],1),_c('div',{staticClass:"actions text-center"},[_c('button',{staticClass:"close cur-p",on:{"click":_vm.hideWechatOverlay}},[_vm._v(" "+_vm._s(_vm.$t('button.close'))+" ")])])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }