






import Component from 'vue-class-component';
import { Vue } from 'vue-property-decorator';

@Component({
  name: 'icon-participant-remove',
})
export default class IconParticipantRemove extends Vue {
}
