


import {Vue, Component, Prop} from 'vue-property-decorator';

export enum ButtonType {
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary'
}

@Component
export default class EwButton extends Vue {

  @Prop({
    type: String,
    default: 'primary',
    validator: (value: string) => {
      return ['primary', 'secondary', 'tertiary'].includes(value);
    }
  })
  public readonly buttonType: string;

  @Prop({
    type: String,
    default: '', // fluid | ''
  })
  public readonly buttonSize: string;

  @Prop({type: String, default: ''})
  public readonly title: string;

  @Prop({type: Boolean, default: false})
  public readonly disabled: boolean;

  // 'danger' | ''
  @Prop({
    type: String,
    default: '',
  })
  public readonly textColor: string;

  public get propButtonType(): string {
    return this.buttonType ? `ew-button-${this.buttonType}` : '';
  }

  public get propButtonSize(): string {
    return this.buttonSize ? `ew-button-size-${this.buttonSize}` : '';
  }

  public get propTextColor(): string {
    return this.textColor ? `ew-button-${this.textColor}` : '';
  }

  public handle(): void {
    this.$emit('ew-button-click');
  }
}
