import { TPromoPage } from '@/_types/promo-page/promo-page.type';
import { TMediaItem } from '@/_modules/events/types/media-item.type';
import ValidationHelper from '@/_helpers/validation.helper';
import { TFile } from '@/_types/file.type';
import FileHelper from '@/_helpers/file.helper';

export default class PromoPageHelper {

  public static updateCompanyMediaItems(promoPage: TPromoPage): void {
    if (!promoPage.companyMediaItems) {
      promoPage.companyMediaItems = PromoPageHelper._getPromoPageCompanyMediaItems(promoPage);
      promoPage.hasActiveBroadcast = !!promoPage.companyMediaItems.find((mediaItem: TMediaItem): boolean => {
        return mediaItem.type === 'broadcast';
      });
      return;
    }

    const newItems = PromoPageHelper._getPromoPageCompanyMediaItems(promoPage);
    const newItemsIds: string[] = newItems.map((mediaItem: TMediaItem): string => {
      return mediaItem.id;
    });
    const currentItems = promoPage.companyMediaItems;
    const currentItemsIds: string[] = [];
    for (let index = currentItems.length - 1; index > -1; index--) {
      if (newItemsIds.indexOf(currentItems[index].id) < 0) {
        currentItems.splice(index, 1);
      } else {
        currentItemsIds.push(currentItems[index].id);
      }
    }

    newItems.forEach((mediaItem: TMediaItem): void => {
      if (currentItemsIds.indexOf(mediaItem.id) < 0) {
        currentItems.push(mediaItem);
      }
    });

    promoPage.hasActiveBroadcast = !!promoPage.companyMediaItems.find((mediaItem: TMediaItem): boolean => {
      return mediaItem.type === 'broadcast';
    });
  }

  private static _getPromoPageCompanyMediaItems(promoPage: TPromoPage): TMediaItem[] {

    const items: TMediaItem[] = [];

    if (promoPage.video_stream_embed) {
      if (ValidationHelper.isValidVideoStreamEmbed(promoPage.video_stream_embed)) {
        items.push({
          id: `company_${promoPage.external_id}_embed_${promoPage.video_stream_embed}`,
          entityType: 'company',
          entityId: promoPage.external_id,
          type: 'embed',
          src: promoPage.video_stream_embed,
          isUnpinned: false,
          isActive: false,
        });
      }
    }

    if (promoPage.photos) {
      promoPage.photos.forEach((file: TFile): void => {
        if (FileHelper.isVideo(file.url)) {
          items.push({
            id: `company_${promoPage.external_id}_video_${file.url}`,
            entityType: 'company',
            entityId: promoPage.external_id,
            type: 'video',
            src: file.url,
            isUnpinned: false,
            isPaused: true,
            isActive: false,
          });
        }
      });
      promoPage.photos.forEach((file: TFile): void => {
        if (FileHelper.isImage(file.url)) {
          items.push({
            id: `company_${promoPage.external_id}_image_${file.url}`,
            entityType: 'company',
            entityId: promoPage.external_id,
            type: 'image',
            src: file.url,
            isUnpinned: false,
            isActive: false,
          });
        }
      });
    }

    return items;
  }

}
