

import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import * as TRTC from 'trtc-js-sdk';
import { Client, LocalStream, PlayOptions, RemoteStream } from 'trtc-js-sdk';
import { TranslateResult } from 'vue-i18n';
import { Moment } from 'moment';
import { Subject } from 'rxjs';
import _cloneDeep from 'lodash.clonedeep';
import { MeetingRoomType } from '@/_modules/meeting-rooms/types/meeting-room-type.enum';
import MeetingRoomsHelper from '@/_modules/meeting-rooms/helpers/meeting-rooms.helper';
import TRTCHelper from '@/_modules/meeting-rooms/helpers/trtc.helper';
import { TRTCMode } from '@/_modules/meeting-rooms/types/trtc-mode.enum';
import { TMeetingRoomState } from '@/_modules/meeting-rooms/types/meeting-room-state.type';
import { TContact } from '@/_types/contact.type';
import { TMeetingRoomConfig } from '@/_modules/meeting-rooms/types/meeting-room-config.type';
import MeetingsHelper from '@/_helpers/meetings.helper';
import { IFixedDraggableChild } from '@/_types/fixed-draggable-child.interface';
import IconFullScreen from '@/_modules/icons/components/icon-full-screen.vue';
import IconMicrophone from '@/_modules/icons/components/icon-microphone.vue';
import IconCamera from '@/_modules/icons/components/icon-camera.vue';
import IconShareScreen from '@/_modules/icons/components/icon-share-screen.vue';
import IconMeetingInvite from '@/_modules/icons/components/icon-meeting-invite.vue';
import IconArrowDown from '@/_modules/icons/components/icon-arrow-down.vue';
import IconCrossedOut from '@/_modules/icons/components/icon-crossed-out.vue';
import ItemsMenu, { TItemsMenuItem } from '@/_modules/controls/components/items-menu/items-menu.vue';
import IconClock from '@/_modules/icons/components/icon-clock.vue';
import IconSmileSad from '@/_modules/icons/components/icon-smile-sad.vue';
import { TPromoPage } from '@/_types/promo-page/promo-page.type';
import IconParticipantMicrophoneUnmute
  from '@/_modules/meeting-rooms/components/icons/icon-participant-microphone-unmute.vue';
import IconParticipantFavorite from '@/_modules/meeting-rooms/components/icons/icon-participant-favorite.vue';
import IconParticipantMicrophoneMute
  from '@/_modules/meeting-rooms/components/icons/icon-participant-microphone-mute.vue';
import IconParticipantUnfavorite from '@/_modules/meeting-rooms/components/icons/icon-participant-unfavorite.vue';
import IconParticipantRemove from '@/_modules/meeting-rooms/components/icons/icon-participant-remove.vue';
import { TCompanyVideoChatState } from '@/_modules/meeting-rooms/types/company-video-chat-state.type';
import eventDiscoveryService from '@/_services/event-discovery.service';
import { TVideoChatKicked } from '@/_modules/meeting-rooms/types/video-chat-kicked.type';
import UtilsHelper from '@/_helpers/utils.helper';
import {TOpenEwSharerPayload} from '@/_store/ew-sharer.store';
import {Action} from 'vuex-class';
TRTC.Logger.setLogLevel(Number(process.env.VUE_APP_TENCENT_MEETING_APP_LOG_LEVEL) as 0 | 1 | 2 | 3 | 4 | 5);

const NOTIFICATION_SOUND_JOIN = require('@/assets/sounds/when.ogg');
const MUTE_AUDIO_AND_VIDEO_ON_JOINING = true;

const SPEAKING_CHECK_INTERVAL = 1000;
const SPEAKING_AUDIO_LEVEL_THRESHOLD = 0.01;
const DEFAULT_PLAYER_CONFIG_LOCAL: PlayOptions = { objectFit: 'cover', muted: true };
const DEFAULT_PLAYER_CONFIG_REMOTE: PlayOptions = { objectFit: 'cover', muted: false };
const DEFAULT_PLAYER_CONFIG_REMOTE_SS: PlayOptions = { objectFit: 'contain', muted: false };
const VIDEO_CHAT_STATE_TOUCH_INTERVAL = 10000;
const VIDEO_CHAT_STATE_TOUCH_MAX_AGE_SEC = 30;
const VIDEO_CHAT_KICK_TIMEOUT_SEC = 60;

export const VIDEO_CHAT_ROOM_TOTAL_PARTICIPANTS: number = 1 + 6; // Moderator and 6 other participants

type TParticipant = {
  trtcUserId: string;
  eventId: number;
  contactId: number;
  isAudioMutedLocally: boolean;
  isAudioMutedByModerator: boolean;
  isAudioMuted: boolean;
  isVideoMuted: boolean;
  stream: LocalStream | RemoteStream;
  ssStream: LocalStream | RemoteStream;
  isLocal: boolean;
  isScreenSharing: boolean;
  isSpeaking: boolean;
  audioLevel: number;
};

@Component({
  props: {
    meetingRoomId: Number,
  },
  components: {
    IconFullScreen,
    ItemsMenu,
    IconMicrophone,
    IconCamera,
    IconShareScreen,
    IconMeetingInvite,
    IconArrowDown,
    IconCrossedOut,
    IconClock,
    IconSmileSad,
    IconParticipantMicrophoneMute,
    IconParticipantMicrophoneUnmute,
    IconParticipantFavorite,
    IconParticipantUnfavorite,
    IconParticipantRemove,
  },
  computed: {
    ...mapGetters({
      getMeetingRoomById: 'meetingRoomsStore/getMeetingRoomById',
      getCompanyVideoChatStateByExternalId: 'meetingRoomsStore/getCompanyVideoChatStateByExternalId',
      promoPageByExternalId: 'promoStore/promoPageByExternalId',
      contact: 'promoPageStore/contact',
      contactById: 'contactsStore/contactById',
    }),
  },
})
export default class CompanyRoom extends Vue implements IFixedDraggableChild {

  @Action('ewSharerStore/openSharer') openSharer: (payload: TOpenEwSharerPayload) => void;
  @Action('ewSharerStore/closeSharer') closeSharer: () => void;

  public dragZoneMouseDown$: Subject<MouseEvent> = new Subject<MouseEvent>();

  public readonly getMeetingRoomById: (meetingRoomId: string) => TMeetingRoomState;
  public readonly promoPageByExternalId: (externalId: string) => TPromoPage;
  public readonly getCompanyVideoChatStateByExternalId: (externalId: string) => TCompanyVideoChatState;
  public readonly contact: TContact;
  public readonly contactById: (contactId: number) => TContact;

  public meetingRoomId: string;
  public trtcClient: Client = null;
  public isConnecting: boolean = true;
  public isSystemRequirementsOK: boolean = true;
  public isSystemCheckErrorMessageVisible: boolean = true;

  public accessTime: string = '00:00:00';
  public accessTimeIntervalId: number = null;

  public speakingCheckInterval: number = null;

  public cameras: MediaDeviceInfo[] = [];
  public microphones: MediaDeviceInfo[] = [];
  public isCameraAccessDenied: boolean = false;
  public isMicrophoneAccessDenied: boolean = false;
  public isChooseCameraMenuVisible: boolean = false;
  public isChooseMicrophoneMenuVisible: boolean = false;
  public cameraId: string = null;
  public microphoneId: string = null;

  public screenShareClient: Client = null;
  public screenShareStream: LocalStream = null;
  public isScreenSharingSupported: boolean = false;
  public isScreenSharingActive: boolean = false;
  public isScreenSharingStatusChanging: boolean = false;

  public participants: TParticipant[] = [null, null, null, null, null, null, null]

  public isKicked: boolean = false;
  public isRoomFull: boolean = false;
  public isRoomEnded: boolean = false;
  public isOtherRoomStarted: boolean = false;
  public lastJoinedParticipantId: number = null;

  public localStream: LocalStream = null;
  public isLocalStreamPublished: boolean = false;

  private privateIsLocalAudioMuted: boolean = false;
  private privateIsLocalVideoMuted: boolean = false;

  private unpublishedVideoChatState: TCompanyVideoChatState = null;
  private touchVideoChatStateInterval: number;
  private isVideoChatStatePublished: boolean = false;

  private isDestroyed: boolean = false;
  private destroyed$: Subject<void> = new Subject<void>();
  private videoChatStateClone: TCompanyVideoChatState = null;

  public created(): void {
    this.cameraId = localStorage.getItem('cameraId') || null;
    this.microphoneId = localStorage.getItem('microphoneId') || null;
    this.isScreenSharingSupported = TRTC.isScreenShareSupported() || false;
  }

  public async mounted(): Promise<void> {

    this.isSystemRequirementsOK = (await TRTC.checkSystemRequirements()).result || false;
    if (!this.isSystemRequirementsOK) {
      return;
    }

    this._subscribeToGlobalEvents();

    await this._checkForVideoAudioAccess();
    if (this.isCameraAndMicrophoneAccessDenied) {
      return;
    }

    this.checkVideoChatState();

    if (this.isRoomNotAvailable) {
      return;
    }

    this.trtcClient = TRTC.createClient(TRTCHelper.createClientConfig({
      type: MeetingRoomType.COMPANY,
      userId: this.localTrtcUserId,
      mode: TRTCMode.RTC,
    }));
    this._subscribeTrtcClientEvents();

    if (this.isScreenSharingSupported) {
      this.screenShareClient = TRTC.createClient(TRTCHelper.createClientConfig({
        type: MeetingRoomType.COMPANY,
        userId: this.localTrtcUserId + '_SS',
        mode: TRTCMode.RTC,
        autoSubscribe: false, // N.B.: this flag replaced Client.setDefaultMuteRemoteStreams() in the TRTC JS SDK
      }));
    }

    this.join();
    this._startSpeakingCheck();
  }

  public beforeDestroy(): void {
    this.unsubscribeFromService();
    this._stopScreenSharing();
    if (this.screenShareClient) {
      this.screenShareClient.leave();
    }
    this.unpublishVideoChatState();
    this._unsubscribeFromGlobalEvents();
    this._stopSpeakingCheck();
    this._stopCountingAccessTime();
    this._disconnectFromTRTC();
    this.dragZoneMouseDown$.complete();
    this.isDestroyed = true;
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public get meetingRoom(): TMeetingRoomState {
    return this.getMeetingRoomById(this.meetingRoomId);
  }

  public get externalId(): string {
    const config = this.config;
    return (config && config.externalId) || null;
  }

  public get promoPage(): TPromoPage {
    return this.promoPageByExternalId(this.externalId);
  }

  public get videoChatState(): TCompanyVideoChatState {
    const realVideoChatState = this.getCompanyVideoChatStateByExternalId(this.externalId);
    return realVideoChatState || this.unpublishedVideoChatState;
  }

  public get isModerator(): boolean {
    const localContactId = this.localContactId;
    return !!(this.videoChatStateClone && this.videoChatStateClone.moderatorContactId === localContactId);
  }

  public get id(): number {
    return this.meetingRoom && this.meetingRoom.id;
  }

  public get isMinimized(): boolean {
    return this.meetingRoom && this.meetingRoom.isMinimized;
  }

  public get isMaximized(): boolean {
    return this.meetingRoom && this.meetingRoom.isMaximized;
  }

  public get config(): TMeetingRoomConfig {
    return this.meetingRoom && this.meetingRoom.config;
  }

  public get eventId(): number {
    const config = this.config;
    return (config && config.eventId) || null;
  }

  public get localContactId(): number {
    const contact = this.contact;
    return (contact && contact.id) || null;
  }

  private get localTrtcUserId(): string {
    const config = this.config;
    return (config && MeetingRoomsHelper.getLocalTrtcUserId(config)) || null;
  }

  public get isLocalAudioMuted(): boolean {
    return !this.microphoneId || this.privateIsLocalAudioMuted || this.isMicrophoneAccessDenied;
  }

  public get isLocalVideoMuted(): boolean {
    return !this.cameraId || this.privateIsLocalVideoMuted || this.isCameraAccessDenied;
  }

  public get toggleMuteLabel(): TranslateResult {
    return this.isLocalAudioMuted ? this.$t('meetingRooms.unmute') : this.$t('meetingRooms.mute');
  }

  public get toggleVideoLabel(): TranslateResult {
    return this.isLocalVideoMuted ? this.$t('meetingRooms["resume video"]') : this.$t('meetingRooms["stop video"]');
  }

  public get toggleMuteTitle(): TranslateResult {
    return (!this.microphoneId || this.isMicrophoneAccessDenied) ? this.$t('meetingRooms.noAvailableMicrophones') : this.toggleMuteLabel;
  }

  public get toggleVideoTitle(): TranslateResult {
    return (!this.cameraId || this.isCameraAccessDenied) ? this.$t('meetingRooms.noAvailableCameras') : this.toggleVideoLabel;
  }

  public get isCameraAndMicrophoneAccessDenied(): boolean {
    return this.isCameraAccessDenied && this.isMicrophoneAccessDenied;
  }

  public get toggleScreenShareTitle(): TranslateResult {
    if (!this.isScreenSharingSupported) {
      return this.$t('meetingRooms.screenShareNotSupported');
    }
    return this.isScreenSharingActive ? this.$t('meetingRooms.stopScreenShare') : this.$t('meetingRooms.startScreenShare');
  }

  public get toggleScreenShareLabel(): TranslateResult {
    return this.isScreenSharingActive ? this.$t('meetingRooms.stopScreenShare') : this.$t('meetingRooms.startScreenShare');
  }

  public get meetingShareUrl(): string {
    const videoChatState = this.videoChatState;
    if (!videoChatState) {
      return null;
    }
    return MeetingsHelper.getMeetingInviteUrl({
      type: MeetingRoomType.COMPANY,
      eventId: this.eventId,
      moderatorContactId: videoChatState.moderatorContactId,
      externalId: videoChatState.externalId,
    });
  }

  public get isRoomNotAvailable(): boolean {
    return this.isRoomEnded || this.isRoomFull || this.isKicked || this.isOtherRoomStarted;
  }

  public get isBrowserNotSupported(): boolean {
    return UtilsHelper.getBrowserWithVersion() === 'Safari 15.1';
  }

  public onVideoChatPublishClick(): void {
    this.publishVideoChat();
  }

  public get microphonesMenuItems(): TItemsMenuItem[] {
    if (!this.microphones) {
      return [];
    }
    const items: TItemsMenuItem[] = [];
    this.microphones.forEach((device: any): void => {
      items.push({
        value: device.deviceId,
        label: device.label,
      });
    });
    return items;
  }

  public get camerasMenuItems(): TItemsMenuItem[] {
    if (!this.cameras) {
      return [];
    }
    const items: TItemsMenuItem[] = [];
    this.cameras.forEach((device: any): void => {
      items.push({
        value: device.deviceId,
        label: device.label,
      });
    });
    return items;
  }

  public get localParticipant(): TParticipant {
    return this.participants.find(p => p && p.isLocal) || null;
  }

  public get videoChatPublishedMoment(): Moment {
    const videoChatState = this.videoChatState;
    if (!videoChatState || !videoChatState.published) {
      return null;
    }
    return this.$moment(videoChatState.published * 1000);
  }

  public onTopCenterMouseDown(event: MouseEvent): void {
    if (!this.isMinimized) {
      return;
    }
    this.dragZoneMouseDown$.next(event);
  }

  public toggleMinimize(): void {
    if (this.isMinimized) {
      this.$store.dispatch('meetingRoomsStore/unMinimize', this.id);
    } else {
      this.$store.dispatch('meetingRoomsStore/minimize', this.id);
    }
  }

  public toggleMaximize(): void {
    if (this.isMaximized) {
      this.$store.dispatch('meetingRoomsStore/unMaximize', this.id);
    } else {
      this.$store.dispatch('meetingRoomsStore/maximize', this.id);
    }
  }

  public leave(): void {
    this.$store.dispatch('meetingRoomsStore/leave', this.id);
    this.$store.dispatch('meetingRoomsStore/removeCompanyVideoChatState', this.videoChatStateClone);
    eventDiscoveryService.requestVideoChatStates();
  }

  public toggleScreenShare(): void {
    if (!this.isScreenSharingSupported || this.isScreenSharingStatusChanging) {
      return;
    }
    if (!this.isScreenSharingActive) {
      this._startScreenSharing();
    } else {
      this._stopScreenSharing();
    }
  }

  public toggleMute(): void {
    if (!this.localStream || !this.microphoneId) {
      return;
    }
    if (this.privateIsLocalAudioMuted) {
      this._unmuteLocalAudio();
    } else {
      this._muteLocalAudio();
    }
  }

  public toggleVideo(): void {
    if (!this.localStream || !this.cameraId) {
      return;
    }
    if (this.privateIsLocalVideoMuted) {
      this._unmuteLocalVideo();
    } else {
      this._muteLocalVideo();
    }
  }

  public onSystemCheckErrorOkClick(): void {
    this.isSystemCheckErrorMessageVisible = false;
  }

  public onChooseCameraClick(event: MouseEvent): void {
    event.stopPropagation();
    this.isChooseCameraMenuVisible = !this.isChooseCameraMenuVisible;
    this.isChooseMicrophoneMenuVisible = false;
  }

  public onChooseMicrophoneClick(event: MouseEvent): void {
    event.stopPropagation();
    this.isChooseMicrophoneMenuVisible = !this.isChooseMicrophoneMenuVisible;
    this.isChooseCameraMenuVisible = false;
  }

  public async chooseCamera(deviceId: string): Promise<void> {
    if (!this.localStream) {
      return;
    }
    await this.localStream.switchDevice('video', deviceId);
    this.cameraId = deviceId;
    if (this.privateIsLocalVideoMuted) {
      this._muteLocalVideo();
    } else {
      this._unmuteLocalVideo();
    }
  }

  public async chooseMicrophone(deviceId: string): Promise<void> {
    if (!this.localStream) {
      return;
    }
    await this.localStream.switchDevice('audio', deviceId);
    this.microphoneId = deviceId;
    if (this.privateIsLocalAudioMuted) {
      this._muteLocalAudio();
    } else {
      this._unmuteLocalAudio();
    }
  }

  public async toggleFullScreen(refName: string): Promise<void> {
    if (
      !this.$refs[refName]
      || !(this.$refs[refName] as HTMLDivElement[])[0]
    ) {
      return;
    }
    const playerElement = (this.$refs[refName] as HTMLDivElement[])[0];

    const fullscreenElement =
      document.fullscreenElement
      // @ts-ignore
      || document.webkitFullscreenElement
      // @ts-ignore
      || document.mozFullScreenElement
      // @ts-ignore
      || document.msFullscreenElement
      // @ts-ignore
      || document.webkitCurrentFullScreenElement;

    if (fullscreenElement) {
      const exitFullScreen =
        document.exitFullscreen
        // @ts-ignore
        || document.webkitExitFullscreen
        // @ts-ignore
        || document.mozCancelFullScreen
        // @ts-ignore
        || document.msExitFullscreen;
      if (exitFullScreen) {
        await exitFullScreen.call(document);
      }
    } else {
      const requestFullScreen =
        playerElement.requestFullscreen
        // @ts-ignore
        || playerElement.webkitRequestFullscreen
        // @ts-ignore
        || playerElement.mozRequestFullScreen
        // @ts-ignore
        || playerElement.msRequestFullscreen;
      if (requestFullScreen) {
        await requestFullScreen.call(playerElement);
      }
    }
  }

  public onParticipantLocalMuteClick(participant: TParticipant): void {
    participant.isAudioMutedLocally = true;
    if (participant.stream) {
      participant.stream.muteAudio();
    }
  }

  public onParticipantLocalUnmuteClick(participant: TParticipant): void {
    participant.isAudioMutedLocally = false;
    if (participant.stream) {
      participant.stream.unmuteAudio();
    }
  }

  public onParticipantFavoriteClick(contactId: number): void {
    if (!contactId) {
      return;
    }
    this.$store.dispatch('contactsStore/addFavContact', { contactId });
  }

  public onParticipantUnfavoriteClick(contactId: number): void {
    if (!contactId) {
      return;
    }
    this.$store.dispatch('contactsStore/removeFavContact', { contactId });
  }

  public onParticipantModeratorMuteClick(participant: TParticipant): void {
    const videoChatState = this.videoChatState;
    if (!videoChatState) {
      return;
    }
    participant.isAudioMutedByModerator = true;

    const participantIndex = this.participants.indexOf(participant);
    const newParticipantsState = [ ...videoChatState.participants ];
    newParticipantsState[participantIndex] = {
      ..._cloneDeep(newParticipantsState[participantIndex]),
      isMuted: true,
    };
    const newVideoChatState = {
      ..._cloneDeep(videoChatState),
      participants: newParticipantsState,
    };
    eventDiscoveryService.touchCompanyVideoChatState(newVideoChatState, VIDEO_CHAT_STATE_TOUCH_MAX_AGE_SEC);
  }

  public onParticipantModeratorUnmuteClick(participant: TParticipant): void {
    const videoChatState = this.videoChatState;
    if (!videoChatState) {
      return;
    }
    participant.isAudioMutedByModerator = false;

    const participantIndex = this.participants.indexOf(participant);
    const newParticipantsState = [ ...videoChatState.participants ];
    newParticipantsState[participantIndex] = {
      ..._cloneDeep(newParticipantsState[participantIndex]),
      isMuted: false,
    };
    const newVideoChatState = {
      ..._cloneDeep(videoChatState),
      participants: newParticipantsState,
    };
    eventDiscoveryService.touchCompanyVideoChatState(newVideoChatState, VIDEO_CHAT_STATE_TOUCH_MAX_AGE_SEC);
  }

  public onParticipantModeratorKickClick(participant: TParticipant): void {
    const videoChatState = this.videoChatState;
    if (!videoChatState) {
      return;
    }
    const participantIndex = this.participants.indexOf(participant);
    const newParticipantsState = _cloneDeep(videoChatState.participants);
    newParticipantsState[participantIndex] = null;
    const newVideoChatState = {
      ..._cloneDeep(videoChatState),
      participants: newParticipantsState,
      kicked: this.getKickedListWithAddedContactId(participant.contactId),
    };
    eventDiscoveryService.touchCompanyVideoChatState(newVideoChatState, VIDEO_CHAT_STATE_TOUCH_MAX_AGE_SEC);
  }

  public getKickedListWithAddedContactId(contactId: number): TVideoChatKicked[] {
    const videoChatState = this.videoChatState;
    if (!videoChatState) {
      return [];
    }
    const newKickedList: TVideoChatKicked[] = videoChatState.kicked ? _cloneDeep(videoChatState.kicked) : [];
    const newKickedItem: TVideoChatKicked = {
      contactId,
      timeout: Math.floor((new Date()).getTime() * 0.001) + VIDEO_CHAT_KICK_TIMEOUT_SEC
    };
    const foundIndex = newKickedList.findIndex(item => item.contactId === contactId);
    if (foundIndex < 0) {
      newKickedList.push(newKickedItem);
    } else {
      newKickedList[foundIndex] = newKickedItem;
    }
    return newKickedList;
  }

  public async exitFullScreen(participant: TParticipant): Promise<void> {
    if (
      !this.$refs['player-' + participant.trtcUserId]
      || !(this.$refs['player-' + participant.trtcUserId] as HTMLDivElement[])[0]
    ) {
      return;
    }
    const playerElementId = 'player-' + participant.trtcUserId;
    const fullscreenElement =
      document.fullscreenElement
      // @ts-ignore
      || document.webkitFullscreenElement
      // @ts-ignore
      || document.mozFullScreenElement
      // @ts-ignore
      || document.msFullscreenElement
      // @ts-ignore
      || document.webkitCurrentFullScreenElement;

    if (fullscreenElement && fullscreenElement.getAttribute('id') === playerElementId) {
      const exitFullScreen =
        document.exitFullscreen
        // @ts-ignore
        || document.webkitExitFullscreen
        // @ts-ignore
        || document.mozCancelFullScreen
        // @ts-ignore
        || document.msExitFullscreen;
      if (exitFullScreen) {
        await exitFullScreen.call(document);
      }
    }
  }

  public onMeetingShareClick(event: PointerEvent): void {
    this.openSharer({
      eventTarget: (event.target as Element),
      url: this.meetingShareUrl,
    });
  }

  private subscribeToService(): void {
    const videoChatState = this.videoChatState;
    if (videoChatState) {
      eventDiscoveryService.subscribe(videoChatState.id);
    }
  }

  private unsubscribeFromService(): void {
    const videoChatState = this.videoChatState;
    if (videoChatState) {
      eventDiscoveryService.unsubscribe(videoChatState.id);
    }
  }

  private checkVideoChatState(): void {
    const config = this.config;
    const videoChatState = this.videoChatState;
    const roomId = this.id;
    const localTrtcUserId = this.localTrtcUserId;
    const { contactId, moderatorContactId, eventId, externalId } = config;

    if (!videoChatState && moderatorContactId === contactId) {
      this.unpublishedVideoChatState = {
        id: `company-video-chat-${eventId}-${externalId}`,
        expire: 0,
        published: 0,
        isPublished: false,
        eventId: eventId,
        externalId: externalId,
        moderatorContactId: moderatorContactId,
        roomId: roomId,
        participants: [
          { contactId: contactId, isMuted: false, trtcUserId: localTrtcUserId },
          null, null, null, null, null, null
        ],
        haveFreeSpots: true,
        kicked: [],
        platform: 'web',
      };
      this.subscribeToService();
    } else if (videoChatState) {
      this.subscribeToService();
    }
  }

  private updateParticipants(): void {
    const videoChatState = this.videoChatState;
    if (!videoChatState) {
      return;
    }

    const currentTimestamp = Math.floor((new Date()).getTime() * 0.001);
    const isModerator = this.isModerator;
    const localContactId = this.localContactId;
    const eventId = this.eventId;
    const localTrtcUserId = this.localTrtcUserId;
    const existingStateParticipantIndex = videoChatState.participants.findIndex(stateParticipant => {
      return stateParticipant && stateParticipant.contactId === localContactId;
    });
    let freeSpotIndex = -1;

    if (
      isModerator
      && !videoChatState.isPublished
      && videoChatState.participants.find((item, index) => {
        return index > 0 && !!item;
      })
    ) {
      const newParticipantsState = _cloneDeep(videoChatState.participants);
      newParticipantsState[1] = null;
      newParticipantsState[2] = null;
      newParticipantsState[3] = null;
      newParticipantsState[4] = null;
      newParticipantsState[5] = null;
      newParticipantsState[6] = null;
      const newVideoChatState = {
        ..._cloneDeep(videoChatState),
        participants: newParticipantsState,
        haveFreeSpots: newParticipantsState.find(p => !p) === null,
      };
      eventDiscoveryService.touchCompanyVideoChatState(newVideoChatState, VIDEO_CHAT_STATE_TOUCH_MAX_AGE_SEC);
      return;
    }

    if (
      !isModerator
      && (
        videoChatState.expire < currentTimestamp
        || !videoChatState.isPublished
        || !videoChatState.participants[0]
      )
    ) {
      this.onRoomEnded();
      return;
    }

    if (this.isContactIdInKickedList(localContactId)) {
      this.onKicked();
      return;
    }

    if (existingStateParticipantIndex < 0) {
      if (!videoChatState.haveFreeSpots) {
        this.onFullRoom();
        return;
      }
      freeSpotIndex = videoChatState.participants.findIndex(stateParticipant => !stateParticipant);
      if (freeSpotIndex < 0) {
        this.onFullRoom();
        return;
      }
    }

    videoChatState.participants.forEach((stateParticipant, index): void => {
      if (existingStateParticipantIndex < 0 && freeSpotIndex === index) {
        /* joining participant */
        this.participants[index] = {
          trtcUserId: localTrtcUserId,
          eventId: eventId,
          contactId: localContactId,
          isSpeaking: false,
          isAudioMuted: false,
          isVideoMuted: false,
          stream: null,
          isLocal: true,
          isScreenSharing: false,
          audioLevel: 0.0,
          isAudioMutedLocally: false,
          isAudioMutedByModerator: false,
          ssStream: null,
        };
        const newParticipantsState = _cloneDeep(videoChatState.participants);
        newParticipantsState[index] = {
          trtcUserId: localTrtcUserId,
          isMuted: false,
          contactId: localContactId,
        };
        const newVideoChatState = {
          ..._cloneDeep(videoChatState),
          participants: newParticipantsState,
          haveFreeSpots: newParticipantsState.find(p => !p) === null,
        };
        eventDiscoveryService.touchCompanyVideoChatState(newVideoChatState, VIDEO_CHAT_STATE_TOUCH_MAX_AGE_SEC);

        this.startCountingAccessTime(); // TODO: count from 0 ?
      } else if (!stateParticipant) {
        /* empty spot */
        this.participants[index] = null;
      } else if (!this.participants[index]) {
        /* joined participant */
        this.participants[index] = {
          trtcUserId: stateParticipant.trtcUserId,
          eventId: eventId,
          contactId: stateParticipant.contactId,
          isSpeaking: false,
          isAudioMuted: false,
          isVideoMuted: false,
          stream: null,
          isLocal: stateParticipant.contactId === localContactId,
          isScreenSharing: false,
          audioLevel: 0.0,
          isAudioMutedLocally: false,
          ssStream: null,
          isAudioMutedByModerator: stateParticipant.isMuted,
        };

        if (isModerator && stateParticipant.contactId !== localContactId) {
          this.lastJoinedParticipantId = stateParticipant.contactId;
          setTimeout(() => {
            if (this.lastJoinedParticipantId === stateParticipant.contactId) {
              this.lastJoinedParticipantId = null;
            }
          }, 6000);
          const audio = new Audio(NOTIFICATION_SOUND_JOIN);
          try { audio.play(); } catch { /* ignore */ }
        }

      } else {
        /* updated participant */
        this.participants[index].isAudioMutedByModerator = stateParticipant.isMuted;
        if (this.participants[index].isLocal) {
          if (this.participants[index].isAudioMutedByModerator) {
            this._muteLocalAudio(true);
          } else {
            this._unmuteLocalAudio(true);
          }
        }
      }
    });

    this.participants = [ ...this.participants ];
  }

  private isContactIdInKickedList(contactId: number): boolean {
    const videoChatState = this.videoChatState;
    if (!videoChatState) {
      return false;
    }
    const currentTimestamp = Math.floor((new Date()).getTime() * 0.001);
    const kickedList = videoChatState.kicked || [];
    const foundItem: TVideoChatKicked = kickedList.find(item => item.contactId === contactId);
    return foundItem && currentTimestamp < foundItem.timeout;
  }

  private onKicked(): void {
    this.isKicked = true;
    this.end();
  }

  private onFullRoom(): void {
    this.isRoomFull = true;
    this.end();
  }

  private onRoomEnded(): void {
    this.isRoomEnded = true;
    this.end();
  }

  private onOtherRoomStarted(): void {
    this.isOtherRoomStarted = true;
    this.end();
  }

  private end(): void {
    this.$store.dispatch('meetingRoomsStore/unMinimize', this.id);
    this.unsubscribeFromService();
    this._stopScreenSharing();
    if (this.screenShareClient) {
      this.screenShareClient.leave();
    }
    this._stopSpeakingCheck();
    this._stopCountingAccessTime();
    this._disconnectFromTRTC();
    this.participants = [ null, null, null, null, null, null, null ];
    this.$store.dispatch('meetingRoomsStore/removeCompanyVideoChatState', this.videoChatStateClone);
    eventDiscoveryService.requestVideoChatStates();
  }

  @Watch('videoChatState', { immediate: true })
  private onVideoChatStateChange(): void {
    if (this.isRoomNotAvailable) {
      return;
    }

    const videoChatState = this.videoChatState;
    const isModerator = this.isModerator;

    if (
      this.videoChatStateClone && videoChatState
      && this.videoChatStateClone.moderatorContactId !== videoChatState.moderatorContactId
    ) {
      this.onOtherRoomStarted();
      return;
    }

    if (isModerator && videoChatState && videoChatState.isPublished && !this.isVideoChatStatePublished) {
      /* moderator re-joined */
      this.publishVideoChatState();
    }

    this.videoChatStateClone = videoChatState ? _cloneDeep(videoChatState) : null;
    this.updateParticipants();
  }

  private _subscribeToGlobalEvents(): void {
    document.addEventListener('click', this._onDocumentClick);
    window.addEventListener('beforeunload', this._onWindowBeforeUnload);
  }

  private _unsubscribeFromGlobalEvents(): void {
    document.removeEventListener('click', this._onDocumentClick);
    window.removeEventListener('beforeunload', this._onWindowBeforeUnload);
  }

  private _onDocumentClick(): void {
    this.isChooseCameraMenuVisible = false;
    this.isChooseMicrophoneMenuVisible = false;
  }

  private _onWindowBeforeUnload(event: Event): string {
    if (this.isCameraAndMicrophoneAccessDenied) {
      return undefined;
    }
    event.returnValue = false; // 'Sure?';
    return 'Sure?';
  }

  private _stopSpeakingCheck(): void {
    if (this.speakingCheckInterval) {
      clearInterval(this.speakingCheckInterval);
      this.speakingCheckInterval = null;
    }
  }

  private _stopCountingAccessTime(): void {
    if (!this.accessTimeIntervalId) {
      return;
    }
    clearInterval(this.accessTimeIntervalId);
    this.accessTimeIntervalId = null;
  }

  private async _disconnectFromTRTC(): Promise<void> {
    await this._trtcClientDisconnect();
    this._stopLocalStream();
    this._stopRemoteStreams();
  }

  private async _trtcClientDisconnect(): Promise<void> {
    if (!this.trtcClient) {
      return;
    }
    if (this.localStream) {
      try {
        await this.trtcClient.unpublish(this.localStream);
      } catch (error) { /* do nothing */
      }
    }
    try {
      await this.trtcClient.leave();
    } catch (error) { /* do nothing */ }
    this.trtcClient.off('*');
  }

  private _stopLocalStream(): void {
    if (!this.localStream) {
      return;
    }
    try {
      this.localStream.stop();
      this.localStream.close();
    } catch {
      /* ignore */
    }
    this.localStream = null;
    const localParticipant = this.localParticipant;
    if (localParticipant) {
      localParticipant.stream = null;
    }
  }

  private _stopRemoteStreams(): void {
    this.participants.forEach((participant: TParticipant) => {
      if (!participant || participant.isLocal || !participant.stream) {
        return;
      }
      participant.stream.stop();
      participant.stream = null;
    });
  }

  private _muteLocalAudio(byModerator: boolean = false): void {
    if (!this.localStream) {
      return;
    }
    const localParticipant = this.localParticipant;
    if (!localParticipant) {
      return;
    }

    if (byModerator) {
      localParticipant.isAudioMutedByModerator = true;
    } else {
      localParticipant.isAudioMuted = true;
    }

    this.localStream.muteAudio();
    this.privateIsLocalAudioMuted = true;
  }

  private _unmuteLocalAudio(byModerator: boolean = false): void {
    if (!this.localStream) {
      return;
    }
    const localParticipant = this.localParticipant;
    if (!localParticipant) {
      return;
    }

    if (byModerator) {
      localParticipant.isAudioMutedByModerator = false;
    } else {
      localParticipant.isAudioMuted = false;
    }

    if (localParticipant.isAudioMutedByModerator || localParticipant.isAudioMuted) {
      return;
    }

    this.localStream.unmuteAudio();
    this.privateIsLocalAudioMuted = false;
  }

  private _muteLocalVideo(): void {
    if (!this.localStream) {
      return;
    }
    this.localStream.muteVideo();
    this.privateIsLocalVideoMuted = true;
    const localParticipant = this.localParticipant;
    if (localParticipant) {
      localParticipant.isVideoMuted = true;
    }
  }

  private _unmuteLocalVideo(): void {
    if (!this.localStream) {
      return;
    }
    this.localStream.unmuteVideo();
    this.privateIsLocalVideoMuted = false;
    const localParticipant = this.localParticipant;
    if (localParticipant) {
      localParticipant.isVideoMuted = false;
    }
  }

  private async _checkForVideoAudioAccess(): Promise<void> {
    try {
      const cameraResult = await navigator.permissions.query({ name: 'camera' });
      if (cameraResult.state === 'denied') {
        this.isCameraAccessDenied = true;
      }
      const microphoneResult = await navigator.permissions.query({ name: 'microphone' });
      if (microphoneResult.state === 'denied') {
        this.isMicrophoneAccessDenied = true;
      }
    } catch (error) {
      /* do nothing */
    }
  }

  private _subscribeTrtcClientEvents(): void {

    this.trtcClient.on('stream-added', evt => {
      const videoChatState = this.videoChatState;
      if (!videoChatState) {
        return;
      }
      const remoteStream: RemoteStream = evt.stream;
      const trtcUserId = remoteStream.getUserId();
      const matches = (/^(\d+)_(\d+)(_SS)?$/gi).exec(trtcUserId);
      if (!matches) {
        return;
      }
      const eventId = this.eventId;
      const streamEventId = parseInt(matches[1], 10);
      const streamContactId = parseInt(matches[2], 10);
      const isStreamScreenSharing = !!matches[3];
      const streamTrtcUserId = '' + eventId + '_' + streamContactId;
      if (eventId !== streamEventId) {
        return;
      }
      const existingParticipant = this.participants.find(p => {
        return p && p.contactId === streamContactId;
      });
      if (!existingParticipant) {
        return;
      }
      if (isStreamScreenSharing) {
        if (existingParticipant.ssStream) {
          existingParticipant.ssStream.stop();
        }
        existingParticipant.ssStream = remoteStream;
        existingParticipant.isScreenSharing = true;
        Vue.nextTick(() => {
          remoteStream.play('player-ss-' + streamTrtcUserId, DEFAULT_PLAYER_CONFIG_REMOTE_SS);
        });
      } else {
        if (existingParticipant.stream) {
          existingParticipant.stream.stop();
        }
        existingParticipant.stream = remoteStream;
        if (existingParticipant.isAudioMutedLocally) {
          existingParticipant.stream.muteAudio();
        }
        Vue.nextTick(() => {
          remoteStream.play('player-' + trtcUserId, DEFAULT_PLAYER_CONFIG_REMOTE);
        });
      }
    });

    this.trtcClient.on('stream-removed', evt => {
      const videoChatState = this.videoChatState;
      if (!videoChatState) {
        return;
      }
      const remoteStream: RemoteStream = evt.stream;
      const trtcUserId = remoteStream.getUserId();
      const matches = (/^(\d+)_(\d+)(_SS)?$/gi).exec(trtcUserId);
      if (!matches) {
        return;
      }
      const eventId = this.eventId;
      const streamEventId = parseInt(matches[1], 10);
      const streamContactId = parseInt(matches[2], 10);
      const isStreamScreenSharing = !!matches[3];
      if (eventId !== streamEventId) {
        return;
      }
      const existingParticipant = this.participants.find(p => {
        return p && p.contactId === streamContactId;
      });
      if (!existingParticipant) {
        return;
      }

      if (isStreamScreenSharing) {
        if (existingParticipant.ssStream) {
          existingParticipant.ssStream.stop();
          existingParticipant.ssStream = null;
        }
        existingParticipant.isScreenSharing = false;
      } else {
        if (existingParticipant.stream) {
          existingParticipant.stream.stop();
          existingParticipant.stream = null;
        }
        const existingParticipantIndex = this.participants.findIndex(p => {
          return p && p.trtcUserId === trtcUserId;
        });
        this.participants[existingParticipantIndex] = null;
        if (this.isModerator) {
          const newParticipantsState = _cloneDeep(videoChatState.participants);
          newParticipantsState[existingParticipantIndex] = null;
          const newVideoChatState = {
            ..._cloneDeep(videoChatState),
            participants: newParticipantsState,
            haveFreeSpots: true,
          };
          eventDiscoveryService.touchCompanyVideoChatState(newVideoChatState, VIDEO_CHAT_STATE_TOUCH_MAX_AGE_SEC);
        } else {
          if (existingParticipantIndex === 0) {
            this.unpublishVideoChatState(true);
            // this.onRoomEnded();
          }
        }
      }
    });

    this.trtcClient.on('mute-audio', evt => {
      const videoChatState = this.videoChatState;
      if (!videoChatState) {
        return;
      }
      const trtcUserId = evt.userId;
      const matches = (/^(\d+)_(\d+)(_SS)?$/gi).exec(trtcUserId);
      if (!matches) {
        return;
      }
      const eventId = this.eventId;
      const streamEventId = parseInt(matches[1], 10);
      const streamContactId = parseInt(matches[2], 10);
      const isStreamScreenSharing = !!matches[3];
      if (eventId !== streamEventId) {
        return;
      }
      const existingParticipant = this.participants.find(p => {
        return p && p.contactId === streamContactId;
      });
      if (!existingParticipant) {
        return;
      }

      if (!isStreamScreenSharing) {
        existingParticipant.isAudioMuted = true;
      }
    });

    this.trtcClient.on('unmute-audio', evt => {
      const videoChatState = this.videoChatState;
      if (!videoChatState) {
        return;
      }
      const trtcUserId = evt.userId;
      const matches = (/^(\d+)_(\d+)(_SS)?$/gi).exec(trtcUserId);
      if (!matches) {
        return;
      }
      const eventId = this.eventId;
      const streamEventId = parseInt(matches[1], 10);
      const streamContactId = parseInt(matches[2], 10);
      const isStreamScreenSharing = !!matches[3];
      if (eventId !== streamEventId) {
        return;
      }
      const existingParticipant = this.participants.find(p => {
        return p && p.contactId === streamContactId;
      });
      if (!existingParticipant) {
        return;
      }

      if (!isStreamScreenSharing) {
        existingParticipant.isAudioMuted = false;
      }
    });

    this.trtcClient.on('mute-video', evt => {
      const videoChatState = this.videoChatState;
      if (!videoChatState) {
        return;
      }
      const trtcUserId = evt.userId;
      const matches = (/^(\d+)_(\d+)(_SS)?$/gi).exec(trtcUserId);
      if (!matches) {
        return;
      }
      const eventId = this.eventId;
      const streamEventId = parseInt(matches[1], 10);
      const streamContactId = parseInt(matches[2], 10);
      const isStreamScreenSharing = !!matches[3];
      if (eventId !== streamEventId) {
        return;
      }
      const existingParticipant = this.participants.find(p => {
        return p && p.contactId === streamContactId;
      });
      if (!existingParticipant) {
        return;
      }
      if (!isStreamScreenSharing) {
        existingParticipant.isVideoMuted = true;
      }
      this.exitFullScreen(existingParticipant);
    });

    this.trtcClient.on('unmute-video', evt => {
      const videoChatState = this.videoChatState;
      if (!videoChatState) {
        return;
      }
      const trtcUserId = evt.userId;
      const matches = (/^(\d+)_(\d+)(_SS)?$/gi).exec(trtcUserId);
      if (!matches) {
        return;
      }
      const eventId = this.eventId;
      const streamEventId = parseInt(matches[1], 10);
      const streamContactId = parseInt(matches[2], 10);
      const isStreamScreenSharing = !!matches[3];
      if (eventId !== streamEventId) {
        return;
      }
      const existingParticipant = this.participants.find(p => {
        return p && p.contactId === streamContactId;
      });
      if (!existingParticipant) {
        return;
      }

      if (!isStreamScreenSharing) {
        existingParticipant.isVideoMuted = false;
      }
    });
  }

  private _startSpeakingCheck(): void {
    this._stopSpeakingCheck();
    this.speakingCheckInterval = window.setInterval(() => {
      if (this.isDestroyed) {
        this._stopSpeakingCheck();
        return;
      }
      this.participants.forEach((participant: TParticipant) => {
        if (!participant) {
          return;
        }
        if (!participant.stream) {
          participant.isSpeaking = false;
          return;
        }
        const audioLevel = participant.stream.getAudioLevel();
        participant.isSpeaking = audioLevel > SPEAKING_AUDIO_LEVEL_THRESHOLD;
        participant.audioLevel = audioLevel;
      });
    }, SPEAKING_CHECK_INTERVAL);
  }

  private async join(): Promise<void> {
    const localTrtcUserId = this.localTrtcUserId;
    const isModerator = this.isModerator;
    let cameras: any[] = [];
    let microphones: any[] = [];
    try {
      cameras = await TRTC.getCameras();
    } catch {
      /* do nothing */
    }
    try {
      microphones = await TRTC.getMicrophones();
    } catch {
      /* do nothing */
    }

    await this.trtcClient.join({ roomId: this.id });

    let cameraId = this.cameraId;
    let microphoneId = this.microphoneId;
    this.cameras = cameras || [];
    this.microphones = microphones || [];

    if (this.cameras.length) {
      let selectedCamera;
      if (cameraId !== null) {
        selectedCamera = this.cameras.find(camera => /* !!camera.deviceId && */ camera.deviceId === cameraId);
      }
      if (!selectedCamera) {
        selectedCamera = cameras[0];
      }
      cameraId = selectedCamera ? selectedCamera.deviceId : null;
    } else {
      cameraId = null;
    }
    if (cameraId === null) {
      this.cameras = [];
    }

    if (this.microphones.length) {
      let selectedMicrophone;
      if (microphoneId !== null) {
        selectedMicrophone = microphones.find(microphone => /* !!microphone.deviceId && */ microphone.deviceId === microphoneId);
      }
      if (!selectedMicrophone) {
        selectedMicrophone = microphones[0];
      }
      microphoneId = selectedMicrophone ? selectedMicrophone.deviceId : null;
    } else {
      microphoneId = null;
    }
    if (microphoneId === null) {
      this.microphones = [];
    }

    this.localStream = null;
    let localStream: LocalStream;

    try {
      localStream = TRTC.createStream({
        userId: localTrtcUserId,
        audio: this.microphones.length > 0 && !this.isMicrophoneAccessDenied,
        video: this.cameras.length > 0 && !this.isCameraAccessDenied,
        cameraId: this.isCameraAccessDenied ? undefined : (cameraId || undefined),
        microphoneId: this.isMicrophoneAccessDenied ? undefined : (microphoneId || undefined),
        mirror: true,
      });
      // localStream.setVideoProfile('720p');
      // localStream.setAudioProfile('standard');
      await localStream.initialize();
    } catch {
      /* mac os says permissions are 'granted' but then denies the permission */
      /* will try to init stream without camera and then without microphone */
      try {
        localStream = TRTC.createStream({
          userId: localTrtcUserId,
          audio: this.microphones.length > 0 && !this.isMicrophoneAccessDenied,
          video: false,
          microphoneId: this.isMicrophoneAccessDenied ? undefined : (microphoneId || undefined),
        });
        // localStream.setAudioProfile('standard');
        await localStream.initialize();
        this.isCameraAccessDenied = true;
      } catch {
        this.isMicrophoneAccessDenied = true;
        try {
          localStream = TRTC.createStream({
            userId: localTrtcUserId,
            audio: false,
            video: this.cameras.length > 0 && !this.isCameraAccessDenied,
            cameraId: this.isCameraAccessDenied ? undefined : (cameraId || undefined),
            mirror: true,
          });
          // localStream.setVideoProfile('720p');
          await localStream.initialize();
        } catch {
          this.isCameraAccessDenied = true;
        }
      }
    }

    this.localStream = localStream || null;
    const localParticipant = this.localParticipant;
    if (localParticipant) {
      localParticipant.stream = this.localStream;
    }
    if (!this.localStream) {
      this.isCameraAccessDenied = true;
      this.isMicrophoneAccessDenied = true;
      return;
    }

    if (!cameraId || !microphoneId) {
      cameras = [];
      microphones = [];
      try {
        cameras = await TRTC.getCameras();
      } catch {
        /* do nothing */
      }
      try {
        microphones = await TRTC.getMicrophones();
      } catch {
        /* do nothing */
      }

      const videoTrack = this.localStream.getVideoTrack();
      const audioTrack = this.localStream.getAudioTrack();
      if (videoTrack) {
        const videoTrackSettings = videoTrack.getSettings();
        cameraId = videoTrackSettings.deviceId;
      }
      if (audioTrack) {
        const audioTrackSettings = audioTrack.getSettings();
        microphoneId = audioTrackSettings.deviceId;
      }

      this.cameras = cameras;
      this.microphones = microphones;
    }

    this.cameraId = cameraId;
    this.microphoneId = microphoneId;

    Vue.nextTick(() => {
      this.localStream.play('player-' + localTrtcUserId, DEFAULT_PLAYER_CONFIG_LOCAL);

      if (UtilsHelper.getBrowserWithVersion() !== 'Safari 15.1') {
        // Muting audio and video by default, AW-2485
        if (MUTE_AUDIO_AND_VIDEO_ON_JOINING) {
          this._muteLocalAudio();
          this._muteLocalVideo();
        }
      }

    });
    this.showWelcomeToTheStreamPopup();

    if (!isModerator) {
      await this.publishLocalStream();
    }

    this._setIsConnecting(false);

    if (this.screenShareClient) {
      await this.screenShareClient.join({
        roomId: this.id
      });
    }
  }

  public isWelcomeToTheStreamPopupVisible: boolean = false;

  private showWelcomeToTheStreamPopup(): void {
    if (UtilsHelper.getBrowserWithVersion() !== 'Safari 15.1') {
      this.isWelcomeToTheStreamPopupVisible = true;
      this.$store.dispatch('meetingRoomsStore/unMinimize', this.id);
    }
  }

  private closeWelcomeToTheStreamPopup(): void {
    this.isWelcomeToTheStreamPopupVisible = false;
  }

  private _setIsConnecting(isConnecting: boolean): void {
    this.isConnecting = isConnecting;
  }

  private startCountingAccessTime(): void {
    if (this.accessTimeIntervalId) {
      clearInterval(this.accessTimeIntervalId);
    }
    this.accessTimeIntervalId = window.setInterval(() => {
      const accessTimeStartMoment = this.videoChatPublishedMoment;
      if (accessTimeStartMoment) {
        const currentTime = this.$moment();
        const diff = currentTime.diff(accessTimeStartMoment);
        this.accessTime = this.$moment(diff).utc(false).format('HH:mm:ss');
      } else {
        this.accessTime = '00:00:00';
      }
    }, 1000);
  }

  private publishVideoChat(): void {
    const videoChatState = this.videoChatState;
    const localContactId = this.localContactId;
    if (!videoChatState || videoChatState.isPublished || videoChatState.moderatorContactId !== localContactId) {
      return;
    }
    this.publishLocalStream();
    this.publishVideoChatState();
    this.startCountingAccessTime();
  }

  private async publishLocalStream(): Promise<void> {
    if (this.isLocalStreamPublished) {
      return;
    }
    await this.trtcClient.publish(this.localStream);
    this.isLocalStreamPublished = true;
  }

  private publishVideoChatState(): void {
    const videoChatState = this.videoChatState;
    const newVideoChatState = {
      ..._cloneDeep(videoChatState),
      isPublished: true,
      published: Math.floor((new Date()).getTime() * 0.001),
    };
    eventDiscoveryService.touchCompanyVideoChatState(newVideoChatState, VIDEO_CHAT_STATE_TOUCH_MAX_AGE_SEC);
    if (this.touchVideoChatStateInterval) {
      window.clearInterval(this.touchVideoChatStateInterval);
      this.touchVideoChatStateInterval = null;
    }
    this.touchVideoChatStateInterval = window.setInterval(() => {
      if (this.isDestroyed) {
        window.clearInterval(this.touchVideoChatStateInterval);
        this.touchVideoChatStateInterval = null;
        return;
      }
      const newVideoChatState = {
        ..._cloneDeep(this.videoChatState),
        kicked: this.videoChatState.kicked.filter(item => {
          if (!item.timeout) {
            return false;
          }
          const nowTimeStampSeconds: number = (new Date()).getTime() * 0.001;
          return item.timeout >= nowTimeStampSeconds;
        })
      };
      eventDiscoveryService.touchCompanyVideoChatState(newVideoChatState, VIDEO_CHAT_STATE_TOUCH_MAX_AGE_SEC);
    }, VIDEO_CHAT_STATE_TOUCH_INTERVAL);
    this.unpublishedVideoChatState = null;
    this.isVideoChatStatePublished = true;
  }

  private unpublishVideoChatState(force?: boolean): void {
    const isModerator = this.isModerator;
    if (!this.videoChatStateClone || !this.videoChatStateClone.isPublished) {
      return;
    }
    if (!isModerator && !force) {
      return;
    }
    eventDiscoveryService.touchCompanyVideoChatState({
      ..._cloneDeep(this.videoChatStateClone),
      isPublished: false,
    }, 0);
  }

  @Watch('cameraId')
  private _onCameraIdChange(newValue: string): void {
    if (newValue) {
      localStorage.setItem('cameraId', newValue);
    } else {
      localStorage.removeItem('cameraId');
    }
  }

  @Watch('microphoneId')
  private _onMicrophoneIdChange(newValue: string): void {
    if (newValue) {
      localStorage.setItem('microphoneId', newValue);
    } else {
      localStorage.removeItem('microphoneId');
    }
  }

  private _startScreenSharing(): void {
    if (!this.screenShareClient || this.isScreenSharingActive || this.isScreenSharingStatusChanging) {
      return;
    }

    this.isScreenSharingActive = true;
    this.isScreenSharingStatusChanging = true;

    try {
      this.screenShareStream = TRTC.createStream({
        userId: this.localTrtcUserId,
        audio: false,
        video: false,
        screen: true,
      });
      this.screenShareStream.initialize().then(() => {
        this.screenShareStream.on('screen-sharing-stopped', (): void => {
          this._stopScreenSharing();
        });
        this.screenShareClient.publish(this.screenShareStream);
      }).catch(() => {
        this._stopScreenSharing();
      });
    } catch (error) {
      this._stopScreenSharing();
    } finally {
      this.isScreenSharingStatusChanging = false;
    }
  }

  private async _stopScreenSharing(): Promise<void> {
    if (!this.screenShareClient || !this.isScreenSharingActive) {
      return;
    }
    this.isScreenSharingActive = false;
    this.isScreenSharingStatusChanging = true;

    if (this.screenShareStream) {
      try {
        await this.screenShareClient.unpublish(this.screenShareStream);
      } catch { /* ignore */ }
    }

    if (this.screenShareStream) {
      try {
        this.screenShareStream.close();
        this.screenShareStream = null;
      } catch { /* ignore */ }
    }

    this.isScreenSharingStatusChanging = false;
  }

}
