// import api from '@/_api/statistics/statistics.api';
import { TStatisticsStoreState } from '@/_modules/statistics/types/statistics-store-state.type';

import { ActionContext, Module } from 'vuex';
import AxiosCancellableRequest from '@/_types/api/axios-cancellable-request.class';
import { TPersonalStat } from '@/_modules/statistics/types/personal-stat.type';
import { TPromoPageStat } from '@/_modules/statistics/types/promo-page-stat.type';
import { TEventStat } from '@/_modules/statistics/types/event-stat.type';

import statisticsApi, {
  TGetStatParams,
  TGetStatPersonalParams,
  TGetStatPromopageParams,
  TExportContactsParams,
  TExportProgramContactsParams,
  TExportPromopagesParams,
  TExportMessagesParams,
  TExportPollsParams,
  TExportOnlineCheckParams,
  TExportResultCompanyParams,
  TExportResultEventCompanyParams,
} from '@/_modules/statistics/api/statistics.api.ts';
import { TAppStoreState } from '@/_types/store/app-store-state.type';
import ApiErrorResponseData from '@/_types/api/api-error-response-data.class';

const getStatRequest = new AxiosCancellableRequest<TGetStatParams, TEventStat>(statisticsApi.getStat.bind(statisticsApi));
const getStatPersonalRequest = new AxiosCancellableRequest<TGetStatPersonalParams, TPersonalStat>(statisticsApi.getStatPersonal.bind(statisticsApi));
const getStatPromopageRequest = new AxiosCancellableRequest<TGetStatPromopageParams, TPromoPageStat>(statisticsApi.getStatPromopage.bind(statisticsApi));
const exportFileContactRequest = new AxiosCancellableRequest<TExportContactsParams, File>(statisticsApi.exportContact.bind(statisticsApi));
const exportFileEventContactsRequest = new AxiosCancellableRequest<TExportContactsParams, File>(statisticsApi.exportEventContacts.bind(statisticsApi));
const exportFileContactsWhoViewedAProgramRequest = new AxiosCancellableRequest<TExportProgramContactsParams, File>(statisticsApi.exportContactsByProgram.bind(statisticsApi));
const exportFilePromoPageRequest = new AxiosCancellableRequest<TExportPromopagesParams, File>(statisticsApi.exportPromopage.bind(statisticsApi));
const exportFileMessagesRequest = new AxiosCancellableRequest<TExportMessagesParams, File>(statisticsApi.exportMessages.bind(statisticsApi));
const exportFileMeetingsRequest = new AxiosCancellableRequest<TExportMessagesParams, File>(statisticsApi.exportMeetings.bind(statisticsApi));
const exportFileNotesRequest = new AxiosCancellableRequest<TExportMessagesParams, File>(statisticsApi.exportNotes.bind(statisticsApi));
const exportFilePollsRequest = new AxiosCancellableRequest<TExportPollsParams, File>(statisticsApi.exportPolls.bind(statisticsApi));
const exportFileOnlineCheckRequest = new AxiosCancellableRequest<TExportOnlineCheckParams, File>(statisticsApi.exportFileOnlineCheckRequest.bind(statisticsApi));
const exportResultCompanyRequest = new AxiosCancellableRequest<TExportResultCompanyParams, File>(statisticsApi.exportResultCompany.bind(statisticsApi));
const exportResultEventCompanyRequest = new AxiosCancellableRequest<TExportResultEventCompanyParams, File>(statisticsApi.exportResultEventCompany.bind(statisticsApi));

const statisticStore: Module<TStatisticsStoreState, TAppStoreState> = {
  namespaced: true,
  state: {
    eventId: null,
    personal: {
      data: null,
      isLoading: false,
      error: null
    },
    promoPage: {
      data: null,
      isLoading: false,
      error: null
    },
    event: {
      data: null,
      isLoading: false,
      error: null
    },
    fileContacts: {
      isLoading: false,
      error: null
    },
    fileEventContacts: {
      isLoading: false,
      error: null
    },
    fileContactsWhoViewedAProgram: {
      isLoading: false,
      error: null
    },
    filePromoPage: {
      isLoading: false,
      error: null
    },
    fileMessages: {
      isLoading: false,
      error: null
    },
    fileMeetings: {
      isLoading: false,
      error: null
    },
    fileNotes: {
      isLoading: false,
      error: null
    },
    filePolls: {
      isLoading: false,
      error: null
    },
    fileOnlineCheck: {
      isLoading: false,
      error: null
    },
    fileResultCompany: {
      isLoading: false,
      error: null
    },
    fileResultEventCompany: {
      isLoading: false,
      error: null
    },
  },
  getters: {
    personal: (state: TStatisticsStoreState): TPersonalStat => {
      return state.personal.data;
    },
    promoPage: (state: TStatisticsStoreState): TPromoPageStat => {
      return state.promoPage.data;
    },
    event: (state: TStatisticsStoreState): TEventStat => {
      return state.event.data;
    },
    isLoadingPersonal: (state: TStatisticsStoreState): boolean => {
      return state.personal.isLoading;
    },
    isLoadingCompany: (state: TStatisticsStoreState): boolean => {
      return state.promoPage.isLoading;
    },
    isLoadingEvent: (state: TStatisticsStoreState): boolean => {
      return state.event.isLoading;
    },
  },
  actions: {

    getPersonal: async ({ commit, state }: ActionContext<TStatisticsStoreState, TAppStoreState>, { eventId }: { eventId: number }): Promise<TPersonalStat> => {
      commit('eventId', eventId);

      if (state.personal.data) {
        return state.personal.data;
      }
      if (state.personal.isLoading) {
        try {
          return await getStatPersonalRequest.promise;
        } catch (error) {
          return null;
        }
      }

      commit('personalStatRequest');
      let data;
      try {
        data = await getStatPersonalRequest.load({ eventId });
        return data;
      } catch (error) {
        commit('personalStatError', error);
        return null;
      } finally {
        commit('personalStat', data);
      }
    },

    getPromoPage: async ({ commit, state }: ActionContext<TStatisticsStoreState, TAppStoreState>, { eventId, externalId }: { eventId: number; externalId: string }): Promise<TPromoPageStat> => {
      commit('eventId', eventId);

      if (state.promoPage.data) {
        return state.promoPage.data;
      }
      if (state.promoPage.isLoading) {
        try {
          return await getStatPromopageRequest.promise;
        } catch (error) {
          return null;
        }
      }

      commit('promopageStatRequest');
      let data;
      try {
        data = await getStatPromopageRequest.load({ eventId, externalId });
        return data;
      } catch (error) {
        commit('promopageStatError', error);
        return null;
      } finally {
        commit('promopageStat', data);
      }
    },

    getEvent: async ({ commit, state }: ActionContext<TStatisticsStoreState, TAppStoreState>, { eventId }: { eventId: number }): Promise<TEventStat> => {
      commit('eventId', eventId);

      if (state.event.data) {
        return state.event.data;
      }
      if (state.event.isLoading) {
        try {
          return await getStatRequest.promise;
        } catch (error) {
          return null;
        }
      }

      commit('eventRequest');
      let data;
      try {
        data = await getStatRequest.load({ eventId });
        return data;
      } catch (error) {
        commit('eventError', error);
        return null;
      } finally {
        commit('event', data);
      }
    },

    exportContacts: async ({ commit }: ActionContext<TStatisticsStoreState, TAppStoreState>, { eventId, format }: { eventId: number; format: string }): Promise<File> => {
      commit('fileContactsRequest');
      try {
        return await exportFileContactRequest.load({ eventId, format });
      } catch (error) {
        commit('fileContactsError', error);
        return null;
      } finally {
        commit('fileContactsSuccess');
      }
    },

    exportEventContacts: async ({ commit }: ActionContext<TStatisticsStoreState, TAppStoreState>, { eventId, format }: { eventId: number; format: string }): Promise<File> => {
      commit('fileEventContactsRequest');
      try {
        return await exportFileEventContactsRequest.load({ eventId, format });
      } catch (error) {
        commit('fileEventContactsError', error);
        return null;
      } finally {
        commit('fileEventContactsSuccess');
      }
    },

    exportContactsWhoViewedAProgram: async ({ commit }: ActionContext<TStatisticsStoreState, TAppStoreState>, { eventId, conferenceId, programId, format }: { eventId: number; conferenceId: number; programId: number; format: string }): Promise<File> => {
      commit('fileContactsWhoViewedAProgramRequest');
      try {
        return await exportFileContactsWhoViewedAProgramRequest.load({ eventId, conferenceId, programId, format });
      } catch (error) {
        commit('fileContactsWhoViewedAProgramError', error);
        return null;
      } finally {
        commit('fileContactsWhoViewedAProgramSuccess');
      }
    },

    exportPromoPages: async ({ commit }: ActionContext<TStatisticsStoreState, TAppStoreState>, { eventId, format }: { eventId: number; format: string }): Promise<File> => {
      commit('filePersonalRequest');
      try {
        return await exportFilePromoPageRequest.load({ eventId, format });
      } catch (error) {
        commit('filePersonalError', error);
        return null;
      } finally {
        commit('filePersonalSuccess');
      }
    },

    exportMessages: async ({ commit }: ActionContext<TStatisticsStoreState, TAppStoreState>, { eventId, format }: { eventId: number; format: string }): Promise<File> => {
      commit('exportMessagesRequest');
      try {
        return await exportFileMessagesRequest.load({ eventId, format });
      } catch (error) {
        commit('exportMessagesError', error);
        return null;
      } finally {
        commit('exportMessagesSuccess');
      }
    },

    exportMeetings: async ({ commit }: ActionContext<TStatisticsStoreState, TAppStoreState>, { eventId, format }: { eventId: number; format: string }): Promise<File> => {
      commit('exportMeetingsRequest');
      try {
        return await exportFileMeetingsRequest.load({ eventId, format });
      } catch (error) {
        commit('exportMeetingsError', error);
        return null;
      } finally {
        commit('exportMeetingsSuccess');
      }
    },

    exportNotes: async ({ commit }: ActionContext<TStatisticsStoreState, TAppStoreState>, { eventId, format }: { eventId: number; format: string }): Promise<File> => {
      commit('exportNotesRequest');
      try {
        return await exportFileNotesRequest.load({eventId, format});
      } catch (error) {
        commit('exportNotesError', error);
        return null;
      } finally {
        commit('exportNotesSuccess');
      }
    },

    exportPolls: async ({ commit }: ActionContext<TStatisticsStoreState, TAppStoreState>, { eventId, format }: { eventId: number; format: string }): Promise<File> => {
      commit('exportPollsRequest');
      try {
        return await exportFilePollsRequest.load({ eventId, format });
      } catch (error) {
        commit('exportPollsError', error);
        return null;
      } finally {
        commit('exportPollsSuccess');
      }
    },

    exportSessionOnlineCheck: async ({commit}: ActionContext<TStatisticsStoreState, TAppStoreState>, {eventId, conferenceId, programId, format}: { eventId: number; conferenceId: number; programId: number;format: string }): Promise<File> => {
      commit('exportOnlineCheckRequest');
      try {
        return await exportFileOnlineCheckRequest.load({eventId, conferenceId, programId, format});
      } catch (error) {
        commit('exportOnlineCheckError', error);
        return null;
      } finally {
        commit('exportOnlineCheckSuccess');
      }
    },

    exportResultCompany: async ({ commit }: ActionContext<TStatisticsStoreState, TAppStoreState>, params: TExportResultCompanyParams): Promise<File> => {
      const { eventId, externalId, format } = params;

      commit('exportResultCompanyRequest');
      try {
        return await exportResultCompanyRequest.load({eventId, externalId, format});
      } catch (error) {
        commit('exportResultCompanyError', error);
        return null;
      } finally {
        commit('exportResultCompanySuccess');
      }
    },

    exportResultEventCompany: async ({ commit }: ActionContext<TStatisticsStoreState, TAppStoreState>, params: TExportResultEventCompanyParams): Promise<File> => {
      const { eventId, format } = params;

      commit('exportResultEventCompanyRequest');
      try {
        return await exportResultEventCompanyRequest.load({eventId, format});
      } catch (error) {
        commit('exportResultEventCompanyError', error);
        return null;
      } finally {
        commit('exportResultEventCompanySuccess');
      }
    },
  },
  mutations: {
    eventId(state: TStatisticsStoreState, eventId: number): void {
      if (!state.eventId) {
        state.eventId = eventId;
        return;
      }

      if (state.eventId === eventId) {
        return;
      }

      getStatRequest.cancel();
      getStatPersonalRequest.cancel();
      getStatPromopageRequest.cancel();

      state.eventId = eventId;
      state.personal = {
        data: null,
        isLoading: false,
        error: null
      };
      state.promoPage = {
        data: null,
        isLoading: false,
        error: null
      };
      state.event = {
        data: null,
        isLoading: false,
        error: null
      };
    },

    personalStatRequest(state: TStatisticsStoreState): void {
      state.personal.data = null;
      state.personal.isLoading = true;
      state.personal.error = null;
    },
    personalStatError(state: TStatisticsStoreState, error: ApiErrorResponseData): void {
      state.personal.error = error;
    },
    personalStat(state: TStatisticsStoreState, personal: TPersonalStat): void {
      state.personal.data = personal || null;
      state.personal.isLoading = false;
    },

    promopageStatRequest(state: TStatisticsStoreState): void {
      state.promoPage.data = null;
      state.promoPage.isLoading = true;
      state.promoPage.error = null;
    },
    promopageStatError(state: TStatisticsStoreState, error: ApiErrorResponseData): void {
      state.promoPage.error = error;
    },
    promopageStat(state: TStatisticsStoreState, promoPage: TPromoPageStat): void {
      state.promoPage.data = promoPage || null;
      state.promoPage.isLoading = false;
    },

    eventRequest(state: TStatisticsStoreState): void {
      state.event.data = null;
      state.event.isLoading = true;
      state.event.error = null;
    },
    eventError(state: TStatisticsStoreState, error: ApiErrorResponseData): void {
      state.event.error = error;
    },
    event(state: TStatisticsStoreState, eventStat: TEventStat): void {
      state.event.data = eventStat || null;
      state.event.isLoading = false;
    },

    fileContactsRequest(state: TStatisticsStoreState): void {
      state.fileContacts.data = null;
      state.fileContacts.isLoading = true;
      state.fileContacts.error = null;
    },
    fileContactsError(state: TStatisticsStoreState, error: ApiErrorResponseData): void {
      state.fileContacts.error = error;
    },
    fileContactsSuccess(state: TStatisticsStoreState): void {
      state.fileContacts.isLoading = false;
      state.fileContacts.error = null;
    },

    fileEventContactsRequest(state: TStatisticsStoreState): void {
      state.fileEventContacts.data = null;
      state.fileEventContacts.isLoading = true;
      state.fileEventContacts.error = null;
    },
    fileEventContactsError(state: TStatisticsStoreState, error: ApiErrorResponseData): void {
      state.fileEventContacts.error = error;
    },
    fileEventContactsSuccess(state: TStatisticsStoreState): void {
      state.fileEventContacts.isLoading = false;
      state.fileEventContacts.error = null;
    },

    fileContactsWhoViewedAProgramRequest(state: TStatisticsStoreState): void {
      state.fileContactsWhoViewedAProgram.data = null;
      state.fileContactsWhoViewedAProgram.isLoading = true;
      state.fileContactsWhoViewedAProgram.error = null;
    },
    fileContactsWhoViewedAProgramError(state: TStatisticsStoreState, error: ApiErrorResponseData): void {
      state.fileContactsWhoViewedAProgram.error = error;
    },
    fileContactsWhoViewedAProgramSuccess(state: TStatisticsStoreState): void {
      state.fileContactsWhoViewedAProgram.isLoading = false;
      state.fileContactsWhoViewedAProgram.error = null;
    },

    filePersonalRequest(state: TStatisticsStoreState): void {
      state.filePromoPage.data = null;
      state.filePromoPage.isLoading = true;
      state.filePromoPage.error = null;
    },
    filePersonalError(state: TStatisticsStoreState, error: ApiErrorResponseData): void {
      state.filePromoPage.error = error;
    },
    filePersonalSuccess(state: TStatisticsStoreState): void {
      state.filePromoPage.isLoading = false;
      state.filePromoPage.error = null;
    },

    exportMessagesRequest(state: TStatisticsStoreState): void {
      state.fileMessages.data = null;
      state.fileMessages.isLoading = true;
      state.fileMessages.error = null;
    },
    exportMessagesError(state: TStatisticsStoreState, error: ApiErrorResponseData): void {
      state.fileMessages.error = error;
    },
    exportMessagesSuccess(state: TStatisticsStoreState): void {
      state.fileMessages.isLoading = false;
      state.fileMessages.error = null;
    },

    exportMeetingsRequest(state: TStatisticsStoreState): void {
      state.fileMeetings.data = null;
      state.fileMeetings.isLoading = true;
      state.fileMeetings.error = null;
    },
    exportMeetingsError(state: TStatisticsStoreState, error: ApiErrorResponseData): void {
      state.fileMeetings.error = error;
    },
    exportMeetingsSuccess(state: TStatisticsStoreState): void {
      state.fileMeetings.isLoading = false;
      state.fileMeetings.error = null;
    },

    exportNotesRequest(state: TStatisticsStoreState): void {
      state.fileNotes.data = null;
      state.fileNotes.isLoading = true;
      state.fileNotes.error = null;
    },
    exportNotesError(state: TStatisticsStoreState, error: ApiErrorResponseData): void {
      state.fileNotes.error = error;
    },
    exportNotesSuccess(state: TStatisticsStoreState): void {
      state.fileNotes.isLoading = false;
      state.fileNotes.error = null;
    },

    exportPollsRequest(state: TStatisticsStoreState): void {
      state.filePolls.data = null;
      state.filePolls.isLoading = true;
      state.filePolls.error = null;
    },
    exportPollsError(state: TStatisticsStoreState, error: ApiErrorResponseData): void {
      state.filePolls.error = error;
    },
    exportPollsSuccess(state: TStatisticsStoreState): void {
      state.filePolls.isLoading = false;
      state.filePolls.error = null;
    },

    exportOnlineCheckRequest(state: TStatisticsStoreState): void {
      state.fileOnlineCheck.data = null;
      state.fileOnlineCheck.isLoading = true;
      state.fileOnlineCheck.error = null;
    },
    exportOnlineCheckError(state: TStatisticsStoreState, error: ApiErrorResponseData): void {
      state.fileOnlineCheck.error = error;
    },
    exportOnlineCheckSuccess(state: TStatisticsStoreState): void {
      state.fileOnlineCheck.isLoading = false;
      state.fileOnlineCheck.error = null;
    },

    exportResultCompanyRequest(state: TStatisticsStoreState): void {
      state.fileResultCompany.isLoading = true;
      state.fileResultCompany.error = null;
    },
    exportResultCompanyError(state: TStatisticsStoreState, error: ApiErrorResponseData): void {
      state.fileResultCompany.error = error;
    },
    exportResultCompanySuccess(state: TStatisticsStoreState): void {
      state.fileResultCompany.isLoading = false;
      state.fileResultCompany.error = null;
    },

    exportResultEventCompanyRequest(state: TStatisticsStoreState): void {
      state.fileResultEventCompany.isLoading = true;
      state.fileResultEventCompany.error = null;
    },
    exportResultEventCompanyError(state: TStatisticsStoreState, error: ApiErrorResponseData): void {
      state.fileResultEventCompany.error = error;
    },
    exportResultEventCompanySuccess(state: TStatisticsStoreState): void {
      state.fileResultEventCompany.isLoading = false;
      state.fileResultEventCompany.error = null;
    },
  },
};

export default statisticStore;
