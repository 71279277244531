import {TPromoPage} from '@/_types/promo-page/promo-page.type';
import ContactHelper from '@/_helpers/contact.helper';

export default class PromoPageHelper {
  public static responseToPromoPageConverter(responseObj: any): TPromoPage {
    if(!responseObj) return null;
    return Object.assign({}, responseObj, {
      attached_contacts: (responseObj.attached_contacts || []).map((item: any) => {
        return ContactHelper.responseToContactConverter(item);
      })
    });
  }
}
