










import Component from 'vue-class-component';
import { Vue } from 'vue-property-decorator';

@Component({
  name: 'icon-plus',
})
export default class IconPlus extends Vue {

}
