import { render, staticRenderFns } from "./side-bar-menu-manager.html?vue&type=template&id=55307b14&scoped=true&"
import script from "./side-bar-menu-manager.vue?vue&type=script&lang=ts&"
export * from "./side-bar-menu-manager.vue?vue&type=script&lang=ts&"
import style0 from "../../_modules/promo/components/side-bar-left/side-bar-left.scss?vue&type=style&index=0&id=55307b14&scoped=true&lang=scss&"
import style1 from "./side-bar-menu-manager.scss?vue&type=style&index=1&id=55307b14&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55307b14",
  null
  
)

export default component.exports