import { ActionContext, Module } from 'vuex';
import loginApi, {
  TConfirmCodeRequestParams,
  TConfirmCodeResponseData,
  TLoginByOauthProviderRequestParams,
  TPasswordChangeConfirmRequestParams,
  TSendLoginRequestParams, TUserAuthData
} from '@/_api/login/login.api';
import ApiErrorResponseData from '@/_types/api/api-error-response-data.class';
import { TAuthStoreState } from '@/_types/store/auth-store-state.type';
import { TAppStoreState } from '@/_types/store/app-store-state.type';
import CookiesHelper from '@/_helpers/cookies-helper';
import { Location } from 'vue-router';
import { AUTH_SOURCE_LOCAL_STORAGE_ITEM_NAME } from '@/_modules/gtm-tracking/track-userid-userstatus/track-userid-userstatus.vue';

const AUTH_COOKIE_MAX_AGE = 60 * 60 * 24 * 30;
const tokenKey = 'user-token';
const initialToken = CookiesHelper.readCookie(tokenKey) || '';

loginApi.token = initialToken;

export enum AuthLoginStatus {
  NONE = '',
  LOADING = 'loading',
  SUCCESS = 'success',
  ERROR = 'error',
}

const authStore: Module<TAuthStoreState, TAppStoreState> = {
  namespaced: true,
  state: {
    confirmId: '',
    deviceId: (new Date()).getTime() + '_id',
    token: initialToken,
    status: AuthLoginStatus.NONE, // TODO: maybe, rename to something like sendCredentialsRequestStatus?
    authError: null,
    forgotPasswordRequestStatus: AuthLoginStatus.NONE,
    forgotPasswordRequestError: null,
    changePasswordRequestStatus: AuthLoginStatus.NONE,
    changePasswordRequestError: null,
    isAuthPopupVisible: false,
    authPopupTargetRoute: null,
    isEnabledSignUp: false,
    userData: null,
    isEmailRegistered: false,
    isStandaloneAuthVisible: false,
    isContactInfoInviterVisible: false,
    isContactInfoInviterCheckingOnBackground: true,
  },
  getters: {
    isAuthenticated: (state: TAuthStoreState): boolean => !!state.token,
    isLoading: (state: TAuthStoreState): boolean => state.status === AuthLoginStatus.LOADING,
    authToken: (state: TAuthStoreState): string => state.token,
    userData: (state: TAuthStoreState): TUserAuthData => state.userData,
    isAuthPopupVisible: (state: TAuthStoreState): boolean => state.isAuthPopupVisible,
    isEnabledSignUp: (state: TAuthStoreState): boolean => state.isEnabledSignUp,
    authPopupTargetRoute: (state: TAuthStoreState): Location => state.authPopupTargetRoute,
    isEmailRegistered: (state: TAuthStoreState): boolean => state.isEmailRegistered,
    authError: (state: TAuthStoreState): ApiErrorResponseData => state.authError,
    isStandaloneAuthVisible: (state: TAuthStoreState): boolean => state.isStandaloneAuthVisible,
    isContactInfoInviterVisible: (state: TAuthStoreState): boolean => state.isContactInfoInviterVisible,
    isContactInfoInviterCheckingOnBackground: (state: TAuthStoreState): boolean => state.isContactInfoInviterCheckingOnBackground,
    deviceId: (state: TAuthStoreState): string => state.deviceId,
    confirmId: (state: TAuthStoreState): string => state.confirmId,
    authStatus: (state: TAuthStoreState): string => state.status,
    forgotPasswordRequestErrorText: (state: TAuthStoreState): string => (state.forgotPasswordRequestError && state.forgotPasswordRequestError.error) || '',
    changePasswordRequestErrorText: (state: TAuthStoreState): string => (state.changePasswordRequestError && state.changePasswordRequestError.error) || '',
  },
  mutations: {
    clearAuthError(state: TAuthStoreState): void {
      state.authError = null;
    },
    setConfirmId(state: TAuthStoreState, confirmId: string): void {
      state.confirmId = confirmId;
    },
    removeConfirmId(state: TAuthStoreState): void {
      state.confirmId = '';
    },
    authSuccess(state: TAuthStoreState, {token, user}: TConfirmCodeResponseData): void {
      CookiesHelper.createCookie({name: tokenKey, value: token, maxAge: AUTH_COOKIE_MAX_AGE});
      state.userData = user;
      state.token = token;
      state.status = AuthLoginStatus.SUCCESS;
      state.authError = null;
    },
    emailLookupRequest(state: TAuthStoreState, data: any): void {
      // state.authError = null;
      state.isEmailRegistered = (data && data.is_registered) || false;
    },
    authRequest(state: TAuthStoreState): void {
      state.status = AuthLoginStatus.LOADING;
    },
    authError(state: TAuthStoreState, errorData: ApiErrorResponseData): void {
      state.status = AuthLoginStatus.ERROR;
      state.authError = errorData;
    },
    authLogout(state: TAuthStoreState): void {

      CookiesHelper.deleteCookie(tokenKey);

      state.token = '';
      state.confirmId = '';
      state.status = AuthLoginStatus.NONE;

      try {
        window.localStorage.removeItem(AUTH_SOURCE_LOCAL_STORAGE_ITEM_NAME);
      } catch { /* ignore */ }
    },
    forgotPasswordRequest(state: TAuthStoreState): void {
      state.forgotPasswordRequestStatus = AuthLoginStatus.LOADING;
    },
    forgotPasswordRequestSuccess(state: TAuthStoreState): void {
      state.forgotPasswordRequestStatus = AuthLoginStatus.SUCCESS;
      state.forgotPasswordRequestError = null;
    },
    forgotPasswordRequestError(state: TAuthStoreState, errorData: ApiErrorResponseData): void {
      state.forgotPasswordRequestStatus = AuthLoginStatus.ERROR;
      state.forgotPasswordRequestError = errorData;
    },
    changePasswordRequest(state: TAuthStoreState): void {
      state.changePasswordRequestStatus = AuthLoginStatus.LOADING;
    },
    changePasswordRequestSuccess(state: TAuthStoreState): void {
      state.changePasswordRequestStatus = AuthLoginStatus.SUCCESS;
      state.changePasswordRequestError = null;
    },
    changePasswordRequestError(state: TAuthStoreState, errorData: ApiErrorResponseData): void {
      state.changePasswordRequestStatus = AuthLoginStatus.ERROR;
      state.changePasswordRequestError = errorData;
    },
    setAuthPopupVisible(state: TAuthStoreState, value: boolean): void {
      state.isAuthPopupVisible = value;
    },
    setAuthPopupTargetRoute(state: TAuthStoreState, value: Location): void {
      state.authPopupTargetRoute = value;
    },
    setSignUpEnabled(state: TAuthStoreState, value: boolean): void {
      state.isEnabledSignUp = value;
    },
    setStandaloneAuthVisibility(state: TAuthStoreState, value: boolean): void {
      state.isStandaloneAuthVisible = value;
    },
    setContactInfoInviterVisibility(state: TAuthStoreState, value: boolean): void {
      state.isContactInfoInviterVisible = value;
    },
    setContactInfoInviterBackgroundMode(state: TAuthStoreState, value: boolean): void {
      state.isContactInfoInviterCheckingOnBackground = value;
    },
  },
  actions: {
    emailLookup: async ({commit}: ActionContext<TAuthStoreState, TAppStoreState>, params: { email: string }): Promise<{is_registered: boolean}> => {
      let response: {is_registered: boolean};
      try {
        response = await loginApi.emailLookup(params);
      } catch (error) {
        commit('authError', error.data);
      }
      commit('emailLookupRequest', response);
      return response;
    },
    signIn: async ({commit}: ActionContext<TAuthStoreState, TAppStoreState>, payload: TSendLoginRequestParams): Promise<void> => {
      commit('authRequest');
      let response;
      try {
        response = await loginApi.signInReq(payload);
      } catch (error) {
        commit('authError', error.data);
        return;
      }
      commit('authSuccess', response);
    },
    signUp: async ({commit}: ActionContext<TAuthStoreState, TAppStoreState>, payload: TSendLoginRequestParams): Promise<void> => {
      commit('authRequest');
      let response;
      try {
        response = await loginApi.signUpReq(payload);
      } catch (error) {
        commit('authError', error.data);
        return;
      }
      commit('authSuccess', response);
    },
    loginByGoogle: async ({commit}: ActionContext<TAuthStoreState, TAppStoreState>, payload: TLoginByOauthProviderRequestParams): Promise<void> => {
      commit('authRequest');
      let response;
      try {
        response = await loginApi.loginByGoogle(payload);
      } catch (error) {
        commit('authError', error.data);
        return;
      }
      commit('authSuccess', response);
    },
    loginByFacebook: async ({commit}: ActionContext<TAuthStoreState, TAppStoreState>, payload: TLoginByOauthProviderRequestParams): Promise<void> => {
      commit('authRequest');
      let response;
      try {
        response = await loginApi.loginByFacebook(payload);
      } catch (error) {
        commit('authError', error.data);
        return;
      }
      commit('authSuccess', response);
    },
    loginByLinkedIn: async ({commit}: ActionContext<TAuthStoreState, TAppStoreState>, payload: TLoginByOauthProviderRequestParams): Promise<void> => {
      commit('authRequest');
      let response;
      try {
        response = await loginApi.loginByLinkedIn(payload);
      } catch (error) {
        commit('authError', error.data);
        return;
      }
      commit('authSuccess', response);
    },
    forgotPassword: async ({commit}: ActionContext<TAuthStoreState, TAppStoreState>, login: string): Promise<void> => {
      commit('forgotPasswordRequest');
      let response;
      try {
        response = await loginApi.forgotPasswordReq(login);
      } catch (error) {
        commit('forgotPasswordRequestError', error.data);
        return;
      }
      commit('forgotPasswordRequestSuccess', response);
      commit('setConfirmId', response.confirmation_id);
    },
    changePassword: async ({commit}: ActionContext<TAuthStoreState, TAppStoreState>, payload: TPasswordChangeConfirmRequestParams): Promise<void> => {
      commit('changePasswordRequest');
      let response;
      try {
        response = await loginApi.changePasswordReq(payload);
      } catch (error) {
        commit('changePasswordRequestError', error.data);
        return;
      }
      commit('changePasswordRequestSuccess', response);
      commit('authSuccess', response);
    },
    sendPhoneNumber: async ({commit}: ActionContext<TAuthStoreState, TAppStoreState>, phone: string): Promise<void> => {
      commit('authRequest');
      let response;
      try {
        response = await loginApi.phoneReq(phone);
      } catch (error) {
        commit('authError', error.data);
        return;
      }
      commit('setConfirmId', response.confirmation_id);
    },
    sendConfirmCode: async ({commit}: ActionContext<TAuthStoreState, TAppStoreState>, data: TConfirmCodeRequestParams): Promise<void> => {
      commit('authRequest');
      let responseData: TConfirmCodeResponseData;
      try {
        responseData = await loginApi.confirmCode(data);
      } catch (error) {
        commit('authError', error.data);
        return;
      }
      commit('authSuccess', responseData);
    },
    authLogout: ({commit}: ActionContext<TAuthStoreState, TAppStoreState>): void => {
      commit('authLogout');
    },
    removeConfirmId: ({commit}: ActionContext<TAuthStoreState, TAppStoreState>): void => {
      commit('removeConfirmId');
    },
    toggleAuthPopupVisible: ({commit, state}: ActionContext<TAuthStoreState, TAppStoreState>): void => {
      commit('setAuthPopupVisible', !state.isAuthPopupVisible);
    },
    setAuthPopupVisible: ({commit}: ActionContext<TAuthStoreState, TAppStoreState>, payload: any): void => {
      const { isAuthPopupVisible, targetRoute, visibleScreen } = payload;
      commit('setAuthPopupVisible', isAuthPopupVisible);
      if (targetRoute) {
        commit('setAuthPopupTargetRoute', targetRoute);
      }
      if (visibleScreen ) {
        // TODO: use the visibleScreen param here and in the component
        // commit('setAuthPopupVisibleScreen', visibleScreen);
      }
    },
    setAuthPopupTargetRoute: ({commit}: ActionContext<TAuthStoreState, TAppStoreState>, payload: Location): void => {
      commit('setAuthPopupTargetRoute', payload);
    },
    setSignUpEnabled: ({commit}: ActionContext<TAuthStoreState, TAppStoreState>, isEnabled: boolean): void => {
      commit('setSignUpEnabled', isEnabled);
    },
    clearAuthPopupTargetRoute({commit}: ActionContext<TAuthStoreState, TAppStoreState>): void {
      commit('setAuthPopupTargetRoute', null);
    },
    clearAuthError({commit}: ActionContext<TAuthStoreState, TAppStoreState>): void {
      commit('clearAuthError');
    },
    authSuccess({commit}: ActionContext<TAuthStoreState, TAppStoreState>, params: TConfirmCodeResponseData): void {
      commit('authSuccess', params);
    },
    setStandaloneAuthVisibility: ({commit}: ActionContext<TAuthStoreState, TAppStoreState>, isVisible: boolean): void => {
      commit('setStandaloneAuthVisibility', isVisible);
    },
    setContactInfoInviterVisibility: ({commit}: ActionContext<TAuthStoreState, TAppStoreState>, isVisible: boolean): void => {
      commit('setContactInfoInviterVisibility', isVisible);
    },
    setContactInfoInviterBackgroundMode: ({commit}: ActionContext<TAuthStoreState, TAppStoreState>, isRunningOnBackground: boolean): void => {
      commit('setContactInfoInviterBackgroundMode', isRunningOnBackground);
    },
  },
};

export default authStore;
