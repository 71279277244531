import { TAppStoreState } from '@/_types/store/app-store-state.type';
import { ActionContext, Module } from 'vuex';

import { TStoreEntityState } from '@/_types/store/store-entity-state.type';
import messagesApi, { TCallMessageListParams, TCallUserMessageListParams, TSendMessageParams } from '@/_api/messages.api';
import AxiosCancellableRequest from '@/_types/api/axios-cancellable-request.class';
import { TApiListResponse } from '@/_types/api/api-list-response.type';
import { TMessage } from '@/_types/messages.type';
import ApiErrorResponseData from '@/_types/api/api-error-response-data.class';

export type TMessagesStoreState = {
  messagesUserListEntity: TStoreEntityState<TApiListResponse<TMessage>>;
  messagesListEntity: TStoreEntityState<TApiListResponse<TMessage>>;
  sendMessageEntity: TStoreEntityState<null>;

  eventId: number;
  userMessages: { [ userId: number ]: TStoreEntityState<TApiListResponse<TMessage>> };
}

const messagesListRequest = new AxiosCancellableRequest<TCallMessageListParams, TApiListResponse<TMessage>>(messagesApi.callMessageList.bind(messagesApi));
const messagesUserListRequest = new AxiosCancellableRequest<TCallUserMessageListParams, TApiListResponse<TMessage>>(messagesApi.callUserMessageList.bind(messagesApi));
const sendMessageRequest = new AxiosCancellableRequest<TSendMessageParams, void >(messagesApi.sendMessage.bind(messagesApi));

const messagesStore: Module<TMessagesStoreState, TAppStoreState> = {
  namespaced: true,
  state: {
    messagesListEntity: {
      isLoading: false,
      data: null,
      error: null,
    },
    messagesUserListEntity: {
      isLoading: false,
      data: null,
      error: null,
    },
    sendMessageEntity: {
      isLoading: false,
      error: null,
    },

    eventId: null,
    userMessages: {},
  },
  getters: {
    // TODO: removeme
    messages: (state: TMessagesStoreState): TApiListResponse<TMessage> => {
      return state.messagesListEntity.data;
    },
    // TODO: removeme
    userMessages: (state: TMessagesStoreState): TApiListResponse<TMessage> => {
      return state.messagesUserListEntity.data;
    },
    // TODO: removeme
    userMessagesIsLoading: (state: TMessagesStoreState): boolean => {
      return state.sendMessageEntity.isLoading;
    },

    getMessagesStateByUserId: (state: TMessagesStoreState) => (userId: number): TStoreEntityState<TApiListResponse<TMessage>> => {
      return state.userMessages[userId] || null;
    },
  },
  actions: {

    setEventId: ({ commit, state }: ActionContext<TMessagesStoreState, TAppStoreState>, eventId: number): void => {
      if (state.eventId === eventId) {
        return;
      }
      commit('setEventId', eventId);
    },

    requestUserMessages: async (context: ActionContext<TMessagesStoreState, TAppStoreState>, params: { userId: number; offset?: number; limit?: number }): Promise<void> => {
      const { commit, state } = context;
      const { userId, offset, limit } = params;
      const currentState = state.userMessages[userId];

      if (currentState && currentState.isLoading) {
        return;
      }

      commit('userMessagesRequestError', { userId, error: null });
      commit('userMessagesRequest', { userId });
      let result: TApiListResponse<TMessage> = null;
      try {
        result = await messagesApi.callUserMessageList({
          eventId: state.eventId,
          userId: userId,
          offset,
          limit
        });
      } catch (error) {
        commit('userMessagesRequestError', { userId, error });
      } finally {
        commit('userMessages', { userId, data: result });
      }
    },

    clearUserMessages: async (context: ActionContext<TMessagesStoreState, TAppStoreState>, params: { userId: number }): Promise<void> => {
      const { commit } = context;
      const { userId } = params;
      commit('clearUserMessages', userId);
    },

    // TODO: removeme
    callMessageList: async (context: ActionContext<TMessagesStoreState, TAppStoreState>, params: TCallMessageListParams): Promise<TApiListResponse<TMessage>> => {
      const { commit } = context;

      commit('messagesListRequest');
      let data;
      try {
        data = await messagesListRequest.load(params);
        return data;
      } catch (error) {
        commit('messagesListError', error);
        return null;
      } finally {
        commit('messagesList', data);
      }

    },

    // TODO: removeme
    callUserMessageList: async (context: ActionContext<TMessagesStoreState, TAppStoreState>, params: TCallUserMessageListParams): Promise<void> => {
      const { commit } = context;

      commit('messagesUserListRequest');
      let data;
      try {
        data = await messagesUserListRequest.load(params);
      } catch (error) {
        commit('messagesUserListError', error);
      } finally {
        commit('messagesUserList', data);
      }

    },

    sendMessage: async (context: ActionContext<TMessagesStoreState, TAppStoreState>, params: TSendMessageParams): Promise<void> => {
      const { commit } = context;

      commit('sendMessageRequest');
      let data;
      try {
        data = await sendMessageRequest.load(params);
      } catch (error) {
        commit('sendMessageError', error);
      } finally {
        commit('sendMessage', data);
      }
    },

    pushMessage: async (context: ActionContext<TMessagesStoreState, TAppStoreState>, params: { message: TMessage }): Promise<void> => {
      const { message } = params;
      const { commit } = context;

      let userId = 0;

      if (message.id < 0) {
        // message.id < 0 means it is a temporary just written message
        if (message.recipient && message.recipient.user_id) {
          userId = message.recipient.user_id;
        }
      } else if (message.id > 0) {
        // message.id > 0 means message has been received from API.
        if (message.contact && message.contact.user_id) {
          userId = message.contact.user_id;
        }
      }

      if (!userId) {
        return;
      }

      commit('pushMessage', { userId, message });
    }
  },
  mutations: {

    setEventId(state: TMessagesStoreState, eventId: number): void {
      state.eventId = eventId;
      state.userMessages = {};
    },

    userMessagesRequest(state: TMessagesStoreState, params: { userId: number }): void {
      const { userId } = params;
      if (!state.userMessages[userId]) {
        state.userMessages[userId] = {
          isLoading: false,
          data: null,
          error: null,
        };
      }
      state.userMessages[userId].isLoading = true;
      state.userMessages = Object.assign({}, state.userMessages);
    },

    pushMessage(state: TMessagesStoreState, params: {userId: number; message: TMessage}): void {
      const { userId, message } = params;
      if (!userId || !message) {
        return;
      }

      if (!state.userMessages[userId]) {
        state.userMessages[userId] = {
          isLoading: false,
          data: {
            Limit: null,
            Offset: null,
            List: [],
            Total: 0
          },
          error: null,
        };
      }

      state.userMessages[userId].data.List.push(message);
      state.userMessages = Object.assign({}, state.userMessages);
    },

    clearUserMessages(state: TMessagesStoreState, userId: number): void {
      if (!state.userMessages[userId]) {
        return;
      }
      state.userMessages[userId].data = null;
      state.userMessages = Object.assign({}, state.userMessages);
    },

    userMessagesRequestError(state: TMessagesStoreState, params: { userId: number; error: ApiErrorResponseData }): void {
      const { userId, error } = params;
      if (!state.userMessages[userId]) {
        return;
      }
      state.userMessages[userId].error = error;
      state.userMessages = Object.assign({}, state.userMessages);
    },

    userMessages(state: TMessagesStoreState, params: { userId: number; data: TApiListResponse<TMessage> }): void {
      const { userId, data } = params;
      if (!state.userMessages[userId]) {
        return;
      }
      if (data) {
        data.List = data.List.reverse();
        if (state.userMessages[userId].data) {
          data.List = data.List.concat(state.userMessages[userId].data.List);
        }
      }
      state.userMessages[userId].data = data;
      state.userMessages[userId].isLoading = false;
      state.userMessages = Object.assign({}, state.userMessages);
    },

    // TODO: removeme
    messagesListRequest(state: TMessagesStoreState): void{
      // state.messagesListEntity.data = null;
      state.messagesListEntity.isLoading = false;
      state.messagesListEntity.error = null;
    },

    // TODO: removeme
    messagesListError(state: TMessagesStoreState, error: ApiErrorResponseData): void {
      state.messagesListEntity.error = error;
    },

    // TODO: removeme
    messagesList(state: TMessagesStoreState, messages: TApiListResponse<TMessage>): void {
      state.messagesListEntity.data = messages;
      state.messagesListEntity.isLoading = false;
    },

    // TODO: removeme
    messagesUserListRequest(state: TMessagesStoreState): void{
      // state.messagesUserListEntity.data = null;
      state.messagesUserListEntity.isLoading = false;
      state.messagesUserListEntity.error = null;
    },

    // TODO: removeme
    messagesUserListError(state: TMessagesStoreState, error: ApiErrorResponseData): void {
      state.messagesUserListEntity.error = error;
    },

    // TODO: removeme
    messagesUserList(state: TMessagesStoreState, messages: TApiListResponse<TMessage>): void {
      if (!messages) { return; }

      const { Limit, Offset, Total, List } = messages;

      state.messagesUserListEntity.data = {
        Limit: Limit,
        Offset: Offset,
        Total: Total,
        List: List.slice().reverse()
      };
      state.messagesUserListEntity.isLoading = false;

    },

    sendMessageRequest(state: TMessagesStoreState): void{
      state.sendMessageEntity.isLoading = true;
      state.sendMessageEntity.error = null;
    },

    sendMessageError(state: TMessagesStoreState, error: ApiErrorResponseData): void {
      state.sendMessageEntity.error = error;
    },

    sendMessage(state: TMessagesStoreState): void {
      state.sendMessageEntity.isLoading = false;
    },
  },
};

export default messagesStore;
