import Vue from 'vue';
import './main.init';
import { VNode } from 'vue/types/vnode';
import { CreateElement } from 'vue/types/vue';
import { RenderContext } from 'vue/types/options';

import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import i18n from '@/i18n';
import '@/_styles/main.scss';

Vue.config.productionTip = false;
Vue.config.ignoredElements = [
  'seatplan-viewer',
];

Vue.component('vnode', {
  functional: true,
  render(h: CreateElement, context: RenderContext): VNode {
    return context.props.node;
  }
});

new Vue({
  router,
  store,
  i18n,
  render: (h): VNode => h(App),
}).$mount('#app');
