


import {Vue, Component, Prop, Ref} from 'vue-property-decorator';

@Component
export default class EwCheckbox extends Vue {

  @Ref('checkbox') checkboxElement: HTMLInputElement;

  public get isChecked(): boolean {
    return this.value;
  };

  @Prop({type: Boolean, default: false})
  public readonly value: boolean;

  @Prop({type: String, default: ''})
  public readonly labelText: string;

  @Prop({type: Boolean, default: false})
  public readonly disabled: boolean;

  public get model(): boolean {
    return this.value;
  }

  public set model(value: boolean) {
    this.$emit('input', value);
  }

  public onClick(): void {
    this.model = !this.model;
  }

}
