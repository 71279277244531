import { ActionContext, Module } from 'vuex';
import MeetingRoomsHelper from '@/_modules/meeting-rooms/helpers/meeting-rooms.helper';
import { MeetingRoomType } from '@/_modules/meeting-rooms/types/meeting-room-type.enum';
import { TMeetingRoomsStoreState } from '@/_modules/meeting-rooms/types/meeting-rooms-store-state.type';
import { TAppStoreState } from '@/_types/store/app-store-state.type';
import { TMeetingRoomState } from '@/_modules/meeting-rooms/types/meeting-room-state.type';
import { TMeetingRoomConfig } from '@/_modules/meeting-rooms/types/meeting-room-config.type';
import { TCompanyVideoChatState } from '@/_modules/meeting-rooms/types/company-video-chat-state.type';

const meetingRoomsStore: Module<TMeetingRoomsStoreState, TAppStoreState> = {
  namespaced: true,
  state: {
    eventId: null,
    meetingRooms: [],
    companyVideoChatStateByExternalId: {},
  },
  getters: {
    getMeetingRoomById: (state: TMeetingRoomsStoreState): (id: number) => TMeetingRoomState => {
      return (meetingRoomId: number): TMeetingRoomState => {
        return state.meetingRooms.find((meetingRoom: TMeetingRoomState) => meetingRoom.id === meetingRoomId);
      };
    },
    meetings: (state: TMeetingRoomsStoreState): TMeetingRoomState[] => {
      return state.meetingRooms.filter((meetingRoom: TMeetingRoomState) => meetingRoom.config.type === MeetingRoomType.MEETING);
    },
    broadcasts: (state: TMeetingRoomsStoreState): TMeetingRoomState[] => {
      return state.meetingRooms.filter((meetingRoom: TMeetingRoomState) => meetingRoom.config.type === MeetingRoomType.BROADCAST);
    },
    companyVideoChats: (state: TMeetingRoomsStoreState): TMeetingRoomState[] => {
      return state.meetingRooms.filter((meetingRoom: TMeetingRoomState) => meetingRoom.config.type === MeetingRoomType.COMPANY);
    },
    getCompanyVideoChatStateByExternalId: (state: TMeetingRoomsStoreState) => (externalId: string): TCompanyVideoChatState => {
      return state.companyVideoChatStateByExternalId[externalId] || null;
    },
  },
  actions: {

    setEventId({ commit }: ActionContext<TMeetingRoomsStoreState, TAppStoreState>, eventId: number): void {
      commit('setEventId', eventId);
    },

    join({ commit }: ActionContext<TMeetingRoomsStoreState, TAppStoreState>, meetingRoomConfig: TMeetingRoomConfig): void {
      commit('addMeetingRoom', {
        id: MeetingRoomsHelper.getMeetingRoomId(meetingRoomConfig),
        config: meetingRoomConfig,
        isMinimized: false,
        isMaximized: false,
      });
    },

    leave({ commit }: ActionContext<TMeetingRoomsStoreState, TAppStoreState>, meetingRoomId: number): void {
      commit('removeMeetingRoom', meetingRoomId);
    },

    minimize({ commit }: ActionContext<TMeetingRoomsStoreState, TAppStoreState>, meetingRoomId: number): void {
      commit('setMeetingRoomIsMinimized', { id: meetingRoomId, isMinimized: true });
    },

    unMinimize({ commit }: ActionContext<TMeetingRoomsStoreState, TAppStoreState>, meetingRoomId: number): void {
      commit('setMeetingRoomIsMinimized', { id: meetingRoomId, isMinimized: false });
    },

    maximize({ commit }: ActionContext<TMeetingRoomsStoreState, TAppStoreState>, meetingRoomId: number): void {
      commit('setMeetingRoomIsMaximized', { id: meetingRoomId, isMaximized: true });
    },

    unMaximize({ commit }: ActionContext<TMeetingRoomsStoreState, TAppStoreState>, meetingRoomId: number): void {
      commit('setMeetingRoomIsMaximized', { id: meetingRoomId, isMaximized: false });
    },

    setCompanyVideoChatStates({ commit }: ActionContext<TMeetingRoomsStoreState, TAppStoreState>, companyVideoChatStates: TCompanyVideoChatState[]): void {
      commit('setCompanyVideoChatStates', companyVideoChatStates);
    },

    setCompanyVideoChatState({ commit }: ActionContext<TMeetingRoomsStoreState, TAppStoreState>, companyVideoChatState: TCompanyVideoChatState): void {
      commit('setCompanyVideoChatState', companyVideoChatState);
    },

    removeCompanyVideoChatState({ commit }: ActionContext<TMeetingRoomsStoreState, TAppStoreState>, companyVideoChatState: TCompanyVideoChatState): void {
      commit('removeCompanyVideoChatState', companyVideoChatState);
    },

  },
  mutations: {

    setEventId(state: TMeetingRoomsStoreState, eventId: number): void {
      if (state.eventId === eventId) {
        return;
      }
      state.eventId = eventId;
      state.meetingRooms = [];
      state.companyVideoChatStateByExternalId = {};
    },

    addMeetingRoom(state: TMeetingRoomsStoreState, newMeetingRoom: TMeetingRoomState): void {
      const { meetingRooms } = state;
      let isMeetingRoomAlreadyExists = false;
      meetingRooms.forEach((meetingRoom: TMeetingRoomState) => {
        if (meetingRoom.id === newMeetingRoom.id) {
          meetingRoom.isMinimized = false;
          isMeetingRoomAlreadyExists = true;
        } else {
          meetingRoom.isMinimized = true;
        }
      });
      if (!isMeetingRoomAlreadyExists) {
        newMeetingRoom.isMinimized = false;
        meetingRooms.push(newMeetingRoom);
      }
    },

    removeMeetingRoom(state: TMeetingRoomsStoreState, meetingRoomId: number): void {
      const { meetingRooms } = state;
      for (let index = 0; index < meetingRooms.length; index++) {
        if (meetingRooms[index].id === meetingRoomId) {
          meetingRooms.splice(index, 1);
          break;
        }
      }
    },

    setMeetingRoomIsMinimized(state: TMeetingRoomsStoreState, { id, isMinimized }: { id: number; isMinimized: boolean }): void {
      const { meetingRooms } = state;
      for (let index = 0; index < meetingRooms.length; index++) {
        if (meetingRooms[index].id === id) {
          meetingRooms[index].isMinimized = isMinimized;
          break;
        }
      }
    },

    setMeetingRoomIsMaximized(state: TMeetingRoomsStoreState, { id, isMaximized }: { id: number; isMaximized: boolean }): void {
      const { meetingRooms } = state;
      for (let index = 0; index < meetingRooms.length; index++) {
        if (meetingRooms[index].id === id) {
          meetingRooms[index].isMaximized = isMaximized;
          break;
        }
      }
    },

    setCompanyVideoChatStates(state: TMeetingRoomsStoreState, companyVideoChatStates: TCompanyVideoChatState[]): void {
      state.companyVideoChatStateByExternalId = companyVideoChatStates.reduce((acc: { [externalId: string]: TCompanyVideoChatState }, item: TCompanyVideoChatState): { [externalId: string]: TCompanyVideoChatState } => {
        acc[item.externalId] = item;
        return acc;
      }, {});
    },

    setCompanyVideoChatState(state: TMeetingRoomsStoreState, companyVideoChatState: TCompanyVideoChatState): void {
      if (!companyVideoChatState) {
        return;
      }
      state.companyVideoChatStateByExternalId[companyVideoChatState.externalId] = companyVideoChatState;
      state.companyVideoChatStateByExternalId = Object.assign({}, state.companyVideoChatStateByExternalId);
    },

    removeCompanyVideoChatState(state: TMeetingRoomsStoreState, companyVideoChatState: TCompanyVideoChatState): void {
      if (!companyVideoChatState) {
        return;
      }
      delete state.companyVideoChatStateByExternalId[companyVideoChatState.externalId];
      state.companyVideoChatStateByExternalId = Object.assign({}, state.companyVideoChatStateByExternalId);
    },

  },
};

export default meetingRoomsStore;
