<template>
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" class="svg-icon">
    <path
      class="circle"
      d="M13 25C19.6274 25 25 19.6274 25 13C25 6.37258 19.6274 1 13 1C6.37258 1 1 6.37258 1 13C1 19.6274 6.37258 25 13 25Z"
      stroke="black"/>
    <path
      class="figure"
      d="M16.6085 15.5283L14.0776 12.9974L16.6085 10.4665C16.7524 10.3226 16.8333 10.1273 16.8333 9.92378C16.8333 9.72022 16.7524 9.52499 16.6085 9.38105C16.5372 9.30978 16.4526 9.25325 16.3595 9.21467C16.2664 9.1761 16.1666 9.15625 16.0658 9.15625C15.8622 9.15625 15.667 9.23711 15.523 9.38105L12.9921 11.912L10.4612 9.38105C10.3173 9.23711 10.122 9.15625 9.91849 9.15625C9.71492 9.15625 9.5197 9.23711 9.37576 9.38105C9.23182 9.52499 9.15095 9.72022 9.15095 9.92378C9.15095 10.1273 9.23182 10.3226 9.37576 10.4665L11.9067 12.9974L9.37576 15.5283C9.30126 15.5977 9.24185 15.6818 9.20123 15.7751C9.16061 15.8685 9.13965 15.9692 9.13965 16.0711C9.13965 16.1729 9.16061 16.2736 9.20123 16.367C9.24185 16.4604 9.30126 16.5444 9.37576 16.6138C9.44677 16.6855 9.53129 16.7425 9.62445 16.7813C9.71761 16.8202 9.81755 16.8402 9.91849 16.8402C10.0194 16.8402 10.1194 16.8202 10.2125 16.7813C10.3057 16.7425 10.3902 16.6855 10.4612 16.6138L12.9921 14.0829L15.523 16.6138C15.6675 16.7568 15.8625 16.8371 16.0658 16.8371C16.269 16.8371 16.4641 16.7568 16.6085 16.6138C16.6802 16.5428 16.7372 16.4582 16.776 16.3651C16.8149 16.2719 16.8349 16.172 16.8349 16.0711C16.8349 15.9701 16.8149 15.8702 16.776 15.777C16.7372 15.6839 16.6802 15.5993 16.6085 15.5283Z"
      fill="black"/>
  </svg>
</template>

<script>
import Vue from 'vue';

const IconCloseCircleOutline = Vue.extend({
  name: 'icon-close-circle-outline',
  props: {
    width: {
      type: Number,
      default: 24,
    },
    height: {
      type: Number,
      default: 24,
    },
  },
});

export default IconCloseCircleOutline;
</script>

<style scoped lang="scss">
.svg-icon {
  .circle {
    stroke: $ew-black;
  }

  .figure {
    fill: $ew-black;
  }

  &:hover {
    .circle {
      stroke: $ew-blue;
    }

    .figure {
      fill: $ew-blue;
    }
  }
}
</style>
