
import DateTimeHelper from '@/_helpers/date-time.helper';
import {TMessage} from '@/_types/messages.type';
import ContactHelper from '@/_helpers/contact.helper';

export default class MessagesHelper {

  public static responseToMessageItemConverter(responseObj: any): TMessage {
    if(!responseObj) return null;
    return Object.assign({}, responseObj, {
      created_at: DateTimeHelper.apiDateToDate(responseObj.created_at),
      updated_at: DateTimeHelper.apiDateToDate(responseObj.updated_at),
      contact: ContactHelper.responseToContactConverter(responseObj.contact),
      recipient: ContactHelper.responseToContactConverter(responseObj.recipient),
      sender: ContactHelper.responseToContactConverter(responseObj.sender)
    });
  }

}
