


import IconEwInputQuestion from '@/_modules/icons/components/icon-ew-input-question.vue';
import {Prop, Vue} from 'vue-property-decorator';
import Component from 'vue-class-component';
import ApiErrorResponseData from '@/_types/api/api-error-response-data.class';
import EwClosePopUpIcon from '@/_modules/icons/components/ew-close-pop-up-icon.vue';

@Component({
  components: {
    IconEwInputQuestion,
    EwClosePopUpIcon
  }
})
export default class ErrorInfo extends Vue {

  public isShowErrorInfo: boolean = false

  @Prop({type: Object, default: (): ApiErrorResponseData => null})
  public readonly errorData: ApiErrorResponseData;

  public get errorRequestId(): string {
    return this.errorData && this.errorData.requestId;
  }

  public get errorCode(): number {
    return this.errorData && this.errorData.code;
  }

  public get errorText(): string {
    return this.errorData && this.errorData.error;
  }

  public showErrorInfo(): void {
    this.isShowErrorInfo = !this.isShowErrorInfo;
  }

  public onCloseClick(): void {
    this.isShowErrorInfo = false;
  }
}
