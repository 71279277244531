import { ActionContext, Module } from 'vuex';
import { TPromoPageStoreState } from '@/_modules/promo/types/promo-page-store-state.type';
import { TAppStoreState } from '@/_types/store/app-store-state.type';
import { TContact } from '@/_types/contact.type';
import { TPromoPage, TCodeList, TCreatePromoPage } from '@/_types/promo-page/promo-page.type';
import eventApi, { TGetEventContactParams } from '@/_modules/events/api/event/event.api';
import AxiosCancellableRequest from '@/_types/api/axios-cancellable-request.class';
import promoPageApi, {
  TGetContactPromoPageParams,
  TCreatePromoPageParams,
  TPromopageCodeListParams,
  TGetAllEventPromoPagesParams,
  TDeletePromoPageParams,
  TDeletePromoPageCodeParams,
  TGetMyEventPromoPagesParams,
  TPromoPageFavoriteParams
} from '@/_modules/promo/api/promo-page.api';
import contactsApi from '@/_api/contacts/contacts.api';
import { TApiListResponse } from '@/_types/api/api-list-response.type';
import { TContactTag } from '@/_types/contact-tag.type';
import _cloneDeep from 'lodash.clonedeep';
import { AxiosResponse } from 'axios';
import ApiErrorResponseData from '@/_types/api/api-error-response-data.class';

const getEventContactRequest = new AxiosCancellableRequest<TGetEventContactParams, TContact>(eventApi.getContact.bind(eventApi));
const editContactRequest = new AxiosCancellableRequest<TEditContactActionParams, boolean>(contactsApi.editContact.bind(contactsApi));
const getContactPromoPageRequest = new AxiosCancellableRequest<TGetContactPromoPageParams, TPromoPage>(promoPageApi.getContactPromoPage.bind(promoPageApi));
const getIndividualPromoPageRequest = new AxiosCancellableRequest<TDeletePromoPageParams, TPromoPage>(promoPageApi.getPromoPage.bind(promoPageApi));
const createPromoPageRequest = new AxiosCancellableRequest<TCreatePromoPageParams, TPromoPage>(promoPageApi.createPromoPage.bind(promoPageApi));
const deletePromoPageRequest = new AxiosCancellableRequest<TDeletePromoPageParams, AxiosResponse>(promoPageApi.deletePromoPage.bind(promoPageApi));
const deletePromoPageCodeRequest = new AxiosCancellableRequest<TDeletePromoPageCodeParams, void>(promoPageApi.deletePromoPageCode.bind(promoPageApi));
const promoPageListAllRequest = new AxiosCancellableRequest<TGetAllEventPromoPagesParams, TPromoPage[]>(promoPageApi.getAllEventPromoPages.bind(promoPageApi));
const promoPageListMyRequest = new AxiosCancellableRequest<TGetMyEventPromoPagesParams, TPromoPage[]>(promoPageApi.getMyEventPromoPages.bind(promoPageApi));
const getPromoPageCodeListRequest = new AxiosCancellableRequest<TPromopageCodeListParams, TApiListResponse<TCodeList[]>>(promoPageApi.promoPageCodeList.bind(promoPageApi));

const PROMOPAGES_PER_PAGE = 60;

export type TEditContactActionParams = {
  event_id: number;
  name: string;
  surname?: string;
  email?: string;
  photo_url?: string;
  country?: string;
  city?: string;
  company_name?: string;
  company_website?: string;
  company_position?: string;
  company_description?: string;
  tags?: TContactTag[];
  phone?: string;
  lang: string;
  facebook?: string;
  linked_in?: string;
  skype?: string;
  telegram?: string;
  twitter?: string;
  viber?: string;
  wechat?: string;
}

export type TSetPromoPagesWithCodes = {
  promoPage?: TPromoPage;
  value: string;
  sortingValue?: number;
  countryId?: number;
}

const promoPageStore: Module<TPromoPageStoreState, TAppStoreState> = {
  namespaced: true,
  state: {
    eventId: null,
    countryId: null,
    isMediaBlockOverlayVisible: false,
    contactEntity: {
      isLoading: false,
      data: null,
      error: null,
    },
    ownerContactEntity: {
      isLoading: false,
      data: null,
      error: null,
    },
    promoPageEntity: { // TODO: rename to contactPromoPageEntity — this stores promopage that current contact owns/assists
      isLoading: false,
      data: null,
      error: null,
    },
    individualPromoPageEntity: { // TODO: is naming OK?
      isLoading: false,
      data: null,
      error: null,
    },
    promoPageListAllEntity: {
      isLoading: false,
      data: null,
      error: null,
    },
    promoPageCodeListAllEntity: {
      isLoading: false,
      data: null,
      error: null,
    },
    isLoading: true,
    search: '',
    viewType: 'map',
    listType: 'all',
    listAll: {
      Limit: PROMOPAGES_PER_PAGE,
      List: [],
      Offset: 0,
      Total: 0
    },
    listMy: {
      Limit: PROMOPAGES_PER_PAGE,
      List: [],
      Offset: 0,
      Total: 0
    },
    listAllCodes: [],
    codes: {
      Limit: PROMOPAGES_PER_PAGE,
      List: [],
      Offset: 0,
      Total: 0
    },
    filterIds: [],
    pavilionId: null,
    isRemoveCoworkerConfirmed: false,
    isRemoveCoworkerPopupVisible: false
  },
  getters: {
    eventId: (state: TPromoPageStoreState): number => {
      return state.eventId;
    },
    isViewTypeMap: (state: TPromoPageStoreState): boolean => {
      return state.viewType === 'map';
    },
    isViewTypeList: (state: TPromoPageStoreState): boolean => {
      return state.viewType === 'list';
    },
    isListTypeAll: (state: TPromoPageStoreState): boolean => {
      return state.listType === 'all';
    },
    isListTypeMy: (state: TPromoPageStoreState): boolean => {
      return state.listType === 'my';
    },
    getListAll: (state: TPromoPageStoreState): TPromoPage[] | void => {
      if (state.listAll) {
        return state.listAll.List || [];
      }
    },
    isListAllMore: (state: TPromoPageStoreState): boolean => {
      return state.listAll.Offset <= state.listAll.Total;
    },
    getListMy: (state: TPromoPageStoreState): TPromoPage[] | void => {
      if (state.listMy) {
        return state.listMy.List || [];
      }
    },
    isListMyMore: (state: TPromoPageStoreState): boolean => {
      return state.listMy.Offset <= state.listMy.Total;
    },
    getListAllCodes: (state: TPromoPageStoreState): any => {
      return state.listAllCodes;
    },
    isFilters: (state: TPromoPageStoreState): boolean => {
      return state.filterIds.length > 0;
    },
    isPavilions: (state: TPromoPageStoreState): any => {
      return state.pavilionId !== null;
    },
    getListAllFilteredByPavilion: (state: TPromoPageStoreState): TPromoPage[] => {
      return state.listAll.List.filter((item: TPromoPage) => {
        return item.pavilion && item.pavilion.id === state.pavilionId;
      });
    },
    contact: (state: TPromoPageStoreState): TContact => {
      return state.contactEntity.data;
    },
    ownerContact: (state: TPromoPageStoreState): TContact => {
      return state.ownerContactEntity.data;
    },
    promoPage: (state: TPromoPageStoreState): TPromoPage => {
      return state.promoPageEntity.data;
    },
    individualPromoPage: (state: TPromoPageStoreState): TPromoPage => {
      return state.individualPromoPageEntity.data;
    },
    promoPageListAll: (state: TPromoPageStoreState): TPromoPage[] => {
      return state.promoPageListAllEntity.data;
    },
    promoPageCodeList: (state: TPromoPageStoreState): TCodeList[] | void => { // TODO: is | void needed?
      if (state.promoPageCodeListAllEntity.data) {
        return state.promoPageCodeListAllEntity.data.List;
      } else {
        return state.codes.List;
      }
    },
    isLoading: (state: TPromoPageStoreState): boolean => {
      return state.isLoading;
    },
    isContactLoading: (state: TPromoPageStoreState): boolean => {
      return state.contactEntity.isLoading;
    },
    isPromoPageLoading: (state: TPromoPageStoreState): boolean => {
      return state.promoPageEntity.isLoading;
    },
    videoStreamEmbed: (state: TPromoPageStoreState): string => {
      return state.promoPageEntity.data && state.promoPageEntity.data.video_stream_embed;
    },
    isMediaBlockOverlayVisible: (state: TPromoPageStoreState): boolean => {
      return state.isMediaBlockOverlayVisible;
    },
    isRemoveCoworkerConfirmed: (state: TPromoPageStoreState): boolean => {
      return state.isRemoveCoworkerConfirmed;
    },
    isRemoveCoworkerPopupVisible: (state: TPromoPageStoreState): boolean => {
      return state.isRemoveCoworkerPopupVisible;
    },
    filterIds: (state: TPromoPageStoreState): number[] => {
      return state.filterIds || [];
    },
  },
  actions: {

    reset: ({ commit }: ActionContext<TPromoPageStoreState, TAppStoreState>): void => {
      commit('setEventId', null);
    },

    refresh: ({ commit, dispatch, state }: ActionContext<TPromoPageStoreState, TAppStoreState>): void => {
      commit('refresh');
      dispatch('getContactPromoPage', state.eventId);
    },

    createPromoPage: async (context: ActionContext<TPromoPageStoreState, TAppStoreState>,
      params: TCreatePromoPageParams): Promise<TPromoPage> => {
      const { commit } = context;
      let data;
      try {
        data = await createPromoPageRequest.load(params);
        commit('rawPromoPageData', params.promoPageData);
        return data;
      } catch (error) {
        commit('createPromoPageError', error);
        return error.data;
      }
    },

    deletePromoPage: async (context: ActionContext<TPromoPageStoreState, TAppStoreState>,
      payload): Promise<AxiosResponse> => {
      const { commit } = context;
      try {
        const response = await deletePromoPageRequest.load(payload);
        if (response.status === 202) {
          const { externalId } = payload;
          commit('deletePromoPage', externalId);
        }
        return response;
      } catch (error) {
        commit('deletePromoPageError', error);
        return null;
      }
    },

    deletePromoPageCode: async (context: ActionContext<TPromoPageStoreState, TAppStoreState>,
      payload): Promise<void> => {
      const { commit } = context;

      try {
        return await deletePromoPageCodeRequest.load(payload);
      } catch (error) {
        commit('deletePromoPageCodeRequestError', error);
        return null;
      }
    },

    getPromoPageListAll: async (context: ActionContext<TPromoPageStoreState, TAppStoreState>,
      eventId): Promise<TPromoPage[]> => {
      const { commit } = context;
      commit('promoPageListAllRequest');
      let data;
      try {
        data = await promoPageListAllRequest.load(eventId);
        return data;
      } catch (error) {
        commit('promoPageListAllError', error);
        return null;
      } finally {
        commit('promoPageListAll', data);
      }
    },

    setListAll: async (context: ActionContext<TPromoPageStoreState, TAppStoreState>): Promise<TPromoPage[]> => {
      const { commit, state } = context;
      commit('SET_LOADING', true);

      try {
        const data = await promoPageListAllRequest.load({
          eventId: state.eventId,
          limit: state.listAll.Limit,
          offset: state.listAll.Offset,
          title: state.search.trim(),
          country_id: state.countryId,
          pavilion_id: state.pavilionId,
          tags: state.filterIds
        });
        commit('SET_LIST_ALL', data);
        return data;
      } catch (error) {
        commit('promoPageListAllError', error);
        return null;
      } finally {
        commit('SET_LOADING', false);
      }
    },

    setListMy: async (context: ActionContext<TPromoPageStoreState, TAppStoreState>): Promise<TPromoPage[]> => {
      const { commit, state } = context;
      commit('SET_LOADING', true);
      try {
        const data = await promoPageListMyRequest.load({
          eventId: state.eventId,
          limit: state.listMy.Limit,
          offset: state.listMy.Offset
        });
        commit('SET_LIST_MY', data);
        return data;
      } catch (error) {
        commit('promoPageListAllError', error);
        return null;
      } finally {
        commit('SET_LOADING', false);
      }
    },

    getPromoPagesWithCodes: async (context: ActionContext<TPromoPageStoreState, TAppStoreState>): Promise<void> => {
      const { commit, dispatch, state } = context;
      try {
        // if (state.codes.List.length === 0) {
        //   await dispatch('getPromoPageCodes', state.eventId);
        // }
        await dispatch('getPromoPageCodes', state.eventId);
        await dispatch('setListAll');
        commit('SET_LIST_ALL_WITH_CODES');
      } catch (error) {
        return null;
      }
    },

    getPromoPageCodes: async (context: ActionContext<TPromoPageStoreState, TAppStoreState>,
      eventId): Promise<TApiListResponse<TCodeList[]>> => {
      const { commit } = context;
      // commit('promoPageListAllRequest');
      let data;
      try {
        data = await getPromoPageCodeListRequest.load({ eventId });
        commit('SET_CODES', data);
        return data;
      } catch (error) {
        // commit('getPromoPageCodeListError', error);
        return null;
      }
    },

    addFavorite: async (context: ActionContext<TPromoPageStoreState, TAppStoreState>,
      payload: TPromoPageFavoriteParams): Promise<void> => {
      const { commit } = context;
      try {
        await promoPageApi.addFavorite(payload);
        commit('TOGGLE_FAVORITE', payload.external_id);
      } catch (e) {
        return null;
      }
    },

    removeFavorite: async (context: ActionContext<TPromoPageStoreState, TAppStoreState>,
      payload: TPromoPageFavoriteParams): Promise<void> => {
      const { commit } = context;
      try {
        await promoPageApi.removeFavorite(payload);
        commit('TOGGLE_FAVORITE', payload.external_id);
      } catch {
        /* ignore */
      }
    },

    // TODO: get rid of this
    promoPageUpdated: ({ commit, dispatch, state }: ActionContext<TPromoPageStoreState, TAppStoreState>): void => {
      commit('promoPageUpdated');
      dispatch('getContactPromoPage', state.eventId);
    },

    getContact: async (context: ActionContext<TPromoPageStoreState, TAppStoreState>, params, force?: boolean): Promise<TContact> => {
      const { eventId } = params;
      const { commit, state } = context;

      commit('setEventId', eventId);

      if (state.contactEntity.data && !params.acceptLanguage && !force) {
        return state.contactEntity.data;
      }

      if (state.contactEntity.isLoading) {
        try {
          return await getEventContactRequest.promise;
        } catch (error) {
          return null;
        }
      }

      commit('contactRequest');
      let data;
      try {
        data = await getEventContactRequest.load(params);
        return data;
      } catch (error) {
        commit('contactError', error);
        return null;
      } finally {
        commit('contact', data);
      }
    },

    contactLookup: async({ state }: ActionContext<TPromoPageStoreState, TAppStoreState>, params: { eventId: number }): Promise<{ contact_exists: boolean }> => {
      if (state.contactEntity.data) {
        return { contact_exists: !!state.contactEntity.data.id };
      }

      let data: TContact;
      try {
        data = await getEventContactRequest.load(params);
        return { contact_exists: !!data.id };
      } catch {
        /* ignore */
      }

      return { contact_exists: !!(data && data.id) || false };
    },

    editContact: async ({ commit, getters, dispatch }: ActionContext<TPromoPageStoreState, TAppStoreState>,
      params: TEditContactActionParams): Promise<boolean> => {

      commit('editContactRequest');

      let responseSuccess = false;
      try {
        responseSuccess = await editContactRequest.load(params);
      } catch (error) {
        commit('contactError', error);
      }

      if (responseSuccess) {
        // Updating the contactEntity in state
        let data: TContact = _cloneDeep(getters.contact); // For previously existing contact
        if (!data) { // For new contact
          const contact = await dispatch('getContact', { eventId: params.event_id });
          data = _cloneDeep(contact);
        }
        data.name = params.name;
        data.surname = params.surname;
        data.fullName = [(params.name || '').trim(), (params.surname || '').trim()].filter(x => x).join(' ');
        data.email = params.email;
        data.photo_url = params.photo_url;
        data.country = params.country || '';
        data.city = params.city;
        data.company_name = params.company_name;
        data.company_website = params.company_website;
        data.company_position = params.company_position;
        data.company_description = params.company_description;
        data.fullCompany = [(params.company_position || '').trim(), (params.company_name || '').trim()].filter(x => x).join(', ');
        data.tags = params.tags;
        data.phone = params.phone;
        data.facebook = params.facebook || null;
        data.linked_in = params.linked_in || null;
        data.skype = params.skype || null;
        data.telegram = params.telegram || null;
        data.twitter = params.twitter || null;
        data.wechat = params.wechat || null;
        data.viber = params.viber || null;

        commit('contact', data);
      }

      commit('setContactLoading', false);

      return responseSuccess;
    },

    setContactPhotoURL: ({ commit }: ActionContext<TPromoPageStoreState, TAppStoreState>, photoURL: string): void => {
      commit('setContactPhotoURL', photoURL);
    },

    // Gets the promopage of the currently logged in contact.
    getContactPromoPage: async (context: ActionContext<TPromoPageStoreState, TAppStoreState>,
      eventId: number): Promise<TPromoPage> => {
      const { commit, state, dispatch } = context;

      commit('setEventId', eventId);

      if (state.promoPageEntity.data) {
        return state.promoPageEntity.data;
      }

      if (state.promoPageEntity.isLoading) {
        try {
          return await getContactPromoPageRequest.promise;
        } catch (error) {
          return null;
        }
      }
      const contact = await dispatch('getContact', { eventId: eventId });
      if (!contact) {
        commit('promoPageError', state.contactEntity.error || new Error('Could not load contact.'));
        return null;
      }

      commit('promoPageRequest');
      let data;
      try {
        data = await getContactPromoPageRequest.load({ eventId, contactId: contact.id });
        return data;
      } catch (error) {
        commit('promoPageError', error);
        return null;
      } finally {
        commit('promoPage', data);
      }
    },

    // Gets info about a given promopage by eventId and company externalId
    getPromoPage: async (context: ActionContext<TPromoPageStoreState, TAppStoreState>,
      params: TDeletePromoPageParams): Promise<TPromoPage> => {
      const { commit, state } = context;
      const { eventId, externalId } = params;

      if (state.individualPromoPageEntity.data) {
        return state.individualPromoPageEntity.data;
      }

      if (state.individualPromoPageEntity.isLoading) {
        try {
          return await getIndividualPromoPageRequest.promise;
        } catch (error) {
          return null;
        }
      }

      commit('individualPromoPageRequest');

      let data;
      try {
        data = await getIndividualPromoPageRequest.load({ eventId, externalId });
        return data;
      } catch (error) {
        commit('individualPromoPageError', error);
        return null;
      } finally {
        commit('individualPromoPage', data);
      }
    },

    setIsMediaBlockOverlayVisible: ({ commit }: ActionContext<TPromoPageStoreState, TAppStoreState>,
      value: boolean): void => {
      commit('setIsMediaBlockOverlayVisible', value);
    },
    setRemoveCoworkerPopupVisible: ({ commit }: ActionContext<TPromoPageStoreState, TAppStoreState>,
      value: boolean): void => {
      commit('setRemoveCoworkerPopupVisible', value);
    },
    setRemoveCoworkerConfirmation: ({ commit }: ActionContext<TPromoPageStoreState, TAppStoreState>,
      value: boolean): void => {
      commit('setRemoveCoworkerConfirmation', value);
    },
    resetFilterIds: ({ commit }: ActionContext<TPromoPageStoreState, TAppStoreState>): void => {
      commit('resetFilterIds');
    },
    clearExpoHallListAll: ({ commit }: ActionContext<TPromoPageStoreState, TAppStoreState>): void => {
      commit('clearExpoHallListAll');
    },
  },
  mutations: {
    refresh(state: TPromoPageStoreState): void {
      state.contactEntity.data = null;
      state.contactEntity.isLoading = false;
      state.contactEntity.error = null;
      state.promoPageEntity.data = null;
      state.promoPageEntity.isLoading = false;
      state.promoPageEntity.error = null;
    },
    setEventId(state: TPromoPageStoreState, eventId: number): void {
      if (state.eventId === eventId) {
        return;
      }
      getEventContactRequest.cancel();
      getContactPromoPageRequest.cancel();

      state.eventId = eventId || null;
      state.contactEntity.data = null;
      state.contactEntity.isLoading = false;
      state.contactEntity.error = null;
      state.promoPageEntity.data = null;
      state.promoPageEntity.isLoading = false;
      state.promoPageEntity.error = null;
    },
    promoPageUpdated(state: TPromoPageStoreState): void {
      state.promoPageEntity.data = null;
      state.promoPageEntity.isLoading = false;
      state.promoPageEntity.error = null;
    },
    contactRequest(state: TPromoPageStoreState): void {
      state.contactEntity.data = null;
      state.contactEntity.isLoading = true;
      state.contactEntity.error = null;
    },
    editContactRequest(state: TPromoPageStoreState): void {
      state.contactEntity.isLoading = true;
      state.contactEntity.error = null;
    },
    setContactPhotoURL(state: TPromoPageStoreState, value: string): void {
      if (!state.contactEntity.data) {
        return;
      }
      state.contactEntity.data.photo_url = value;
    },
    contactError(state: TPromoPageStoreState, error: ApiErrorResponseData): void {
      state.contactEntity.error = error;
    },
    contact(state: TPromoPageStoreState, contact: TContact): void {
      state.contactEntity.data = contact || null;
      state.contactEntity.isLoading = false;
    },

    promoPageRequest(state: TPromoPageStoreState): void {
      state.promoPageEntity.data = null;
      state.promoPageEntity.isLoading = true;
      state.promoPageEntity.error = null;
    },
    promoPageError(state: TPromoPageStoreState, error: ApiErrorResponseData): void {
      state.promoPageEntity.error = error;
    },
    promoPage(state: TPromoPageStoreState, promoPage: TPromoPage): void {
      state.promoPageEntity.data = promoPage || null;
      state.promoPageEntity.isLoading = false;
      const owner = ((promoPage && promoPage.attached_contacts) || []).find(item => item.role === 'owner');
      state.ownerContactEntity.data = owner && owner.contact;
      state.ownerContactEntity.isLoading = false;
    },

    individualPromoPageRequest(state: TPromoPageStoreState): void {
      state.individualPromoPageEntity.data = null;
      state.individualPromoPageEntity.isLoading = true;
      state.individualPromoPageEntity.error = null;
    },
    individualPromoPageError(state: TPromoPageStoreState, error: ApiErrorResponseData): void {
      state.individualPromoPageEntity.error = error;
    },
    individualPromoPage(state: TPromoPageStoreState, promoPage: TPromoPage): void {
      state.individualPromoPageEntity.data = promoPage || null;
      state.individualPromoPageEntity.isLoading = false;
    },

    createPromoPageRequest(state: TPromoPageStoreState): void {
      state.promoPageEntity.data = null;
      state.promoPageEntity.isLoading = false;
      state.promoPageEntity.error = null;
    },
    createPromoPageError(state: TPromoPageStoreState, error: ApiErrorResponseData): void {
      state.promoPageEntity.error = error;
    },
    createPromoPage(state: TPromoPageStoreState, promoPage: TPromoPage): void {
      state.promoPageEntity.data = promoPage || null;
      state.promoPageEntity.isLoading = false;
    },
    deletePromoPageError(state: TPromoPageStoreState, error: ApiErrorResponseData): void {
      state.promoPageEntity.error = error;
    },

    deletePromoPageCodeRequestError(state: TPromoPageStoreState, error: ApiErrorResponseData): void {
      state.promoPageEntity.error = error;
    },
    promoPageListAllRequest(state: TPromoPageStoreState): void {
      state.promoPageListAllEntity.data = null;
      state.promoPageListAllEntity.isLoading = false;
      state.promoPageListAllEntity.error = null;
    },
    promoPageListAllError(state: TPromoPageStoreState, error: ApiErrorResponseData): void {
      state.promoPageListAllEntity.error = error;
    },
    promoPageListAll(state: TPromoPageStoreState, response: TPromoPage[]): void {
      state.promoPageListAllEntity.data = response || null;
      state.promoPageListAllEntity.isLoading = false;
    },
    setPromoPageCodeList(state: TPromoPageStoreState, response: TApiListResponse<TPromoPage>): void {
      state.listAll.List.push(...response.List);
      state.listAll.Offset += state.listAll.Limit;
      if (state.listAll.Total === 0) {
        state.listAll.Total = response.Total;
      }
    },
    SET_LOADING(state: TPromoPageStoreState, isLoading: boolean): void {
      state.isLoading = isLoading;
    },
    SET_VIEW_TYPE(state: TPromoPageStoreState, type: TPromoPageStoreState['viewType']): void {
      state.viewType = type;
    },
    SET_LIST_TYPE(state: TPromoPageStoreState, type: TPromoPageStoreState['listType']): void {
      state.listType = type;
    },
    SET_SEARCH(state: TPromoPageStoreState, params: TSetPromoPagesWithCodes): void {
      const { value, countryId } = params;

      state.listAll.List = [];
      state.listAll.Offset = 0;
      state.listAll.Total = 0;
      if (countryId) {
        state.countryId = countryId || null;
        state.search = '';
      } else {
        state.search = value ? value.trim() : '';
        state.countryId = null;
      }

    },
    SET_LIST_ALL(state: TPromoPageStoreState, response: TApiListResponse<TPromoPage>): void {
      state.listAll.List.push(...response.List);
      state.listAll.Offset += state.listAll.Limit;
      state.listAll.Total = response.Total;
    },
    SET_LIST_MY(state: TPromoPageStoreState, response: TApiListResponse<TPromoPage>): void {
      state.listMy.List.push(...response.List);
      state.listMy.Offset += state.listMy.Limit;
      if (state.listMy.Total === 0) {
        state.listMy.Total = response.Total;
      }
    },
    TOGGLE_FAVORITE(state: TPromoPageStoreState, external_id: string): void {
      const item = state.listType === 'all' ?
        state.listAll.List.find((e: TPromoPage) => {
          return e.external_id === external_id;
        }) :
        state.listMy.List.find((e: TPromoPage) => {
          return e.external_id === external_id;
        });
      item.is_favorite = !item.is_favorite;
    },
    SET_CODES(state: TPromoPageStoreState, response: TApiListResponse<TCodeList>): void {
      state.codes = response;
    },
    SET_LIST_ALL_WITH_CODES(state: TPromoPageStoreState): void {
      state.listAllCodes = [];
      state.listAll.List.forEach((promoPage: TPromoPage) => {
        const itemWithCodes = state.codes.List.find((code: TCodeList) => {
          return code.external_id === promoPage.external_id;
        });
        if (itemWithCodes) {
          // @ts-ignore // TODO: why the hell is it creating a property that does not exist in TPromoPage?
          // @ts-ignore // TODO: Нормально ж было написано а теперь непонятный код
          promoPage.codes = itemWithCodes.codes;
        }
        // @ts-ignore // TODO: resolve the above todo and this won't have to be ignored
        state.listAllCodes.push(promoPage);
      });
    },
    rawPromoPageData(state: TPromoPageStoreState, data: TCreatePromoPage): void {
      const code = {
        title: data.title,
        external_id: data.external_id,
        codes: [{ code: data.code }]
      };
      state.listAllCodes.unshift(code as never); // TODO: wtf never
    },
    deletePromoPage(state: TPromoPageStoreState, externalId: string): void {
      const index = state.listAllCodes.findIndex((item: any) => {
        return item.external_id === externalId;
      });
      state.listAllCodes.splice(index, 1);
    },
    SET_FILTER_IDS(state: TPromoPageStoreState, filterIds: number[]): void {
      state.filterIds = filterIds;
    },
    SET_PAVILION_ID(state: TPromoPageStoreState, pavilionId: null | number): void {
      state.pavilionId = pavilionId;
    },
    RESET_LIST_ALL(state: TPromoPageStoreState): void {
      state.listAll.List = [];
      state.listAll.Offset = 0;
    },
    RESET_LIST_MY(state: TPromoPageStoreState): void {
      state.listMy.List = [];
      state.listMy.Offset = 0;
    },
    setContactLoading(state: TPromoPageStoreState, value: boolean): void {
      state.contactEntity.isLoading = value;
    },
    setIsMediaBlockOverlayVisible(state: TPromoPageStoreState, value: boolean): void {
      state.isMediaBlockOverlayVisible = value;
    },
    setPromoPagesWithCodes(state: TPromoPageStoreState, params: TSetPromoPagesWithCodes): void {
      const { promoPage, sortingValue } = params;

      state.listAllCodes.find((it, idx) => {
        // @ts-ignore TODO: resolve this: does not exist on type never
        if (it.external_id === promoPage.external_id) {
          if (sortingValue > state.listAllCodes.length) {
            // @ts-ignore TODO: resolve this: does not exist on type never
            state.listAllCodes[idx].sorting = (state.listAllCodes.length - 1) + 1;
          } else {
            // @ts-ignore TODO: resolve this: does not exist on type never
            state.listAllCodes[idx].sorting = sortingValue;
          }
        }
      });

      state.listAllCodes = [...state.listAllCodes];

    },
    setRemoveCoworkerConfirmation: (state: TPromoPageStoreState, value: boolean): void => {
      state.isRemoveCoworkerConfirmed = value;
    },
    setRemoveCoworkerPopupVisible: (state: TPromoPageStoreState, value: boolean): void => {
      state.isRemoveCoworkerPopupVisible = value;
    },
    resetFilterIds: (state: TPromoPageStoreState): void => {
      state.filterIds = [];
    },
    clearExpoHallListAll: (state: TPromoPageStoreState): void => {
      state.listAll = {
        Limit: PROMOPAGES_PER_PAGE,
        List: [],
        Offset: 0,
        Total: 0
      };
    },
  },
};

export default promoPageStore;
