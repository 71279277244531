import { MeetingRoomType } from '@/_modules/meeting-rooms/types/meeting-room-type.enum';
import { ClientConfig } from 'trtc-js-sdk';
import { TTRTCClientConfigParams } from '@/_modules/meeting-rooms/types/trtc-client-config-params.type';
import TencentHelper from '@/_helpers/tencent.helper';
import { BroadcastType } from '@/_types/broadcasts/broadcast-type.enum';

export default class TRTCHelper {

  public static createClientConfig({ type, userId, mode, broadcastType, autoSubscribe }: TTRTCClientConfigParams): ClientConfig {
    switch (type) {
      case MeetingRoomType.MEETING: {
        const sdkAppId = Number(process.env.VUE_APP_TENCENT_MEETING_APP_ID);
        const secretKey = process.env.VUE_APP_TENCENT_MEETING_APP_SECRET_KEY;
        const expire = Number(process.env.VUE_APP_TENCENT_MEETING_APP_EXPIRE_TIME);
        const userSig = TencentHelper.generateUserSig(userId, sdkAppId, secretKey, expire);
        return { mode, sdkAppId, userId, userSig, autoSubscribe };
      }

      case MeetingRoomType.COMPANY: {
        const sdkAppId = Number(process.env.VUE_APP_TENCENT_COMPANY_APP_ID);
        const secretKey = process.env.VUE_APP_TENCENT_COMPANY_APP_SECRET_KEY;
        const expire = Number(process.env.VUE_APP_TENCENT_COMPANY_APP_EXPIRE_TIME);
        const userSig = TencentHelper.generateUserSig(userId, sdkAppId, secretKey, expire);
        return { mode, sdkAppId, userId, userSig, autoSubscribe };
      }

      case MeetingRoomType.BROADCAST: {
        if (broadcastType === BroadcastType.PROMO_CONTACT) {
          const sdkAppId = Number(process.env.VUE_APP_TENCENT_STREAMING_APP_ID);
          const secretKey = process.env.VUE_APP_TENCENT_STREAMING_APP_SECRET_KEY;
          const expire = Number(process.env.VUE_APP_TENCENT_STREAMING_APP_EXPIRE_TIME);
          const userSig = TencentHelper.generateUserSig(userId, sdkAppId, secretKey, expire);
          return { mode, sdkAppId, userId, userSig };
        } else if (broadcastType === BroadcastType.PROGRAM_SPEAKER) {
          const sdkAppId = Number(process.env.VUE_APP_TENCENT_PROGRAM_APP_ID);
          const secretKey = process.env.VUE_APP_TENCENT_PROGRAM_APP_SECRET_KEY;
          const expire = Number(process.env.VUE_APP_TENCENT_PROGRAM_APP_EXPIRE_TIME);
          const userSig = TencentHelper.generateUserSig(userId, sdkAppId, secretKey, expire);
          return { mode, sdkAppId, userId, userSig };
        } else {
          return null;
        }
      }

      default:
        return null;
    }
  }

}
