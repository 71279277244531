



import Vue from 'vue';

const LanguageViewer = Vue.extend({
  name: 'languageViewer',
});

export default LanguageViewer;
