import Vue from 'vue'
import Vuex from 'vuex'
import api from '../../api';

Vue.use(Vuex);

export default {
  strict: true,
  namespaced: true,
  state: {
    productImage: {},
    productsList: {},
    uploadedProductImage: {},
    productImageLoading: false,
    uploadProductsError: ''
  },
  getters: {
    getProductImageLoading(state) {
      return state.productImageLoading
    },
    uploadedProductImage(state) {
      return state.uploadedProductImage
    },
    productImage(state) {
      return state.productImage
    },
  },
  mutations: {
    setProductImage(state, data) {
      state.productImage = data;
    },
    setUploadedProductImage(state, data) {
      state.uploadedProductImage = data;
    },
    setProductImageLoading(state, data) {
      state.productImageLoading = data
    },
    setUploadProductsError(state, data) {
      state.uploadProductsError = data;
    }
  },
  actions: {
    setProductImage: async function ({commit}, formData) {
      commit('setProductImage', formData);
    },

    /* Asks the API to upload an image to the cloud
     */
    uploadProductImage: async function ({commit, rootState}, image) {
      let token = rootState.authStore.token;
      try {
        return await api.createEvent.uploadImages({token, image})
      } catch(e){
        commit('setUploadProductsError', e.data);
      }
    },
  }
}
