import { ActionContext, Module } from 'vuex';
import { TAppStoreState } from '@/_types/store/app-store-state.type';
import { TCalendarStoreState } from '@/_modules/ew-calendar/types/calendar-store-state.type';

const calendarStore: Module<TCalendarStoreState, TAppStoreState> = {
  namespaced: true,
  state: {
    markedDates: []
  },
  getters: {
    getMarkedDates: (state: TCalendarStoreState): string[] => {
      return state.markedDates;
    },
  },
  actions: {
    setMarkedDates: ({ commit }: ActionContext<TCalendarStoreState, TAppStoreState>, markedDates: string[]): void => {
      commit('setMarkedDates', markedDates);
    },
  },
  mutations: {
    setMarkedDates(state: TCalendarStoreState, markedDates: string[]): void {
      state.markedDates = markedDates;
    },
  },
};

export default calendarStore;
