export enum NotificationType {
  NONE = 'unknown-notification-type',
  CONTACT_MESSAGE = 'contact-message',
  CONTACT_MEETING_REQUEST = 'contact-meeting-request',
  CONTACT_MEETING_REMINDER = 'contact-meeting-reminder',
  NEW_COMPANY_BROADCAST_STARTED = 'new-company-broadcast-started',
  CONFERENCE_PROGRAM_STARTED = 'conference-program-started',
  MEETING_IS_CONFIRMED = 'meeting-is-confirmed',
  MEETING_IS_CANCELED = 'meeting-is-canceled',
}
