import {ActionContext, Module} from 'vuex';
import {TAppStoreState} from '@/_types/store/app-store-state.type';
import {
  TRequestStandaloneContactParams,
  TStandaloneContactStoreState
} from '@/_modules/standalone-contact/types/standalone-contact-store-state.type';
import {TContact} from '@/_types/contact.type';
import contactsApi from '@/_api/contacts/contacts.api';

const standaloneContactStore: Module<TStandaloneContactStoreState, TAppStoreState> = {
  namespaced: true,
  state: {
    standaloneContact: null,
  },
  getters: {
    contact: (state: TStandaloneContactStoreState): TContact => {
      return state.standaloneContact || null;
    },
  },
  actions: {
    requestStandaloneContact: async ({ commit }: ActionContext<TStandaloneContactStoreState, TAppStoreState>, params: TRequestStandaloneContactParams): Promise<void> => {
      const contact: TContact = await contactsApi.getStandaloneContact(params);
      commit('setStandaloneContact', contact || null);
    },
  },
  mutations: {
    setStandaloneContact(state: TStandaloneContactStoreState, payload: TContact): void {
      state.standaloneContact = payload || null;
    },
  },
};

export default standaloneContactStore;
