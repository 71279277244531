import BaseApi from '@/_types/api/base.api.class';
import {AxiosRequestConfig, CancelToken} from 'axios';
import { TNote } from '@/_types/store/note.type';
import { TApiListResponse } from '@/_types/api/api-list-response.type';
import NotesHelper from '@/_helpers/notes.helper';

export type TGetNotesQueryParams = {
  user_id?: number;
  external_id?: string;
  offset?: number;
  limit?: number;
}

export type TGetNotesParams = {
  eventId: number;
  queryParams?: TGetNotesQueryParams;
}

export type TNoteDataFields = {
  is_private: boolean;
  text: string;
  title: string;
}

export type TCreateOrEditNoteParams = {
  noteId?: number;
  eventId?: number;
  queryParams?: TNoteDataFields;
}

export class NotesApi extends BaseApi {

  public async getAllNotes(params: TGetNotesParams, cancelToken?: CancelToken): Promise<TApiListResponse<TNote>> {
    const { eventId, queryParams } = params;
    const config: AxiosRequestConfig = {
      url: `/event/${eventId}/note/list/all`,
      method: 'GET',
      cancelToken,
      params: queryParams,
    };

    let response;
    try {
      response = await this.axios.request<TApiListResponse<TNote>>(config);
    } catch (error) {
      throw this.responseError(error);
    }

    response.data.List = response.data.List.map(item => {
      return NotesHelper.responseToNoteItemConverter(item);
    });

    return response.data;
  }

  public async getMyNotes(params: TGetNotesParams, cancelToken?: CancelToken): Promise<TApiListResponse<TNote>> {
    const { eventId, queryParams } = params;
    const config: AxiosRequestConfig = {
      url: `/event/${eventId}/note/list`,
      method: 'GET',
      cancelToken,
      params: queryParams,
    };

    let response;
    try {
      response = await this.axios.request<TApiListResponse<TNote>>(config);
    } catch (error) {
      throw this.responseError(error);
    }

    response.data.List = response.data.List.map(item => {
      return NotesHelper.responseToNoteItemConverter(item);
    });

    return response.data;
  }

  public async addNote(params: TCreateOrEditNoteParams, cancelToken?: CancelToken): Promise<TNote> {

    const { eventId, queryParams } = params;
    const config: AxiosRequestConfig = {
      url: `/event/${eventId}/note`,
      method: 'POST',
      params: queryParams,
      cancelToken,
    };

    let response;
    try {
      response = await this.axios.request<TNote>(config);
    } catch (error) {
      throw this.responseError(error);
    }

    return NotesHelper.responseToNoteItemConverter(response.data);

  }

  public async editNote(params: TCreateOrEditNoteParams, cancelToken?: CancelToken): Promise<boolean> {
    const {noteId, queryParams} = params;
    const config: AxiosRequestConfig = {
      url: `/note/${noteId}`,
      method: 'PATCH',
      params: queryParams,
      cancelToken,
    };

    let response;
    try {
      response = await this.axios.request<any>(config);
    } catch (error) {
      throw this.responseError(error);
    }

    return response && response.status === 202;
  };

  public async removeNote(noteId: TGetNotesParams, cancelToken?: CancelToken): Promise<boolean> {
    const config: AxiosRequestConfig = {
      url: `/note/${noteId}`,
      method: 'DELETE',
      cancelToken,
    };


    let response;
    try {
      response = await this.axios.request<any>(config);
    } catch (error) {
      throw this.responseError(error);
    }

    return response && response.status === 202;
  }

  public async addFav(noteId: number, cancelToken?: CancelToken): Promise<boolean> {

    const config: AxiosRequestConfig = {
      url: `/note/fav/${noteId}`,
      method: 'PUT',
      cancelToken,
    };

    let response;
    try {
      response = await this.axios.request<number>(config);
    } catch (error) {
      throw this.responseError(error);
    }

    return response && response.status === 202;
  }
  public async removeFav(noteId: number, cancelToken?: CancelToken): Promise<boolean> {

    const config: AxiosRequestConfig = {
      url: `/note/fav/${noteId}`,
      method: 'DELETE',
      cancelToken,
    };

    let response;
    try {
      response = await this.axios.request<number>(config);
    } catch (error) {
      throw this.responseError(error);
    }

    return response && response.status === 202;
  }

}

const notesApi = new NotesApi();
export default notesApi;
