import { ActionContext, Module } from 'vuex';
import { TLivePageStoreState } from '@/_modules/promo/types/live-page-store-state.type';
import { TAppStoreState } from '@/_types/store/app-store-state.type';
import AxiosCancellableRequest from '@/_types/api/axios-cancellable-request.class';
import { TLivePage } from '@/_types/promo-page/live-page.type';
import promoPageApi, {
  TApiSponsors,
  TGetLivePageParams,
  TPatchLivePageParams
} from '@/_modules/promo/api/promo-page.api';
import uploadApi, { TUploadFileParams, TUploadFileResponse} from '@/_api/upload/upload.api';
import ApiErrorResponseData from '@/_types/api/api-error-response-data.class';

const getLivePageRequest = new AxiosCancellableRequest<TGetLivePageParams, TLivePage>(promoPageApi.getLivePage.bind(promoPageApi));

const cabinetLobbyStore: Module<TLivePageStoreState, TAppStoreState> = {
  namespaced: true,
  state: {
    eventId: null,
    unpinnedMedia: {
      intro: false,
      agenda: false,
    },
    livePageEntity: {
      isLoading: false,
      data: null,
      error: null,
    }
  },
  getters: {
    eventId: (state: TLivePageStoreState): number => {
      return state.eventId;
    },
    isLivePageLoading: (state: TLivePageStoreState): boolean => {
      return state.livePageEntity.isLoading;
    },
    livePageData: (state: TLivePageStoreState): TLivePage => {
      return state.livePageEntity.data;
    },
    isIntroMediaUnpinned: (state: TLivePageStoreState): boolean => {
      return state.unpinnedMedia.intro;
    },
    isAgendaMediaUnpinned: (state: TLivePageStoreState): boolean => {
      return state.unpinnedMedia.agenda;
    },
    livePageError: (state: TLivePageStoreState): ApiErrorResponseData => {
      return state.livePageEntity.error;
    },
  },
  actions: {

    reset: ({ commit }: ActionContext<TLivePageStoreState, TAppStoreState>): void => {
      commit('setEventId', null);
    },

    refresh: ({ commit, dispatch, state }: ActionContext<TLivePageStoreState, TAppStoreState>): void => {
      commit('refresh');
      dispatch('getLivePage', state.eventId);
    },

    getLivePage: async (context: ActionContext<TLivePageStoreState, TAppStoreState>, eventId: number): Promise<TLivePage> => {
      const { commit, state } = context;
      if (state.eventId !== eventId) {
        commit('setEventId', eventId);
      }

      if (state.livePageEntity.data) {
        return state.livePageEntity.data;
      }

      if (state.livePageEntity.isLoading) {
        try {
          return await getLivePageRequest.promise;
        } catch (error) {
          return null;
        }
      }

      commit('startLivePageRequest');
      let data;

      try {
        data = await getLivePageRequest.load({ eventId });
        commit('setLivePage', data);
        return data;
      } catch (error) {
        commit('livePageError', error.data);
        return null;
      } finally {
        commit('setLivePage', data);
      }
    },

    saveLivePage: async (context: ActionContext<TLivePageStoreState, TAppStoreState>, params: TPatchLivePageParams): Promise<number> => {
      const { commit} = context;
      const { eventId, livePageData } = params;

      let data;
      try {
        data = await promoPageApi.patchLivePage({eventId, livePageData});
        return data;
      } catch (error) {
        commit('livePageError', error.data);
        return null;
      }
    },

    saveSponsor: async (context: ActionContext<TLivePageStoreState, TAppStoreState>, params: TApiSponsors): Promise<number> => {
      const { eventId, sponsor } = params;
      return await promoPageApi.putSponsor({eventId, sponsor});
    },
    deleteSponsor: async (context: ActionContext<TLivePageStoreState, TAppStoreState>, params: TApiSponsors): Promise<number> => {
      const { eventId, sponsor } = params;
      return await promoPageApi.deleteSponsor({eventId, sponsor});
    },

    // TODO: move into more proper store module?
    uploadFile: async (context: ActionContext<TLivePageStoreState, TAppStoreState>, file: TUploadFileParams): Promise<TUploadFileResponse> => {
      return await uploadApi.uploadFile({...file});
    },

    toggleUnpinned: (context: ActionContext<TLivePageStoreState, TAppStoreState>, blockName: string): void => {
      const { commit } = context;
      commit('toggleUnpinned', blockName);
    },

    closeUnpinned: (context: ActionContext<TLivePageStoreState, TAppStoreState>, blockName: string): void => {
      const { commit } = context;
      commit('setPinned', blockName);
    },

    unpin: (context: ActionContext<TLivePageStoreState, TAppStoreState>, blockName: string): void => {
      const { commit } = context;
      commit('setUnpinned', blockName);
    },

  },
  mutations: {
    toggleUnpinned(state: TLivePageStoreState, blockName: string): void {
      state.unpinnedMedia[blockName] = !state.unpinnedMedia[blockName];
    },

    setUnpinned(state: TLivePageStoreState, blockName: string): void {
      state.unpinnedMedia[blockName] = true;
    },

    setPinned(state: TLivePageStoreState, blockName: string): void {
      state.unpinnedMedia[blockName] = false;
    },

    refresh(state: TLivePageStoreState): void {
      state.livePageEntity.isLoading = false;
      state.livePageEntity.data = null;
      state.livePageEntity.error = null;
    },

    setEventId(state: TLivePageStoreState, eventId: number): void {
      if (state.eventId === eventId) {
        return;
      }

      state.eventId = eventId || null;
      state.livePageEntity.data = null;
      state.livePageEntity.isLoading = false;
      state.livePageEntity.error = null;
    },

    startLivePageRequest(state: TLivePageStoreState): void {
      state.livePageEntity.data = null;
      state.livePageEntity.isLoading = true;
      state.livePageEntity.error = null;
    },

    setLivePage(state: TLivePageStoreState, livePageData: TLivePage): void {
      state.livePageEntity.data = livePageData || null;
      state.livePageEntity.isLoading = false;
    },

    livePageError(state: TLivePageStoreState, error: ApiErrorResponseData): void {
      state.livePageEntity.error = error;
    },
  },
};

export default cabinetLobbyStore;
