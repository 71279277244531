


import { Vue, Component, Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { Action, Getter } from 'vuex-class';
import DateTimeHelper from '@/_helpers/date-time.helper';
import {TCategory, TEvent} from '@/_types/event.type';
import { TContact } from '@/_types/contact.type';
import EventLanguageSelector from '@/_modules/events/components/event-language-selector/event-language-selector.vue';
import timezone from '@/_modules/events/components/timezones.json';
import { TTimezoneInfo } from '@/_types/timezone-info.type';
import { Validations } from 'vuelidate-property-decorators';
import { TVuelidateRuleSet } from '@/_types/vuelitation-rule-set.type';
import { required } from 'vuelidate/lib/validators';
import IconSearch from '@/_modules/icons/components/icon-search.vue';
import IconSquareDelete from '@/_modules/icons/components/icon-square-delete.vue';
import _cloneDeep from 'lodash.clonedeep';
import { TranslateResult } from 'vue-i18n';
import DatepickerHelper, { TMuseUIDatepickerDateTimeFormat } from '@/_helpers/datepicker.helper';
import { TApiListResponse } from '@/_types/api/api-list-response.type';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import Avatar from '@/_components/avatar/avatar.vue';
import _isEqual from 'lodash.isequal';
import eventApi from '@/_modules/events/api/event/event.api';
import EventHelper from '@/_helpers/event.helper';
import SimplePopup from '@/_modules/controls/components/simple-popup/simple-popup.vue';
import CabinetCreateUser from '@/_modules/promo-cabinet/components/cabinet-create-user/cabinet-create-user.vue';
import ApiErrorResponseData from '@/_types/api/api-error-response-data.class';
import ErrorInfo from '@/_modules/error-info/error-info.vue';
import CabinetContactInviterSettings
  from '@/_modules/promo-cabinet/components/cabinet-contact-inviter-settings/cabinet-contact-inviter-settings.vue';
import SideBarMenuManager from '@/_components/side-bar-menu-manager/side-bar-menu-manager.vue';

const CONTACT_SEARCH_LIMIT = 5;
const CONTACT_SEARCH_DEBOUNCE_TIME = 1000;

// TODO: move to side-bar-menu-manager.vue
export const MENU_PROPERTIES_KEY_NAME = 'eventMenuItemProperties';
export const MENU_CUSTOM_TITLE_KEY_NAME = 'customTitle';

const isValidEndDateNotInPast = (endDate: Date, date_start: Date): boolean => { // TODO: bad code, refactor! Move to helper after refactoring
  if (date_start && endDate) {
    return true;
  }

  if (date_start && endDate) {
    const _startDate: number = new Date(date_start).getTime() / 1000;
    const _dateEnd = new Date(endDate).getTime() / 1000;
    return _dateEnd > _startDate;
  } else {
    return true;
  }
};

type TFormData = {
  title?: string;
  date_start: Date;
  date_end: Date;
  event_type_id?: number;
  languages?: string[];
  time_region: string;
  categories: TCategory[];
}

@Component({
  components: {
    EventLanguageSelector,
    IconSearch,
    IconSquareDelete,
    Avatar,
    CabinetCreateUser,
    SimplePopup,
    ErrorInfo,
    CabinetContactInviterSettings,
    SideBarMenuManager,
  },
  computed: {
    ...mapState('eventStore', ['typeList']),
  }
})
export default class CabinetEventSettings extends Vue {

  @Action('categoriesStore/getCategories') getCategories: (forced?: boolean) => void;

  @Getter('_eventStore/event') event: TEvent;
  @Getter('promoPageStore/contact') myself: TContact;
  @Getter('_eventStore/eventError') eventError: ApiErrorResponseData;
  @Getter('_eventStore/eventSettingsError') eventSettingsError: ApiErrorResponseData;
  @Getter('categoriesStore/categories') categories: TCategory[];

  @Validations()
  public readonly validations: TVuelidateRuleSet<TFormData> = {
    formData: {
      title: {
        required,
      },
      date_start: {
        required,
      },
      date_end: {
        required,
        isValidEndDate(date_end: Date, formData: TFormData): boolean {
          return isValidEndDateNotInPast(date_end, formData.date_start);
        }
      },
      time_region: {
        required,
      }
    },
  };

  public languages: string[] = [];
  public formData: TFormData = {
    title: '',
    date_start: null,
    date_end: null,
    event_type_id: null,
    time_region: '',
    categories: [],
  };

  public endDateMinimum: Date = new Date();
  public isShowStartDate: boolean = false;
  public isShowEndDate: boolean = false;
  public isShowTimezoneLocation: boolean = false;
  public isShowEventType: boolean = false;
  public isCategoriesSelectOpen: boolean = false;
  public isSendSuccess: boolean = false;
  public updatedHintText: TranslateResult = '';
  public timezone: TTimezoneInfo[] = timezone;
  public timezoneLocation: TTimezoneInfo = {} as TTimezoneInfo;
  public allowEventSettingsReRendering: boolean = true;

  public destroyed$: Subject<void> = new Subject<void>();
  public contactsSearch$: Subject<void> = new Subject<void>();

  public get eventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  public get isEventOrganizer(): boolean {
    return EventHelper.isContactEventOrganizer(this.event, this.myself);
  }

  public get eventEditors(): TContact[] {
    if (!this.event || !this.event.editors) {
      return [];
    }

    return this.event.editors;
  }

  public get isEventEditor(): boolean {
    if (!this.myself) {
      return false;
    }
    return !this.isEventOrganizer && !!this.eventEditors.find((editor: TContact) => editor.id === this.myself.id);
  }

  @Watch('event', { immediate: true, deep: true })
  public onEventInfoChange(): void {
    if (!this.event) {
      return;
    }

    this.formData.title = this.event.title;
    this.formData.date_start = new Date(this.event.date_start);
    this.formData.date_end = new Date(this.event.date_end);
    this.formData.event_type_id = this.event.event_type.id;
    this.formData.time_region = this.event.time_region;
    this.formData.categories = (this.event.categories || []);
    this.languages = this.event.languages;
    this.formData.time_region = this.event.time_region;
    this.initEventEditorsArray();

    for (let i = 0; i < timezone.length; i++) {
      if (timezone[i].utc.indexOf(this.formData.time_region) > -1) {
        this.timezoneLocation = timezone[i];
      }
    }

  }

  @Watch('timezoneLocation', { immediate: true })
  public onTimezoneLocationChanged(): void {
    if (this.timezoneLocation && !this.timezoneLocation.utc) {
      return;
    }
    this.formData.time_region = this.timezoneLocation.utc[0];
  }

  public created(): void {
    this.updateEventTypes();
    this.getCategories();

    this.contactsSearch$.pipe(
      takeUntil(this.destroyed$),
      debounceTime(CONTACT_SEARCH_DEBOUNCE_TIME),
    ).subscribe(() => {
      this.updateFoundContacts();
    });

    this.subscribeToPageEvents();
  }

  public async updateEventTypes(): Promise<void> {
    await this.$store.dispatch('eventStore/eventType');
  }

  public autoSetEndDate(val: Date): void {

    const start: number = new Date(this.formData.date_start).getTime() / 1000;
    const end: number = new Date(this.formData.date_end).getTime() / 1000;

    // minimum end date is 1 hour ahead, used in the :min-date prop
    this.endDateMinimum = new Date(start + 60 * 60 * 1000);

    // val has to be present and be a JS Date object. Simple check using getTime
    if (val && ('getTime' in val)) {
      if (!this.formData.date_end || start > end) {
        const date_end = new Date(val);
        date_end.setHours(23, 59, 59);

        // If auto-set date_end time is closer than 1 hour, move it to next day 23:59
        if (date_end.getTime() - val.getTime() < (60 * 60 * 1000)) {
          date_end.setTime(date_end.getTime() + 1000 * 60 * 60 * 24);
        }
      }
    }
  }

  public checkEndDate(val: Date): void {
    if (this.formData.date_start === this.formData.date_end) {
      const startDate = new Date(this.formData.date_end);

      // Set hours to date_start.hours + 1
      if (val.getTime() - +startDate < 60 * 60 * 1000) {
        this.formData.date_end = new Date(startDate.getTime() + 60 * 60 * 1000);
      }
    }
  }

  public getCalendarDateTimeFormat(): TMuseUIDatepickerDateTimeFormat {
    return DatepickerHelper.getMuseUIDatepickerDateTimeFormat();
  }

  public firstDayOfWeek(): number {
    return DatepickerHelper.getFirstDayOfWeekNumber();
  }

  public async patchEvent(): Promise<void> {
    this.$v.formData.$touch();

    if (!this.formData || this.$v.formData.$pending || this.$v.formData.$invalid) {
      return;
    }

    await this.saveEventEditors();

    const categoryIds: number[] = (this.formData.categories || []).map(cat => cat.id);

    const payload = {
      eventId: this.eventId,
      formData: Object.assign({}, this.formData, {
        date_start: DateTimeHelper.dateToApiDate(this.formData.date_start),
        date_end: DateTimeHelper.dateToApiDate(this.formData.date_end),
        categories: categoryIds,
      }),
    };

    const result = await this.$store.dispatch('_eventStore/editEvent', payload);
    if (result) {
      this.$v.$reset();
      this.isSendSuccess = true;
      setTimeout(() => {
        this.updatedHintText = '';
      }, 3000);

      this.allowEventSettingsReRendering = false; // AW-1788 small UX bug #3 from comments
      await this.$store.dispatch('_eventStore/refresh');
      this.allowEventSettingsReRendering = true;
      this.updatedHintText = this.$t('eventPage.edit.updatedSuccess');
    } else {
      this.isSendSuccess = false;
      this.updatedHintText = this.$t('eventPage.edit.updatedError');
    }

    setTimeout(() => {
      this.updatedHintText = '';
    }, 3000);
  }

  public preview(): void {
    const routeData = this.$router.resolve({ name: 'event-info', params: { eventId: this.$route.params.eventId } });
    window.open(routeData.href, '_blank');
  }

  /* *********************************************** */

  public editorSearchString: string = '';
  public isEditorSearchResultsVisible: boolean = false;
  public isSearchingEditors: boolean = false;
  public isSearchedThroughAllEditors: boolean = false;
  public foundEditorContacts: TContact[] = [];
  public selectedEditors: TContact[] = [];
  public isAddUserPopupVisible: boolean = false;

  public initEventEditorsArray(): void {
    this.selectedEditors = [];
    if (this.eventEditors.length) {
      this.selectedEditors = _cloneDeep(this.eventEditors);
    }
    this.updateFoundContacts();
  }

  public openAddUserPopup(): void {
    this.isAddUserPopupVisible = true;
  }

  public closeAddUserPopup(): void {
    this.isAddUserPopupVisible = false;
  }

  public onEditorSearchInputClick(event: MouseEvent): void {
    event.stopPropagation();
    event.stopImmediatePropagation();
  }

  public onEditorSearchInputFocusIn(): void {
    this.isEditorSearchResultsVisible = true;
  }

  public onFoundEditorContactClick(contact: TContact): void {
    if (contact && !this.selectedEditors.find(editorContact => editorContact.id === contact.id)) {
      this.selectedEditors.push(_cloneDeep(contact));
      if (this.foundEditorContacts && this.foundEditorContacts.length === 1) { // AW-
        this.isEditorSearchResultsVisible = false;
      }
      this.updateFoundContacts();
    }
  }

  public isRemoveEditorButtonVisible(editor: TContact): boolean {

    if (!this.myself || !this.event) {
      return false;
    }

    const isMyself: boolean = editor.id === this.myself.id;

    return !isMyself && this.isEventOrganizer;
  }

  public onRemoveEditorClick(contact: TContact): void {
    if (!this.isRemoveEditorButtonVisible(contact)) {
      return;
    }
    this.selectedEditors = _cloneDeep(this.selectedEditors.filter(editor => editor.id !== contact.id));
  }

  public async updateFoundContacts(): Promise<void> {
    this.isSearchingEditors = true;
    const searchString = this.editorSearchString.trim();

    const contactsSearchResults: TApiListResponse<TContact> = await this.$store.dispatch('contactsStore/searchContacts', {
      eventId: this.eventId,
      limit: 100,
      offset: 0,
      search: searchString || undefined,
    });

    const foundContacts = (contactsSearchResults && contactsSearchResults.List) || [];
    const foundContactsExcludedEditors = foundContacts.filter(item => this.selectedEditors.map(editor => editor.id)
      .indexOf(item.id) < 0);
    if (foundContactsExcludedEditors.length >= CONTACT_SEARCH_LIMIT) {
      this.foundEditorContacts = foundContactsExcludedEditors.slice(0, CONTACT_SEARCH_LIMIT);
    } else {
      this.foundEditorContacts = [...foundContactsExcludedEditors];
    }

    this.isSearchedThroughAllEditors = foundContacts.length === ((contactsSearchResults && contactsSearchResults.Total) || -1);
    this.isSearchingEditors = false;
  }

  public subscribeToPageEvents(): void {
    fromEvent<MouseEvent>(document, 'click')
      .pipe(takeUntil(this.destroyed$))
      .subscribe(this.onDocumentClick);
  }

  public onDocumentClick(): void {
    this.isEditorSearchResultsVisible = false;
  }

  public getContactFullName(contact: TContact): string {
    if (!contact) {
      return '';
    }
    return contact.fullName || [contact.name, contact.surname].filter(x => x).join(' ');
  }

  @Watch('editorSearchString', { immediate: true })
  public onEditorSearchStringChange(): void {
    this.contactsSearch$.next();
  }

  public async saveEventEditors(): Promise<void> {
    const isSavingEditorsNeeded = !_isEqual(this.eventEditors, this.selectedEditors);
    if (!isSavingEditorsNeeded) {
      return;
    }

    const editorsToAdd: TContact[] = this.selectedEditors.filter(selectedEditor => {
      // those who are present in selectedEditors, but not in eventEditors
      return !this.eventEditors.find(oldEditor => oldEditor.id === selectedEditor.id);
    });

    const editorsToDelete: TContact[] = this.eventEditors.filter(oldEditor => {
      // those who are present in eventEditors, but not in selectedEditors
      return !this.selectedEditors.find(selectedEditor => oldEditor.id === selectedEditor.id);
    });

    const processedEditors: Promise<void>[] = [];

    editorsToAdd.forEach(editor => {
      processedEditors.push(eventApi.putEventEditor({ eventId: this.event.id, contactId: editor.id }));
    });

    editorsToDelete.forEach(editor => {
      processedEditors.push(eventApi.deleteEventEditor({ eventId: this.event.id, contactId: editor.id }));
    });

    await Promise.all(processedEditors);
  }

  /*************************/

  public getEventURL(): string {
    const pathnameParts = location.pathname.split('/');
    pathnameParts.splice(4);
    const path = pathnameParts.join('/');

    return location.origin + path;
  }

  public onCategorySelectChange(e: Event): void {
    this.isCategoriesSelectOpen = false;

    const categorySelect: HTMLSelectElement = (e.target || null) as HTMLSelectElement;
    if (!categorySelect) {
      return;
    }

    this.formData.categories = [];
    if (categorySelect.value === '0') {
      return;
    }

    const selectedCategory: TCategory = this.categories.find(cat => cat.id === parseInt(categorySelect.value, 10));
    this.formData.categories.push(selectedCategory);
  }

  public isCategoryOptionSelected(cat: TCategory): boolean {
    return !!(this.formData.categories || []).find(category => category.id === cat.id);
  }

  public get emptyCategoryHTML(): string {
    if (this.isCategoriesSelectOpen) {
      return '— ' + this.$t('eventSettings.withoutCategory') + ' —';
    }

    return '';
  }

}
