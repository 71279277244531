import BaseApi from '@/_types/api/base.api.class';
import { AxiosRequestConfig, AxiosResponse, CancelToken } from 'axios';
import { TEventStat } from '@/_modules/statistics/types/event-stat.type';
import { TPersonalStat } from '@/_modules/statistics/types/personal-stat.type';
import { TPromoPageStat } from '@/_modules/statistics/types/promo-page-stat.type';
import { TApiListResponse } from '@/_types/api/api-list-response.type';

const TIMEOUT_STATISTICS = 20000;
export const BASE_URL_STATISTICS = process.env.VUE_APP_API_BASE_URL_STATISTICS;

export type TPutStatParams = {
  eventId: number;
  actionName: string;
  data: any;
}

export type TGetStatParams = {
  eventId: number;
}

export type TGetStatPersonalParams = {
  eventId: number;
}

export type TGetStatPromopageParams = {
  eventId: number;
  externalId: string;
}

export type TExportContactsParams = {
  eventId: number;
  format: string;
}

export type TExportProgramContactsParams = {
  eventId: number;
  conferenceId: number;
  programId: number;
  format: string;
}

export type TExportPromopagesParams = {
  eventId: number;
  format: string;
}

export type TExportMessagesParams = {
  eventId: number;
  format: string;
}

export type TExportPollsParams = {
  eventId: number;
  format: string;
}

export type TExportOnlineCheckParams = {
  eventId: number;
  conferenceId: number;
  programId: number;
  format: string;
}

export type TExportMeetingsParams = {
  eventId: number;
  format: string;
}

export type TExportNotesParams = {
  eventId: number;
  format: string;
}

export type TGetStatProgramQuestionnaires = {
  eventId: number;
  programId: number;
}

export type TGetStatProgramQuestionnairesResponse = {
  title: string;
  id: number;
  participant_amount: number;
  question_list: TGetStatProgramQuestionnairesQuestion[];
}

export type TGetStatProgramQuestionnairesQuestion = {
  text: string;
  id: number;
  participant_amount: number;
  answer_option_list: TGetStatProgramQuestionnairesAnswerOption[];
}

export type TGetStatProgramQuestionnairesAnswerOption = {
  id: 0;
  text: string;
  participant_amount: number;
}

export type TExportResultCompanyParams = {
  eventId: number;
  externalId: number;
  format: string;
}

export type TExportResultEventCompanyParams = {
  eventId: number;
  format: string;
}

export class StatisticsApi extends BaseApi {

  public async putStat(params: TPutStatParams): Promise<void> {
    const { eventId, actionName, data } = params;
    const config: AxiosRequestConfig = {
      method: 'PUT',
      baseURL: BASE_URL_STATISTICS,
      url: `/event/${eventId}/stat`,
      params: {
        event_id: eventId,
        action_name: actionName,
        data
      },
    };

    try {
      await this.axios.request<void>(config);
    } catch (error) {
      throw this.responseError(error);
    }
  }

  public async getStat(params: TGetStatParams, cancelToken?: CancelToken): Promise<TEventStat> {
    const { eventId } = params;
    const config: AxiosRequestConfig = {
      method: 'GET',
      baseURL: BASE_URL_STATISTICS,
      url: `/event/${eventId}/stat`,
      timeout: TIMEOUT_STATISTICS,
      cancelToken,
    };

    let response;
    try {
      response = await this.axios.request <TEventStat>(config);
    } catch (error) {
      throw this.responseError(error);
    }
    return response.data;
  };

  public async getStatPersonal(params: TGetStatPersonalParams, cancelToken?: CancelToken): Promise<TPersonalStat> {
    const { eventId } = params;
    const config: AxiosRequestConfig = {
      method: 'GET',
      baseURL: BASE_URL_STATISTICS,
      url: `/event/${eventId}/stat/personal`,
      timeout: TIMEOUT_STATISTICS,
      cancelToken,
    };

    let response;
    try {
      response = await this.axios.request<TPersonalStat>(config);
    } catch (error) {
      throw this.responseError(error);
    }
    return response.data;
  };

  public async getStatPromopage(params: TGetStatPromopageParams, cancelToken?: CancelToken): Promise<TPromoPageStat> {
    const { eventId, externalId } = params;

    const config: AxiosRequestConfig = {
      method: 'GET',
      baseURL: BASE_URL_STATISTICS,
      url: `/event/${eventId}/stat/promopage/${externalId}`,
      timeout: TIMEOUT_STATISTICS,
      cancelToken
    };

    let response;
    try {
      response = await this.axios.request<TPromoPageStat>(config);
    } catch (error) {
      throw this.responseError(error);
    }
    return response.data;
  };

  public async getStatProgramQuestionnaires(params: TGetStatProgramQuestionnaires, cancelToken?: CancelToken): Promise<TGetStatProgramQuestionnairesResponse[]> {

    const { eventId, programId } = params;

    const config: AxiosRequestConfig = {
      method: 'GET',
      baseURL: BASE_URL_STATISTICS,
      url: `/event/${eventId}/program/${programId}/questionnaires_stat`,
      timeout: TIMEOUT_STATISTICS,
      cancelToken
    };

    let response;
    try {
      response = await this.axios.request<TApiListResponse<TGetStatProgramQuestionnairesResponse>>(config);
    } catch (error) {
      throw this.responseError(error);
    }

    return response.data.List;
  }

  public async exportContact(params: TExportContactsParams, cancelToken?: CancelToken): Promise<File> {
    const { eventId, format } = params;
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: `/event/${eventId}/export/contacts`,
      responseType: 'blob',
      params: {
        format,
      },
      cancelToken
    };

    let response;
    try {
      response = await this.axios.request<File>(config);
    } catch (error) {
      throw this.responseError(error);
    }
    return response.data;
  };

  public async exportEventContacts(params: TExportContactsParams, cancelToken?: CancelToken): Promise<File> {
    const { eventId, format } = params;
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: `/event/${eventId}/export/contacts/all`,
      responseType: 'blob',
      params: {
        format,
      },
      cancelToken
    };

    let response;
    try {
      response = await this.axios.request<File>(config);
    } catch (error) {
      throw this.responseError(error);
    }
    return response.data;
  };

  public async exportContactsByProgram(params: TExportProgramContactsParams, cancelToken?: CancelToken): Promise<File> {
    const { eventId, conferenceId, programId, format } = params;
    const config: AxiosRequestConfig = {
      method: 'GET',
      baseURL: BASE_URL_STATISTICS,
      url: `/event/${eventId}/conference/${conferenceId}/program/${programId}/export/contacts`,
      responseType: 'blob',
      params: {
        format,
      },
      cancelToken
    };

    let response;
    try {
      response = await this.axios.request<File>(config);
    } catch (error) {
      throw this.responseError(error);
    }
    return response.data;
  };

  public async exportPromopage(params: TExportPromopagesParams, cancelToken?: CancelToken): Promise<File> {
    const { eventId, format } = params;
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: `/event/${eventId}/export/promopages`,
      responseType: 'blob',
      params: {
        format,
      },
      cancelToken
    };

    let response: AxiosResponse<File>;
    try {
      response = await this.axios.request<File>(config);
    } catch (error) {
      throw this.responseError(error);
    }
    return response.data;
  };

  public async exportMessages(params: TExportMessagesParams, cancelToken?: CancelToken): Promise<File> {
    const { eventId, format } = params;
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: `/event/${eventId}/export/messages`,
      responseType: 'blob',
      params: {
        format,
      },
      cancelToken
    };

    let response;
    try {
      response = await this.axios.request<File>(config);
    } catch (error) {
      throw this.responseError(error);
    }
    return response.data;
  };

  public async exportMeetings(params: TExportMeetingsParams, cancelToken?: CancelToken): Promise<File> {
    const { eventId, format } = params;
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: `/event/${eventId}/export/meetings`,
      responseType: 'blob',
      params: {
        format,
      },
      cancelToken
    };

    let response;
    try {
      response = await this.axios.request<File>(config);
    } catch (error) {
      throw this.responseError(error);
    }
    return response.data;
  };

  public async exportManagedMeetings(params: TExportMeetingsParams, cancelToken?: CancelToken): Promise<File> {
    const { eventId, format } = params;
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: `/event/${eventId}/export/meetings/all`,
      responseType: 'blob',
      params: {
        format,
      },
      cancelToken
    };

    let response;
    try {
      response = await this.axios.request<File>(config);
    } catch (error) {
      throw this.responseError(error);
    }
    return response.data;
  };

  public async exportNotes(params: TExportNotesParams, cancelToken?: CancelToken): Promise<File> {
    const { eventId, format } = params;
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: `/event/${eventId}/export/notes`,
      responseType: 'blob',
      params: {
        format,
      },
      cancelToken
    };

    let response;
    try {
      response = await this.axios.request<File>(config);
    } catch (error) {
      throw this.responseError(error);
    }
    return response.data;
  };

  public async exportPolls(params: TExportPollsParams, cancelToken?: CancelToken): Promise<File> {
    const { eventId, format } = params;
    const config: AxiosRequestConfig = {
      method: 'GET',
      baseURL: BASE_URL_STATISTICS,
      url: `/event/${eventId}/questionnaire/stat/export`,
      responseType: 'blob',
      params: {
        format,
      },
      cancelToken
    };

    let response;
    try {
      response = await this.axios.request<File>(config);
    } catch (error) {
      throw this.responseError(error);
    }
    return response.data;
  };

  public async exportFileOnlineCheckRequest(params: TExportOnlineCheckParams, cancelToken?: CancelToken): Promise<File> {
    const { eventId, conferenceId, programId, format } = params;
    const config: AxiosRequestConfig = {
      method: 'GET',
      baseURL: BASE_URL_STATISTICS,
      url: `/event/${eventId}/conference/${conferenceId}/program/${programId}/export/online_check`,
      responseType: 'blob',
      params: {
        format,
      },
      cancelToken
    };

    let response;
    try {
      response = await this.axios.request<File>(config);
    } catch (error) {
      throw this.responseError(error);
    }
    return response.data;
  };

  public async exportResultCompany(params: TExportResultCompanyParams, cancelToken?: CancelToken): Promise<File> {
    const { eventId, externalId, format } = params;
    const config: AxiosRequestConfig = {
      method: 'GET',
      baseURL: BASE_URL_STATISTICS,
      url: `/event/${eventId}/stat/promopage/${externalId}/export`,
      responseType: 'blob',
      params: {
        format,
      },
      cancelToken
    };

    let response;
    try {
      response = await this.axios.request<File>(config);
    } catch (error) {
      throw this.responseError(error);
    }
    return response.data;
  };

  public async exportResultEventCompany(params: TExportResultEventCompanyParams, cancelToken?: CancelToken): Promise<File> {
    const { eventId, format } = params;
    const config: AxiosRequestConfig = {
      method: 'GET',
      baseURL: BASE_URL_STATISTICS,
      url: `/event/${eventId}/stat/export`,
      responseType: 'blob',
      params: {
        format,
      },
      cancelToken
    };

    let response;
    try {
      response = await this.axios.request<File>(config);
    } catch (error) {
      throw this.responseError(error);
    }
    return response.data;
  };

}

const statisticsApi = new StatisticsApi();

export default statisticsApi;
