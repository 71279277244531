import BaseApi from '@/_types/api/base.api.class';
import {AxiosRequestConfig} from 'axios';
import {TApiListResponse} from '@/_types/api/api-list-response.type';

export type TTicket = {
  id?: number;
  file_url: string;
  title?: string;
  date_start?: string;
  date_end?: string;
  data?: string;
}

export type TGetTicketsRequestParams = {
  eventId: number;
  limit?: number;
  offset?: number;
}

export type TGetSingleTicketRequestParams = {
  ticketId: number;
}

export type TDeleteTicketRequestParams = {
  ticketId: number;
}

export type TAddTicketRequestParams = {
  event_id: number;
  title?: string;
  file_url: string;
  date_start?: string;
  date_end?: string;
  data?: string;
}

export class TicketsApi extends BaseApi {

  public async getTickets(params: TGetTicketsRequestParams): Promise<TTicket[]> {
    const { eventId, limit, offset } = params;
    const config: AxiosRequestConfig = {
      url: '/event/' + eventId + '/ticket/list',
      method: 'GET',
      params: {
        limit,
        offset,
      }
    };

    let response;
    try {
      response = await this.axios.request<TApiListResponse<TTicket>>(config);
    } catch (error) {
      throw this.responseError(error);
    }

    return response && response.data && response.data.List;
  }

  public async deleteTicket(params: TDeleteTicketRequestParams): Promise<void> {
    const { ticketId } = params;
    const config: AxiosRequestConfig = {
      url: `/ticket/${ticketId}`,
      method: 'DELETE',
    };
    await this.axios.request(config);
  }

  public async getTicket(params: TGetSingleTicketRequestParams): Promise<TTicket> {
    const { ticketId } = params;
    const config: AxiosRequestConfig = {
      url: '/ticket/' + ticketId,
      method: 'GET',
    };

    let response;
    try {
      response = await this.axios.request<TTicket>(config);
    } catch (error) {
      throw this.responseError(error);
    }

    return response && response.data;
  }

  public async addTicket(params: TAddTicketRequestParams): Promise<TTicket> {
    const { event_id } = params;
    const preparedParams: any = {...params};
    delete preparedParams.event_id;
    const config: AxiosRequestConfig = {
      url: '/event/' + event_id + '/ticket',
      method: 'POST',
      params: preparedParams,
    };

    let response;
    try {
      response = await this.axios.request<TTicket>(config);
    } catch (error) {
      throw this.responseError(error);
    }

    return response && response.data;
  }

}

const ticketsApi = new TicketsApi();
export default ticketsApi;
