import {ActionContext, Module} from 'vuex';
import {TSeatPlanStoreState} from '@/_types/store/seat-plan-store-state.type';
import {TAppStoreState} from '@/_types/store/app-store-state.type';
import seatPlanApi from '@/_api/seatplan.api';

const seatPlanStore: Module<TSeatPlanStoreState, TAppStoreState> = {
  namespaced: true,
  state: {
    eventId: null,
    planJson: null,
    jsonError: '',
    isLoading: false,
  },
  getters: {
    eventId: (state: TSeatPlanStoreState): number => state.eventId,
    isPlanJson: (state: TSeatPlanStoreState): boolean => !!state.planJson,
    planJson: (state: TSeatPlanStoreState): any => state.planJson,
    isLoading: (state: TSeatPlanStoreState): boolean => state.isLoading,
  },
  actions: {

    getSeatPlanJson: async ({ commit, state }: ActionContext<TSeatPlanStoreState, TAppStoreState>, { eventId }: any): Promise<void> => {
      if (state.eventId === eventId) {
        return;
      }

      commit('setEventId', eventId);
      commit('setIsLoading', true);
      await seatPlanApi.getSeatPlanJson({ eventId })
        .then(resolve => {
          commit('setJson', resolve);
          commit('setIsLoading', false);
        }).catch(() => {
          // commit('jsonError', e.data);
          commit('setIsLoading', false);
        });
    },

    clearSeatPlanJson: async ({ commit }: ActionContext<TSeatPlanStoreState, TAppStoreState>): Promise<void> => {
      commit('clearJson');
    },

  },
  mutations: {

    setEventId(state: TSeatPlanStoreState, eventId: number): void {
      state.eventId = eventId;
      state.planJson = null;
      state.jsonError = null;
      state.isLoading = false;
    },

    setJson(state: TSeatPlanStoreState, data: any): void {
      if (!data) {
        state.planJson = null;
      } else {
        state.planJson = JSON.stringify(data);
      }
    },

    clearJson(state: TSeatPlanStoreState): void {
      state.planJson = '';
    },

    jsonError(state: TSeatPlanStoreState, data: any): void {
      state.jsonError = data;
    },

    setIsLoading(state: TSeatPlanStoreState, isLoading: boolean): void {
      state.isLoading = isLoading;
    },

  },
};

export default seatPlanStore;
