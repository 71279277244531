import router from '@/router';
import CryptoJS from 'crypto-js';
import { TMeetingInvite } from '@/_types/meeting/meeting-invite.type';
import { TMeeting } from '@/_types/meeting/meeting.type';
import DateTimeHelper from '@/_helpers/date-time.helper';
import ContactHelper from '@/_helpers/contact.helper';
import { MeetingRoomType } from '@/_modules/meeting-rooms/types/meeting-room-type.enum';

const MEETING_INVITE_CRYPTO_SALT = 'h7^%DLj&*$Jho28(';

export type TMeetingInviteParams = {
  type: MeetingRoomType;
  eventId: number;
  meetingId?: number;
  meetingDate?: number;
  moderatorContactId?: number;
  externalId?: string;
};

export default class MeetingsHelper {

  public static getMeetingInviteUrl(params: TMeetingInviteParams): string {
    const { type, eventId, meetingId, meetingDate, moderatorContactId, externalId } = params;
    if (!type || !eventId) {
      return null;
    }
    if (type !== MeetingRoomType.MEETING && type !== MeetingRoomType.COMPANY) {
      return null;
    }
    if (type === MeetingRoomType.MEETING && (!meetingId || !meetingDate)) {
      return null;
    }
    if (type === MeetingRoomType.COMPANY && (!moderatorContactId || !externalId)) {
      return null;
    }

    const meetingInvite: TMeetingInvite = {
      eventId,
      type,
      meetingId: meetingId || undefined,
      meetingDate: meetingDate || undefined,
      moderatorContactId: moderatorContactId || undefined,
      externalId: externalId || undefined,
    };

    const inviteKey = MeetingsHelper.generateMeetingInviteKey(meetingInvite);
    if (!inviteKey) {
      return null;
    }
    const routeName = type === MeetingRoomType.MEETING ? 'meeting-invite' : 'company-video-chat-invite';
    return location.origin + router.resolve({
      name: routeName,
      params: {
        eventId: '' + eventId,
        inviteKey: inviteKey,
      }
    }).href;
  }

  public static generateMeetingInviteKey(meetingInvite: TMeetingInvite): string {
    return btoa(CryptoJS.AES.encrypt(JSON.stringify(meetingInvite), MEETING_INVITE_CRYPTO_SALT).toString());
  }

  public static parseMeetingInviteKey(inviteKey: string): TMeetingInvite {
    let meetingInvite: TMeetingInvite = null;
    try {
      const bytes = CryptoJS.AES.decrypt(atob(inviteKey), MEETING_INVITE_CRYPTO_SALT);
      meetingInvite = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    } catch (error) {
      return null;
    }
    return meetingInvite;
  }

  public static responseToMeetingsConverter(responseObj: any): TMeeting {
    if(!responseObj) return null;
    return Object.assign({}, responseObj, {
      date_start: DateTimeHelper.apiDateToDate(responseObj.date_start),
      date_end: DateTimeHelper.apiDateToDate(responseObj.date_end),
      creator_contact: ContactHelper.responseToContactConverter(responseObj.creator_contact),
      user_contact: ContactHelper.responseToContactConverter(responseObj.user_contact),
      contact: ContactHelper.responseToContactConverter(responseObj.contact)
    });
  }

}
