import i18n from '@/i18n';

type TMuseUIDatepickerTexts = {
  firstDay: number;
  dayAbbreviation: [string, string, string, string, string, string, string];
  dayList: [string, string, string, string, string, string, string];
  monthList: [string, string, string, string, string, string, string, string, string, string, string, string];
  monthLongList: [string, string, string, string, string, string, string, string, string, string, string, string];
}

export type TMuseUIDatepickerDateTimeFormat = {
  formatDisplay: (date: Date) => string;
  formatMonth: (date: Date) => string;
  getWeekDayArray: () => string[];
  getMonthList: () => string[];
  formatDateDisplay: (date: Date) => string;
}

export default class DatepickerHelper {

  public static getMuseUIDatepickerDateTimeFormat(): TMuseUIDatepickerDateTimeFormat {

    const TEXTS: TMuseUIDatepickerTexts = this.getMuseUIDatepickerTexts();

    return {
      formatDateDisplay(date: Date): string {
        return `${date.getDate()} ${TEXTS.monthList[date.getMonth()]}`;
      },
      formatDisplay(date: Date): string {
        return `${TEXTS.dayList[date.getDay()]}, ${TEXTS.monthList[date.getMonth()]} ${date.getDate()}`;
      },
      formatMonth(date: Date): string {
        return `${TEXTS.monthLongList[date.getMonth()]} ${date.getFullYear()}`;
      },
      getMonthList(): string[] {
        return TEXTS.monthList;
      },
      getWeekDayArray(): string[] {
        const firstDayOfWeek = TEXTS.firstDay ? TEXTS.firstDay : 0; // firstDay or fallback to Sunday
        const beforeArray = [];
        const afterArray = [];
        for (let i = 0; i < TEXTS.dayAbbreviation.length; i++) {
          if (i < firstDayOfWeek) {
            afterArray.push(TEXTS.dayAbbreviation[i]);
          } else {
            beforeArray.push(TEXTS.dayAbbreviation[i]);
          }
        }
        return beforeArray.concat(afterArray);
      }
    };
  }

  public static getFirstDayOfWeekNumber(): number {
    const textData: TMuseUIDatepickerTexts = this.getMuseUIDatepickerTexts();
    return textData.firstDay;
  }

  /* mu-datepicker translation
   * https://muse-ui.org/#/en-US/date-input
   * N.B. formatDateDisplay method is NOT DOCUMENTED!
   */
  private static getMuseUIDatepickerTexts(): TMuseUIDatepickerTexts {

    // TODO: check against { APP_AVAILABLE_LANGUAGES } from '@/i18n' and use default locale if check failed

    return {
      firstDay: parseInt(i18n.tc('datepicker.firstDayNumber'), 10),
      dayAbbreviation: [
        i18n.tc('datepicker.dayAbbreviations.sunday'),
        i18n.tc('datepicker.dayAbbreviations.monday'),
        i18n.tc('datepicker.dayAbbreviations.tuesday'),
        i18n.tc('datepicker.dayAbbreviations.wednesday'),
        i18n.tc('datepicker.dayAbbreviations.thursday'),
        i18n.tc('datepicker.dayAbbreviations.friday'),
        i18n.tc('datepicker.dayAbbreviations.saturday'),
      ],
      dayList: [
        i18n.tc('datepicker.dayList.sunday'),
        i18n.tc('datepicker.dayList.monday'),
        i18n.tc('datepicker.dayList.tuesday'),
        i18n.tc('datepicker.dayList.wednesday'),
        i18n.tc('datepicker.dayList.thursday'),
        i18n.tc('datepicker.dayList.friday'),
        i18n.tc('datepicker.dayList.saturday'),
      ],
      monthList: [
        i18n.tc('datepicker.monthNamesShort.january'),
        i18n.tc('datepicker.monthNamesShort.february'),
        i18n.tc('datepicker.monthNamesShort.march'),
        i18n.tc('datepicker.monthNamesShort.april'),
        i18n.tc('datepicker.monthNamesShort.may'),
        i18n.tc('datepicker.monthNamesShort.june'),
        i18n.tc('datepicker.monthNamesShort.july'),
        i18n.tc('datepicker.monthNamesShort.august'),
        i18n.tc('datepicker.monthNamesShort.september'),
        i18n.tc('datepicker.monthNamesShort.october'),
        i18n.tc('datepicker.monthNamesShort.november'),
        i18n.tc('datepicker.monthNamesShort.december'),
      ],
      monthLongList: [
        i18n.tc('datepicker.monthNamesFull.january'),
        i18n.tc('datepicker.monthNamesFull.february'),
        i18n.tc('datepicker.monthNamesFull.march'),
        i18n.tc('datepicker.monthNamesFull.april'),
        i18n.tc('datepicker.monthNamesFull.may'),
        i18n.tc('datepicker.monthNamesFull.june'),
        i18n.tc('datepicker.monthNamesFull.july'),
        i18n.tc('datepicker.monthNamesFull.august'),
        i18n.tc('datepicker.monthNamesFull.september'),
        i18n.tc('datepicker.monthNamesFull.october'),
        i18n.tc('datepicker.monthNamesFull.november'),
        i18n.tc('datepicker.monthNamesFull.december'),
      ],
    };

  }

}
