


























































import { Vue, Component, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { TUser } from '@/_types/user.type';
import { TEvent } from '@/_types/event.type';
import { TContact } from '@/_types/contact.type';
import Navigation from '@/_components/navigation/navigation.vue';
import { TPromoPage } from '@/_types/promo-page/promo-page.type';
import CookieConsentBox from '@/_components/cookie-consent-box/cookie-consent-box.vue';
import MobileAppHint from '@/_components/mobile-app-hint/mobile-app-hint.vue';
import BrandingHeader from '@/_modules/standalone-company/components/branding-header/branding-header.vue';
import notificationsService from '@/_modules/promo/services/notifications.service';
import PersonalCodeAcceptor from '@/_modules/events/components/personal-code-acceptor/personal-code-acceptor.vue';
import TrackUseridUserStatus from '@/_modules/gtm-tracking/track-userid-userstatus/track-userid-userstatus.vue';
import { TUserAuthData } from '@/_api/login/login.api';
import statisticsApi from '@/_modules/statistics/api/statistics.api';
import contactsApi from '@/_api/contacts/contacts.api';
import HelpCrunchIcon from '@/_components/helpcrunch-icon/helpcrunch-icon.vue';
import EwSharer from '@/_components/ew-sharer/ew-sharer.vue';
import {TEventSettings} from '@/_types/event-settings.type';
import EwPopUp from '@/_modules/standalone-company/components/ew-pop-up/ew-pop-up.vue';
import AuthOverlay from '@/_components/auth/auth-overlay/auth-overlay.vue';
import ContactInfoInviter from '@/_modules/promo/components/contact-info-inviter/contact-info-inviter.vue';

@Component({
  components: {
    EwPopUp,
    BrandingHeader,
    Navigation,
    CookieConsentBox,
    MobileAppHint,
    PersonalCodeAcceptor,
    TrackUseridUserStatus,
    HelpCrunchIcon,
    EwSharer,
    AuthOverlay,
    ContactInfoInviter,
  },
})
export default class App extends Vue {

  @Getter('authStore/authToken') readonly userToken: string;
  @Getter('authStore/userData') readonly userData: TUserAuthData;
  @Getter('authStore/deviceId') readonly deviceId: string;
  @Getter('authStore/authStatus') readonly authStatus: string;
  @Getter('authStore/isAuthenticated') readonly isAuthenticated: boolean;
  @Getter('authStore/isLoading') public readonly isAuthLoading: boolean;
  @Getter('authStore/isAuthPopupVisible') public readonly isAuthPopupVisible: boolean;
  @Getter('authStore/isContactInfoInviterVisible') public readonly isContactInfoInviterVisible: boolean;
  @Getter('authStore/isContactInfoInviterCheckingOnBackground') public readonly isContactInfoInviterCheckingOnBackground: boolean;
  @Getter('_userStore/user') public readonly user: TUser;
  @Getter('_eventStore/event') public readonly event: TEvent;
  @Getter('_eventStore/eventSettings') public readonly eventSettings: TEventSettings;
  @Getter('_eventStore/isEventSettingsPolled') public readonly isEventSettingsPolled: boolean;
  @Getter('promoPageStore/contact') public readonly contact: TContact;
  @Getter('promoPageStore/promoPage') public readonly promoPage: TPromoPage;
  @Getter('sideBarLeftStore/isOpen') public readonly isSideBarLeftOpen: boolean;
  @Getter('sideBarRightStore/isOpen') public readonly isSideBarRightOpen: boolean;
  @Getter('sideBarRightStore/isSideBarRightComponentPresent') public readonly isSideBarRightComponentPresent: boolean;

  public isTranslationsEmpty: boolean = localStorage.getItem('isTranslationsEmpty') === 'true';

  public get isHelpCrunchIconEnabled(): boolean {
    return (this.isEventSettingsPolled && this.eventSettings && this.eventSettings.layout && this.eventSettings.layout.is_support_chat_enabled) || false;
  }

  public mounted(): void {
    window.addEventListener('isTranslationsEmpty-localstorage-changed', (event) => {
      this.isTranslationsEmpty = (event as CustomEvent).detail.storage === 'true';
    });
  }

  public async created(): Promise<void> {
    this.setDocumentLangAttribute();
    Vue.moment.locale(this.$i18n.locale);
  }

  public get eventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  @Watch('authStatus')
  private async onAuthStatusChange(newVal: string): Promise<void> {
    if (newVal !== 'success' || !this.userToken || !this.userData || !this.eventId) {
      return;
    }

    let contact: TContact = await contactsApi.callCurrentUserContact({ eventId: this.eventId });

    const data: any = {
      middle_name: '',
      first_name: contact ? (contact.name || '') : '',
      last_name: contact ? (contact.surname || '') : '',
      email: contact ? (contact.email || '') : '',
      id: contact ? (contact.id || '') : '',
      phone: this.userData.phone,
      token: {
        token: this.userToken,
        type: 'WEB',
        device_id: this.deviceId,
        user_id: this.userData.id
      },
    };

    await statisticsApi.putStat({ eventId: this.eventId, actionName: 'new_login', data });

  }

  public get isEventHeadPanelVisible(): boolean {
    if (this.$route.name === 'standalone-company') {
      return false;
    }

    return !!this.$route.matched.find(route => route.path.indexOf('/:lang/events/:eventId') >= 0);
  }

  public get isNavigationVisible(): boolean {

    if (this.isEventHeadPanelVisible) {
      return false;
    }

    if (
      this.$route.meta
      && typeof this.$route.meta.showNavigation !== 'undefined'
      && !this.$route.meta.showNavigation
    ) {
      return false;
    }

    return (
      this.$route.name
      // TODO: we already have meta.showNavigation - change route config instead
      && this.$route.name.indexOf('promo') < 0
    );
  }

  public get isPersonalCodeAcceptorOn(): boolean {
    return !!(this.$route.query && this.$route.query.code);
  }

  public get isUserIdUserStatusTrackerOn(): boolean {
    return this.user && this.user.id && this.isAuthenticated;
  }

  /* Do not remove - responsible for store(s) refresh */
  @Watch('eventId', { immediate: true })
  private _onEventIdChange(): void {
    this.refreshEventStore();
    this.refreshPromoPageStore();
    this.configureNotificationsService();
  }

  @Watch('contact', { immediate: true })
  private onContactChange(): void {
    this.configureNotificationsService();
    this.refreshNotificationsStore();
  }

  /* Do not remove - responsible for store(s) refresh */
  @Watch('isAuthenticated', { immediate: true })
  private onIsAuthenticatedChange(): void {
    this.refreshUserStore();
    this.refreshPromoPageStore();
  }

  @Watch('isSideBarLeftOpen', { immediate: true })
  private onIsSideBarLeftOpen(newVal: boolean): void {
    const doc: HTMLDocument = document;
    if (!document || !document.documentElement) {
      return;
    }
    const docEl: HTMLElement = doc.documentElement;
    const className = 'root-side-bar-left-open';
    if (newVal) {
      docEl.classList.add(className);
      return;
    }
    docEl.classList.remove(className);
  }

  @Watch('isSideBarRightOpen', { immediate: true })
  private onIsSideBarRightOpen(newVal: boolean): void {
    const doc: HTMLDocument = document;
    if (!document || !document.documentElement) {
      return;
    }
    const docEl: HTMLElement = doc.documentElement;
    const className = 'root-side-bar-right-open';
    if (newVal) {
      docEl.classList.add(className);
      return;
    }
    docEl.classList.remove(className);
  }

  @Watch('isSideBarRightComponentPresent', { immediate: true })
  private onIsSideBarRightComponentPresentChange(newVal: boolean): void {
    const doc: HTMLDocument = document;
    if (!document || !document.documentElement) {
      return;
    }
    const docEl: HTMLElement = doc.documentElement;
    if (newVal) {
      docEl.classList.add('root-side-bar-right-present');
      docEl.classList.remove('root-side-bar-right-absent');
      return;
    }
    docEl.classList.add('root-side-bar-right-absent');
    docEl.classList.remove('root-side-bar-right-present');
  }

  private setDocumentLangAttribute(): void {
    document.documentElement.lang = this.$i18n.locale;
  }

  private configureNotificationsService(): void {
    notificationsService.configure({
      eventId: this.eventId,
      contactId: (this.contact && this.contact.id) || null,
      userId: (this.user && this.user.id) || null,
    });
  }

  private refreshUserStore(): void {
    if (this.isAuthenticated) {
      this.$store.dispatch('_userStore/getUser');
    } else {
      this.$store.dispatch('_userStore/reset');
    }
  }

  private refreshEventStore(): void {
    if (this.eventId) {
      this.$store.dispatch('_eventStore/getEvent', this.eventId);
      // TODO: remove old store request and the whole old eventStore
      window.setTimeout(() => {
        this.$store.dispatch('eventStore/event', this.$route.params.eventId);
      }, 1000);
    } else {
      this.$store.dispatch('_eventStore/reset');
    }
  }

  private refreshPromoPageStore(): void {
    if (this.isAuthenticated && this.eventId) {
      this.$store.dispatch('promoPageStore/getContact', { eventId: this.eventId });
      this.$store.dispatch('promoPageStore/getContactPromoPage', this.eventId);
    } else {
      this.$store.dispatch('promoPageStore/reset');
    }
  }

  private refreshNotificationsStore(): void {
    if (!this.eventId) {
      return;
    }
    this.$store.dispatch('notificationsStore/getNotificationList', this.eventId);
  }

}
