export type TCreateCookieParams = {
  name: string;
  value: string | number;
  maxAge?: number;
};

export default class CookiesHelper {

  /* Creates a cookie with provided properties
   */
  public static createCookie(params: TCreateCookieParams): void {

    const { name, value, maxAge } = params;

    let prefixes: string[] = [ '' ];
    let domains: string[] = [
      'eventswallet.com',
      'eventswallet.fr',
      'eventswallet.cn',
    ];
    if (
      domains.indexOf(window.location.hostname) < 0
      && !domains.find(domain => window.location.hostname.indexOf('.' + domain) > -1)
    ) {
      domains.push(window.location.hostname);
    }

    // TODO: removeme after 2021-05-26
    if (maxAge < 1) {
      /* going to remove cookie */
      prefixes = [ ...prefixes, 'dev-', 'local-' ];
      domains = [ ...domains, 'local.eventswallet.com', 'dev-web.eventswallet.com' ];
      if (
        domains.indexOf(window.location.hostname) < 0
        && domains.find(domain => window.location.hostname.indexOf('.' + domain) > -1)
      ) {
        domains = [ ...domains, window.location.hostname ];
      }
    }

    prefixes.forEach(prefix => {
      domains.forEach(domain => {
        const parts: string[] = [];

        parts.push(prefix + name + '=' + value);
        parts.push('domain=' + domain);
        parts.push('path=/');
        parts.push('secure');
        parts.push('samesite=strict');

        if (maxAge) {
          parts.push('max-age=' + maxAge);
        }

        document.cookie = parts.join(';');
      });
    });
  }

  /* Reads a cookie by a given name
   */
  public static readCookie(name: string): string {
    const nameEQ: string = name + '=';
    const ca: string[] = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c: string = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length, c.length);
      }
    }
    return '';
  }

  /* Removes a cookie with the given name
   * by setting its expires property to past date
   */
  public static deleteCookie(name: string): void {
    this.createCookie({ name, value: '', maxAge: -1 });
  }

}
