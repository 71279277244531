import Vue from 'vue';
import Vuex from 'vuex';

// TODO: refactoring
// @ts-ignore
import VueLocalStorage from 'vue-localstorage';
import MuseUI from 'muse-ui';
import 'muse-ui/dist/muse-ui.css';
import Vuelidate from 'vuelidate';
import VueMoment from 'vue-moment';
import moment from 'moment';
// TODO: refactoring
// @ts-ignore
import vuescroll from 'vue-scroll';
import Vue2PerfectScrollbar from 'vue2-perfect-scrollbar';
import PerfectScrollbar from 'perfect-scrollbar';
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';
// TODO: refactoring
// @ts-ignore
import LoadScript from 'vue-plugin-load-script';
import VTooltip from 'v-tooltip';
import EwCircularLoading from '@/_ui/ew-circular-loading/ew-circular-loading.vue';
import EditEntityButton from '@/_modules/promo/components/edit-entity-button/edit-entity-button.vue';

import 'video.js/dist/video-js.min.css';

Vue.use(Vuex);
Vue.use(VueLocalStorage, { bind: true });
declare module 'vue/types/vue' {
  // eslint-disable-next-line @typescript-eslint/interface-name-prefix
  interface VueConstructor {
    // TODO: refactoring
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    localStorage: any;
  }
}
Vue.use(MuseUI);
Vue.use(Vuelidate);
Vue.use(VueMoment, { moment });
declare module 'vue/types/vue' {
  // eslint-disable-next-line @typescript-eslint/interface-name-prefix
  interface VueConstructor {
    moment: moment.Moment;
  }
}
Vue.use(vuescroll);
Vue.use(Vue2PerfectScrollbar);
declare module 'vue/types/vue' {
  // eslint-disable-next-line @typescript-eslint/interface-name-prefix
  interface Vue {
    ps?: PerfectScrollbar;
  }
}
Vue.use(LoadScript);
Vue.use(VTooltip, {
  defaultPlacement: 'top',
  defaultOffset: 10,
  defaultDelay: {
    show: 500,
    hide: 300,
  },
});
Vue.component('ew-circular-loading', EwCircularLoading);
Vue.component('edit-entity-button', EditEntityButton);
