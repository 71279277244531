


import { mapGetters } from 'vuex';
import { Vue, Component, Watch } from 'vue-property-decorator';
import { TEvent } from '@/_types/event.type';
import { TContact } from '@/_types/contact.type';
import { TRegisterUserParams } from '@/_modules/events/api/event/event.api';
import IconSquareEdit from '@/_modules/icons/components/icon-square-edit.vue';
import IconSquareDelete from '@/_modules/icons/components/icon-square-delete.vue';
import IconPlus from '@/_modules/icons/components/icon-plus.vue';
import FormFileUploader from '@/_modules/controls/components/form-file-uploader/form-file-uploader.vue';
import { TFile } from '@/_types/file.type';
import ErrorInfo from '@/_modules/error-info/error-info.vue';
import ApiErrorResponseData from '@/_types/api/api-error-response-data.class';
@Component({
  components: {
    FormFileUploader,
    IconSquareEdit,
    IconSquareDelete,
    IconPlus,
    ErrorInfo
  },
  computed: {
    ...mapGetters({
      myself: 'promoPageStore/contact',
      event: '_eventStore/event',
    }),
  }
})
export default class CabinetCreateUser extends Vue {

  public first_name: string = '';
  public last_name: string = '';
  public email: string = '';
  public company_name: string = '';
  public company_position: string = '';
  public isFormSending: boolean = false;

  public emailErrorText: string = '';
  public firstNameErrorText: string = '';
  public lastNameErrorText: string = '';

  public formStatusSuccess: boolean = false;
  public formStatusError: boolean = false;
  public formStatusText: string = '';
  public photo: TFile = null;
  public photoUrl: string = '';
  public photoError: string = '';
  public isPhotoLoading: boolean = false;
  public createUserError: ApiErrorResponseData = null

  public readonly myself: TContact;
  public readonly event: TEvent;

  public get eventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  public get isSendButtonDisabled(): boolean {
    return this.isFormSending;
  }

  @Watch('photo')
  private onImageChange(): void {
    this.photoUrl = (this.photo && this.photo.url);
    this.isPhotoLoading = false;
    this.photoError = '';
  }

  private async sendForm(): Promise<void> {
    if (this.isFormSending) {
      return;
    }

    this.clearAllErrors();
    this.clearFormResult();

    if (!this.first_name.trim()) {
      this.firstNameErrorText = this.$t('createUserPopup.fields.errors.name') as string;
    }

    if (!this.last_name.trim()) {
      this.lastNameErrorText = this.$t('createUserPopup.fields.errors.lastName') as string;
    }

    if (!this.isFormValid()) {
      return;
    }

    this.isFormSending = true;

    const payload: TRegisterUserParams = {
      event_id: this.eventId,
      event_access: true,
      email: this.email.trim(),
      first_name: this.first_name.trim(),
      last_name: this.last_name.trim(),
      company_name: this.company_name.trim(),
      company_position: this.company_position.trim(),
      photo_url: this.photoUrl
    };

    const result: any = await this.$store.dispatch('_eventStore/registerUser', payload);
    this.displayFormResult(result);

    this.isFormSending = false;
  }

  private isFormValid(): boolean {
    return this.isEmailValid() && !!this.first_name.trim() && !!this.last_name.trim();
  }

  private isEmailValid(): boolean {
    let isValid = true;
    const value: string = this.email.trim();
    if (
      ((value.match(/@/g) || []).length > 1)
      || value === '@'
      || value === ''
      || (value.indexOf('..') >= 0)
      || /(^\.)|(^@)|(\.@)|(@\.)|(@-)|(\.$)|(@$)|(-$)/.test(value)
      || /\..{2,}$/.test(value) === false
      || /\..{2,}$/.test(value) === false
      || /\.\d+$/.test(value)
    ) {
      isValid = false;
    }

    if (!isValid) {
      this.emailErrorText = this.$t('createUserPopup.fields.errors.email') as string;
    } else {
      this.clearEmailError();
    }

    return isValid;

  }

  private clearEmailError(): void {
    this.emailErrorText = '';
  }

  private clearFirstNameError(): void {
    this.firstNameErrorText = '';
  }

  private clearLastNameError(): void {
    this.lastNameErrorText = '';
  }

  private clearAllErrors(): void {
    this.clearEmailError();
    this.clearFirstNameError();
    this.clearLastNameError();
    this.createUserError = null;
  }

  private displayFormResult(result: any): void {
    if (result && result.status === 200) {
      this.formStatusSuccess = true;
      this.formStatusText = this.$t('createUserPopup.formSuccessUserCreated') as string;
      if (result.data && result.data.Message === 'User exist. Access granted') {
        this.formStatusText = this.$t('createUserPopup.formSuccessContactAdded') as string;
      }
      this.clearFormData();
      this.$emit('success');
    } else {
      this.formStatusError = true;
      if (result && result.data && result.data.error) {
        this.formStatusText = this.getProcessedError(result.data.error);
        this.createUserError = result.data;
      } else if (result) {
        this.formStatusText = JSON.stringify(result);
      } else {
        this.formStatusText = this.$t('createUserPopup.formGeneralError') as string;
      }
    }
  }

  private clearFormData(): void {
    this.first_name = '';
    this.last_name = '';
    this.email = '';
    this.company_name = '';
    this.company_position = '';
    this.photoUrl = '';
    this.photo = null;
  }

  private getProcessedError(error: string): string {
    let result: string = error;
    if (error.indexOf('could not send email') >= 0) {
      result = this.$t('createUserPopup.formEmailError') as string;
    }
    return result;
  }

  private clearFormResult(): void {
    this.formStatusError = false;
    this.formStatusSuccess = false;
    this.formStatusText = '';
  }

  private deletePhoto(): void {
    this.photoUrl = '';
    this.photo = null;
  }

  private setPhotoError(err: string): void {
    this.photoError = err || '';
    setTimeout(() => {
      this.photoError = '';
      this.isPhotoLoading = false;
    }, 3000);
  }

  private setIsPhotoImageLoading(value: TFile): void {
    if (value && value.url === '') {
      this.isPhotoLoading = true;
      return;
    } else if (value.url) {
      this.isPhotoLoading = false;
      this.photoUrl = value.url;
    }
    this.isPhotoLoading = false;
  }
}
