import {ActionContext, Module} from 'vuex';
import {TAppStoreState} from '@/_types/store/app-store-state.type';
import {
  TSimpleTimeSlot,
  TStandaloneCompanyStoreState
} from '@/_modules/standalone-company/types/standalone-company-store-state.type';

const standaloneCompanyStore: Module<TStandaloneCompanyStoreState, TAppStoreState> = {
  namespaced: true,
  state: {
    timeSlotSelection: [],
  },
  getters: {

    getTimeSlotSelection: (state: TStandaloneCompanyStoreState): TSimpleTimeSlot[] => {
      return state.timeSlotSelection;
    },

  },
  actions: {

    setTimeSlotSelection: ({ commit }: ActionContext<TStandaloneCompanyStoreState, TAppStoreState>, params: TSimpleTimeSlot[]): void => {
      commit('setTimeSlotSelection', params);
    },

    clearTimeSlotSelection: ({ commit }: ActionContext<TStandaloneCompanyStoreState, TAppStoreState>): void => {
      commit('setTimeSlotSelection', []);
    },
  },
  mutations: {

    setTimeSlotSelection(state: TStandaloneCompanyStoreState, payload: TSimpleTimeSlot[]): void {
      state.timeSlotSelection = payload;
    },
  },
};

export default standaloneCompanyStore;
