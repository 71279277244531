


import Component from 'vue-class-component';
import { Vue } from 'vue-property-decorator';

const DEFAULT_AVATAR = require('@/assets/images/no-avatar-60x60.svg');
const DEFAULT_AVATAR_COMPANY = require('@/assets/images/no-avatar-company-60x60.svg');
const DEFAULT_AVATAR_EVENT = require('@/assets/images/no-avatar-event-60x60.svg');

@Component({
  name: 'avatar',
  props: {
    src: {
      type: String,
      default: '',
    },
    width: {
      type: Number,
      default: 48,
    },
    mode: {
      type: String,
      default: ''
    },
    entityName: {
      type: String,
      default: 'person' // person, company, event
    },
    borderRadiusMode: {
      type: String,
      default: '' // br10
    },
    initials: {
      type: String,
      default: ''
    },
  },
})
export default class Avatar extends Vue {

  public src: string;
  public width: number;
  public mode: string;
  public initials: string;
  public entityName: string;

  public get computedSrc(): string {
    // return this.src || (this.entityName === 'company' ? DEFAULT_AVATAR_COMPANY : DEFAULT_AVATAR);
    if(this.src) {
      return this.src;
    } else if (this.entityName === 'company') {
      return DEFAULT_AVATAR_COMPANY;
    } else if (this.entityName === 'event') {
      return DEFAULT_AVATAR_EVENT;
    } else {
      return DEFAULT_AVATAR;
    }
  }

  public get style(): string {
    return `width: ${this.width}px; height: ${this.width}px;`;
  }

}
