import { BinaryLike } from 'crypto';

export default class TencentHelper {

  public static generateUserSig(userId: string, sdkAppId: number, secretKey: string, expire: number): string {
    // ToDo: this should be made by server
    // ToDo: secretKey should never appear in client code

    // TODO: refactoring
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const crypto = require('crypto');
    // TODO: refactoring
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const zlib = require('zlib');
    const newBuffer = (fill: string, encoding?: BufferEncoding): Buffer => {
      // TODO: refactoring
      // eslint-disable-next-line node/no-deprecated-api
      return Buffer.from ? Buffer.from(fill, encoding) : new Buffer(fill, encoding);
    };
    const base64url: {
      unescape: (str: string) => string;
      escape: (str: string) => string;
      encode: (str: string) => string;
      decode: (str: string) => string;
    } = {
      unescape: (str: string): string => {
        return (str + Array(5 - str.length % 4))
          .replace(/_/g, '=')
          .replace(/-/g, '/')
          .replace(/\*/g, '+');
      },
      escape: (str: string): string => {
        return str.replace(/\+/g, '*')
          .replace(/\//g, '-')
          .replace(/=/g, '_');
      },
      encode: (str: string): string => {
        return base64url.escape(newBuffer(str).toString('base64'));
      },
      decode: (str: string): string => {
        return newBuffer(base64url.unescape(str), 'base64').toString();
      },
    };
    // function base64encode(str) {
    //   return newBuffer(str).toString('base64')
    // }
    // function base64decode(str) {
    //   return newBuffer(str, 'base64').toString()
    // }

    const identifier = '' + userId;
    const currTime = Math.floor(Date.now() / 1000);
    const expireTime = currTime + expire;
    const sigDoc: { [key: string]: string | number } = {
      'TLS.ver': '2.0',
      'TLS.identifier': identifier,
      'TLS.sdkappid': sdkAppId,
      'TLS.time': currTime,
      'TLS.expire': expireTime,
    };

    let contentToBeSigned: BinaryLike = 'TLS.identifier:' + identifier + '\n';
    contentToBeSigned += 'TLS.sdkappid:' + sdkAppId + '\n';
    contentToBeSigned += 'TLS.time:' + currTime + '\n';
    contentToBeSigned += 'TLS.expire:' + expireTime + '\n';
    const hmac = crypto.createHmac('sha256', secretKey);
    sigDoc['TLS.sig'] = hmac.update(contentToBeSigned).digest('base64');
    const compressed = zlib.deflateSync(newBuffer(JSON.stringify(sigDoc))).toString('base64');
    return base64url.escape(compressed);
  }

}
