






import Component from 'vue-class-component';
import { Vue } from 'vue-property-decorator';

@Component({
  name: 'icon-clock',
})
export default class IconClock extends Vue {
}
